import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { trunc } from '../utils'
import { useNavigate, useParams } from "react-router-dom";
import { globalStore } from '../state/store';
import { Autocomplete, Avatar, Box, Chip, CircularProgress, Grid, IconButton, InputAdornment, 
         List, ListItem, ListItemAvatar, ListItemText, Pagination, PaginationItem, Rating,
         Snackbar, Stack, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { CanveoCircularProgress, ClauseLibraryList, CoreDrawer,
         DialogClause, DialogTemplate, Editor, FabStandard, Header, SelectLabels, ThumbAgr } from '../components';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCirclePlus, faFileLines, faFilter, faPen, faTag } from '@fortawesome/pro-solid-svg-icons';

import logo from '../assets/img/ct-logo.png'

import axios from 'axios';

const Templates = () => {

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const pageSizeTemplates = 8;
  const pageSizeTopics = 8;
  const navigate = useNavigate();
  const { tid } = useParams();

  const [state, dispatch] = useContext(globalStore);
  const [filters, setFilters] = React.useState({ agrTypeID: '', reference: '', labels: [] });
  const [filteredTemplates, setFilteredTemplates] = React.useState([]);
  const [filteredTopics, setFilteredTopics] = React.useState([]);

  const [curPageTemplates, setCurPageTemplates] = React.useState(1);
  const [curPageTopics, setCurPageTopics] = React.useState(1);
  const [dialogTemplateOpen, setDialogTemplateOpen] = React.useState(false);
  const [dialogClauseOpen, setDialogClauseOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [template, setTemplate] = React.useState(null);

  useEffect(() => { // Update the filteredTemplates array upon change of filters or Templates in state
    const ft = state.templates
      .filter((t) => t.orgID === state.org._id) // Filters out any recently "deleted_orgID" template versions
      .filter((t) => !state.templates.some((temp) => temp._id !== t._id && temp.blueprintID === t.blueprintID && (temp.active || (!t.active && temp.version > t.version))))
      .filter((t) => filters.agrTypeID === '' || t.agrTypeID === filters.agrTypeID) // FILTER: agr types
      .filter((t) => filters.reference !== undefined && t.reference.toLowerCase().includes(filters.reference.toLowerCase())) // FILTER: reference
      .filter((t) => filters.labels.length === 0 || filters.labels.every((fl) => t.labelIDs.includes(fl))) // FILTER: labels
      .sort((a, b) => (a.agrTitle > b.agrTitle) ? 1 : -1)

    setFilteredTemplates(ft)
    setCurPageTemplates(1)

  }, [state.templates, filters, tid])

  useEffect(() => {
    const ft = state.clauseTypes
      .filter((t) => t.active) // FILTER: labels
      .sort((a, b) => (a.name > b.name) ? 1 : -1)

    setFilteredTopics(ft)
    setCurPageTopics(1)
  }, [state.clauseTypes, dialogClauseOpen])

  useEffect(() => { // Pull the entire template (incl. content) when you're loading a new template page

    if(Boolean(tid) && tid.length > 10 && tid.length < 30) {
      setLoading(true)

      axios.get(state.settings.api + "template/" + tid)
      .then((resTemp) => {

        if(resTemp.data.success) {
          dispatch({ type: "INIT_WORKFLOWS", payload: resTemp.data.data.workflows })
          dispatch({ type: "INIT_PARAMS_DOC", payload: resTemp.data.data.params })
          setTemplate(resTemp.data.data.template)
          setLoading(false);

        } else { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to open the template" }); setLoading(false) }
      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to open the template" }); setLoading(false) })

    }

  }, [tid])

  const handleLabelSelect = (newLabels) => {
    let newLabs = []
    newLabels.forEach((nl) => {
      newLabs.push(nl._id);
    })
    
    setFilters({...filters, labels: newLabs})
  }

  const closeTemplateDialog = (action) => {
    if(['snackTemplateCreated'].includes(action)) {
      dispatch({ type: "NEW_SNACKBAR", payload: "The template was created" });

    }
    setDialogTemplateOpen(false)
  }

  const closeClauseTypeDialog = (action) => {
    if(['clauseTypeGuidanceCreated', 'clauseTypeGuidanceUpdated', 'clauseTypeDeleted', 
    'clauseLibUpdated', 'clauseLibDeleted'].includes(action)) {
      dispatch({ 
        type: "NEW_SNACKBAR", 
        payload: 
          ['clauseTypeGuidanceCreated'].includes(action) ?
              "The guidance was created" :
          ['clauseTypeGuidanceUpdated'].includes(action) ?
              "The guidance was updated" :
          ['clauseTypeDeleted'].includes(action) ? 
              "The clause topic was deleted" :
          ['clauseLibUpdated'].includes(action) ?
              "The clause library was updated" :
          ['clauseLibDeleted'].includes(action) ? 
              "The clause library item was deleted" :
              ""
      });
    }
    setDialogClauseOpen(null)
  }

  const handleEditClauseLibItem = (item) => {

    setDialogClauseOpen({type: 'editlibrary', clitem: item})

  }
  
  return (
    <div>

        {loading ?
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CanveoCircularProgress />
        </Grid>
        :
        // Template Editing Page
        !['main','library','topics'].includes(tid) && Boolean(template)/* && Boolean(template.content)*/ ?
          <Box sx={{ mt: 10, mb: 6, mx: 0 }}>
            <Editor 
            page="Template"
            template={template}
            />

            {/*}
            <Box sx={{mt:15, pl:15}}>
              {tid}<br/><br/>

              Click into template : will bring you to an Editor Screen, to consider:<br/>
              - Activation / Deactivate / Copy into new / Export / Delete Template
              - Version Mgt<br/>
              - Agr Type / Reference<br/>
              - Effective Date<br/>
              - Default Children (Exhs)
            </Box>
            */}

          </Box>
        : 
        // Default "Home" screen for Templates
        ['main', 'library', 'topics'].includes(tid) && !loading ?
        <Box sx={{display: 'flex', width: '100%', margin: '0px', padding: '0px' }}>
          <Header page={"Templates"} />
          <CoreDrawer page={"Templates"} active={['main'].includes(tid) ? "templates" : tid} variant={null} />

          {
          ['main'].includes(tid) ? 
          <Box sx={{ my: 10, mx: isSmUp ? 3 : 2, width: '100%' }}>

            <FabStandard
            click={e => setDialogTemplateOpen(true)}
            text="New"
            icon={faFileLines}
            />

            <Grid container direction="column" alignItems="center">
              <Grid item xs={12} style={{width: '100%', maxWidth: '980px'}}>
                <Box sx={{my: 7, width: '100%'}}>

                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="filter-agrType"
                        options={
                          state.agrTypes.filter((at) => at.active)
                          .filter((at) => state.templates.some((t) => t.agrTypeID === at._id))
                          .sort((a, b) => (a.fullName[0] > b.fullName[0]) ? 1 : -1)} // labels
                        onChange={(e, newVal) => setFilters({...filters, agrTypeID: newVal === null ? '' : newVal._id})}
                        getOptionLabel={(option) => trunc(option.fullName[0], 35)}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Agreement Type"
                            placeholder="Agreement Type..."
                            //sx={{width: '200px'}}
                            InputProps={
                            {
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faFilter} />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Reference"
                        placeholder="Reference..."
                        value={filters.reference}
                        onChange={e => setFilters({...filters, reference: e.target.value})}
                        fullWidth
                        //sx={{width: '200px'}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={faFilter} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <SelectLabels
                      adornIcon={faFilter}
                      handleLabelSelect={handleLabelSelect}
                      selectedIDs={filters.labels}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>

                {filteredTemplates.length > 0 ? 

                <Grid container direction="row" spacing={2} sx={{
                  [theme.breakpoints.only('xs')]: { width: '340px' },
                  [theme.breakpoints.only('sm')]: { width: '410px' },
                  [theme.breakpoints.up('md')]: { width: '880px' },
                }}>
                  {filteredTemplates
                  .slice((curPageTemplates - 1) * pageSizeTemplates, curPageTemplates * pageSizeTemplates)
                  .map((t) => {
                    t.agrStatus = t.active ? "Active" : "Draft";
                    return (
                      <Grid item key={t._id} xs={6} md={3}>
                      <ThumbAgr 
                      ag={t} 
                      actionReq={!t.active}
                      thumbClick={e => navigate('/templates/' + t._id)}
                      showLogo={state.org.logoURL}
                      primaryLegalName={t.reference}
                      secondaryLegalName={'Version ' + t.version}
                      isTemplate
                      />
                      </Grid>
                    )
                  })}
                </Grid>
                :
                <Box sx={{mx:5, my: 5}}>
                  <Typography align="center">No templates yet.</Typography>
                </Box>
                }

              </Grid>
              <Grid item sx={{ mt: 7}}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(filteredTemplates.length / pageSizeTemplates)}
                    defaultPage={1}
                    page={curPageTemplates}
                    onChange={(e, newVal) => setCurPageTemplates(newVal)}
                    siblingCount={0}
                    boundaryCount={2}
                    renderItem={(item) => (
                      <PaginationItem {...item} />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          :
          ['library'].includes(tid) ? 
          <Box sx={{ my: 17, mx: isSmUp ? 3 : 2, width: '100%' }}>

            <ClauseLibraryList
            type={'edit'}
            onSelect={handleEditClauseLibItem}
            />

          </Box>
          :
          ['topics'].includes(tid) ? 
          <Box sx={{ my: 10, mx: isSmUp ? 3 : 2, width: '100%' }}>

            <FabStandard
            click={e => setDialogClauseOpen({type: 'newtopic'})}
            text="New"
            icon={faTag}
            />
            
            <Grid container direction="column" alignItems="center">  
              <Grid item>

                {state.clauseTypes.filter((ct) => ct.active).length > 0 ? 
                <Grid container direction="row" sx={{mt: 2}}>
                  <List sx={{width: isMdUp ? '500px' : isSmUp ? '400px' : '300px'}}>
                  {filteredTopics
                  .slice((curPageTopics - 1) * pageSizeTopics, curPageTopics * pageSizeTopics)
                  .map((topic, i) => {
                    //t.agrStatus = t.active ? "Active" : "Draft";
                    let guidance = state.clauseTypeGuides.filter((ctg) => ctg.ctid === topic._id)[0]

                    return (
                      <ListItem
                      key={topic._id}
                      secondaryAction={
                          <Tooltip placement="right" title="Update topic guidance">
                          <IconButton edge="end" onClick={e => setDialogClauseOpen({...topic, type: 'updatetopic'})}>
                            <FontAwesomeIcon icon={faPen} style={{fontSize: '15px', padding: '5px'}} />
                          </IconButton>
                          </Tooltip>
                      }
                      >
                        <ListItemAvatar>
                          <Avatar src={topic.orgID ==='CANVEO' ? 'https://storage.googleapis.com/cvt-images/canveo_transp.png' : state.org.logoURL} 
                          sx={{backgroundColor: theme.palette.primary.contrastText}}
                          style={Boolean(guidance) && Boolean(guidance.guidance) ? {} : { filter: 'grayscale(100%)', opacity: '0.6' }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography style={{fontWeight: '700'}}>{topic.name}</Typography>}
                          secondary={Boolean(guidance) && Boolean(guidance.guidance) ? trunc(guidance.guidance,50) : '<none>'}
                        />
                      </ListItem>
                    )
                  })}
                  </List>
                </Grid>
                :
                <Box sx={{mx:5, my: 5}}>
                  <Typography align="center">No topics yet.</Typography>
                </Box>
                }

              </Grid>
              <Grid item sx={{ mt: 5}}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(filteredTopics.length / pageSizeTopics)}
                    defaultPage={1}
                    page={curPageTopics}
                    onChange={(e, newVal) => setCurPageTopics(newVal)}
                    siblingCount={0}
                    boundaryCount={2}
                    renderItem={(item) => (
                      <PaginationItem {...item} />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

          </Box>
          : ''
          }
        </Box>
        :
        ''}

      <DialogTemplate
        open={Boolean(dialogTemplateOpen)}
        closeDialog={closeTemplateDialog}
      />

      <DialogClause
        open={Boolean(dialogClauseOpen)}
        details={dialogClauseOpen}
        closeDialog={closeClauseTypeDialog}
      />

    </div>
  );
};
export default Templates;