export function hasMark(node, markType) {
    let isMark = false;

    if(['mark'].includes(node.getType()) && 
    node.getIDs().some((id) => 
        (['publicComment'].includes(markType) && id.startsWith("cp_"))/* ||
        (['internalComment'].includes(markType) && id.startsWith("cp") && props.partyID.substring(5) === id.substring(2, id.indexOf('_')))*/
    )) {
      isMark = true;

    } else if(Boolean(node.__children) && node.__children.length > 0) {
      node.getChildren().forEach((n) => {
        if(hasMark(n, markType)) {
          isMark = true;
        }
      })
    }
    return isMark;
}