const subsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_SUBS":
            return { subs: action.payload };
        case "ADD_SUB":
            return {
                subs: [...state.subs, action.payload]
            };
        case "UPDATE_SUB":
            let newVal = action.payload;
            let newSubs = state.subs;
            let idx = state.subs.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newSubs[idx] = newVal;
            }
            return { subs: newSubs };
        default:
            return { subs: state.subs }
    }
};
  
export default subsReducer