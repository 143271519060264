export const currencies = [ 
    { iso: 'USD', symbol: '$', desc: 'US Dollar (USD)' },
    { iso: 'EUR', symbol: '€', desc: 'Euro (EUR)' },
    { iso: 'JPY', symbol: '¥', desc: 'Japanese Yen (JPY)' },
    { iso: 'GBP', symbol: '£', desc: 'Great British Pound (GBP)' },
    { iso: 'AUD', symbol: 'AUD', desc: 'Australian Dollar (AUD)' },
    { iso: 'CAD', symbol: 'CAD', desc: 'Canadian Dollar (CAD)' },
    { iso: 'CHF', symbol: 'CHF', desc: 'Swiss Franc (CHF)' },
    { iso: 'CNY', symbol: 'CNY', desc: 'Chinese Renminbi (CNY)' },
    { iso: 'SEK', symbol: 'SEK', desc: 'Swedish Krona (SEK)' },
    { iso: 'NZD', symbol: 'NZD', desc: 'New Zealand Dollar (NZD)' },
    { iso: 'MXN', symbol: 'MXN', desc: 'Mexican Peso (MXN)' },
    { iso: 'SGD', symbol: 'SGD', desc: 'Singapore Dollar (SGD)' },
    { iso: 'HKD', symbol: 'HKD', desc: 'Hong Kong Dollar (HKD)' },
    { iso: 'NOK', symbol: 'NOK', desc: 'Norwegian Krone (NOK)' },
    { iso: 'KRW', symbol: 'KRW', desc: 'South Korean Won (KRW)' },
    { iso: 'TRY', symbol: 'TRY', desc: 'Turkish Lira (TRY)' },
    { iso: 'INR', symbol: 'INR', desc: 'Indian Rupee (INR)' },
    { iso: 'BRL', symbol: 'BRL', desc: 'Brazilian Real (BRL)' },
    { iso: 'ZAR', symbol: 'ZAR', desc: 'South African Rand (ZAR)' },
    { iso: 'RUB', symbol: 'RUB', desc: 'Russian Ruble (RUB)' },
    { iso: 'ARS', symbol: 'ARS', desc: 'Argentinian Peso (ARS)' },
];