import React, { useEffect } from 'react';
import theme from '../../theme/theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, Box, Button, Grid, InputAdornment, FormControl, FormControlLabel, 
         RadioGroup, Radio, TextField, Typography } from '@mui/material';
import { countries } from '../../assets/static'
//import { countryToFlag } from '../../utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCity, faSign, faLandmark, faMapSigns, faRoad, faUser, 
         faEnvelope, faHashtag, faMapMarkedAlt, faChevronCircleDown, faChevronCircleUp } from "@fortawesome/pro-solid-svg-icons";

const axios = require('axios');

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined' && isoCode !== undefined
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const EntityForm = (props) => {

  //const useStyles = makeStyles(theme => ({
  const styles = {
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    showLink: {
      margin: '-10px 0px -5px 0px',
      fontWeight: '600',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    mapIcon: {
      color: theme.palette.secondary.main,
      marginRight: '12px',
    }
  }
  //));
  //const classes = useStyles();

  const [country, setCountry] = React.useState(null)
  //const [countryError, setCountryError] = React.useState('');
  //const [userError, setUserError] = React.useState('');
  const [errMsg, setErrMsg] = React.useState(null)
  const [entityCategory, setEntityCategory] = React.useState(null);
  const [hideAddress2, setHideAddress2] = React.useState(props.initialValues.address2 !== undefined && props.initialValues.address2 !== null && props.initialValues.address2 !== '' ? false : true);
  const [showAddressFields, setShowAddressFields] = React.useState(false)

  //const sm = props.cardUpdating ? 12 : 8
  //const md = props.cardUpdating ? 12 : 6

  useEffect(() => { // re-initialize country upon initialValues change
    setCountry(countries.filter((c) => c.code === props.initialValues.country)[0] !== undefined ? countries.filter((c) => c.code === props.initialValues.country)[0] : null)
    setEntityCategory(props.initialValues.entityCategory !== undefined ? props.initialValues.entityCategory : 'Company')
  }, [props.initialValues])

  const handleCategoryChange = (event) => {
    setEntityCategory(event.target.value);
  };

  const handleCountryChange = (value) => {
    //setTaxIDs([]) // clear the tax ids
    setCountry(value);
  }

  /*
  const handleTaxIDsChange = (type, value) => {

    let tids = taxids
    if(tids.filter((t) => t.type === type)[0] === undefined) {
      tids.push({type: type, value: value});
    } else {
      let idx = tids.findIndex((t) => t.type === type)
      tids[idx] = {type: type, value: value};
    }

    setTaxIDs([...tids])
  }*/

  /*
  const handleTaxIDEval = (type, value) => {
    let newVal = value;
    newVal = newVal.toUpperCase() // alpha to Upper
    newVal = newVal.replace(/\s/g, '') // replace any spaces
    if(taxes.filter((t) => t.type === type)[0] !== undefined) {
      let taxFormat = taxes.filter((t) => t.type === type)[0].ex
      if(taxFormat.match(/^([A-Z]{2})/) && !newVal.match(/^([A-Z]{2})/) && taxFormat.length - 2 === newVal.length) {
        newVal = taxFormat.substr(0,2) + newVal;
      }
    }

    if(newVal !== value) {
      let tids = taxids
      let idx = tids.findIndex((t) => t.type === type)
      tids[idx] = {type: type, value: newVal};
      setTaxIDs([...tids])
    }
  }*/

  let valSchema = Yup.object({ // BASE SCHEMA
    shortName: Yup.string()
      .min(2, 'The company name is too short')
      .max(40, 'The company name is too long')
      .required('Company name is required'),
    legalName: Yup.string()
      .min(4, 'The legal name is too short')
      .max(50, 'The legal name is too long')
      .required('Legal name is required'),
    streetAndNo: Yup.string()
      .min(4, 'The address line is too short')
      .max(50, 'The address is too long')
      .required('Address is required'),
    address2: Yup.string()
      .min(3, 'The address line is too short')
      .max(50, 'The address is too long'),
    city: Yup.string()
      .min(3, 'The city is too short')
      .max(40, 'The city is too long')
      .required('City is required'),
    zip: Yup.string()
      .min(3, 'The ZIP is too short')
      .max(15, 'The ZIP is too long')
      .required('ZIP is required'),
    state: Yup.string()
      .min(1, 'The State is too short')
      .max(30, 'The State is too long')
  })

/*
  let valSchema = Yup.object({
    shortName: Yup.string()
      .min(2, 'The company name is too short')
      .max(40, 'The company name is too long')
      .required('Company name is required'),
    legalName: Yup.string()
      .min(4, 'The legal name is too short')
      .max(50, 'The legal name is too long')
      .required('Legal name is required'),
    streetAndNo: Yup.string()
      .min(4, 'The address line is too short')
      .max(50, 'The address is too long')
      .required('Address is required'),
    address2: Yup.string()
      .min(3, 'The address line is too short')
      .max(50, 'The address is too long'),
    city: Yup.string()
      .min(3, 'The city is too short')
      .max(40, 'The city is too long')
      .required('City is required'),
    zip: Yup.string()
      .min(3, 'The ZIP is too short')
      .max(15, 'The ZIP is too long')
      .required('ZIP is required'),
    state: Yup.string()
      .min(1, 'The State is too short')
      .max(30, 'The State is too long')
  })*/
  if(entityCategory === 'Person') { 

    valSchema = valSchema.shape({ // TWEAK SCHEMA FOR PERSON
      firstName:  Yup.string()
        .min(2, 'The first name is too short')
        .max(35, 'The first name is too long')
        .required('First name is required'),
      lastName: Yup.string()
        .min(2, 'The last name is too short')
        .max(40, 'The last name is too long')
        .required('Last name is required'),
      email:  Yup.string()
        .email('Invalid email address')
        .required('Email address is required'),
      title: Yup.string()
        .min(2, 'The title is too short')
        .max(40, 'The title is too long')
        .required('A title is required'),
      shortName: Yup.string().notRequired(),
      legalName: Yup.string().notRequired(),
    })
  }
  if(props.optionalAddress) {  // TWEAK SCHEMA AND MAKE ADDRESS OPTIONAL

    valSchema = valSchema.shape({ // TWEAK SCHEMA FOR PERSON
      streetAndNo: Yup.string().notRequired(),
      address2: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      zip: Yup.string().notRequired(),
    })

  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      entityCategory: entityCategory,
      shortName: props.initialValues.shortName,
      legalName: props.initialValues.legalName,
      streetAndNo: props.initialValues.streetAndNo,
      address2: props.initialValues.address2,
      city: props.initialValues.city,
      zip: props.initialValues.zip,
      state: props.initialValues.state,
      firstName: props.initialValues.firstName,
      lastName: props.initialValues.lastName,
      email: props.initialValues.email,
      title: props.initialValues.title,
    },
    validationSchema: valSchema,
    onSubmit: values => {
      setErrMsg(null);
      //setCountryError('');
      //setUserError('');
      if((country !== null && country.code !== undefined && country.code.length === 2) || props.isSignup){
        values.country = props.isSignup ? null : country.code
        values.entityCategory = entityCategory
        values.displayName = (['Person'].includes(entityCategory) || props.isSignup) ? (values.firstName + ' ' + values.lastName) : ''
        values.shortName = (['Person'].includes(entityCategory)) ? values.displayName : values.shortName
        values.legalName = (['Person'].includes(entityCategory)) ? values.displayName : values.legalName


        // TODO: If Person and creating NEW = > check the email address doesn't yet exist
        //['Person'].includes(entityCategory)
        props.handleSubmit(values);

        /*
        if(entityCategory === "Person" && ['newAgr'].includes(props.type)) { // First check that the email address not already exists

          axios.get('/user/protect/user/mail/' + values.email)
          .then(function(res) {

            if(res.data.data !== undefined && res.data.data !== null && res.data.data.role === 'Counterparty') {
              setUserError("This user is already setup for a different organisation, please contact canveo support for help.")
            } else if (res.data.data !== undefined && res.data.data !== null) {
              setUserError("This user is setup for a Canveo customer, please contact canveo support for help.")
            } else { // User not found -

              props.handleSubmit(values);

            }

          }).catch(function(err) { console.log(err) }) 

        } else {
          props.handleSubmit(values);
        }*/
      } else {
        setErrMsg('Please select a country');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>

      {// Show Error Msg if applicable
      errMsg !== null && errMsg !== '' ?
      <Grid item container xs={12} justifyContent="center">
        <Box mt={2} mb={2}>
          <Typography color="error">{errMsg}</Typography>
        </Box>
      </Grid>
      :''}

      {
      //!['new', 'new-client', 'update', 'update-client','updatecp','signup'].includes(props.type) && 
      !props.hideFields.includes('entityCategory') ?
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <FormControl component="fieldset">
          <RadioGroup row name="entityCategory" value={entityCategory} onChange={handleCategoryChange}>
          <FormControlLabel value="Company" control={<Radio color="primary" />} label="Company" />
            <FormControlLabel value="Person" control={<Radio color="primary" />} label="Person" />
          </RadioGroup>
        </FormControl>
      </Grid>
      : '' }

      {(['Person'].includes(entityCategory) || props.isSignup) && !props.hideFields.includes('firstName') ?

      <Grid item container xs={12}>
        <Grid item container xs={6} style={{paddingRight: '20px'}}>
          <TextField
              required
              //size={props.cardUpdating ? "small" : "medium"}
              disabled={props.disableFields.includes('firstName')}
              //style={{backgroundColor: theme.palette.primary.contrastText}}
              fullWidth
              variant="outlined"
              id="firstName"
              label={"First Name(s)"}
              placeholder={"e.g. Jonathan Robert"}
              name="firstName"
              autoComplete="firstName"
              {...formik.getFieldProps('firstName')}
              error={formik.touched.firstName && formik.errors.firstName ? true : false}
              helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faUser} />
                  </InputAdornment>
                  ),
              }}
          />
        </Grid>
        <Grid item container xs={6}>
          <TextField
            required
            //size={props.cardUpdating ? "small" : "medium"}
            disabled={props.disableFields.includes('lastName')}
            //style={{backgroundColor: theme.palette.primary.contrastText}}
            fullWidth
            variant="outlined"
            id="lastName"
            label={"Last Name"}
            placeholder={"e.g. Williams"}
            name="lastName"
            autoComplete="lastName"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName ? true : false}
            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
          />
        </Grid>
      </Grid>
      : ''}

      {entityCategory === 'Company' && !props.hideFields.includes('shortName') ?
      <Grid item container xs={12}>
        <TextField
            required
            //size={props.cardUpdating ? "small" : "medium"}
            disabled={props.disableFields.includes('shortName')}
            //style={{backgroundColor: theme.palette.primary.contrastText}}
            fullWidth
            variant="outlined"
            id="shortName"
            label={"Company Name"}
            placeholder={"Company name"}
            name="shortName"
            autoComplete="sname"
            {...formik.getFieldProps('shortName')}
            error={formik.touched.shortName && formik.errors.shortName ? true : false}
            helperText={formik.touched.shortName && formik.errors.shortName ? formik.errors.shortName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faBuilding}/>
                </InputAdornment>
                ),
            }}
        />
      </Grid>
      :''}

      {entityCategory === 'Company' && !props.hideFields.includes('legalName') ?
      <Grid item container xs={12}>
        <TextField
            required
            //size={props.cardUpdating ? "small" : "medium"}
            disabled={props.disableFields.includes('legalName')}
            fullWidth
            variant="outlined"
            id="legalName"
            label={"Legal Name"}
            placeholder={"Legal entity name (e.g. ABC LLC)"}
            name="legalName"
            autoComplete="legalname"
            {...formik.getFieldProps('legalName')}
            error={formik.touched.legalName && formik.errors.legalName ? true : false}
            helperText={formik.touched.legalName && formik.errors.legalName ? formik.errors.legalName : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLandmark} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      :''}


    
      {(['Person'].includes(entityCategory) || props.isSignup) && !props.hideFields.includes('email') ?
      <Grid item container xs={12}>
        <TextField
            required
            //size={props.cardUpdating ? "small" : "medium"}
            disabled={props.disableFields.includes('email')}
            fullWidth
            variant="outlined"
            id="email"
            label={"Email address"}
            placeholder={"name@domain.com"}
            name="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      :''}

      {(['Person'].includes(entityCategory) || props.isSignup) && !props.hideFields.includes('title') ?
      <Grid item container xs={12}>
      <TextField
          required
          //size={props.cardUpdating ? "small" : "medium"}
          disabled={props.disableFields.includes('title')}
          fullWidth
          variant="outlined"
          id="title"
          label={"Title"}
          placeholder={"Corporate title"}
          name="title"
          autoComplete="title"
          {...formik.getFieldProps('title')}
          error={formik.touched.title && formik.errors.title ? true : false}
          helperText={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
          InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <FontAwesomeIcon icon={faLandmark} />
              </InputAdornment>
              ),
          }}     
      />
      </Grid>
      :''}

      {props.optionalAddress ?
        <Button variant="text" disableElevation sx={{mt:2}}
        onClick={e => setShowAddressFields(!showAddressFields)}>
          {showAddressFields ? "Hide" : "Complete"} address&nbsp;&nbsp;
          <FontAwesomeIcon icon={showAddressFields ? faChevronCircleUp : faChevronCircleDown} />
        </Button>
      :''}

      {!props.hideFields.includes('streetAndNo') && (!props.optionalAddress || showAddressFields) ?
      <Grid item container xs={12}>
        <TextField
            required
            //size={props.cardUpdating ? "small" : "medium"}
            disabled={props.disableFields.includes('streetAndNo')}
            fullWidth
            variant="outlined"
            id="streetAndNo"
            label="Address Line 1"
            placeholder="Street and number"
            name="streetAndNo"
            autoComplete="streetAndNo"
            {...formik.getFieldProps('streetAndNo')}
            error={formik.touched.streetAndNo && formik.errors.streetAndNo ? true : false}
            helperText={formik.touched.streetAndNo && formik.errors.streetAndNo ? formik.errors.streetAndNo : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faRoad} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      :''}

      {!props.hideFields.includes('address2') && (!props.optionalAddress || showAddressFields) ?
      <Grid item container xs={12}>
        {hideAddress2 ? 
        <Box width="100%">
          <Typography variant="body2" align="right" onClick={e => setHideAddress2(false)} style={styles.showLink}>+ Address line 2</Typography>
        </Box>
        :
        <TextField
            disabled={props.disableFields.includes('address2')}
            //size={props.cardUpdating ? "small" : "medium"}
            fullWidth
            variant="outlined"
            id="address2"
            label="Address Line 2"
            placeholder="Address line 2"
            name="address2"
            autoComplete="address2"
            {...formik.getFieldProps('address2')}
            error={formik.touched.address2 && formik.errors.address2 ? true : false}
            helperText={formik.touched.address2 && formik.errors.address2 ? formik.errors.address2 : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSign} />
                </InputAdornment>
                ),
            }}     
        />
        }
      </Grid>
      :''}

      {!props.hideFields.includes('city') && (!props.optionalAddress || showAddressFields) ?
      <Grid item container xs={12}>
        <TextField
            required
            //size={props.cardUpdating ? "small" : "medium"}
            disabled={props.disableFields.includes('city')}
            fullWidth
            variant="outlined"
            id="city"
            label="City"
            placeholder="City"
            name="city"
            autoComplete="city"
            {...formik.getFieldProps('city')}
            error={formik.touched.city && formik.errors.city ? true : false}
            helperText={formik.touched.city && formik.errors.city ? formik.errors.city : ''}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FontAwesomeIcon icon={faCity} />
                </InputAdornment>
                ),
            }}     
        />
      </Grid>
      :''}

      {!props.hideFields.includes('zip') && (!props.optionalAddress || showAddressFields) ?
      <Grid item container xs={12}>
        <Grid direction="row" container>
          <Grid item xs={6} style={{paddingRight: '8px'}}>
            <TextField
              required
              //size={props.cardUpdating ? "small" : "medium"}
              disabled={props.disableFields.includes('zip')}
              fullWidth
              variant="outlined"
              id="zip"
              label="ZIP code"
              placeholder="ZIP code"
              name="zip"
              autoComplete="zip"
              {...formik.getFieldProps('zip')}
              error={formik.touched.zip && formik.errors.zip ? true : false}
              helperText={formik.touched.zip && formik.errors.zip ? formik.errors.zip : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faMapSigns} />
                  </InputAdornment>
                  ),
              }}     
            />
          </Grid>
          <Grid item xs={6} style={{paddingLeft: '8px'}}>
            <TextField
              //size={props.cardUpdating ? "small" : "medium"}
              disabled={props.disableFields.includes('state')}
              fullWidth
              variant="outlined"
              id="state"
              label="State/Province"
              placeholder="State / Province / Region"
              name="state"
              autoComplete="state"
              {...formik.getFieldProps('state')}
              error={formik.touched.state && formik.errors.state ? true : false}
              helperText={formik.touched.state && formik.errors.state ? formik.errors.state : ''}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                  </InputAdornment>
                  ),
              }}     
            />
          </Grid>
        </Grid>
      </Grid>
      :''}

      {!props.hideFields.includes('country') ?
      <Grid item container xs={12}>

        <Autocomplete
        sx={{ width: '100%' }}
        disabled={props.disableFields.includes('country')}
        options={countries}
        value={country}
        autoHighlight
        onChange={(e, value) => handleCountryChange(value)}
        getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
        renderOption={(props, option) => (
            <Box component="li" sx={{ '& > span': { mr: 2, flexShrink: 0 } }} {...props}>
            <span>{countryToFlag(option.code)}</span>
            {option.label} ({option.code})
            </Box>
        )}
        renderInput={(params) => (
            <TextField {...params} 
            label={"Country"}
            variant="outlined"
            inputProps={{ // to avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious
              ...params.inputProps,
              autoComplete: 'new-password',
            }} />
        )}
        />

      </Grid>
      :''}

      {/*(['signup'].includes(props.type) || props.type === 'billing') && country !== null && getTaxTypesByCountry(country.code).length > 0 ?
      
        getTaxTypesByCountry(country.code).map((tt,i) => {
          return (
            <Grid item container xs={12} sm={sm} md={md} key={tt.type}>
              <TextField
                  fullWidth
                  size={props.cardUpdating ? "small" : "medium"}
                  variant="outlined"
                  value={taxids.filter((tid) => tid.type === tt.type)[0] !== undefined ? taxids.filter((tid) => tid.type === tt.type)[0].value : ''}
                  onChange={(e) => handleTaxIDsChange(tt.type, e.target.value)}
                  //onBlur={(e) => handleTaxIDEval(tt.type, e.target.value)}
                  label={tt.type.substring(3).toUpperCase() + " ID"}
                  placeholder={"Tax number (optional)"}
                  helperText={"Format as: " + tt.ex}
                  InputProps={{
                      startAdornment: (
                      <InputAdornment position="start">
                          <FontAwesomeIcon icon={faHashtag} color={theme.palette.grey[600]} />
                      </InputAdornment>
                      ),
                  }}
              />
          </Grid>
          );
        })

      :''*/}

      <Grid item container xs={12}>
        <Button
          fullWidth
          disableElevation
          //size={props.cardUpdating ? "small" : "medium"}
          sx={{ fontSize: '17px', fontWeight: '600', padding: '7px 20px'}}
          type={"submit"}
          variant="contained"
          color="primary"
          disabled={props.submitting/* ||
          //countryError !== '' || userError !== '' || // uncommenting will lock the form after the error comes up
          (['newAgr'].includes(props.type) && 
          ((entityCategory === 'Person' && (
            formik.values.firstName === '' ||
            formik.values.lastName === '' ||
            formik.values.email === '' ||
            formik.values.streetAndNo === '' ||
            formik.values.city === '' ||
            formik.values.zip === '' ||
            country === '' || country === null
           )) 
           ||
           (entityCategory !== 'Person' && (
            formik.values.shortName === '' ||
            formik.values.legalName === '' ||
            formik.values.streetAndNo === '' ||
            formik.values.city === '' ||
            formik.values.zip === '' ||
           country === '' || country === null))))*/}
          >{props.buttonName}
        </Button>
      </Grid>
    </Grid>

    </form>
  );
};

export default EntityForm