import { $isElementNode, $isRangeSelection, $isTextNode, ElementNode, $getSelection } from 'lexical';
import { $createRedlineNode } from "./RedlineNode";
import { addClassNamesToElement, removeClassNamesFromElement } from '@lexical/utils';

export class ClauseNode extends ElementNode {
  __clauseTypes;
  __workflows;
  __libIDs;
  __filter;
  __lock;

  static getType() {
    return 'clause';
  }

  static clone(node) {
    return new ClauseNode(
      Array.from(node.__clauseTypes), 
      Array.from(node.__workflows), 
      Array.from(node.__libIDs), 
      node.__filter, 
      node.__lock,
      node.__key
    );
  }

  constructor(clauseTypes, workflows, libIDs, filter, lock, key) {
    super(key);
    this.__clauseTypes = clauseTypes || [];
    this.__workflows = workflows || [];
    this.__libIDs = libIDs || [];
    this.__filter = filter;
    this.__lock = lock;
  }

  static importJSON(serializedNode) {

    //clauseTypes, workflows, libIDs, filter, lock
    const node = $createClauseNode(serializedNode);

    //node.setFormat(serializedNode.format);
    //node.setDetail(serializedNode.detail);
    //node.setMode(serializedNode.mode);
    //node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'clause',
      clauseTypes: this.getClauseTypes(),
      workflows: this.getWorkflows(),
      libIDs: this.getLibIDs(),
      filter: this.getFilter(),
      lock: this.getLock(),
      version: 1,
    };
  }

  createDOM() {
    const element = document.createElement('div');
    return element;
  }

  updateDOM(prevNode, element, config ) {

    element.style = {}

    if(this.__filter === "in") {
      element.style.padding = "30px 35px";
      element.style.margin = "15px 0px";
      element.style.boxShadow = "rgba(0, 0, 0, 0.08) 0px 3px 24px 0px";
      element.style.borderRadius = "20px";
    } else if(this.__filter === "out") {
      element.style.display = "none";
    //} else if(this.__filter === "none") { // TEMP: TODO DELETE
      //element.style.border = "1px solid #ff0000"; // TEMP: TODO DELETE
    }
    return false;
  }

  importDOM() {
    return null;
  }

  isInline() {
    return false;
  }

  getFilter() {
    const self = this.getLatest();
    return self.__filter;
  }

  changeFilter(newFilter) { // 'none', 'in', 'out'
    const self = this.getWritable();
    self.__filter = newFilter;
  }

  getLock() {
    const self = this.getLatest();
    return self.__lock;
  }

  changeLock(newLock) { // 'none', 'own', 'cp', 'all'
    const self = this.getWritable();
    self.__lock = newLock;
  }

  hasClauseType(ctid) {
    const clauseTypes = this.getClauseTypes();
    for (let i = 0; i < clauseTypes.length; i++) {
      if (ctid === clauseTypes[i]) {
        return true;
      }
    }
    return false;
  }

  getClauseTypes() {
    const self = this.getLatest();
    return $isClauseNode(self) ? self.__clauseTypes : [];
  }

  assignClauseTypes(ctids) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      self.__clauseTypes = ctids
    }
  }

  addClauseType(ctid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const clauseTypes = self.__clauseTypes;
      self.__clauseTypes = clauseTypes;
      for (let i = 0; i < clauseTypes.length; i++) {
        // If we already have it, don't add again
        if (ctid === clauseTypes[i]) return;
      }
      clauseTypes.push(ctid);
    }
  }

  deleteClauseType(ctid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const clauseTypes = self.__clauseTypes;
      self.__clauseTypes = clauseTypes;
      for (let i = 0; i < clauseTypes.length; i++) {
        if (ctid === clauseTypes[i]) {
          clauseTypes.splice(i, 1);
          return;
        }
      }
    }
  }

  hasWorkflow(wfid) {
    const workflows = this.getWorkflows();
    for (let i = 0; i < workflows.length; i++) {
      if (wfid === workflows[i]) {
        return true;
      }
    }
    return false;
  }

  getWorkflows() {
    const self = this.getLatest();
    return $isClauseNode(self) ? self.__workflows : [];
  }

  assignWorkflows(wfs) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      self.__workflows = wfs
    }
  }

  addWorkflow(wfid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const workflows = self.__workflows;
      self.__workflows = workflows;
      for (let i = 0; i < workflows.length; i++) {
        // If we already have it, don't add again
        if (wfid === workflows[i]) return;
      }
      workflows.push(wfid);
    }
  }

  deleteWorkflow(wfid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const workflows = self.__workflows;
      self.__workflows = workflows;
      for (let i = 0; i < workflows.length; i++) {
        if (wfid === workflows[i]) {
          workflows.splice(i, 1);
          return;
        }
      }
    }
  }

  hasLibID(lid) {
    const libIDs = this.getLibIDs();
    for (let i = 0; i < libIDs.length; i++) {
      if (lid === libIDs[i]) {
        return true;
      }
    }
    return false;
  }

  getLibIDs() {
    const self = this.getLatest();
    return $isClauseNode(self) ? self.__libIDs : [];
  }

  addLibID(lid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const libIDs = self.__libIDs;
      self.__libIDs = libIDs;
      for (let i = 0; i < libIDs.length; i++) {
        // If we already have it, don't add again
        if (lid === libIDs[i]) return;
      }
      libIDs.push(lid);
    }
  }

  deleteLibID(lid) {
    const self = this.getWritable();
    if ($isClauseNode(self)) {
      const libIDs = self.__libIDs;
      self.__libIDs = libIDs;
      for (let i = 0; i < libIDs.length; i++) {
        if (lid === libIDs[i]) {
          libIDs.splice(i, 1);
          return;
        }
      }
    }
  }
}

export function $createClauseNode({clauseTypes, workflows, libIDs, filter, lock}) {
  return new ClauseNode(clauseTypes, workflows, libIDs, filter, lock);
}

export function $isClauseNode(node) {
  return node instanceof ClauseNode;
}