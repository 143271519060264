import React, { useEffect, useContext } from 'react';
import { globalStore } from '../state/store';
import { Avatar, Autocomplete, Box, TextField } from '@mui/material';
import theme from '../theme/theme';
import { trunc } from '../utils'; 

export default function SelectUserForOrg(props) {

  const [state] = useContext(globalStore);
  const [userOptions, setUserOptions] = React.useState([])

  useEffect(() => {

    // TODO: Pull from online - search as you type
    if(state.user.orgID === props.orgID) {
      setUserOptions(
        state.users
        .filter((u) => u.active)
        .filter((u) => !props.hiddenUsers.some((hu) => hu._id === u._id))
      );
    } else {
      setUserOptions(
        props.cpUsers
        .filter((u) => u.active)
        .filter((u) => !props.hiddenUsers.some((hu) => hu._id === u._id))
      );
    }
  }, [])

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      options={userOptions}
      onChange={(e, newVal) => props.handleSelectUser(newVal)}
      autoHighlight
      getOptionLabel={(option) => option.displayName}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {trunc(option.displayName, 20)}
          {/*
          <Avatar src={option.photoURL} style={{marginRight: '10px'}} />
          {trunc(option.displayName, 15)}
          <span style={{fontSize: '13px', color: theme.palette.grey[600], marginLeft: '10px'}}>{trunc(option.email, 15)}</span>
          */}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search and select user..."
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}