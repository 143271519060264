import React, { useContext, useRef, useEffect } from 'react';
import theme from '../theme/theme';
import { globalStore } from '../state/store';
import { countryToFlag } from '../utils';
import { Autocomplete, Box, Button, CircularProgress, 
         Dialog, Fade, Grid, InputAdornment, TextField, Typography,
         createFilterOptions, useMediaQuery, styled } from '@mui/material';
//import { EntityForm } from './forms';
import { DialogEntity } from '.'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser, faProjectDiagram, faSearch, faUniversity } from '@fortawesome/pro-solid-svg-icons';
import { faHubspot, faSalesforce } from '@fortawesome/free-brands-svg-icons'

import countries from '../assets/static/countries';

import defaultLogo from '../assets/img/defaultlogo.png';
import defaultUser from '../assets/img/defaultuser.png';
import hubspot_icon from '../assets/img/hubspot_icon.png';
import salesforce_icon from '../assets/img/salesforce_icon.png';

const MyStyledButton = styled('button')((props) => ({
    backgroundColor: props.myBackgroundColor,
}));

const CssTextField = styled(TextField)({
  color: theme.palette.grey[800],
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '20px',
    padding: '13px 10px',
    '& fieldset': {
      borderColor: 'transparent',
      //boxShadow: 'rgba(0, 0, 0, 0.10) 0px 3px 34px 0px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[800],
      //boxShadow: 'rgba(0, 0, 0, 0.20) 0px 3px 34px 0px',
    },
    '&.Mui-focused fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      //boxShadow: 'rgba(0, 0, 0, 0.20) 0px 3px 34px 0px',
    },
  },
});

export default function SelectOrCreateCpty(props) {

    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const styles = {
        networkIcon: {
            color: theme.palette.primary.main,
            fontSize: '11px',
            padding: '0px 15px 0px 20px'
        },
        cptyIcon: {
            color: theme.palette.secondary.main,
            fontSize: '11px',
            padding: '0px 15px 0px 20px'
        },
        cptySpan: {
            fontSize: '12px',
            color: theme.palette.secondary.main,
        },
        networkSpan: {
            fontSize: '12px',
            color: theme.palette.primary.main,
        },
        boxResizing: {
            width: isMdUp ? '500px' : '320px'
        },
        boxHidden: {
            visible: 'hidden',
            height: '0px',
            width: '0px',
            position: 'absolute',
            left: '0px',
            top: '0px',
            zIndex: '-1000'
        }
    }

    const entityInputRef = useRef(null);

    const [state, dispatch] = useContext(globalStore);
    const [loading, setLoading] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState(null)
    const [cptyEntOptions, setCptyEntOptions] = React.useState([]);
    const [orgOptions, setOrgOptions] = React.useState([])
    const [activeCptyOrg, setActiveCptyOrg] = React.useState(null)
    const [activeCptyEnt, setActiveCptyEnt] = React.useState(null)
    const [activePerson, setActivePerson] = React.useState(null)
    const [integrationSearch, setIntegrationSearch] = React.useState({platform: null, query: ''})
    const [integrationSearchResults, setIntegrationSearchResults] = React.useState(null)

    const [dialogEntityOpen, setDialogEntityOpen] = React.useState(null)

    const filter = createFilterOptions();

    useEffect(() => {
        if(state.cpents.length > 0) {
            let oos = []
            state.cpents.forEach((cpe) => {
                if(!oos.some((o) => o._id === cpe.orgID)) {
                    oos.push({
                        _id: cpe.orgID,
                        shortName: cpe.shortName,
                        logoURL: cpe.logoURL,
                        entityCategory: cpe.entityCategory,
                    })
                }
            })
            setOrgOptions(oos)
        }
    }, [state.cpents])
    
    const handleCptyOrgChange = (value) => { // Changing the ORG for the Counterparty

        console.log("value", value)

        // Start with creating of array "cptyEntOptions", basically the entities for that Org that can be selected
        let eos = []
        if(value !== null && !value.isNetwork && value._id !== undefined) {
            // If it's a non-network ORG - then filter only the available entities for that ORG
            eos = state.cpents.filter((e) => e.orgID === value._id)
            setCptyEntOptions(eos)

        } else if (value !== null && value.isNetwork && value._id !== undefined) { 
            // If it's a network ORG - then pull all entities for the ORG
            /*
            axios.get('/entity/subsidiaries/' + value._id)
            .then(function(res) {
                setCptyEntOptions(res.data.data.filter((e) => e.isLive))
                if(res.data.data.filter((e) => e.isLive).length === 1) { setActiveCptyEnt(res.data.data.filter((e) => e.isLive)[0]) }
            }).catch(function(err) { 
                setErrMsg("Error fetching entities for network org")
                setLoading(false)
            })*/
        
        } else if(value !== undefined && value !== null && value.toBeCreated) { 
            // if creating a new ORG, reset the ENT (if applicable))
            setActiveCptyEnt(null) 
            setDialogEntityOpen({type: 'newcp', ent: { shortName: value.shortName }})

        } else if (value === null) {
            // If no ORG is selected, then there are no cptyEntOptions
            setActiveCptyEnt(null)
            //setActiveOwnEnt({ shortName: props.org.shortName, logoURL: props.org.logoURL })
            setCptyEntOptions([])
        }

        if(eos.length === 1) {  // If you have only one possible entity - then auto-select
            setActiveCptyEnt(eos[0]); 
            // If the only entity is a person - then also pull the person(user) detail
            if(eos[0].entityCategory === 'Person'){ 
                /*
                axios.get('/org/org/' + value._id) // Need to pull org by ID to get the matching email of the person
                .then(function(resorg) {
                    axios.get('/user/protect/user/mail/' + resorg.data.data.email) // Get matching user
                    .then(function(resusr) {
                        setActivePerson(resusr.data.data)
                    }).catch(function(err) { 
                        setErrMsg("Error fetching user")
                        setLoading(false)
                    })
                }).catch(function(err) { 
                    setErrMsg("Error fetching org")
                    setLoading(false)
                })*/
            }
        } else { // if there are multiple options - reset
            setActiveCptyEnt(null)
        }
        setActiveCptyOrg(value)
        setErrMsg(null)
        // Focus the entity field if you have selected the ORG and it's not a Person
        if(eos[0] !== undefined && eos[0].entityCategory !== 'Person') { 
            entityInputRef.current.focus() } 
    }

    const handleIntegrationCancel = () => {
        setIntegrationSearch({platform: null, query: ''})
        setIntegrationSearchResults(null)
        setErrMsg(null)
    }

    const handleIntegrationSearch = (intSearch) => {
        setLoading(true)

        if(intSearch !== undefined && intSearch !== null && 
        intSearch.platform !== undefined && intSearch.platform !== null &&
        intSearch.query !== undefined && intSearch.query !== null && intSearch.query.length > 2) {

            /*
            axios.post('/integration/protect/entity/search', { 
                searchString: intSearch.query, 
                integration: intSearch.platform,
                orgID: props.org._id, 
                refreshToken: 
                ['hubspot'].includes(intSearch.platform) ? props.org.integrations.hubspot :
                ['salesforce'].includes(intSearch.platform) ? props.org.integrations.salesforce.refreshToken : null,
                instanceURL: ['salesforce'].includes(intSearch.platform) ? props.org.integrations.salesforce.instanceURL : null
            }).then((res) => {

                setIntegrationSearchResults(res.data.data)
                setLoading(false)

            }).catch((err) => {
                setErrMsg('Unable to perform integration search')
                setLoading(false)   
            })*/
        }
    }

    const handleIntegrationSelect = (sourceInfo) => {
    
        setLoading(true)
        let source = // used for the error msg
        sourceInfo !== undefined && sourceInfo !== null && ['hubspot'].includes(sourceInfo.integration) ? 'Hubpspot' :
        sourceInfo !== undefined && sourceInfo !== null && ['salesforce'].includes(sourceInfo.integration) ? 'Salesforce' : 'the Source System'
        
        if(integrationSearch !== undefined && integrationSearch !== null && 
        integrationSearch.platform !== undefined && integrationSearch.platform !== null &&
        integrationSearch.query !== undefined && integrationSearch.query !== null &&
        integrationSearch.query.length > 2) {

            /*
            axios.post('/integration/protect/entity/single', { 
                integration: sourceInfo.integration,
                sourceID: sourceInfo.sourceID,
                orgID: props.org._id, 
                refreshToken: ['salesforce'].includes(sourceInfo.integration) ? props.org.integrations.salesforce.refreshToken : props.org.integrations.hubspot,
                instanceURL: ['salesforce'].includes(sourceInfo.integration) ? props.org.integrations.salesforce.instanceURL : null
            }).then((res) => {

                let intent = res.data.data !== undefined && res.data.data !== null ? res.data.data : {}

                if(props.cpents.some((cpe) => cpe.sourceData !== undefined && cpe.sourceData !== null && cpe.sourceData.sourceID === intent.id)) {

                setErrMsg('This entity was already imported from ' + source + ', cancel and select it instead')
                setLoading(false)

                } else if(intent !== undefined && intent !== null &&
                intent.name !== undefined && intent.name !== null) { // Proceed to import the entity from Source System

                    let parentOrg = null;

                    if(intent.parentID !== undefined && intent.parentID !== null &&
                        props.cpents.some((cpe) => cpe.sourceData !== undefined && cpe.sourceData !== null && cpe.sourceData.sourceID === intent.parentID)) {
                        // The parent was already imported from the Source, add under the parent
                        let parentEnt = props.cpents.filter((cpe) => cpe.sourceData !== undefined && cpe.sourceData !== null && cpe.sourceData.sourceID === intent.parentID)[0]  
                        parentOrg = { shortName: parentEnt.shortName, _id: parentEnt.parentOrgID, logoURL: parentEnt.logoURL, isNetwork: false }          
                        setActiveCptyOrg(parentOrg)  
                    }

                    callbackEntityForm({
                        entityCategory: 'Company',
                        shortName: intent.name,
                        legalName: intent.name,
                        streetAndNo: intent.address !== undefined && intent.address !== null ?
                        intent.address : '[address]',
                        address2: intent.address2 !== undefined ? intent.address2 : '',
                        city: intent.city !== undefined && intent.city !== null ? intent.city : '[city]',
                        zip: intent.zip !== undefined && intent.zip !== null ? intent.zip : '[zip]',
                        state: intent.state !== undefined ? intent.state : '',
                        country: intent.country !== undefined && intent.country !== null &&
                        countries.filter((c) => c.label.trim().toLowerCase() === intent.country.trim().toLowerCase())[0] !== undefined ? 
                            countries.filter((c) => c.label.trim().toLowerCase() === intent.country.trim().toLowerCase())[0].code : 'US',
                        sourceData: { source: sourceInfo.integration, sourceID: sourceInfo.sourceID },
                        parentOrg: parentOrg,
                    })

                } else {
                    setErrMsg('Unable to import this record from ' + source)
                    setLoading(false)
                }
                
            }).catch((err) => {
                setErrMsg('Unable to select this record from ' + source)
                setLoading(false)
            })*/
        }

    }

    const closeEntityDialog = ({ent, person}) => {

        console.log("closeEntityDialog", ent, person)
        setDialogEntityOpen(null)
        if(ent !== undefined && ent !== null && ent.orgID !== undefined) {
            handleCompleteSelectOrCreate(
                {_id: ent.orgID, shortName: ent.shortName, logoURL: ent.logoURL}, // org
                ent, // ent
                person) // person
        }
    }

    /*
    const callbackEntityForm = (child) => { // callbackEntityForm
    
        setLoading(true)
        setErrMsg(null)

        
        let org = 
            child.parentOrg !== undefined && child.parentOrg !== null ? child.parentOrg :
            activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.logoURL !== undefined && !activeCptyOrg.toBeCreated ? 
                activeCptyOrg :
            activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.toBeCreated && 
            orgOptions.filter((oo) => !oo.isNetwork && oo.shortName.toLowerCase() === child.shortName.trim().toLowerCase())[0] !== undefined ? 
                orgOptions.filter((oo) => !oo.isNetwork && oo.shortName.toLowerCase() === child.shortName.trim().toLowerCase())[0] : null

        let creationDate = new Date().toISOString();
        let addr =  [{
            streetAndNo: child.streetAndNo !== undefined && child.streetAndNo !== null ? child.streetAndNo.trim() : '',
            address2: child.address2 !== undefined && child.address2 !== null ? child.address2.trim() : '',
            zip: child.zip !== undefined && child.zip !== null ? child.zip.trim() : '',
            city: child.city !== undefined && child.city !== null ? child.city.trim() : '',
            country: child.country,
            state: child.state !== undefined && child.state !== null ? child.state.trim() : '',
            addressType: 'billto',
        }]
        //let parentHasLogo = org !== null && org.logoURL !== undefined && org.logoURL !== '';
        let displayName = activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.entityCategory === 'Person' ? 
                child.firstName.trim() + ' ' + child.lastName.trim() : ''

        
        console.log("child", child)
        console.log("addr", addr)
        console.log("displayName", displayName)

        // Entity EDITING: Update the activeEnt and in the "cptyEntOptions" array
        /*
        if(child !== undefined && child !== null && 
        (props.network.filter((n) => n.shortName.trim() === child.shortName.trim())[0] !== undefined || 
        props.network.filter((n) => n.legalName.trim() === child.legalName.trim())[0] !== undefined)){
            // TODO: This is not entirely complete, as network orgs will only have the legalname on an org level and not necessarily all underlying entities
            // You've edited and but this legal entity already exists for a Network Cpty
            setErrMsg('This company already exists in the Canveo network, go back and select that one.')
            setLoading(false)

        } else if(activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeEdited) {
            // You're completing the editing of an entity - update the Active Entity detail
            setActiveCptyEnt({ ...activeCptyEnt,
                //logoURL: parentHasLogo ? activeCptyOrg.logoURL : activeCptyEnt.entityCategory === 'Person' ? defaultUser : defaultLogo,
                entityCategory: child.entityCategory,
                shortName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.shortName.trim(), 
                legalName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.legalName.trim(), 
                address: addr
            })

            let cpos = cptyEntOptions
            let index = cpos.findIndex((c) => c._id === activeCptyEnt._id)
            cpos[index] = {...cpos[index], 
                shortName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.shortName.trim(), 
                legalName: activeCptyEnt.entityCategory === 'Person' ? displayName : child.legalName.trim(), 
                country: child.country,
                address: addr }

            if(activeCptyEnt.entityCategory === 'Person') {
                setActivePerson({ ...activePerson, title: child.title.trim() })
            }

            setCptyEntOptions(cpos);
            setLogoEditing(true)

        } else if (org === null) { 
            // You're creating a NEW ORG w/ entity - create, then attach to activeOrg, activeEnt and entOptions
            let displayName = child.entityCategory === 'Person' ? child.firstName.trim() + ' ' + child.lastName.trim() : ''
            if(props.cpents.filter((c) => c.orgID === props.org._id && c.entityType === (props.cptyType.charAt(0).toUpperCase() + props.cptyType.slice(1)) &&
            ((child.entityCategory === 'Person' && c.legalName.trim().toLowerCase() === displayName.toLowerCase()) ||
             (child.entityCategory !== 'Person' && c.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())))[0] === undefined) {
                // This entity does not yet exist as a counterparty - Create a cpty/shadow org first
                axios.post('/org/org', {
                    shortName: child.entityCategory === 'Person' ? displayName : child.shortName.trim(),
                    legalName: child.entityCategory === 'Person' ? displayName : child.legalName.trim(),
                    orgurl: '',
                    logoURL: child.entityCategory === 'Person' ? defaultUser : defaultLogo,
                    clientType: 'cpty',
                    creationDate: creationDate,
                    email: child.entityCategory === 'Person' ? child.email.toLowerCase().trim() : '',
                })
                .then(function (resorg) { // successfully created the ORG - now create the entity
                    axios.post('/entity/protect/entity', {
                    orgID: props.org._id, 
                    parentOrgID: resorg.data.data._id,
                    entityType: props.cptyType.charAt(0).toUpperCase() + props.cptyType.slice(1),
                    entityCategory: child.entityCategory,
                    shortName: child.entityCategory === 'Person' ? displayName : child.shortName.trim(), 
                    legalName: child.entityCategory === 'Person' ? displayName : child.legalName.trim(), 
                    address: addr, 
                    logoURL: child.entityCategory === 'Person' ? defaultUser : defaultLogo,
                    primaryContact: {},
                    signatory: {},
                    isLive: true,
                    sourceData: child.sourceData !== undefined && child.sourceData !== null && 
                        child.sourceData.source !== undefined && child.sourceData.sourceID !== undefined ? child.sourceData : null,
                    creationBy: props.user._id,
                    creationDate: creationDate,
                    lastUpdateBy: props.user._id,
                    lastUpdateDate: creationDate,
                    })
                    .then(function (resent) { // got a msg from the server
            
                        if(child.entityCategory === 'Person') { // This is a Person
                
                            axios.post('/user/protect/user', { // Create the corresponding User record
                                orgID: resorg.data.data._id,
                                firstName: child.firstName.trim(),
                                lastName: child.lastName.trim(),
                                displayName: displayName.trim(),
                                email: child.email.toLowerCase().trim(),
                                password: generateRandomString(20),
                                title: child.title.trim(),
                                role: 'Counterparty',
                                defaultModule: 'counterparty',
                                photoURL: defaultUser,
                                creationBy: props.user._id,
                                creationDate: new Date().toISOString(),
                                active: true,
                            })
                            .then(function (resuser) { // got a msg from the server
                
                                props.createNotification(
                                    'Welcome new User', // notificationType
                                    'Welcome to Canveo', // title 
                                    child.firstName.trim() + ', we\'re excited to have you on board', // description
                                    '/core/help/welcome', // linkTo
                                    false, // isRead
                                    resuser.data.data._id, // uid
                                );
                                setActivePerson({...resuser.data.data, wasCreated: true})
                                setLoading(false)
                
                            }).catch(function (err) { setErrMsg("Unable to create the user, it may already exist") })
                        }
                
                        let oos = orgOptions;
                        oos.push({shortName: child.entityCategory === 'Person' ? displayName : child.shortName.trim(), _id: resorg.data.data._id, logoURL: child.entityCategory === 'Person' ? defaultUser : defaultLogo, isNetwork: false})
                        setOrgOptions(oos.sort((a,b) => (a.shortName > b.shortName) ? 1 : ((b.shortName > a.shortName) ? -1 : 0)))
                        setActiveCptyOrg({...resorg.data.data, wasCreated: true})
                        setActiveCptyEnt(resent.data.data);
                        setCptyEntOptions([resent.data.data]); // Add newly created to Entity Options
                        setLogoEditing(true);
                        setLoading(false)
                    
                    }).catch(function(err) { setErrMsg("Unable to create the entity, it may already exist, refresh your page and retry") })
                }).catch(function(err) { setErrMsg("Unable to create the company, it may already exist, refresh your page and retry") })

            } else {
                // NOTE: Special handling on this particular errMsg - utilizing PotentialEntity
                setErrMsg("Unable to create the entity, it already exists")
                setPotentialEntity(props.cpents.filter((c) => c.orgID === props.org._id && c.entityType === (props.cptyType.charAt(0).toUpperCase() + props.cptyType.slice(1)) &&
                    ((child.entityCategory === 'Person' && c.legalName.trim().toLowerCase() === displayName.toLowerCase()) ||
                     (child.entityCategory !== 'Person' && c.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())))[0])
                setLoading(false)
            }

        } else if((activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeCreated) || org !== null) {
            // You're creating a new entity for an existing ORG - so attach it to the "cptyEntOptions" array
            // Check (same as above) whether the entity alredy exists and may be inactive
            let pEnt = props.cpents.filter((c) => c.orgID === props.org._id && c.entityType === (props.cptyType.charAt(0).toUpperCase() + props.cptyType.slice(1)) &&
                ((child.entityCategory === 'Person' && c.legalName.trim().toLowerCase() === displayName.toLowerCase()) ||
                (child.entityCategory !== 'Person' && c.legalName.trim().toLowerCase() === child.legalName.trim().toLowerCase())))[0]
            if(pEnt !== undefined){
                setErrMsg("Unable to create the entity, it already exists")
                setPotentialEntity(pEnt)
                setLoading(false)

            } else {
                
                axios.post('/entity/protect/entity', {
                    orgID: props.org._id, 
                    parentOrgID: org._id,
                    entityType: props.cptyType.charAt(0).toUpperCase() + props.cptyType.slice(1),
                    entityCategory: child.entityCategory,
                    shortName: org.shortName, 
                    legalName: child.legalName.trim(), 
                    address: addr, 
                    logoURL: parentHasLogo ? org.logoURL : defaultLogo,
                    primaryContact: {},
                    signatory: {},
                    isLive: true,
                    sourceData: child.sourceData !== undefined && child.sourceData !== null && 
                        child.sourceData.source !== undefined && child.sourceData.sourceID !== undefined ? child.sourceData : null,
                    creationBy: props.user._id,
                    creationDate: creationDate,
                    lastUpdateBy: props.user._id,
                    lastUpdateDate: creationDate,
                })
                .then(function (res) { // got a msg from the server
                    setActiveCptyOrg(org)
                    setActiveCptyEnt(res.data.data);
                    setCptyEntOptions(cpo => [...cpo, res.data.data]); // Add newly created to Entity Options
                    props.triggerCpentsFetchOnExit();
                    handleCompleteSelectOrCreate(org, res.data.data, activePerson);

                }).catch(function(err) { setErrMsg("Unable to create the new entity, it may already exist") })

            }

        }
    }*/

    const handleCompleteSelectOrCreate = (org, ent, person) => {
        props.completeSelectOrCreate(org, ent, person)
        setActiveCptyOrg(null) // reset the variables
        setActiveCptyEnt(null)
        setActivePerson(null)
    }

    return (
    <div>
        {loading ?
        <Box align="center" mt={10} mb={5} width="100%">
        <CircularProgress size={24} />
        </Box>
        :
        // NEED TO SETUP SUBSIDIARY
        state.subs.length === 0 ?
        <Box mt={5} mb={5}>
        <Typography align="center">Please set up an entity for your organisation in the Admin module</Typography>
        </Box>
        :
        // DEFAULT SCENARIO : SELECT THE ORG & ENTITY
        <form onSubmit={e => handleCompleteSelectOrCreate(activeCptyOrg, activeCptyEnt, activePerson)}>
        <Grid container direction="column" alignItems="stretch">
            <Grid item sx={styles.boxResizing}>
                <Box sx={{my: 2}}>
                    <Typography align="center" variant="h6">
                    Select counterparty&nbsp;&nbsp;
                    {/*
                    <InformationalTooltip msg={"To create a new " + (props.cptyType === 'corpcp' ? 'counterparty' : props.cptyType) + ", start typing the company name of the new supplier and click \"Create [...] as a new...\".\n\nTo select a " + (props.cptyType === 'corpcp' ? 'counterparty' : props.cptyType) + " that is already created: Start typing and the available counterparties will show.\n\nSimilarly, you can select or create an additional legal entity of your counterparty.\n\nThe options that show as \"Canveo Network\" are companies that have a Canveo account, these do not need to be created by you and can be selected as counterparties if applicable."} />
                    */}
                    </Typography>
                </Box>
                {errMsg !== null ? // THERE IS AN ERROR
                <Box sx={{mb:4}}><Typography variant="subtitle1" color="error" align="center">{errMsg}</Typography></Box>
                :''}
                <Box sx={{mt:6}}>
                <Autocomplete // THE ORG AUTOCOMPLETE
                    value={activeCptyOrg}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                        handleCptyOrgChange({ shortName: newValue, new: true });
                        } else if (newValue && newValue.inputValue && (newValue.isHubspot || newValue.isSalesforce)) {
                        setIntegrationSearch({platform: newValue.isHubspot ? 'hubspot' : 'salesforce', query: newValue.inputValue})
                        handleIntegrationSearch({platform: newValue.isHubspot ? 'hubspot' : 'salesforce', query: newValue.inputValue})
                        } else if (newValue && (newValue.inputValue || newValue.inputValue === '')) { // Create a new value from the user input
                        handleCptyOrgChange({ shortName: newValue.inputValue, toBeCreated: true });
                        } else { handleCptyOrgChange(newValue); } // mouseSelect
                        
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(params.inputValue !== '' && params.inputValue.length > 1 ? options : options.filter((o) => !o.isNetwork), params);
                        // Suggest the creation of a new value
                        if(params.inputValue !== '' && !filtered.some((f) => f.shortName.toLowerCase() === params.inputValue.toLowerCase())) {
                            filtered.push({
                                inputValue: params.inputValue,
                                shortName: `Create "${params.inputValue}" as a new counterparty`,
                            });
                            if(props.org !== undefined && props.org !== null && props.org.integrations !== undefined && props.org.integrations !== null &&
                            props.org.integrations.hubspot !== undefined && props.org.integrations.hubspot !== null) { 
                                filtered.push({ // When you have a hubspot integration, also show the hubspot search option
                                inputValue: params.inputValue,
                                shortName: `Search "${params.inputValue}" inside Hubspot`,
                                isHubspot: true,
                                });
                            }
                            if(props.org !== undefined && props.org !== null && props.org.integrations !== undefined && props.org.integrations !== null &&
                            props.org.integrations.salesforce !== undefined && props.org.integrations.salesforce !== null && 
                            props.org.integrations.salesforce.instanceURL !== undefined && props.org.integrations.salesforce.instanceURL !== null) { 
                                filtered.push({ // When you have a salesforce integration, also show the salesforce search option
                                inputValue: params.inputValue,
                                shortName: `Search "${params.inputValue}" inside Salesforce`,
                                isSalesforce: true,
                                });
                            }
                        } else if (params.inputValue === '') {
                            filtered.unshift({
                                isNew: true,
                                inputValue: params.inputValue,
                                shortName: `Create a new counterparty`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-for-org"
                    options={orgOptions !== undefined && orgOptions !== null && orgOptions.length > 0 ? 
                        orgOptions.filter((oo) => !props.selectedCounterparties.some((scp) => scp.org.shortName === oo.shortName))
                        : []}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
                        if (option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
                        return option.shortName; // Regular option
                    }}
                    renderOption={(props, option) => 
                        <li {...props}>{

                            <Typography 
                            variant={option._id !== undefined ? "body1" : "subtitle1"}
                            color="textPrimary">
                            {option.shortName}
                            {option.isNetwork || option.isHubspot || option.isSalesforce ? 
                            <>
                            <FontAwesomeIcon 
                            icon={option.isHubspot ? faHubspot : option.isSalesforce ? faSalesforce : faProjectDiagram} 
                            style={option.isHubspot || option.isSalesforce ? styles.cptyIcon : styles.networkIcon} />
                            <span style={option.isHubspot || option.isSalesforce ? styles.cptySpan : styles.networkSpan}>
                                {option.isHubspot ? "Hubspot" : option.isSalesforce ? "Salesforce" : "Canveo Network"}
                            </span>
                            </>
                            :
                            <>
                            <FontAwesomeIcon icon={['Person'].includes(option.entityCategory) ? faUser : faBuilding} 
                            style={styles.cptyIcon} />
                            <span style={styles.cptySpan}>{"Counterparty"}</span>
                            </>
                            }
                            </Typography>
                        }</li>}
                    sx={{ width: '100%' }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} 
                        label={"Search or create"} 
                        placeholder={"Counterparty name..."}
                        autoFocus
                        variant="outlined" 
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            autoComplete: 'new-password',
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={activeCptyOrg === undefined || activeCptyOrg === null || activeCptyOrg._id === undefined ?
                                        faSearch : faBuilding} color={theme.palette.primary.main} />
                                </InputAdornment>
                            ),
                        }} />
                    )}
                />
                </Box> 
            </Grid>

            <Grid sx={activeCptyOrg !== null && cptyEntOptions.length > 0 && 
            (activeCptyEnt === null || activeCptyEnt.entityCategory !== 'Person') ? 
                styles.boxResizing : styles.boxHidden}>
                <Box align="left" mt={2}>

                <Autocomplete // THE ENTITY AUTOCOMPLETE
                    value={activeCptyEnt}
                    onChange={(e, newValue) => {
                        if (typeof newValue === 'string') {
                            setDialogEntityOpen({type: 'newcp', ent: { 
                                orgID: activeCptyOrg._id, 
                                ownerOrgID: state.org._id,
                                shortName: activeCptyOrg.shortName, 
                                legalName: newValue,
                                logoURL: activeCptyOrg.logoURL }
                            })
                            //setActiveCptyEnt({ legalName: newValue });
                        } else if (newValue && newValue.inputValue) { // Create a new value from the user input
                            setDialogEntityOpen({type: 'newcp', ent: { 
                                orgID: activeCptyOrg._id, 
                                ownerOrgID: state.org._id,
                                shortName: activeCptyOrg.shortName, 
                                legalName: newValue.inputValue,
                                logoURL: activeCptyOrg.logoURL }
                            })
                            //setActiveCptyEnt({ legalName: newValue.inputValue });
                        } else { setActiveCptyEnt(newValue); } // mouseSelect
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if(params.inputValue !== '' &&/* !activeCptyOrg.isNetwork && */
                        !filtered.some((f) => f.legalName.toLowerCase() === params.inputValue.toLowerCase()) &&
                        !cptyEntOptions.some((cpo) => ['Person'].includes(cpo.entityCategory)) // Cannot create another entity for a Person
                        /* &&
                        props.org !== undefined && props.org.clientType !== 'legalOrg'*/) {
                            filtered.unshift({
                            inputValue: params.inputValue,
                            legalName: `Create "${params.inputValue}" as a new legal entity`,
                            });
                        }                      
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={cptyEntOptions || []}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') { return option; } // Value selected with enter, right from the input
                        if (option.inputValue) { return option.inputValue; } // Add "xxx" option created dynamically
                        return (option.legalName + // Regular option
                            (option.address !== undefined && option.address[0] !== undefined && option.address[0].country !== undefined ? " " + countryToFlag(option.address[0].country) : ''));
                    }}
                    renderOption={(props, option) => 
                        <li {...props}>{
                        <Typography 
                            variant={option._id !== undefined ? "body1" : "subtitle1"}
                            color={"textPrimary"}>
                            {option.legalName + 
                            (option.address !== undefined && option.address[0] !== undefined && option.address[0].country !== undefined ? " " + countryToFlag(option.address[0].country) : '')} 
                        </Typography>
                    }</li>}
                    style={{ width: '100%' }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} 
                        inputRef={entityInputRef}
                        label={activeCptyEnt !== null && activeCptyEnt.entityCategory !== undefined && activeCptyEnt.entityCategory === 'Person' ? "Full legal name" : "Select legal entity"} 
                        variant="outlined" fullWidth
                        placeholder="Select legal entity..."
                        //style={{visible: 'hidden'}}
                        InputProps={
                            // Contional rendering to truly hide the Input until you activate it (needed for autofocus)
                            activeCptyOrg !== null && cptyEntOptions.length > 0 && 
                            (activeCptyEnt === null || activeCptyEnt.entityCategory !== 'Person') ?
                            {
                                ...params.InputProps,
                                autoComplete: 'new-password',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faUniversity} color={theme.palette.primary.main} />
                                    </InputAdornment>
                                ),
                            }
                            :

                            {style: {
                                //visible: 'hidden',
                                backgroundColor: 'transparent',
                                //borderColor: 'transparent'
                                //border: '0px',
                                height: '0px',
                                width: '0px',
                                position: 'absolute',
                                top: '0px',
                                margin: '0px',
                                padding: '0px',
                                fontSize: '1px',
                                //boxShadow: 'none',
                                //'& fieldset': {
                                    //borderColor: 'transparent',
                                //},
                            }}}                        
                        />
                    )}
                />

                </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
                <Box mt={2}>
                <Button 
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                disabled={activeCptyOrg === null || activeCptyEnt === null}
                type="submit"
                //onClick={e => handleCompleteSelectOrCreate(activeCptyOrg, activeCptyEnt, activePerson)}
                style={{fontSize: '17px', fontWeight: '600', padding: '5px 10px 5px 10px'}}
                >Next</Button>
                </Box>
            </Grid>

        </Grid>
        </form>
        // END OF DEFAULT SCENARIO : SELECT ORG / ENTITY
        }
        <DialogEntity
          open={Boolean(dialogEntityOpen)}
          closeDialog={closeEntityDialog}
          type={dialogEntityOpen !== null && dialogEntityOpen.type !== undefined ? dialogEntityOpen.type : ''}
          ent={dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined ? dialogEntityOpen.ent : ''}
        />
    </div>
  );
}

/*
import { randomString as generateRandomString, countryToFlag } from '../utils'
import { Avatar, Box, Button, CircularProgress, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, 
         TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UploadImg, InformationalTooltip } from '.';
import { EntityForm } from './forms';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram, faBuilding, faUser, faArrowLeft, faSearch, faUniversity } from "@fortawesome/free-solid-svg-icons";
import { faHubspot, faSalesforce } from '@fortawesome/free-brands-svg-icons';





const filter = createFilterOptions();

const axios = require('axios');

const SelectOrCreateCpty = (props) => {

    const useStyles = makeStyles(theme => ({
        bold: {
            fontWeight: '700',
        },
        networkIcon: {
            color: theme.palette.primary.main,
            fontSize: '11px',
            padding: '0px 15px 0px 20px'
        },
        cptyIcon: {
            color: theme.palette.secondary.main,
            fontSize: '11px',
            padding: '0px 15px 0px 20px'
        },
        cptySpan: {
            fontSize: '12px',
            color: theme.palette.secondary.main,
        },
        networkSpan: {
            fontSize: '12px',
            color: theme.palette.primary.main,
        },
    }));
    const classes = useStyles();

    const history = useHistory()

    const [loading, setLoading] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState(null);
    const [logoEditing, setLogoEditing] = React.useState(false);
    const [potentialEntity, setPotentialEntity] = React.useState(null)
    const [activeCptyOrg, setActiveCptyOrg] = React.useState(null);
    const [activeCptyEnt, setActiveCptyEnt] = React.useState(null);
    const [activePerson, setActivePerson] = React.useState(null);
    const [integrationSearch, setIntegrationSearch] = React.useState({platform: null, query: ''})
    const [integrationSearchResults, setIntegrationSearchResults] = React.useState(null)
    const [orgOptions, setOrgOptions] = React.useState([]);
    const [cptyEntOptions, setCptyEntOptions] = React.useState([]);

    useEffect(() => {
        let unmounted = false;
        // Create the Array of "orgOptions" - essentially the options that can be selected by the User as CptyOrg
        let oos = [] 
        let ents = (props.module === 'sales' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Customer') : 
                    props.module === 'procurement' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Supplier') : 
                    props.module === 'partnerships' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Partner') : 
                    props.module === 'people' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Employee') :
                    props.module === 'corporate' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Corpcp') : []);

        ents.forEach((e) => { // Add Org from internal master data
            if(!oos.some((o) => o._id === e.parentOrgID) && e.isLive) { // Not already added due to an Org having multiple affiliates
                oos.push({shortName: e.shortName, _id: e.parentOrgID, logoURL: e.logoURL, isNetwork: false})
            }
        })

        if(['sales', 'procurement', 'partnerships', 'corporate'].includes(props.module)) { // Only include network orgs for B2B, partnerships and corporate
            props.network.filter((n) => n._id !== props.org._id).forEach((n) => { // Add Orgs from the Network
                if(!oos.some((o) => o.shortName === n.shortName)) { // Not already having an org with the same name
                    oos.push({...n, isNetwork: true});
                }
            })
        }

        if (!unmounted) { 
            // Coming from the Relationship page - preselect the ORG
            if(props.cporgid !== null && oos.filter((o) => o._id === props.cporgid)[0] !== undefined) {
                handleCptyOrgChange(oos.filter((o) => o._id === props.cporgid)[0])
            }
            setOrgOptions(oos.sort((a,b) => (a.shortName > b.shortName) ? 1 : ((b.shortName > a.shortName) ? -1 : 0))) 
        }
        return () => { unmounted = true; }; // unmounting
    },[props.module,props.cpents,props.network, props.org._id,props.cporgid])


    const retryEntityForm = () => {
        setLoading(false)
        setErrMsg(null)
    }

    const callbackLogoUploadForm = (child) => {
        // If logo to be changed across entities
        props.updateLogoForOrg(activeCptyOrg._id, child); // FAF: Updates in the DB, incl. entities but not locally yet
        // If photo to be changed for user
        let person = activePerson
        if(activeCptyEnt.entityCategory === 'Person' && person !== undefined && person !== null) {
            axios.put('/user/protect/photo/' + activePerson._id, { photoURL: child })
            person.photoURL = child
        }
        props.triggerCpentsFetchOnExit()
        handleCompleteSelectOrCreate({...activeCptyOrg, logoURL: child}, {...activeCptyEnt, logoURL: child}, person);
    }

    const handleCptyOrgChange = (value) => { // Changing the ORG for the Counterparty

        // Start with creating of array "cptyEntOptions", basically the entities for that Org that can be selected
        let eos = []
        if(value !== null && !value.isNetwork && value._id !== undefined) {
            // If it's a non-network ORG - then filter only the available entities for that ORG
            eos = (props.module === 'sales' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Customer') : 
                    props.module === 'procurement' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Supplier') : 
                    props.module === 'partnerships' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Partner') : 
                    props.module === 'people' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Employee') :
                    props.module === 'corporate' ? props.cpents.filter((c) => c.isLive && c.entityType === 'Corpcp') : [])
                    .filter((e) => e.parentOrgID === value._id)
            setCptyEntOptions(eos)

        } else if (value !== null && value.isNetwork && value._id !== undefined) { 
            // If it's a network ORG - then pull all entities for the ORG
            axios.get('/entity/subsidiaries/' + value._id)
            .then(function(res) {
                setCptyEntOptions(res.data.data.filter((e) => e.isLive))
                if(res.data.data.filter((e) => e.isLive).length === 1) { setActiveCptyEnt(res.data.data.filter((e) => e.isLive)[0]) }
            }).catch(function(err) { 
                setErrMsg("Error fetching entities for network org")
                setLoading(false)
            })
        
        } else if(value !== undefined && value !== null && value.toBeCreated) { 
            // if creating a new ORG, reset the ENT (if applicable))
            setActiveCptyEnt(null) 

        } else if (value === null) {
            // If no ORG is selected, then there are no cptyEntOptions
            setActiveCptyEnt(null)
            //setActiveOwnEnt({ shortName: props.org.shortName, logoURL: props.org.logoURL })
            setCptyEntOptions([])
        }

        if(eos.length === 1) {  // Id you have only one possible entity - then auto-select
            setActiveCptyEnt(eos[0]); 
            // If the only entity is a person - then also pull the person(user) detail
            if(eos[0].entityCategory === 'Person'){ 
                axios.get('/org/org/' + value._id) // Need to pull org by ID to get the matching email of the person
                .then(function(resorg) {
                    axios.get('/user/protect/user/mail/' + resorg.data.data.email) // Get matching user
                    .then(function(resusr) {
                        setActivePerson(resusr.data.data)
                    }).catch(function(err) { 
                        setErrMsg("Error fetching user")
                        setLoading(false)
                    })
                }).catch(function(err) { 
                    setErrMsg("Error fetching org")
                    setLoading(false)
                })
            }
        } else { // if there are multiple options - reset
            setActiveCptyEnt(null)
        }
        setActiveCptyOrg(value)
        setErrMsg(null)
    }

    const handleCompleteSelectOrCreate = (org, ent, person) => {
        props.completeSelectOrCreate(org, ent, person)
        setActiveCptyOrg(null) // reset the variables
        setActiveCptyEnt(null)
        setActivePerson(null)
    }

    return (
    <div>
        <Grid container direction="column" justify="flex-start" alignItems="stretch">

            

            
            // CREATING / EDITING OF COUNTERPARTY SCENARIO
            (activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.wasCreated) ||
            (activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.toBeCreated) ||
            (activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeCreated)
            ?
            <Box mt={0}>
            <Typography align="center" variant="h6">
                {"Create a new " + (props.cptyType === 'corpcp' ? 'counterparty' : props.cptyType)}&nbsp;&nbsp;
                <InformationalTooltip msg={logoEditing ?
                "Upload a (square) logo of your counterparty for enhanced usability and recognition. You can typically find and download a logo quickly by web-searching \"Company name Logo\"." :
                "When you create (or edit) a company, the company name would be their common business name (for example \"Google\") while the Legal name would be their legal entity name (for example \"Google LLC\")."} />
            </Typography>
            <Box align="left" mt={3}>

                {// Show any error msg that has come due to creating / editing the cpty
                errMsg !== null ? 
                <Box mb={3}>
                    <Typography align="center" variant="subtitle1" color="error">{errMsg}</Typography> 
                    {// If the provided entity name matches an already existing entity - show the "potential entity" option
                    potentialEntity !== null ?
                    <Box mt={2} align="center">
                        <Button onClick={potentialEntity.isLive ? e => handleCptyOrgChange(null) : e => history.push('/core/' + props.module + '/masterdata/edit/' + potentialEntity._id)}
                        color="secondary" variant="contained" disableElevation>
                        {(potentialEntity.isLive ? "Select " : "Reactivate ") + potentialEntity.legalName}
                        </Button>
                    </Box>
                    :''}
                    <Box mt={2} align="center">
                        <Button variant="contained" disableElevation size="small" color="secondary" onClick={retryEntityForm}>Clear error and retry</Button>
                    </Box>
                </Box>
                : ''}

                {!logoEditing ? 
                <>
                <EntityForm 
                submitting={loading}
                parentCallback={callbackEntityForm}
                type={'newAgr'}
                hideRadioCategory={props.module === 'people' ? true : false}
                editEntity={(activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.toBeCreated)}
                initialValues={{
                    entityCategory: activeCptyEnt !== undefined && activeCptyEnt !== null && 
                        activeCptyEnt.entityCategory !== undefined && activeCptyEnt.entityCategory !== null ? activeCptyEnt.entityCategory : 
                        props.module === 'people' ? 'Person' : 'Company',
                    shortName: activeCptyOrg !== undefined && activeCptyOrg !== null && activeCptyOrg.shortName !== undefined ? 
                        activeCptyOrg.shortName : '', // formData.shortName, 
                    legalName: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.legalName !== undefined ? 
                        activeCptyEnt.legalName : '', // formData.legalName, 
                    streetAndNo: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                            activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].streetAndNo : '', // formData.streetAndNo,
                    address2: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 !== undefined ? 
                            activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].address2 : '', // formData.address2,
                    city: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                            activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].city : '', // formData.city,
                    zip: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                            activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].zip : '', // formData.zip,
                    state: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                            activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].state : '', // formData.state,
                    country: activeCptyEnt !== undefined && activeCptyEnt !== null && activeCptyEnt.address !== undefined && 
                        activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0] !== undefined ? 
                            activeCptyEnt.address.filter((a) => a.addressType === 'billto')[0].country : '',
                    firstName: activePerson !== undefined && activePerson !== null && activePerson.firstName !== undefined ? activePerson.firstName : '',
                    lastName: activePerson !== undefined && activePerson !== null && activePerson.lastName !== undefined ? activePerson.lastName : '',
                    email: activePerson !== undefined && activePerson !== null && activePerson.email !== undefined ? activePerson.email : '',
                    title: activePerson !== undefined && activePerson !== null && activePerson.title !== undefined ? activePerson.title : '',
                }}
                />
                <Box mt={1} align="center">
                <Button color="primary" onClick={e => handleCptyOrgChange(null)}>Select a different {(props.cptyType === 'corpcp' ? 'counterparty' : props.cptyType)}</Button>
                </Box>
                </>

                : // UPLOAD / UPDATE THE LOGO

                <>
                <Box mt={2} mb={3} align="center">                
                <UploadImg 
                    type={activeCptyEnt.entityCategory !== undefined && activeCptyEnt.entityCategory === 'Person' ? "avatar" : "logo"}
                    parentCallback={callbackLogoUploadForm}
                    initialImg={activeCptyEnt.logoURL} />
                </Box>
                <Box mt={1} align="center">
                <Button color="primary" onClick={e => handleCompleteSelectOrCreate(activeCptyOrg, activeCptyEnt, activePerson)}>Skip this step</Button>
                </Box>
                </>

                }

            </Box>
            </Box>
            :
            // INTEGRATION SEARCH SCENARIO : SELECT AND IMPORT FROM SOURCE SYSTEM
            integrationSearch !== undefined && integrationSearch !== null && integrationSearch.platform !== null && 
            integrationSearch.query !== undefined && integrationSearch.query !== null ?
            <Box align="center" mt={4}>
            <Grid item xs={12} sm={8} md={6}>
                {errMsg !== null ?
                <Box mb={3}><Typography color="error">{errMsg}</Typography></Box>
                :''}
                <form onSubmit={e => handleIntegrationSearch(integrationSearch)}>
                <Box align="left">
                <TextField
                    label={
                    ['hubspot'].includes(integrationSearch.platform) ? "Search Hubspot..." :
                    ['salesforce'].includes(integrationSearch.platform) ? "Search Salesforce..." : "Search"}
                    placeholder="Search by name or domain"
                    fullWidth
                    variant="outlined"
                    value={integrationSearch.query}
                    onChange={e => setIntegrationSearch({...integrationSearch, query: e.target.value})}
                />
                <Box mt={2}>
                    <Button color="primary" variant="contained" disableElevation fullWidth 
                    type="submit"
                    disabled={integrationSearch === undefined || integrationSearch === null || integrationSearch.platform === null || 
                    integrationSearch.query === undefined || integrationSearch.query === null || integrationSearch.query.length < 3}>Search</Button>
                </Box>
                </Box>
                </form>

                <Box mt={1} align="right">
                <Button color="primary" variant="text" onClick={handleIntegrationCancel}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel</Button>
                </Box>

                {integrationSearchResults !== null ?
                <Box mt={3}>

                {integrationSearchResults.total > 0 && integrationSearchResults.results !== undefined &&
                integrationSearchResults.results !== null && integrationSearchResults.results.length > 0 ?
                <Box mb={3}>
                    <List>
                    {integrationSearchResults.results.map((sr) => (
                    <ListItem key={sr.sourceID} button onClick={e => handleIntegrationSelect({integration: sr.source, sourceID: sr.sourceID})}>
                        <ListItemAvatar>
                        <Avatar src={['hubspot'].includes(integrationSearch.platform) ? hubspot_icon : salesforce_icon} />
                        </ListItemAvatar>
                        <ListItemText 
                        primary={<Typography className={classes.bold}>
                        {sr.name !== undefined && sr.name !== null ? sr.name : "Unknown Company"}</Typography>}
                        secondary={
                        <>
                        {['hubspot'].includes(integrationSearch.platform) ? "Hubspot" : "Salesforce"}&nbsp;ID:&nbsp;
                        {sr.sourceID !== undefined && sr.sourceID !== null ? sr.sourceID : "n/a"}
                        {sr.domain !== undefined && sr.domain !== null ? <>&nbsp;({sr.domain})</> : ""}
                        </>} />
                    </ListItem>
                    ))}
                    
                    </List>
                </Box>
                :''}

                <Typography color="textSecondary" variant="body2" align="center">
                    {integrationSearchResults.total} results{
                    integrationSearchResults.total === 0 ? ", please retry" :
                    integrationSearchResults.total > 10 ? ", refine your search" : ""}
                </Typography>

                </Box>
                :''}
            </Grid>
            </Box>
            
        </Grid>
    </div>
    );
}

export default SelectOrCreateCpty*/