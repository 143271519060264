import { createCommand } from 'lexical';

export const ADD_WORKFLOW_COMMAND = createCommand();
export const APPLY_FILTER_COMMAND = createCommand();
export const ASSIGN_TOPICS_COMMAND = createCommand();
export const INSERT_FROM_LIBRARY = createCommand();
export const INSERT_INLINE_COMMAND = createCommand();
export const REMOVE_CLAUSETYPE_COMMAND = createCommand();
export const REMOVE_MARK = createCommand();
export const REMOVE_WORKFLOW = createCommand();
export const SAVE_TO_LIBRARY = createCommand();
