import {
  TextNode,
} from 'lexical';

function convertMentionElement(domNode) {
  const node = $createMentionNode(domNode.textContent);
  return {
    node,
  };
}

const mentionStyle = 'background-color: #eeeeee; padding: 2px 5px; border-radius: 10px; font-weight: 600;';

export class MentionNode extends TextNode {
  __mention;
  __uid;

  static getType() {
    return 'mention';
  }

  static clone(node) {
    return new MentionNode(node.__mention, node.__text, node.__uid, node.__key);
  }

  constructor(mentionName, text, uid, key) {
    super(Boolean(text) ? text : mentionName, key);
    this.__mention = mentionName;
    this.__uid = uid;
  }

  static importJSON(serializedNode) {
    const node = $createMentionNode(serializedNode.mentionName);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      uid: this.__uid,
      type: 'mention',
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.style.cssText = mentionStyle;
    dom.className = 'mention';
    return dom;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.textContent = this.__text;
    return {element};
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }
}

export function $createMentionNode(mentionName, text, uid) {
  const mentionNode = new MentionNode(mentionName, text, uid);
  mentionNode.setMode('segmented').toggleDirectionless();
  return mentionNode;
}

export function $isMentionNode(node) {
  return node instanceof MentionNode;
}