import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import theme from '../theme/theme';

/*
const InfoTooltip = withStyles({
    arrow: {
        //"&:before": {
          //border: "1px solid #E6E8ED"
        //},
        color: '#62b2fc'
    },
    tooltip: {
        backgroundColor: '#62b2fc',
        //color: theme.palette.grey[900],
        fontSize: "14px",
        fontWeight: 500,
        padding: '25px',
        color: theme.palette.primary.contrastText,
        //backgroundColor: '#f6f6f6',
        //border: '1px solid #eeeeee',
        borderRadius: '20px',
    }
})(Tooltip);*/

/*
const TooltipProminent = (props) => {

    return (
          <InfoTooltip arrow
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          title={<span style={{ whiteSpace: 'pre-line' }}>{props.title}</span>}>
              <span>
              <FontAwesomeIcon icon={faInfoCircle} style={{color: '#62b2fc', fontSize: '15px'}} />
              </span>
          </InfoTooltip>
    );
}*/

const TooltipProminent = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.grey[800],
    maxWidth: 260,
    fontSize: '15px',
    fontWeight: '300',
    padding: '15px 20px',
    border: '1px solid #dadde9',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px',
    border: '1px solid' + theme.palette.grey[200],
  },
}));

export default TooltipProminent