import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect } from "react";
import { analyzeNode } from "../utils";
import { CoreDrawerToc } from '../../'

export default function ToolbarPlugin() {

  const [editor] = useLexicalComposerContext();
  const [toc, setToc] = React.useState([])

  useEffect(() => {

    let tocItems = [] // tocGen => Table of Contents Generation

    editor.getEditorState().read(() => {
        const nodeMapArray = [...editor.getEditorState()._nodeMap];
        const root = nodeMapArray.filter((n) => n[1].getType() === 'root')[0];
        root[1].__children.forEach((nodeKey) => {

            let node = Boolean(nodeMapArray.filter((nma) => nma[0] === nodeKey)[0]) ? 
                nodeMapArray.filter((nma) => nma[0] === nodeKey)[0][1] : null

            if(Boolean(node.__children) && node.__children.length > 0 && node.getTextContent().trim().length > 3) {

                let nodeDetails = analyzeNode(node.getTextContent())

                let level = 
                    Boolean(nodeDetails.fourthLeaderLead) ? 4 :
                    Boolean(nodeDetails.thirdLeaderLead) ? 3 :
                    Boolean(nodeDetails.secondLeaderLead) ? 2 : 1
                let endPos = nodeDetails.firstDotPositionAfterLead > -1 && nodeDetails.firstDotPositionAfterLead < 50 ? nodeDetails.firstDotPositionAfterLead : 50
                
                let item = 
                    nodeDetails.isDefinition ? nodeDetails.clauseTitle :
                    node.getTextContent().substring(0, endPos)

                const element = editor.getElementByKey(node.getKey())

                tocItems.push({ element: element, level: level, item: item })
            }
        })
    })
    setToc(tocItems)

  }, [])

  return (
    <CoreDrawerToc toc={toc} />
  );
}