const partiesReducer = (state, action) => {

    switch (action.type) {
        case "INIT_PARTIES":
            return { parties: action.payload };
        default:
            return { parties: state.parties }
    }
};
  
export default partiesReducer