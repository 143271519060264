import { $createMarkNode, $getMarkIDs, $isMarkNode, $unwrapMarkNode, $wrapSelectionInMarkNode, 
         MarkNode } from '../nodes/MarkNode';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { createDOMRange, createRectsFromDOMRange } from '@lexical/selection';
import { mergeRegister, registerNestedElementResolver } from '@lexical/utils';
import { $getRoot, $getNodeByKey, $getSelection, $isRangeSelection, $isTextNode,
         COMMAND_PRIORITY_EDITOR, KEY_ESCAPE_COMMAND } from 'lexical';
import { INSERT_INLINE_COMMAND, REMOVE_MARK } from '../commands';

import { PlainTextEditor } from './commenting/PlainTextEditor';

import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useLayoutEffect, getClauseNode, useOnChange, getClauseHTML } from '../utils';
import { Autocomplete, Box, Button, Checkbox, 
         Collapse, 
         FormControl, FormControlLabel, InputLabel, 
         MenuItem, Select, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { ContextItems, ContextMenu, ParamEditable, SelectUserForOrg } from '../../';
import { globalStore } from '../../../state/store';

import { createWorkflow } from './commenting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowLeft, faArrowRight, faUserPlus } from '@fortawesome/pro-solid-svg-icons';

import { greatPatternFinder, randomString as generateRandomString, trunc } from '../../../utils';
import { paramtypes } from '../../../assets/static';
import logo from '../../../assets/img/ct-logo.png'

import axios from 'axios';

const filter = createFilterOptions();

function CommentInputBox({
  type,
  partyID,
  docID,
  isTemplating,
  editor,
  user,
  cancelAddWorkflow,
  createWorkflowAndAttachToDOM,
  attachWorkflowToDOM,
  predictedParam,
}) {
  const [state, dispatch] = useContext(globalStore);
  const [content, setContent] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [subscribers, setSubscribers] = useState([])
  const boxRef = useRef(null);
  const selectionState = useMemo(
    () => ({
      container: document.createElement('div'),
      elements: [],
    }),
    [],
  );

  const updateLocation = useCallback(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const range = createDOMRange(
          editor,
          anchor.getNode(),
          anchor.offset,
          focus.getNode(),
          focus.offset,
        );
        const boxElem = boxRef.current;
        if (range !== null && boxElem !== null) {
          const {left, bottom, width} = range.getBoundingClientRect();
          
          const selectionRects = createRectsFromDOMRange(editor, range);
          
          let correctedLeft =
            selectionRects.length === 1 ? left + width / 2 - 125 : left - 125;
          if (correctedLeft < 10) {
            correctedLeft = 10;
          }
          boxElem.style.left = `${correctedLeft}px`;
          boxElem.style.top = `${bottom + 20 + window.scrollY}px`;
          boxElem.style.position = 'absolute';
          const selectionRectsLength = selectionRects.length;
          const {elements, container} = selectionState;
          const elementsLength = elements.length;

          for (let i = 0; i < selectionRectsLength; i++) {
            const selectionRect = selectionRects[i];
            let elem = elements[i];
            if (elem === undefined) {
              elem = document.createElement('span');
              elements[i] = elem;
              container.appendChild(elem);
            }
            const color = 
              ['approval'].includes(type) ? '15,160,90' :
              ['param'].includes(type) ? '75,140,245' : '255, 212, 0';
            const style = `position:absolute;top:${selectionRect.top + window.scrollY}px;left:${selectionRect.left}px;height:${selectionRect.height}px;width:${selectionRect.width}px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`;
            elem.style.cssText = style;
          }
          for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
            const elem = elements[i];
            container.removeChild(elem);
            elements.pop();
          }
        }
      }
    });
  }, [editor, selectionState]);

  useLayoutEffect(() => {
    updateLocation();
    const container = selectionState.container;
    const body = document.body;
    if (body !== null) {
      body.appendChild(container);
      return () => {
        body.removeChild(container);
      };
    }
  }, [selectionState.container, updateLocation]);

  useEffect(() => {
    window.addEventListener('resize', updateLocation);

    return () => {
      window.removeEventListener('resize', updateLocation);
    };
  }, [updateLocation]);

  const onEscape = (event) => {
    event.preventDefault();
    cancelAddWorkflow();
    return true;
  };

  const submitParam = () => {

    let isParamFromLibrary = !['new'].includes(param.libid) && state.params.lib.some((li) => li._id === param.libid)
    let newParam = param
    let newLibParam = !isParamFromLibrary && newParam.isLibItem ? newParam : null

    // If Applicable: CREATE Parameter for library
    if(Boolean(newLibParam)) { // This param needs to be duplicated into a Library Param
      newLibParam.docID = null;
      newLibParam.lid = null;
      delete newLibParam.libid;

      axios.post(state.settings.api + 'param', { param: newLibParam }) // POST the library Param
      .then((resLibParam) => {
        if(resLibParam.data.success) {

          dispatch({ type: "ADD_PARAM", payload: { type: 'lib', item: resLibParam.data.data } })

        } else { console.log("unable to create Library Param")}
      }).catch((err) => { console.log("err creating Library Param", err) })
    }

    // CREATE Parameter and attach to DOM
    newParam.lid = 'pa' + partyID.substring(5) + '_' + generateRandomString(5)
    newParam.isLibItem = false;
    delete newParam.libid;
    
    axios.post(state.settings.api + 'param', { param: newParam }) // POST the regular Param
    .then((resParam) => {
      if(resParam.data.success) {
        dispatch({ type: "ADD_PARAM", payload: { type: 'doc', item: resParam.data.data } })
        attachWorkflowToDOM(newParam.lid)
      } else { console.log("unable to create Param")}
    }).catch((err) => { console.log("err creating Param", err) })
  }

  const submitComment = () => {

    let clauseHTML = editor.getEditorState().read(() => {
      const anchor = Boolean($getSelection()) ? $getSelection().anchor : null
      const clauseNode = Boolean(anchor) ? getClauseNode(anchor.getNode()) : null;
      const html = Boolean(clauseNode) ? getClauseHTML(clauseNode) : null
      return Boolean(html) ? html : null;
    });
    
    let quote = editor.getEditorState().read(() => {
      const selection = $getSelection();
      return selection !== null ? selection.getTextContent() : '';
    });

    if (quote.length > 100) {
      quote = quote.slice(0, 99) + '…';
    }

    let wfType = 
          ['approval'].includes(type) ? 'approval' :
          isInternal ? 'ithread' : 'pthread';

    let prefix = 
        ['approval'].includes(wfType) ? 
            ('ap' + partyID.substring(5) + '_') :
        ['ithread'].includes(wfType) ? 
            ('cp' + partyID.substring(5) + '_') : 
            ('cp_')
    
    let allSubs = subscribers;
    let emailRecipients = []
    let doc = isTemplating ? state.templates.filter((t) => t._id === docID)[0] : 
          state.agrs.filter((a) => a._id === docID)[0]
    let assignee = null

    if(['ithread', 'pthread'].includes(wfType)) {
      subscribers.forEach((s) => {
        let recipient = state.users.filter((u) => u._id === s)[0];
        if(Boolean(doc)) {
          emailRecipients.push({ doc, recipient, content, isInternal, isTemplating, clauseHTML })
        }
      })
    } else if(['approval'].includes(wfType) && Boolean(approverSelected)) {
      let recipient = approverSelected;
      emailRecipients.push({ doc, recipient, content, isInternal, isTemplating, clauseHTML })
      assignee = [{ uid: approverSelected._id, displayName: approverSelected.displayName, status: 'pending' }]
    }

    if(!allSubs.some((s) => s === user._id)) { allSubs.unshift(user._id) } // Insert creator as subscriber
    createWorkflowAndAttachToDOM(createWorkflow(wfType, prefix, quote, content, user, assignee, allSubs, docID), emailRecipients, true);
  
  };

  const onChange = useOnChange(setContent, setCanSubmit, setSubscribers);
  const [isInternal, setIsInternal] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [approverSelected, setApproverSelected] = React.useState(null);
  const [param, setParam] = React.useState({ 
    orgID: state.org._id,
    libid: null,
    name: '',
    ref: '',
    type: null,
    val1: '',
    val2: '', 
    valCalc: '',
    note: '', 
    createWizard: false,
    createWizardQuestion: '',
    isLibItem: false,
    docID: docID
  })

  useEffect(() => {

    if(Boolean(predictedParam) && Boolean(predictedParam.type)) {
      setParam({...param, 
        type: predictedParam.type,
        val1: predictedParam.val1,
        val2: predictedParam.val2,
      })
    }

  }, [predictedParam])

  const handleParamValueChange = (sfid, valnumber, newval) => {
    if(valnumber === 'val1') {
        setParam({...param, val1: newval})
    } else if(valnumber === 'val2') {
        setParam({...param, val2: newval})
    }
  }

  return (
    <div className="CommentPlugin_CommentInputBox" ref={boxRef}>
      {
      // Param - Step 0
      /*
      stp 1: Name + note<br/>
      stp 2: Type + value<br/>
      stp 3: Config (wiz1/2, reuse, replic)
      */
      ['param'].includes(type) && step === 0 ?

      <div style={{ padding: '20px 20px 0px 20px' }}>
        <Box sx={{mt: 1, mb:1}}>
          <Autocomplete
            //value={param.value}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setParam({...param, libid: 'new', name: newValue });
              } else if (newValue && newValue.inputValue) { // Create a new value from the user input
                setParam({...param, libid: 'new', name: newValue.inputValue });
              } else {
                setParam({...param, 

                  createWizard: newValue.createWizard,
                  createWizardQuestion: newValue.createWizardQuestion,
                  name: newValue.name,
                  note: newValue.note,
                  ref: newValue.ref,
                  type: newValue.orgID === 'CANVEO' ? newValue.type : param.type,
                  val1: newValue.orgID === 'CANVEO' ? newValue.val1 : param.val1,
                  val2: newValue.orgID === 'CANVEO' ? newValue.val2 : param.val2,
                  libid: newValue.orgID === 'CANVEO' ? 'canveo' : 'library'
                });
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              //const isExisting = options.some((option) => inputValue === option.name);
              if (inputValue !== ''/* && !isExisting*/) {
                filtered.push({
                  inputValue,
                  name: `Create "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={state.params.lib.sort((a, b) => (a.name > b.name) ? 1 : -1)}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(props, option) => 
            <li {...props} style={{fontSize: '14px'}}>
              {trunc(option.name, 24)}
              {option.orgID === 'CANVEO' ? <img src={logo} alt="Canveo Managed" style={{width: '12px', height: '12px', marginLeft: '10px'}} />:''}
            </li>}
            sx={{ width: '100%' }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Parameter name" placeholder={"Select or create..."} />
            )}
          />
        </Box>

        {/*param.libid !== null && */Boolean(param.libid) && !['canveo'].includes(param.libid) ?
        <Box sx={{mb:0.5}}>
          <TextField 
            label={"Reference"}
            placeholder='e.g. "For Customers"'
            value={param.ref}
            onChange={e => setParam({...param, ref: e.target.value})}
            style={{width: '100%'}}
          />
          <Box sx={{mt:1}}>
            <TextField
            label={"Internal note"}
            placeholder={"Provide internal note..."}
            value={param.note}
            onChange={e => setParam({...param, note: e.target.value})}
            style={{width: '100%'}}
            rows={3}
            multiline
            />
          </Box>
        </Box>
        :''}
      </div>
      : 
      // Param - Step 1 
      ['param'].includes(type) && step === 1 ?

      <div style={{ padding: '20px 20px 0px 20px' }}>
        <Box sx={{mt: 1, mb:1}}>
          <FormControl variant="outlined" fullWidth>
            {/*<InputLabel style={{backgroundColor: theme.palette.grey[100], padding: '0px 2px 0px 2px'}}>Parameter Type</InputLabel>
            */}
            <Select
            value={param.type}
            onChange={e => setParam({...param, type: e.target.value})}
            renderValue={selected => {
                let s =  paramtypes.filter((tc) => tc.value === selected)[0] !== undefined ? 
                  paramtypes.filter((tc) => tc.value === selected)[0].type : ""
                return <span>{s}</span>;
            }}
            >
            {paramtypes
            //.filter((tc) => props.templating || tc.value !== 'auto')
            .map((pt,i) => (
              <MenuItem key={i} value={pt.value}><span style={{fontWeight: '700', marginRight: '10px'}}>{pt.type}</span>{pt.desc}</MenuItem>
            ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{mb:2}}>

          <ParamEditable                        
          fromClause={false}
          type={param.type}
          val1={param.val1}
          val2={param.val2}
          onFieldChange={handleParamValueChange}
          sfid={"newparam"} />

        </Box>
      </div>
      :
      // Param - Step 2
      // TODO: find-all and replicate
      ['param'].includes(type) && step === 2 ?
      <div style={{ padding: '20px 20px 0px 20px' }}>
        <Box sx={{mt: 1, mb:1}}>
          
          <FormControlLabel 
          control={<Checkbox size="small" color="primary" checked={param.createWizard} 
            onChange={e => setParam({...param, createWizard: e.target.checked})} />}
          label={<Typography>Include in Wizard</Typography>}
          />
          <Collapse in={param.createWizard}>
            <Box sx={{mt:1}}>
              <TextField
              label={"Wizard Question"}
              placeholder={"Provide question..."}
              value={param.createWizardQuestion}
              onChange={e => setParam({...param, createWizardQuestion: e.target.value})}
              style={{width: '100%'}}
              rows={3}
              multiline
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={{mt: 1, mb:2}}>

          {!['library', 'canveo'].includes(param.libid) ?
          <FormControlLabel 
          control={<Checkbox size="small" color="primary" checked={param.isLibItem} 
            onChange={e => setParam({...param, isLibItem: e.target.checked})} />}
          label={<Typography>Save to parameter library</Typography>}
          />
          :''}

        </Box>
      </div>
      : 
      // Approval - Step 0
      ['approval'].includes(type) && step === 0 ?

      <div style={{ padding: '20px 20px 0px 20px' }}>
        <Box sx={{mt: 1, mb:2}}><Typography variant="h6" align="center">Select Approver</Typography></Box>

        <Box sx={{mb: ['Counterparty'].includes(state.user.role) ? 0 : 2}}>
          <SelectUserForOrg
            orgID={state.org._id}
            //cpUsers={state.users.filter((u) => u.active && u._id !== state.user._id && (!isTemplating || ['Admin', 'Legal'].includes(u.role)))}
            handleSelectUser={e => setApproverSelected(e)}
            hiddenUsers={state.users.filter((u) => isTemplating && ['Business'].includes(u.role) )}
            userSelected={approverSelected}
          />
        </Box>

        {['Counterparty'].includes(state.user.role) ?
        <Box sx={{mt: 1, textAlign: 'center'}}>
          <Button color="secondary">Create Collaborator&nbsp;&nbsp;<FontAwesomeIcon icon={faUserPlus}/></Button>
        </Box>
        :''}
      </div>
      :
      // Send / Add Message
      <PlainTextEditor
        onEscape={onEscape}
        onChange={onChange}
        placeholder={['approval'].includes(type) ? "Add a message..." : null}
        isTemplating={isTemplating}
        isReply={false}
        isApproval={['approval'].includes(type)}
      />
      }

      <div className="CommentPlugin_CommentInputBox_Buttons">
        
        <div style={{marginRight: 'auto', margin: '1px auto 0px 6px'}}>
          {/*['comment'].includes(type) ?
          <FormControlLabel 
            control={<Checkbox size="small" color="primary" checked={isInternal} 
            disabled={isTemplating}
            onMouseDown={e => { e.preventDefault(); setIsInternal(!isInternal)}} // preventDefault to keep focus in text
            />}
            label={<Typography variant="subtitle2">Internal</Typography>}
          />
          :*/
          ['approval', 'param'].includes(type) && [1,2].includes(step) ?
          <Button
            onClick={e => setStep(0)}>
            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back
          </Button>
          :''}
        </div>

        {(['approval'].includes(type) && [0].includes(step)) ||
        (['param'].includes(type) && [0,1].includes(step) && !['canveo'].includes(param.libid)) ?
        <Button
          onClick={e => setStep(step + 1)}
          disabled={
            (['approval'].includes(type) && !Boolean(approverSelected)) ||
            (['param'].includes(type) && (
              (step === 0 && !Boolean(param.name)) ||
              (step === 0 && param.libid === 'new' && !Boolean(param.ref)) ||
              (step === 0 && param.libid === 'new' && state.params.lib.some((li) => li.name === param.name && li.ref === param.ref))
            ))
          }>
          Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
        </Button>
        :
        <Button
          onClick={['param'].includes(type) ? submitParam : submitComment}
          disabled={!canSubmit && !['param'].includes(type)}>
          Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
        </Button>
        }
      </div>
    </div>
  );
}

export default function WorkflowPlugin(props) {

  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const markNodeMap = useMemo(() => {
    return new Map();
  }, []);
  const [activeAnchorKey, setActiveAnchorKey] = useState(null);
  const [activeClauseKey, setActiveClauseKey] = useState(null);
  const [isClauseLibrary, setIsClauseLibrary] = useState(false);
  const [activeIDs, setActiveIDs] = useState([]);
  const [activeClauseItems, setActiveClauseItems] = useState({ cts: [], wfs: [] });
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showApprovalInput, setShowApprovalInput] = useState(false);
  const [showParamInput, setShowParamInput] = useState(false);
  const [predictedParam, setPredictedParam] = useState(null);

  const cancelAddWorkflow = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      // Restore selection
      if (selection !== null) {
        selection.dirty = true;
      }
    });
    setShowCommentInput(false);
    setShowApprovalInput(false);
    setShowParamInput(false);
    setPredictedParam(null);

  }, [editor]);

  /*
  const deleteComment = useCallback(
    (comment, thread) => {
      setComments((_comments) => {
        const nextComments = Array.from(_comments);

        if (thread !== undefined) {
          for (let i = 0; i < nextComments.length; i++) {
            const nextComment = nextComments[i];
            if (nextComment.type === 'thread' && nextComment.id === thread.id) {
              const newThread = cloneThread(nextComment);
              nextComments.splice(i, 1, newThread);
              const threadComments = newThread.comments;
              const index = threadComments.indexOf(comment);
              threadComments.splice(index, 1);
              if (threadComments.length === 0) {
                const threadIndex = nextComments.indexOf(newThread);
                nextComments.splice(threadIndex, 1);
                // Remove ids from associated marks
                const id = thread !== undefined ? thread.id : comment.id;
                const markNodeKeys = markNodeMap.get(id);
                if (markNodeKeys !== undefined) {
                  // Do async to avoid causing a React infinite loop
                  setTimeout(() => {
                    editor.update(() => {
                      for (const key of markNodeKeys) {
                        const node = $getNodeByKey(key);
                        if ($isMarkNode(node)) {
                          node.deleteID(id);
                          if (node.getIDs().length === 0) {
                            $unwrapMarkNode(node);
                          }
                        }
                      }
                    });
                  });
                }
              }
              break;
            }
          }
        } else {
          const index = nextComments.indexOf(comment);
          nextComments.splice(index, 1);
        }
        return nextComments;
      });
    },
    [editor, markNodeMap],
  );*/

  const createWorkflowAndAttachToDOM = useCallback(
    (
      newWF,
      emailRecipients,
      isInlineComment
    ) => {

      axios.post(state.settings.api + 'workflow', { workflow: newWF })
      .then((resWF) => {
        if(resWF.data.success) { // Add newly created WF to the reducer
          let createdWF = resWF.data.data;
          dispatch({ type: "ADD_WORKFLOW", payload: createdWF })
          if (isInlineComment) {
            
            attachWorkflowToDOM(resWF.data.data.lid)

            // Now send emails to recipients
            emailRecipients.forEach((r) => {
              axios.post(state.settings.api + 'mail/informcomm', {
                doc: r.doc, 
                whiteLabel: r.isTemplating ? null : null, // TODO 
                partyFullString: r.isTemplating ? null : null, // TODO 
                recipient: r.recipient, 
                isPublic: !r.isInternal,
                isTemplating: r.isTemplating,
                isApproval: ['approval'].includes(newWF.wfType),
                comment: r.content,
                clauseHTML: r.clauseHTML,
                wfid: createdWF._id, 
                lid: createdWF.lid,
              })
            })
          }
        }
      }).catch((err) => { console.log("err saving to workflow", err) })
    },
    [editor],
  );

  const attachWorkflowToDOM = (newlid) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const focus = selection.focus;
        const anchor = selection.anchor;
        const isBackward = selection.isBackward();
        const lid = newlid;

        // Wrap content in a MarkNode
        $wrapSelectionInMarkNode(selection, isBackward, lid);

        // Make selection collapsed at the end
        if (isBackward) {
          focus.set(anchor.key, anchor.offset, anchor.type);
        } else {
          anchor.set(focus.key, focus.offset, focus.type);
        }
      }
      setShowCommentInput(false);
    });
  }

  useEffect(() => {
    const changedElems = [];
    for (let i = 0; i < activeIDs.length; i++) {
      const id = activeIDs[i];
      const keys = markNodeMap.get(id);
      if (keys !== undefined) {
        for (const key of keys) {
          const elem = editor.getElementByKey(key);
          if (elem !== null) {
            elem.classList.add('selected');
            changedElems.push(elem);
          }
        }
      }
    }
    return () => {
      for (let i = 0; i < changedElems.length; i++) {
        const changedElem = changedElems[i];
        changedElem.classList.remove('selected');
      }
    };
  }, [activeIDs, editor, markNodeMap]);

  useEffect(() => {
    const markNodeKeysToIDs = new Map();

    return mergeRegister(
      registerNestedElementResolver(
        editor,
        MarkNode,
        (from) => {
          return $createMarkNode(from.getIDs());
        },
        (from, to) => {
          // Merge the IDs
          const ids = from.getIDs();
          ids.forEach((id) => {
            to.addID(id);
          });
        },
      ),
      editor.registerMutationListener(MarkNode, (mutations) => {
        for (const [key, mutation] of mutations) {
          const node = $getNodeByKey(key);
          let ids = [];

          if (mutation === 'destroyed') {
            ids = markNodeKeysToIDs.get(key) || [];
          } else if ($isMarkNode(node)) {
            ids = node.getIDs();
          }

          for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            let markNodeKeys = markNodeMap.get(id);
            markNodeKeysToIDs.set(key, ids);

            if (mutation === 'destroyed') {
              if (markNodeKeys !== undefined) {
                markNodeKeys.delete(key);
                if (markNodeKeys.size === 0) {
                  markNodeMap.delete(id);
                }
              }
            } else {
              if (markNodeKeys === undefined) {
                markNodeKeys = new Set();
                markNodeMap.set(id, markNodeKeys);
              }
              if (!markNodeKeys.has(key)) {
                markNodeKeys.add(key);
              }
            }
          }
        }
      }),
      editor.registerUpdateListener(({editorState, tags}) => {
        editorState.read(() => {
          const selection = $getSelection();
          let hasActiveIds = false;
          let hasAnchorKey = false;

          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();

            // Assign Active Workflow IDs (Comments, Approvals, Params)
            if ($isTextNode(anchorNode)) {
              const commentIDs = $getMarkIDs(
                anchorNode,
                selection.anchor.offset,
              );
              if (commentIDs !== null) {
                setActiveIDs(commentIDs);
                hasActiveIds = true;
              }
              if (!selection.isCollapsed()) {
                setActiveAnchorKey(anchorNode.getKey());
                hasAnchorKey = true;
              }
            }

            // Assign Active Clause Types
            //if ($isTextNode(anchorNode)) {
            let cNode = getClauseNode(anchorNode)
            setActiveClauseItems({
              cts: Boolean(cNode) ? cNode.getClauseTypes() : [],
              wfs: Boolean(cNode) ? cNode.getWorkflows() :[],
            });
            setActiveClauseKey(Boolean(cNode) ? cNode.getKey() : null)
            setIsClauseLibrary(
                Boolean(cNode) && Boolean(cNode.getLibIDs()) && 
                cNode.getLibIDs().some((clid) => clid.startsWith(state.org._id) &&
                  state.clauseLibItems.some((cli) => cli._id === clid.substring(clid.indexOf('_') + 1))))

          } else {
            setActiveClauseItems({ cts: [], wfs: [] })
            setActiveClauseKey(null)
            setIsClauseLibrary(false)
          }

          if (!hasActiveIds) {
            setActiveIDs((_activeIds) =>
              _activeIds.length === 0 ? _activeIds : [],
            );
          }
          if (!hasAnchorKey) {
            setActiveAnchorKey(null);
          }
        });
        if (!tags.has('collaboration')) {
          setShowCommentInput(false);
          setShowApprovalInput(false);
          setShowParamInput(false)
        }
      }),
      editor.registerCommand(
        INSERT_INLINE_COMMAND,
        (type) => {
          const domSelection = window.getSelection();
          domSelection.removeAllRanges();
          if(['comment'].includes(type)) {
            setShowCommentInput(true);
          } else if(['approval'].includes(type)) {
            setShowApprovalInput(true);
          } else if(['param'].includes(type)) {
            // Retrieve the SmartField type
            editor.getEditorState().read(() => {

              let selectedText = $getSelection().getTextContent()
              let values = greatPatternFinder(selectedText);
              setPredictedParam(values);

            })

            setShowParamInput(true);
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        REMOVE_MARK,
        (lid) => {
          const nodeMapArray = [...editor.getEditorState()._nodeMap];
          nodeMapArray.filter((n) => n[1].getType() === 'mark' && n[1].getIDs().includes(lid)).forEach((nodeKey) => {
            let node = nodeKey[1];
            node.deleteID(lid);
            if (node.getIDs().length === 0) {
              $unwrapMarkNode(node);
            }
          })
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      )
    );
  }, [editor, markNodeMap, state.clauseLibItems]);

  /*
  const getClauseTypesForNode = (node) => {
    let cts = []

    if(node.getType() === 'clause') {
      cts = node.getClauseTypes();
      let key = node.getKey();
      cts.forEach((ct) => ct = {...ct, key: key })
    } else if(Boolean(node.getParent())){
      cts = getClauseTypesForParent(node.getParent());
    }
    return cts;

  }

  function getClauseTypesForParent(node) {
    let cts = []

    if(node.getType() === 'root') {
      cts = []
    } else if(node.getType() === 'clause') {
      cts = node.getClauseTypes();
      let key = node.getKey();
      cts.forEach((ct) => ct = {...ct, key: key })
    } else if(Boolean(node.getParent())){
      cts = getClauseTypesForParent(node.getParent());
    }
    return cts;
  }

  function getClauseKey(node) {
    let key = null;
    if(node.getType() === 'root') {
      key = null
    } else if(node.getType() === 'clause') {
      key = node.getKey();
    } else if(Boolean(node.getParent())){
      key = getClauseKey(node.getParent());
    }
    return key;
  }*/

  const onAddComment = () => {
    editor.dispatchCommand(INSERT_INLINE_COMMAND, 'comment');
  };

  const onAddApproval = () => {
    editor.dispatchCommand(INSERT_INLINE_COMMAND, 'approval');
  };

  const onAddParam = () => {
    editor.dispatchCommand(INSERT_INLINE_COMMAND, 'param');
  };

  return (
    <>
      {(showCommentInput || showApprovalInput || showParamInput) &&
        createPortal(
          <CommentInputBox
            type={showApprovalInput ? 'approval' : showParamInput ? 'param' : 'comment'}
            predictedParam={predictedParam}
            partyID={props.partyID}
            docID={props.docID}
            isTemplating={props.isTemplating}
            editor={editor}
            user={state.user}
            cancelAddWorkflow={cancelAddWorkflow}
            createWorkflowAndAttachToDOM={createWorkflowAndAttachToDOM}
            attachWorkflowToDOM={attachWorkflowToDOM}
          />,
          document.body,
        )}

      {activeAnchorKey !== null && 
      !showCommentInput && !showApprovalInput && !showParamInput &&
        createPortal(
          <ContextMenu 
            visible={true} 
            onAddComment={onAddComment} 
            onAddApproval={onAddApproval}
            onAddParam={onAddParam}
          />,
          document.body,
      )}

      {activeAnchorKey === null && Boolean(activeClauseKey) &&
        createPortal(
          <ContextItems 
            visible={true}
            publicComments={activeIDs.filter((id) => id.startsWith('cp_'))}
            internalComments={activeIDs.filter((id) => id.startsWith('cp' + props.partyID.substring(5)))}
            //approvals={activeIDs.filter((id) => id.startsWith('ap' + props.partyID.substring(5)))}
            params={activeIDs.filter((id) => id.startsWith('pa' + props.partyID.substring(5)))}
            activeClauseItems={activeClauseItems}
            activeClauseKey={activeClauseKey}
            isClauseLibrary={isClauseLibrary}
            isTemplating={props.isTemplating}
            partyID={props.partyID}
            docID={props.docID}
          />,
          document.body,
      )}
    </>
  );
}