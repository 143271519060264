import { $isElementNode, $isRootNode, $isLeafNode, 
         $setSelection, $isRangeSelection, $getPreviousSelection  } from "lexical";

export function wrapLeafNodesInElements(
  selection,
  createElement,
  wrappingElement,
  targetParentType // X-add
) {
  const nodes = selection.getNodes();
  const nodesLength = nodes.length;
  const anchor = selection.anchor;

  if (
    nodesLength === 0 ||
    (nodesLength === 1 &&
      anchor.type === 'element' &&
      anchor.getNode().getChildrenSize() === 0)
  ) {
    const target =
      anchor.type === 'text'
        ? anchor.getNode().getParentOrThrow()
        : anchor.getNode();
    const children = target.getChildren();
    let element = createElement();
    children.forEach((child) => element.append(child));

    if (wrappingElement) {
      element = wrappingElement.append(element);
    }

    target.replace(element);

    return;
  }

  const firstNode = nodes[0];
  const elementMapping = new Map();
  const elements = [];
  // The below logic is to find the right target for us to
  // either insertAfter/insertBefore/append the corresponding
  // elements to. This is made more complicated due to nested
  // structures.
  let target = $isElementNode(firstNode)
    ? firstNode
    : firstNode.getParentOrThrow();

  if (target.isInline()) {
    target = target.getParentOrThrow();
  }

  while (target !== null) {
    const prevSibling = target.getPreviousSibling();

    if (prevSibling !== null) {
      target = prevSibling;
      break;
    }

    target = target.getParentOrThrow();

    if ((Boolean(targetParentType) && target.getType() === targetParentType) || $isRootNode(target)) { // X-change
      break;
    }
  }

  const emptyElements = new Set();

  // Find any top level empty elements
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i];

    if ($isElementNode(node) && node.getChildrenSize() === 0) {
      emptyElements.add(node.getKey());
    }
  }

  const movedLeafNodes = new Set();

  // Move out all leaf nodes into our elements array.
  // If we find a top level empty element, also move make
  // an element for that.
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i];
    let parent = node.getParent();

    if (parent !== null && parent.isInline()) {
      parent = parent.getParent();
    }

    if (
      parent !== null &&
      $isLeafNode(node) &&
      !movedLeafNodes.has(node.getKey())
    ) {
      const parentKey = parent.getKey();

      if (elementMapping.get(parentKey) === undefined) {
        const targetElement = createElement();
        elements.push(targetElement);
        elementMapping.set(parentKey, targetElement);
        // Move node and its siblings to the new
        // element.
        parent.getChildren().forEach((child) => {
          targetElement.append(child);
          movedLeafNodes.add(child.getKey());
        });
        $removeParentEmptyElements(parent, targetParentType); // X-change
      }
    } else if (emptyElements.has(node.getKey())) {
      elements.push(createElement());
      node.remove();
    }
  }

  if (wrappingElement) {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      wrappingElement.append(element);
    }
  }

  // If our target is the root, let's see if we can re-adjust
  // so that the target is the first child instead.
  if ((Boolean(targetParentType) && target.getType() === targetParentType) || $isRootNode(target)) { // X-change
    const firstChild = target.getFirstChild();

    if ($isElementNode(firstChild)) {
      target = firstChild;
    }
    console.log("x1")

    if (firstChild === null) {
      console.log("x2")
      if (wrappingElement) {
        console.log("x3a")
        target.append(wrappingElement);
      } else {
        console.log("x3b", elements)
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          target.append(element);
        }
      }
    } else {
      console.log("x4")
      if (wrappingElement) {
        firstChild.insertBefore(wrappingElement);
      } else {
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          firstChild.insertBefore(element);
        }
      }
    }
  } else {
    if (wrappingElement) {
      target.insertAfter(wrappingElement);
    } else {
      for (let i = elements.length - 1; i >= 0; i--) {
        const element = elements[i];
        target.insertAfter(element);
      }
    }
  }

  const prevSelection = $getPreviousSelection();

  if (
    $isRangeSelection(prevSelection) &&
    isPointAttached(prevSelection.anchor) &&
    isPointAttached(prevSelection.focus)
  ) {
    $setSelection(prevSelection.clone());
  } else {
    selection.dirty = true;
  }
}

function isPointAttached(point) {
  return point.getNode().isAttached();
}

function $removeParentEmptyElements(startingNode, targetParentType) {
  let node = startingNode;

  //console.log("removeParentEmptyElements", node)

  while (node !== null && !$isRootNode(node) && !(Boolean(targetParentType) && targetParentType === node.getType())) {
    const latest = node.getLatest();
    const parentNode = node.getParent();

    if (latest.__children.length === 0) {
      node.remove(true);
    }

    node = parentNode;
  }
}