import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { Collapse, Grid, IconButton, ListItemIcon, ListItemText,
         Menu, MenuItem, Tooltip } from '@mui/material';
import { ContextItem, DialogClause, DialogParam } from '.';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SELECTION_CHANGE_COMMAND, COMMAND_PRIORITY_HIGH, $getNodeByKey } from "lexical";
import { mergeRegister } from "@lexical/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookCircleArrowRight, faBookOpenCover, faFloppyDisk, faNote, faStar, faTag, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { analyzeNode } from './editor/utils';

const ContextItems = (props) => {

  const [editor] = useLexicalComposerContext();
  const [expanded, setExpanded] = React.useState(null);
  const [anchorElClauseStar, setAnchorElClauseStar] = React.useState(null);
  const [dialogClauseOpen, setDialogClauseOpen] = React.useState(null);
  const [dialogParamOpen, setDialogParamOpen] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [creatingInternalWorkflow, setCreatingInternalWorkflow] = React.useState(null)

  useEffect(() => {

    return mergeRegister(
        editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            setExpanded(null);
            return true;
        }, COMMAND_PRIORITY_HIGH )
    )
  }, [editor]);

  useEffect(() => {

    let newItems = []
    props.activeClauseItems.cts.forEach((ct) => { newItems.push({type: 'ct', id: ct}) })
    props.activeClauseItems.wfs.forEach((wf) => { newItems.push({type: wf.startsWith('ap') ? 'ap' : 'ic', id: wf}) })
    //props.internalComments.forEach((ic) => { newItems.push({type: 'ic', id: ic}) })
    props.publicComments.forEach((pc) => { newItems.push({type: 'pc', id: pc}) })

    //props.approvals.forEach((ap) => { newItems.push({type: 'ap', id: ap}) })
    props.params.forEach((pa) => { newItems.push({type: 'pa', id: pa}) })

    setItems(newItems);

  }, [props.activeClauseItems])

  const expandItem = (id) => {
    setExpanded(id);
    if(id === null) {
      setCreatingInternalWorkflow(null);
      handleCloseClauseStarMenu()
    }
  }

  const handleClauseAlternatives = () => {
    handleClauseStarMenuOption('alternativeClause');
  }

  const handleClauseStarMenuOption = (option) => {

    if(['assignTopic', 'insertLibrary', 'alternativeClause'].includes(option)) {
      handleCloseClauseStarMenu()
      setDialogClauseOpen({ 
          type: ['alternativeClause'].includes(option) ? 'alternativeclause' : ['insertLibrary'].includes(option) ? 'insertclause' : 'assigntopic', 
          activeCTs: props.activeClauseItems.cts, 
          activeClauseKey: props.activeClauseKey,
      });

    } else if(['createInternalComment', 'createInternalApproval'].includes(option)) {
      setAnchorElClauseStar(null);
      setCreatingInternalWorkflow(option);

    } else if(['saveLibrary'].includes(option)) {
      handleCloseClauseStarMenu()
      editor.getEditorState().read(() => {
          let clauseNode = $getNodeByKey(props.activeClauseKey)
          let clauseDetails = Boolean(clauseNode) ? analyzeNode(clauseNode.getTextContent()) : null
          let clauseText = Boolean(clauseDetails) ? clauseNode.getTextContent().substring(clauseDetails.endPositionOfLead).trim() : null

          if(Boolean(clauseNode) && Boolean(clauseText)) {
              setDialogClauseOpen({ 
                  type: 'savelibrary', 
                  activeCTs: props.activeClauseItems.cts, 
                  activeClauseKey: props.activeClauseKey,
                  clauseText: clauseText,
              })
          }
      })
    }
  }

  const closeClauseDialog = (action) => {
      if(Boolean(action)) {
        editor.dispatchCommand(action.command, action.payload);
      }
      setDialogClauseOpen(null);
  }

  const closeParamDialog = () => {
    setDialogParamOpen(null);
  }

  const openParamDialog = (paramID) => {
    setDialogParamOpen({ type: 'updateDocParam', paramID: paramID });
  }

  const handleOpenClauseStarMenu = (event) => {
    if(Boolean(props.activeClauseKey)) {
        editor.update(() => {
            let clauseNode = $getNodeByKey(props.activeClauseKey)
            clauseNode.changeFilter('in');
            const element = editor.getElementByKey(props.activeClauseKey)
            element.scrollIntoView({ block: 'center'});
            setAnchorElClauseStar(event.currentTarget);
        });
    }
  };

  const handleCloseClauseStarMenu = () => {
    if(Boolean(props.activeClauseKey)) {
        editor.update(() => {
            let clauseNode = $getNodeByKey(props.activeClauseKey)
            clauseNode.changeFilter('none');
            const element = editor.getElementByKey(props.activeClauseKey)
            element.scrollIntoView({ block: 'center'});
            setAnchorElClauseStar(null);
        });
    }
  }

  return (
    <div style={props.visible ? { 
        position: 'fixed',
        width: '260px', 
        right: '20px',
        top: '75px',
    } : {display: 'none'}}>

        {expanded === null && !Boolean(creatingInternalWorkflow) ?
        <Tooltip title="Clause Options" placement="left">
        <IconButton 
        onClick={handleOpenClauseStarMenu}
        sx={{
            backgroundColor: Boolean(anchorElClauseStar) ? theme.palette.primary.main : theme.palette.primary.contrastText,
            height: '50px', 
            width: '50px', 
            borderRadius: '50%', 
            marginLeft: '210px', 
            marginBottom: '10px',
            border: '1px solid' + theme.palette.grey[200],
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px'
        }}>
            <FontAwesomeIcon icon={faStar} style={{fontSize: '20px', 
            color: 
                props.isClauseLibrary ?
                    '#f0b13d' :
                Boolean(anchorElClauseStar) ? 
                    theme.palette.primary.contrastText : theme.palette.grey[600] }} />
        </IconButton>
        </Tooltip>
        :''}

        <Menu
        sx={{ mt: '55px', mr: '200px' }}
        id="menu-appbar-filter"
        anchorEl={anchorElClauseStar}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        disableScrollLock={true}
        open={Boolean(anchorElClauseStar)}
        onClose={handleCloseClauseStarMenu}
        >

        {[
            { id: 'createInternalComment', name: "Add internal note", icon: faNote },
            { id: 'createInternalApproval', name: "Send for internal approval", icon: faThumbsUp },
            { id: 'assignTopic', name: "Assign clause topic", icon: faTag },
            ...!props.isClauseLibrary ? [{ id: 'saveLibrary', name: "Save to clause library", icon: faFloppyDisk }] : [],
            { id: 'insertLibrary', name: "Insert from library", icon: faBookCircleArrowRight },
            
        ].map((m, i) => (

        <MenuItem key={m.id} onClick={e => handleClauseStarMenuOption(m.id)} style={{width: '260px'}}>
            <ListItemIcon>
                {Boolean(m.icon) ?
                <FontAwesomeIcon icon={m.icon} />
                :''}
            </ListItemIcon>
            <ListItemText>{m.name}</ListItemText>
        </MenuItem>
        ))}
        </Menu>

        {Boolean(creatingInternalWorkflow) ?
          <ContextItem
          type={creatingInternalWorkflow}
          expandItem={expandItem}
          isExpanded={true}
          activeClauseKey={props.activeClauseKey}
          keyAction={e => null}
          isTemplating={props.isTemplating}
          partyID={props.partyID}
          docID={props.docID}
          />
        :''}

        {items.map((item) => (
            <Collapse in={(expanded === null || expanded === item.id) && !Boolean(anchorElClauseStar) && !Boolean(creatingInternalWorkflow)} 
            key={item.id}>
            <ContextItem
            id={item.id}
            type={
              ['ct'].includes(item.type) ? 
                  "clauseType" : 
              ['ic'].includes(item.type) ? 
                  "internalComment" :
              ['pc'].includes(item.type) ?
                  "publicComment" :
              ['ap'].includes(item.type) ?
                  "approval" :
              ['pa'].includes(item.type) ?
                  "param" :
                  null}
            expandItem={expandItem}
            isExpanded={expanded === item.id}
            activeClauseKey={props.activeClauseKey}
            keyAction={
              ['ct'].includes(item.type) ? 
                  e => handleClauseAlternatives () :
              ['pa'].includes(item.type) ?
                  e => openParamDialog(item.id) :
                  e => null}
            isTemplating={props.isTemplating}
            />
            </Collapse>
        ))}

        {/*props.activeCTs.map((ct) => (
            <Collapse in={(expanded === null || expanded === ct) && !Boolean(anchorElClauseStar)} key={ct}>
            <ContextItem
            id={ct}
            type="clauseType"
            expandItem={expandItem}
            isExpanded={expanded === ct}
            activeClauseKey={props.activeClauseKey}
            keyAction={handleClauseAlternatives}
            isTemplating={props.isTemplating}
            />
            </Collapse>
        ))*/}

        {/*props.internalComments.map((ic) => (
            <Collapse in={(expanded === null || expanded === ic) && !Boolean(anchorElClauseStar)} key={ic}>
            <ContextItem
            id={ic}
            type="internalComment"
            expandItem={expandItem}
            isExpanded={expanded === ic}
            activeClauseKey={props.activeClauseKey}
            isTemplating={props.isTemplating}
            />
            </Collapse>
        ))*/}

        {/*props.publicComments.map((pc) => (
            <Collapse in={(expanded === null || expanded === pc) && !Boolean(anchorElClauseStar)} key={pc}>
            <ContextItem
            id={pc}
            type="publicComment"
            expandItem={expandItem}
            isExpanded={expanded === pc}
            activeClauseKey={props.activeClauseKey}
            isTemplating={props.isTemplating}
            />
            </Collapse>
        ))*/}

        {/*props.approvals.map((ap) => (
            <Collapse in={(expanded === null || expanded === ap) && !Boolean(anchorElClauseStar)} key={ap}>
            <ContextItem
            id={ap}
            type="approval"
            expandItem={expandItem}
            isExpanded={expanded === ap}
            activeClauseKey={props.activeClauseKey}
            isTemplating={props.isTemplating}
            />
            </Collapse>
        ))*/}

        {/*props.params.map((pa) => (
            <Collapse in={(expanded === null || expanded === pa) && !Boolean(anchorElClauseStar)} key={pa}>
            <ContextItem
            id={pa}
            type="param"
            expandItem={expandItem}
            isExpanded={expanded === pa}
            activeClauseKey={props.activeClauseKey}
            isTemplating={props.isTemplating}
            keyAction={openParamDialog}
            />
            </Collapse>
        ))*/}

      <DialogClause
        open={Boolean(dialogClauseOpen)}
        details={dialogClauseOpen}
        closeDialog={closeClauseDialog}
      />

      <DialogParam
        open={Boolean(dialogParamOpen)}
        details={dialogParamOpen}
        closeDialog={closeParamDialog}
      />
        
    </div>
  );
};
export default ContextItems;
