import React, { useContext } from 'react';
import theme from '../theme/theme';
import { useParams } from "react-router-dom";
import { globalStore } from '../state/store';
import { countries } from '../assets/static'
import { countryToFlag, getAddressForEntity, trunc } from '../utils';

import { Avatar, Box, Button, Collapse, FormControl, FormControlLabel, 
         FormGroup, FormLabel, Grid, InputAdornment, List, ListItemButton, 
         ListItemAvatar, ListItemText, Pagination, PaginationItem, RadioGroup, Radio,
         Snackbar, Stack, Typography, IconButton, Switch, TextField } from "@mui/material";

import { CanveoCircularProgress, CoreDrawer, DialogEntity, DialogUser, FabStandard, Header,
         SelectUserForOrg, UploadImg } from '../components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity, faUserPlus, faEye, faTrash, faArrowLeft, faFill, faPaintbrush } from '@fortawesome/pro-solid-svg-icons';

import defaultUser from '../assets/img/defaultuser.png';
import hubspot from '../assets/img/hubspot.png';
import salesforce from '../assets/img/salesforce.png';
import axios from 'axios';

const Admin = () => {

  const { section } = useParams();
  const pageSize = 10;

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [curPage, setCurPage] = React.useState(1)
  //const [users, setUsers] = React.useState([])
  const [dialogEntityOpen, setDialogEntityOpen] = React.useState(null)
  const [dialogUserOpen, setDialogUserOpen] = React.useState(null)
  const [addingSignerForEntity, setAddingSignerForEntity] = React.useState(null) // {ent, user}
  const [whiteLabelErrMsg, setWhiteLabelErrMsg] = React.useState(null)
  const [whiteLabelColor, setWhiteLabelColor] = React.useState(
      state.org.orgSettings !== undefined && state.org.orgSettings.whiteLabel !== undefined &&
      state.org.orgSettings.whiteLabel !== null && state.org.orgSettings.whiteLabel.color !== undefined ? 
          state.org.orgSettings.whiteLabel.color : theme.palette.secondary.main)
  const [whiteLabelContrast, setWhiteLabelContrast] = React.useState(
      state.org.orgSettings !== undefined && state.org.orgSettings.whiteLabel !== undefined &&
      state.org.orgSettings.whiteLabel !== null && state.org.orgSettings.whiteLabel.contrastColor !== undefined ? 
          state.org.orgSettings.whiteLabel.contrastColor : theme.palette.secondary.contrastText)

  /*
  const dbusers = [
    { _id: "usr1", firstName: "Rob", lastName: "Wegbrands", displayName: "Rob Wegbrands", title: 'Co-Founder', email: "rweg@getcanveo.com", phone: "+1571529011", photoURL: "https://storage.googleapis.com/ctimages/rOw5mC1yo8d5shlhzqu0", role: "Admin", readOnly: false, active: true },
    { _id: "usr2", firstName: "Remy", lastName: "Sharp", displayName: "Remy Sharp", title: 'Sales Manager', email: "remy@getcanveo.com", phone: "", photoURL: "https://mui.com/static/images/avatar/1.jpg", role: "Business", readOnly: false, active: true },
    { _id: "usr3", firstName: "Travis", lastName: "Howard", displayName: "Travis Howard", title: 'Legal', email: "travis@getcanveo.com", phone: "", photoURL: "", role: "Legal", readOnly: false, active: true },
    { _id: "usr4", firstName: "Agnes", lastName: "Walker", displayName: "Agnes Walker", title: 'Sales associate', email: "agnes@getcanveo.com", phone: "+1234567789", photoURL: "https://mui.com/static/images/avatar/4.jpg", role: "Business", readOnly: true, active: true },
  ]*/
  /*
  useEffect(() => {
    if(section === 'users') {
        //todo: pull from DB when loading "users" section, can pull only active
        //setUsers(state.users.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1))

    } else
    if(section === 'signers') {
      let signerIdsToPull = []
      state.subs
      .filter((s) => s.active)
      .forEach((s) => {
        if(s.defaultSigners !== undefined && s.defaultSigners !== null && s.defaultSigners.length > 0) {
          s.defaultSigners.forEach((ds) => {
            if(!signerIdsToPull.some((sitp) => sitp === ds)) {
              signerIdsToPull.push(ds);
            }
          })
        }
      })
      // Here comes the pulling of users: signerIdsToPull
      // todo: pull users that are default signers
      setUsers(state.users.filter((dbu) => signerIdsToPull.some((sitp) => sitp === dbu._id)))

    }

  }, [section])*/

  const closeEntityDialog = (action) => {
    if(['snackEntCreated', 'snackEntUpdated'].includes(action)) {
      dispatch({ 
        type: "NEW_SNACKBAR", 
        payload: 
          ['snackEntCreated'].includes(action) ?
            "The entity was created" :
          ['snackEntUpdated'].includes(action) ?
            "The entity was updated" : ""
      })
    }
    setDialogEntityOpen(null)
  }

  const closeUserDialog = (snack, newUser) => {
  
    if(['snackUserCreated', 'snackUserUpdated', 'snackUserDeleted'].includes(snack)) {
      dispatch({ type: ['snackUserCreated'].includes(snack) ? "ADD_USERS" : "UPDATED_USERS", payload: newUser })
      dispatch({ 
        type: "NEW_SNACKBAR", 
        payload: 
          ['snackUserCreated'].includes(snack) ?
            "The user was created, a welcoming e-mail has been sent" :
          ['snackUserUpdated'].includes(snack) ?
            "The user has been updated" :
          ['snackUserDeleted'].includes(snack) ?
            "The user has been deleted" : ""
      })
    }
    setDialogUserOpen(null)
  }

  const removeDefaultSignerForEntity = (uid, entity) => {
    console.log("TODO: SEND TO DATABASE FOR UPDATING")
    let newent = entity;
    newent.defaultSigners = entity.defaultSigners.filter((ds) => ds !== uid)
    pushNewEntity(newent)
  }

  const handleSubmitNewDefaultSigner = () => {

    if(addingSignerForEntity.ent !== undefined && addingSignerForEntity.ent._id !== undefined &&
    addingSignerForEntity.user !== undefined && addingSignerForEntity.user._id !== undefined) {

      let newent = addingSignerForEntity.ent;
      let newDS = newent.defaultSigners !== undefined && newent.defaultSigners !== null && newent.defaultSigners.length > 0 ? newent.defaultSigners : []
      if(!newDS.some((ds) => ds === addingSignerForEntity.user._id)) { newDS.push(addingSignerForEntity.user._id) }
      newent.defaultSigners = newDS
      pushNewEntity(newent)

    }
    setAddingSignerForEntity(null)
  }

  const pushNewEntity = (newent) => {
    axios.put(state.settings.api + "entity/" + newent._id, { entity: newent })
    .then((resEnt) => {
      if(resEnt.data.success) { // Add newly updated entity to the reducer
        dispatch({ type: "UPDATE_SUB", payload: newent })
        dispatch({ type: "NEW_SNACKBAR", payload: "The entity was updated"})
      } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the entity"}) }
    }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the entity"}) })
  }

  const handleSettingsChange = (setting, newVal) => {

    // todo: update ORG in state
    let newOrg = state.org
    let newOrgSettings = newOrg.orgSettings !== undefined && newOrg.orgSettings !== null ? newOrg.orgSettings : {} 
    
    if(['headerInPdf'].includes(setting)) {
      newOrgSettings.headerInPdf = newVal;
    } else if(['whiteLabel'].includes(setting)){
      // Default the whiteLabel setting if it didn't fully exist yet
      let newWL = newOrgSettings.whiteLabel !== undefined && newOrgSettings.whiteLabel !== null &&
        newOrgSettings.whiteLabel.orgName !== undefined && newOrgSettings.whiteLabel.logoURL !== undefined &&
        newOrgSettings.whiteLabel.color !== undefined && newOrgSettings.whiteLabel.contrastColor !== undefined ?
            newOrgSettings.whiteLabel :
            {
              orgName: state.org.shortName, 
              logoURL: state.org.logoURL,
              color: whiteLabelColor,
              contrastColor: whiteLabelContrast
            }

      newWL.level = newVal;
      newOrgSettings.whiteLabel = newWL
    }
    newOrg.orgSettings = newOrgSettings;
    console.log("newOrg", newOrg)
    pushOrg(newOrg);
  }

  const handleWhiteLabelColorChange = (type, newColor) => {

    let newOrg = state.org

    let newWhiteLabel = newOrg.orgSettings.whiteLabel

    if(!newColor.startsWith('#')) { newColor = '#' + newColor }
    if(['color', 'contrast'].includes(type) && /^#[0-9A-F]{6}$/i.test(newColor) &&
    newWhiteLabel !== undefined && newWhiteLabel !== null) {
      setWhiteLabelErrMsg(null)

      if(['color'].includes(type)) { 
        newWhiteLabel.color = newColor; 
        setWhiteLabelColor(newColor);
      } else if(['contrast'].includes(type)) { 
        newWhiteLabel.contrastColor = newColor; 
        setWhiteLabelContrast(newColor);
      }
      
      newOrg.whiteLabel = newWhiteLabel;
      pushOrg(newOrg);
      //dispatch({ type: "UPDATE_ORG", payload: newOrg })
      //console.log("TODO UPDATE DB WHITE NEW WHITELABEL")

    } else {
      setWhiteLabelErrMsg("Please provide a valid HEX color code")
    }

  }

  const handleInstallIntegration = (newVal, integration) => {

    if(newVal && ['hubspot', 'salesforce'].includes(integration)) {

      // TODO NEW INTEGRAITON
      // NOTE FOR HUBSPOT: CHANGES from refreshToken string to Object { refreshToken }
      /*
      axios.get('/integration/protect/install/' + integration)
      .then((res) => { // rerouting to the Integration Install page
        window.location.assign(res.data.data)
      }).catch((err) => console.log("err", err))*/

    } else if (['hubspot', 'salesforce'].includes(integration) && !newVal) { // De-install integration

      let newIntegrations = state.org.integrations !== undefined && state.org.integrations !== null ? state.org.integrations : {}
      if(['hubspot'].includes(integration)) {
        delete newIntegrations.hubspot
      } else if(['salesforce'].includes(integration)) {
        delete newIntegrations.salesforce
      }

      let newOrg = state.org
      newOrg.integrations = newIntegrations
      pushOrg(newOrg);

    }
  }

  const handleSubmitImage = (child) => {
    if(child) { // A new logoURL was submitted
      setLoading(true)
      axios.put(state.settings.api + "org/orglogo/" + state.org._id, { newLogo: child })
      .then((res) => {

        if(res.data.success) {
          let newOrg = state.org;
          newOrg.logoURL = child;
          dispatch({ type: "UPDATE_ORG", payload: newOrg })
          state.subs.forEach((s) => {
            s.logoURL = child;
            dispatch({ type: "UPDATE_CPENT", payload: s })
          })
          setLoading(false)
          dispatch({ type: "NEW_SNACKBAR", payload: "Company settings were updated" })
          
        } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the company settings" }) }
      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the company settings" }); setLoading(false); })
    }
  }

  const pushOrg = (newOrg) => {
    axios.put(state.settings.api + "org/" + newOrg._id, { org: newOrg })
    .then((resOrg) => {
      if(resOrg.data.success) {
        dispatch({ type: "UPDATE_ORG", payload: resOrg.data.data })
        dispatch({ type: "NEW_SNACKBAR", payload: "Company settings were updated" });
      } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the company settings" }) }
    }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the company settings" }); })
  }

  return (
    <div>

      <Box sx={{display: 'flex', //justifyContent: 'center',
            width: '100%', margin: '0px', padding: '0px'/*, backgroundColor: '#ffeeff'*/ }}>

        <Header page={"Admin"} />
        <CoreDrawer page={"Admin"} active={section} variant={null} />

        <Box sx={{ my: 8, mx: 3, width: '100%' }}>

          { 
          loading ? 
          <Box>
            <Grid container direction="column" alignItems="center" sx={{mt: 15}}>
              <CanveoCircularProgress />
            </Grid>
          </Box>
          :
          // Manage Entities
          ['entities'].includes(section) ?
          <Box>
            <FabStandard
            click={e => setDialogEntityOpen({type: 'newsub'})}
            text="New"
            icon={faUniversity}
            />
            <Grid container direction="column" alignItems="center" sx={{my: 5}}>
              <Grid item>
                <Typography variant="h4">Manage Entities</Typography>
              </Grid>
              <Grid item sx={{mt: 5}}>
                <List sx={{width: '400px'}}>
                {state.subs
                .filter((s) => s.active)
                .sort((a, b) => (a.legalName > b.legalName) ? 1 : -1)
                .slice((curPage - 1) * pageSize, curPage * pageSize)
                .map((s) => (
                  <ListItemButton key={s._id} onClick={e => setDialogEntityOpen({type: 'updsub', ent: s})}>
                    <ListItemAvatar>
                      <Avatar src={s.logoURL} style={{backgroundColor: theme.palette.primary.contrastText}} />
                    </ListItemAvatar>
                    <ListItemText 
                      primary={s.legalName} 
                      secondary={
                        countries.filter((c) => c.code === getAddressForEntity(s, 'billto').country)[0] !== undefined ?
                            <>
                              {countries.filter((c) => c.code === getAddressForEntity(s, 'billto').country)[0].label}&nbsp;&nbsp;
                              {countryToFlag(getAddressForEntity(s, 'billto').country)}
                            </> 
                            :
                            "Country not defined"} />
                  </ListItemButton>
                  ))}
                </List>
              </Grid>
              <Grid item sx={{ mt: 5}}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(state.subs.filter((s) => s.active).length / pageSize)}
                    defaultPage={1}
                    page={curPage}
                    onChange={(e, newVal) => setCurPage(newVal)}
                    siblingCount={0}
                    boundaryCount={2}
                    renderItem={(item) => (
                      <PaginationItem {...item} />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          :
          // Manage Users
          ['users'].includes(section) ?
          <Box>
            <FabStandard
            click={e => setDialogUserOpen({ type: 'newusr' })}
            text="New"
            icon={faUserPlus}
            />
            <Grid container direction="column" alignItems="center" sx={{my: 5}}>
              <Grid item>
                <Typography variant="h4">Manage Users</Typography>
              </Grid>
              <Grid item sx={{mt: 5}}>
                <List sx={{width: '400px'}}>
                {state.users
                .filter((u) => u.active)
                .slice((curPage - 1) * pageSize, curPage * pageSize)
                .map((u) => (
                  <ListItemButton key={u._id} onClick={e => setDialogUserOpen({type: 'updusr', user: u})}>
                    <ListItemAvatar>
                      <Avatar src={u.photoURL} />
                    </ListItemAvatar>
                    <ListItemText 
                      primary={u.displayName} 
                      secondary={
                        <>
                        <span style={{ fontWeight: '700',
                        color: 
                          ['Admin'].includes(u.role) ? 
                              theme.palette.secondary.main :
                          ['Legal'].includes(u.role) ? 
                              theme.palette.primary.main :
                          ['Business'].includes(u.role) ? 
                              theme.palette.tertiary.main : theme.palette.grey[700]}}>
                          {u.role}
                          {u.readOnly ? 
                          <>&nbsp;&nbsp;<FontAwesomeIcon icon={faEye} /></> : ''}                          
                        </span>
                        <span style={{color: theme.palette.grey[600]}}>{trunc(" - " + u.title,20)}</span>
                        </>} />
                  </ListItemButton>
                  ))}
                </List>
              </Grid>
              <Grid item sx={{ mt: 5}}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(state.users.filter((u) => u.active).length / pageSize)}
                    defaultPage={1}
                    page={curPage}
                    onChange={(e, newVal) => setCurPage(newVal)}
                    siblingCount={0}
                    boundaryCount={2}
                    renderItem={(item) => (
                      <PaginationItem {...item} />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          :
          // Manage Default Signers
          ['signers'].includes(section) ?
          <Box>
            <Grid container direction="column" alignItems="center" sx={{my: 5}}>
              <Grid item>
                <Typography variant="h4">Default Signers</Typography>
              </Grid>

              {Boolean(addingSignerForEntity) ?
              <Grid item sx={{my: 5, width: '320px'}}>
                <Typography align="center" style={{marginBottom: '40px'}}>  
                  Select the default signer for<br/>
                  <span className="bold">{addingSignerForEntity.ent.legalName}</span>:
                </Typography>
                <SelectUserForOrg
                  orgID={state.user.orgID}
                  handleSelectUser={e => setAddingSignerForEntity({...addingSignerForEntity, user: e})}
                  userSelected={addingSignerForEntity.user}
                />
                <Button variant="contained" disableElevation fullWidth sx={{mt:2}}
                onClick={handleSubmitNewDefaultSigner}>Submit</Button>
                <Button variant="text" sx={{mt:1}} onClick={e => setAddingSignerForEntity(null)}>
                  <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel
                </Button>
              </Grid>
              :
              <>
              <Grid item sx={{mt: 5}}>

                {state.subs
                .filter((s) => s.active)
                .sort((a, b) => (a.legalName > b.legalName) ? 1 : -1)
                .slice((curPage - 1) * pageSize, curPage * pageSize)
                .map((s, i) => (

                  <Grid container direction="row" key={i} 
                  sx={{py: 3, px: 2, borderBottom: '1px dotted' + theme.palette.grey[300], 
                  borderTop: i === 0 ? '1px dotted' + theme.palette.grey[300] : '0px'}}>
                    <Grid item sx={{width: '300px'}}>
                      <Typography variant="subtitle1">
                        {trunc(s.legalName, 26)}
                        {getAddressForEntity(s, "billto").country !== undefined ?
                          <>&nbsp;&nbsp;{countryToFlag(getAddressForEntity(s, "billto").country)}</> :
                          ''}
                      </Typography>
                    </Grid>
                    <Grid item sx={{width: '260px'}}>
                      {s.defaultSigners !== undefined && s.defaultSigners !== null &&
                      s.defaultSigners.length > 0 ?

                        s.defaultSigners.map((ds, j) => (
                          <Grid direction="row" container alignItems={"center"} key={j}>
                            <Grid item>
                              <Avatar src={state.users.filter((u) => u._id === ds)[0] !== undefined ?
                                state.users.filter((u) => u._id === ds)[0].photoURL : defaultUser} 
                                style={{width: '30px', height: '30px', margin: '5px 10px 5px 0px'}}
                              />
                            </Grid>
                            <Grid item>
                              <Typography key={i + "_" + ds}>
                                {state.users.filter((u) => u._id === ds)[0] !== undefined ?
                                    trunc(state.users.filter((u) => u._id === ds)[0].displayName,20) :
                                    "Unknown"}
                              </Typography>
                            </Grid>
                            <Grid item sx={{paddingLeft: '10px'}}>
                              <IconButton onClick={e => removeDefaultSignerForEntity(ds, s)}>
                                <FontAwesomeIcon icon={faTrash} style={{color: theme.palette.error.main, fontSize: '14px', padding: '4px 5px'}} />
                              </IconButton>

                            </Grid>
                          </Grid>
                          
                        ))


                      :
                        <Typography color="textSecondary">
                          No default signers defined
                        </Typography>
                      }
                      <Button color="primary" size="small" variant="contained" disableElevation
                      sx={{mt:2, padding: '2px 20px'}} onClick={e => setAddingSignerForEntity({ent: s, user: {}})}>
                        <FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;Add Default Signer
                      </Button>

                    </Grid>

                  </Grid>

                ))}

                
              </Grid>
              <Grid item sx={{ mt: 5}}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(state.subs.filter((s) => s.active).length / pageSize)}
                    defaultPage={1}
                    page={curPage}
                    onChange={(e, newVal) => setCurPage(newVal)}
                    siblingCount={0}
                    boundaryCount={2}
                    renderItem={(item) => (
                      <PaginationItem {...item} />
                    )}
                  />
                </Stack>
              </Grid>
              </>
              }
            </Grid>
          </Box>
          :
          // Manage Branding
          ['branding'].includes(section) ?
          <Box>

            <Grid container direction="column" alignItems="center">
              <Grid item sx={{mt: 4, mb: 2}}>
                <UploadImg 
                type="logo" 
                handleSubmitImage={handleSubmitImage}
                initialImg={state.org.logoURL} />
              </Grid>
              <Grid item>

                <Box sx={{mb:3}}>
                  <FormControl>
                    <FormLabel>PDF Branding</FormLabel>
                    <RadioGroup row name="headerInPdf"
                    value={state.org.orgSettings !== undefined && state.org.orgSettings !== null && state.org.orgSettings.headerInPdf}
                    onChange={e => handleSettingsChange('headerInPdf', e.target.value)}
                    >
                      <FormControlLabel value={false} control={<Radio />} label="None" />
                      <FormControlLabel value={true} control={<Radio />} label={"Our Logo + Address"} />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{mb:5}}>
                  <FormControl>
                    <FormLabel>White Label counterparty emails</FormLabel>
                    <RadioGroup name="whiteLabel"
                    row value={state.org.orgSettings !== undefined && state.org.orgSettings !== null && state.org.orgSettings.whiteLabel !== undefined &&
                    state.org.orgSettings.whiteLabel !== null && ['email'].includes(state.org.orgSettings.whiteLabel.level) ?
                    state.org.orgSettings.whiteLabel.level : "none"}
                    onChange={e => handleSettingsChange('whiteLabel', e.target.value)}
                    >
                      <FormControlLabel value="none" control={<Radio />} label="None" />
                      <FormControlLabel value="email" control={<Radio />} label="Our Logo + Colors" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Collapse in={state.org.orgSettings !== undefined && 
                state.org.orgSettings.whiteLabel !== undefined && 
                state.org.orgSettings.whiteLabel !== null && 
                ["email"].includes(state.org.orgSettings.whiteLabel.level)}>
                <Grid item container direction="column" alignItems={"center"}>

                  <Grid item>

                    <div style={{
                      fontWeight: '500',
                      backgroundColor: whiteLabelColor,
                      color: whiteLabelContrast,
                      width: '160px',
                      //height: '30px',
                      padding: '6px 15px 6px 15px',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}>Sample Button</div>
                  
                  </Grid>
                  {whiteLabelErrMsg !== null ? 
                  <Grid item sx={{mt:5}}>
                    <Typography align="center" color="error">{whiteLabelErrMsg}</Typography>
                  </Grid>
                  :''}
                  <Grid item sx={{mt:5}}>
                    <TextField
                      id="outlined-name"
                      label="Accent Color"
                      placeholder="HEX Code..."
                      defaultValue={whiteLabelColor}
                      onBlur={e => handleWhiteLabelColorChange('color', e.target.value)}
                      InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faFill} />
                        </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item sx={{mt:2}}>
                    <TextField
                      id="outlined-name"
                      label="Contrast Color"
                      placeholder="HEX Code..."
                      defaultValue={whiteLabelContrast}
                      onBlur={e => handleWhiteLabelColorChange('contrast', e.target.value)}
                      InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <FontAwesomeIcon icon={faPaintbrush} />
                        </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                </Grid>
              </Collapse>

            </Grid>

          </Box>
          :
          // Manage Subscription
          ['subscription'].includes(section) ?
          <Box>
            <Grid container direction="column" alignItems="center" sx={{my:5}}>
              <Grid item>
                <Typography variant="h4">Subscription Details</Typography>
              </Grid>
              <Grid item>

                <FormGroup name="integrations">
                <Box align="center" sx={{my: 5}}>
                  <Box align="left" mt={1} mb={1} style={{width: '140px'}}>
                  <FormControlLabel 
                  control={
                    <Switch
                    checked={state.org !== undefined && state.org.integrations !== undefined && state.org.integrations !== null && 
                      state.org.integrations.salesforce !== undefined && state.org.integrations.salesforce !== null ? true : false}
                    onChange={e => handleInstallIntegration(e.target.checked, 'salesforce')} />} 
                  label={<img src={salesforce} alt="Salesforce" width="60" style={{margin: '7px 5px 0px 5px'}} />}
                  />
                  </Box>
                  <Box align="left" mt={1} mb={1} style={{width: '140px'}}>
                  <FormControlLabel 
                  control={
                    <Switch
                    checked={state.org !== undefined && state.org.integrations !== undefined && state.org.integrations !== null && 
                      state.org.integrations.hubspot !== undefined && state.org.integrations.hubspot !== null ? true : false}
                    onChange={e => handleInstallIntegration(e.target.checked, 'hubspot')} />} 
                  label={<img src={hubspot} alt="Hubspot" width="70" style={{margin: '7px 5px 0px 5px'}} />}
                  />
                  </Box>
                </Box>
                </FormGroup>

              </Grid>

              {/* todo
              <Grid item sx={{mt: 4, mb: 2}}>
              Subscription details coming soon.
              </Grid>*/}

            </Grid>
          </Box>
          : '' }
        </Box>
      </Box>

      <DialogEntity
        open={Boolean(dialogEntityOpen)}
        closeDialog={closeEntityDialog}
        type={dialogEntityOpen !== null && dialogEntityOpen.type !== undefined ? dialogEntityOpen.type : ''}
        ent={dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined ? dialogEntityOpen.ent : ''}
      />

      <DialogUser
        open={Boolean(dialogUserOpen)}
        closeDialog={closeUserDialog}
        type={dialogUserOpen !== null && dialogUserOpen.type !== undefined ? dialogUserOpen.type : ''}
        userToUpdate={dialogUserOpen !== null && dialogUserOpen.user !== undefined ? dialogUserOpen.user : ''}
      />

    </div>
  );
};
export default Admin;