export function hasRedline(node) {
    let isRedline = false;
    if(['redline'].includes(node.getType())) {
      isRedline = true;
    } else if(Boolean(node.__children) && node.__children.length > 0) {
      node.getChildren().forEach((n) => {
        if(hasRedline(n)) {
          isRedline = true;
        }
      })
    }
    return isRedline;
}