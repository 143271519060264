import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { useNavigate } from "react-router-dom";
import { Autocomplete, Avatar, Box, Chip, CircularProgress, Grid,
         InputAdornment, Popper, TextField, createFilterOptions, styled, Typography } from '@mui/material';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faFileLines, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { countryToFlag } from '../utils';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    borderRadius: '40px',
    padding: '20px',
    '& fieldset': {
      borderColor: 'transparent',
      boxShadow: 'rgba(0, 0, 0, 0.10) 0px 3px 34px 0px',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
      boxShadow: 'rgba(0, 0, 0, 0.20) 0px 3px 34px 0px',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
      boxShadow: 'rgba(0, 0, 0, 0.20) 0px 3px 34px 0px',
    },
  },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-groupLabel': {
    //backgroundColor: theme.palette.primary.main,
    //color: theme.palette.common.white,
    fontWeight: '700',
    color: theme.palette.grey[900],
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  '& .MuiAutocomplete-option': {
    //padding: '15px 15px 15px 60px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  //'& .MuiAutocomplete-paper': {
    //backgroundColor: '#ff0000',
  //}
  
}));


export default function SearchBar(props) {

  const styles = { 
    avIcon: { backgroundColor: theme.palette.primary.contrastText },
    chipSearch: { padding: '2px 7px', marginRight: '10px', fontWeight: '600'}
  }

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState([])
  const [searchSelection, setSearchSelection] = React.useState([])

  const filter = createFilterOptions();

  useEffect(() => { // Need to report to parent whether search selection has at least one chip

    if(searchSelection.length > 0) { props.setIsSearching(true); } 
    else { props.setIsSearching(false); }

  },[searchSelection])

  // TODO: Generate search options based on typing - in order to scale e.g. 1000s of cpents, etc. 
  useEffect(() => {
    let so = []
    // INITIAL OPTIONS
    //navigate('/new')
    //const navigate = useNavigate();
    so.push(
      { title: "Create New Agreement", category: "Quick Links", id: "createnew", 
        chip: <Chip icon={<FontAwesomeIcon icon={faCirclePlus} />} label={"New"} color="secondary" style={styles.chipSearch} /> })


    state.agrTypes // AGREEMENT TYPES
    .sort((a, b) => (a.fullName[0] > b.fullName[0]) ? 1 : -1)
    .forEach((at) => {
        so.push({ title: at.fullName[0], category: "Agreement Type", id: at._id,
            avatar: <Avatar style={styles.avIcon} alt={at.fullName[0]}><FontAwesomeIcon icon={faFileLines} /></Avatar> })
    })
    state.cpents // COUNTERPARTIES
    .sort((a, b) => (a.legalName > b.legalName) ? 1 : -1)
    .forEach((c) => {
        so.push({ title: c.legalName, category: "Counterparty", id: c.id,
            avatar: <Avatar style={styles.avIcon} alt={c.legalName} src={c.logoURL} /> })
    })
    state.labels // SUBS
    .sort((a, b) => (a.name > b.name) ? 1 : -1)
    .forEach((l) => {
        so.push({ title: l.name, category: "Label", id: l.id, isLabel: true, colorType: l.colorType })
    })
    state.subs // SUBS
    .sort((a, b) => (a.legalName > b.legalName) ? 1 : -1)
    .forEach((s) => {
        so.push({ title: s.legalName, category: "Entity", id: s.id,
            avatar: <Avatar style={styles.avIcon} alt={s.legalName} src={s.logoURL} /> })
    })
    state.countries // COUNTRIES
    .sort((a, b) => (a.label > b.label) ? 1 : -1)
    .forEach((c) => {
        so.push({ title: c.label, category: "Country", id: c.id,
            avatar: <Avatar style={styles.avIcon} alt={c.label}>{countryToFlag(c.code)}</Avatar> })
    })

    // TODO DATE RANGE
    // TODO RECENT / SAVE SEARCHES (FEW OUT OF THE BOX)

    setSearchOptions(so)

  }, [state])

  const handleSearchSelection = (newValue) => {

    console.log("searchChange")
    setSearchSelection(newValue);

  }


  return (
    <div>  
    <Autocomplete
        multiple
        id="tags-outlined"
        freeSolo
        options={searchOptions}
        value={searchSelection}
        onChange={(event, newValue) => { handleSearchSelection(newValue) }}
        getOptionLabel={(option) => option.title}
        groupBy={(option) => option.category}
        PopperComponent={StyledPopper}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="filled" 
              color={option.isLabel ? option.colorType : "default"}
              sx={option.isLabel ? {fontWeight: '600'} : {backgroundColor: theme.palette.grey[200]}}
              avatar={option.isLabel ? null : option.avatar}
              label={option.title} {...getTagProps({ index })} />
            ))
        }
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(
              params !== undefined && params !== null && 
              params.inputValue !== '' && params.inputValue.length > 1 ? 
                  options.filter((o) => !['Quick Links'].includes(o.category)) : 
                  options.filter((o) => ['Quick Links'].includes(o.category)),
              params);
          return filtered;
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {['Quick Links'].includes(option.category) ?
            <>
            {option.chip}
            <Typography>{option.title}</Typography>
            </>
            :
            <Typography>{option.title}</Typography>}
          </Box>
        )}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined" 
            fullWidth
            placeholder={"Hi " + state.user.firstName + ", how can we help you today?"}
            InputProps={
            searchSelection.length > 0 ? 
            {
                ...params.InputProps,
                autoComplete: 'new-password',
            }
            :    
            {
                ...params.InputProps,
                autoComplete: 'new-password',
                startAdornment: (
                    <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} color={theme.palette.primary.main} />
                    </InputAdornment>
                ),
            }}  
          />
        )}
    />
    {searchSelection.length > 0 ?
    <Grid container direction="column" alignItems="center">
      <Grid item sx={{mt:10}}>
      You're searching = show results
      </Grid>
    </Grid>
    :''}
    </div>
  );
}