import MentionsPlugin from '../MentionsPlugin';
import { MentionNode } from '../../nodes/MentionNode';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { KEY_ESCAPE_COMMAND, CLEAR_EDITOR_COMMAND, $createTextNode,
         $getRoot, $createParagraphNode, $createLineBreakNode } from 'lexical';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';

import { useEffect } from 'react';
import { useLayoutEffect } from '../../utils';

function EditorRefPlugin({
  editorRef,
}) {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    editorRef.current = editor;
    return () => {
      editorRef.current = null;
    };
  }, [editor, editorRef]);

  return null;
}

function EditingPlugin({
  editing
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {

    if(Boolean(editing)) {
      console.log("clear x1")
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      editor.update(() => {
        let pars = editing.content.split('\n')
        let root = $getRoot()
        let tNodes = []
        pars.forEach((par,i) => {
          if(i>0){ tNodes.push($createLineBreakNode()) }
          tNodes.push($createTextNode(par))
        })
        let firstNode = root.getChildren()[0]
        if(Boolean(firstNode) && firstNode.getType() === 'paragraph') {
          let selection = firstNode.select()
          selection.insertNodes(tNodes)
        }
      })
    } else {
      editor.getEditorState().read(() => {
        let root = $getRoot()
        if(Boolean(root.getTextContent()) && root.getTextContent().length > 0) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }
      })
    }
    
  }, [editing]);

  return null
}

function EscapeHandlerPlugin({
  onEscape,
}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      (event) => {
        return onEscape(event);
      },
      2,
    );
  }, [editor, onEscape]);

  return null;
}

export function PlainTextEditor({
  autoFocus,
  onEscape,
  onChange,
  editorRef,
  placeholder = 'Type a comment...',
  isTemplating,
  isReply,
  isApproval,
  editing
}) {
  const initialConfig = {
    namespace: 'CommentEditor',
    nodes: [MentionNode],
    onError: (error) => {
      throw error;
    },
  };

  /*useEffect(() => {

    if(Boolean(editing)) { // editing object changed - set value of editor to update
      console.log("editing changed - now change the editor", editing)
    }

  },[editing])*/


  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="CommentPlugin_CommentInputBox_EditorContainer">
        <PlainTextPlugin
          contentEditable={<ContentEditable className={isReply ? 'ContentEditable__rootReply' : 'ContentEditable__root'} spellCheck={false} />}
          placeholder={<div className={"Placeholder__root"} style={isReply ? {top: '15px'} : {}}>{placeholder}</div>}
        />
        {!isApproval && <MentionsPlugin isTemplating={isTemplating} />}
        <EditingPlugin editing={editing} />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin />
        {autoFocus !== false && <AutoFocusPlugin />}
        <EscapeHandlerPlugin onEscape={onEscape} />
        <ClearEditorPlugin />
        {editorRef !== undefined && <EditorRefPlugin editorRef={editorRef} />}
      </div>
    </LexicalComposer>
  );
}