function getAddressForEntity(entity, addressType) { 
    let a = {}

    if(entity !== undefined && entity !== null && entity.address !== undefined && entity.address !== null &&
    addressType !== undefined && addressType !== null &&
    entity.address.filter((a) => a.addressType === addressType)[0] !== undefined){

        a = entity.address.filter((a) => a.addressType === addressType)[0]
        
    }

    return a; // returns an Object {} (if empty) or Address Object: { zip, state, etc. }
}

export default getAddressForEntity