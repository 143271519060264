import React, { useContext } from 'react';
import theme from '../theme/theme';
import { Button, Box, Grid, Rating, TextField, Typography, 
         styled } from '@mui/material';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarOutlined } from '@fortawesome/pro-light-svg-icons';
import { CanveoCircularProgress } from '.';
import axios from 'axios';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    width: '230px',
    borderRadius: '20px 0px 0px 20px',
  },
});

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#f0b13d',
  },
  '& .MuiRating-iconHover': {
    color: '#f0b13d',
  },
});

export default function ThankYouScreen(props) {

  const [state] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [rating, setRating] = React.useState(null)
  const [message, setMessage] = React.useState('');
  const [emailValue, setEmailValue] = React.useState(state.user.email);

  const handleRatingChange = (newValue) => {
    let aid = Boolean(state.agrs) && Boolean(state.agrs.filter((a) => a.parentAID === null || a.parentAID === undefined || a.parentAID === '')[0]) ? 
      state.agrs.filter((a) => a.parentAID === null || a.parentAID === undefined || a.parentAID === '')[0]._id : ''
    axios.post(state.settings.api + "rating", { rating: newValue, agrID: aid })
    setRating(newValue);
  }

  return (
    <div>
    {loading ?
    <Grid container direction="column" alignItems="center" sx={{mt:25}}>
        <Grid item>
            <CanveoCircularProgress />
        </Grid>
    </Grid>    
    :
    <>
    <Grid container direction="column" alignItems="center">
        <Grid item sx={{mt:10}}>
            <Typography variant="h3" style={{fontWeight: '700'}}>
            Thanks for using Canveo<span style={{color: theme.palette.secondary.main}}>.</span>
            </Typography>
        </Grid>
        <Grid item sx={{mt: 9, mb:4}}>
            <Typography variant="h5" style={{fontWeight: '500'}}>
            Rate your experience<span style={{color: theme.palette.secondary.main}}>.</span>
            </Typography>
        </Grid>
        <Grid item>

            <StyledRating
                name="customized-color"
                value={rating}
                onChange={(e, newValue) => { handleRatingChange(newValue); }}
                precision={1}
                icon={<FontAwesomeIcon icon={faStar} style={{fontSize: '40px', padding: '4px'}} />}
                emptyIcon={<FontAwesomeIcon icon={faStarOutlined} style={{fontSize: '40px', padding: '4px'}} />}
            />

        </Grid>

        {Boolean(rating) && rating < 4 ?
        <>
        <Grid item sx={{mt: 9, mb:4}}>
            <Typography variant="h5" style={{fontWeight: '500'}}>
            Tell us how we can improve<span style={{color: theme.palette.secondary.main}}>.</span>
            </Typography>
        </Grid>
        <Grid item sx={{width: '320px'}}>

          <TextField
            id="outlined-textarea"
            placeholder="Share how we can improve..."
            multiline
            autoFocus
            rows={4}
            style={{width: '100%'}}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <Box sx={{mt:1}}>
            <Button variant="contained" color="secondary" disableElevation fullWidth>
              Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </Box>

        </Grid>
        </>
        :''}

        {rating === null || rating > 3 ?
        <>
        <Grid item sx={{mt: 9, mb:4}}>
            <Typography variant="h5" style={{fontWeight: '500'}}>
            Try Canveo for free<span style={{color: theme.palette.secondary.main}}>.</span>
            </Typography>
        </Grid>
        <Grid item>
            <CssTextField
            value={emailValue}
            onChange={e => setEmailValue(e.target.value)}
            />
            <Button sx={{
                borderRadius: '0px 20px 20px 0px',
                fontSize: '16px',
                padding: '16px 15px',
            }}
            variant="contained"
            color="secondary"
            disableElevation
            >Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
            </Button>
        </Grid>
        </>
        :''}

    </Grid>
    </>
    }

    
    </div>
  );
}