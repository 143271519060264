import React, { useContext, useEffect } from 'react';
import theme from './theme/theme'
import axios from 'axios';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { globalStore } from './state/store';
import { initializeUser } from './state/initializeUser';
import { Account, Admin, Agreement, Dashboard, Login, New, 
         Templates, SuperAdmin } from "./views";
import { CanveoCircularProgress } from './components';
import { Box, Button, Grid, Snackbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimesCircle, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

function Main() {

  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [actResult, setActResult] = React.useState(null);
  const [nextButton, setNextButton] = React.useState({text: 'Login to Canveo', route: '/login'})

  useEffect(() => {
    if(state.user._id === undefined || state.user.email === undefined) { // You're re-initializing OR not logged in
      axios.get(state.settings.api + "auth/loggedinuser") // Check if the user is already logged in (ie. has a back-end Cookie)
      .then((res) => { 
        
        const isLoggedIn = res.data !== undefined && res.data.data !== undefined && res.data.data !== null && 
                         res.data.data.user !== undefined && res.data.data.user !== null &&
                         res.data.data.user._id !== undefined && res.data.data.user.email !== null

        if(location.pathname.startsWith("/act/") && location.pathname.split("/").length === 8) {
          // SCENARIO: User clicked on an "ACT" link (e.g. to approve)

          let uid = location.pathname.split("/")[2]
          let isTemplate = location.pathname.split("/")[3] === 't';
          let docid = location.pathname.split("/")[4]
          let wfid = location.pathname.split("/")[5]
          let lid = location.pathname.split("/")[6]
          let response = location.pathname.split("/")[7]

          if(Boolean(uid) && Boolean(docid) && Boolean(wfid) && Boolean(lid) && Boolean(response) &&
          uid.length > 10 && uid.length < 30 && docid.length > 10 && docid.length < 30 && 
          wfid.length > 10 && wfid.length < 30 && lid.length > 2 && lid.length < 30 && ['a','r'].includes(response)) {

            axios.put(state.settings.api + "workflow/actlink/" + wfid, { uid, docid, isTemplate, lid, response })
            .then((resAct) => {
              let nb = nextButton;
              if(isLoggedIn) {
                initializeUser(dispatch, res.data.data); // Re-initialize
                nb = { 
                  text: 'View ' + (isTemplate ? 'Template' : 'Agreement'),
                  route: "/" + (isTemplate ? 'templates' : 'agreement') + "/" + docid
                }
              }
              setActResult('complete-' + response);
              setNextButton(nb)
              setLoading(false);

            }).catch((err) => { setActResult('fail'); setLoading(false); })
          } else { setActResult('fail'); setLoading(false); }

        } else if(isLoggedIn) { 
          // SCENARIO: The user is already logged in
          // TODO: potentially reroute for loginp
          initializeUser(dispatch, res.data.data); // Re-initialize
          if(location.pathname === "/") { navigate('/dashboard') } // Route to Dashboard
          setLoading(false);

        } else if(location.pathname.startsWith("/loginc/") && location.pathname.split("/").length === 5) {

          // SCENARIO: The user is accessing through a custom link
          let cl = location.pathname.split("/")[2]
          let uid = location.pathname.split("/")[3]
          let aid = location.pathname.split("/")[4]

          axios.post(state.settings.api + "auth/customlink", { uid: uid, aid: aid, cl: cl })
          .then((res) => {
            initializeUser(dispatch, res.data.data); // Initialize based on custom link
            navigate('/agreement/' + aid);
            setLoading(false);
          }).catch((err) => { navigate('/') })

        } else {
          if(!location.pathname.startsWith('/createpassword/')) {
            navigate('/');
          }
          setLoading(false)
        }
      }).catch((err) => { navigate('/') }) // Unable to pull whether the user is logged in or not
    }

  }, [state.user.email])

  return (
    <div>
      {
      loading ?
      <Grid container direction="column" justifyContent="center" alignItems="center" sx={{height: '100vh'}}>
        <Grid item sx={{mb:1}}>
          <CanveoCircularProgress />
        </Grid>
      </Grid>
      :
      Boolean(actResult) ? 
      <Grid container direction="column" justifyContent="center" alignItems="center" sx={{height: '100vh'}}>
        <Grid item sx={{mb:2, textAlign: 'center'}}>
          <FontAwesomeIcon 
          style={{fontSize: '80px', color: 
              ['complete-a'].includes(actResult) ? theme.palette.success.main :
              ['complete-r'].includes(actResult) ? theme.palette.error.main : theme.palette.warning.main}}
          icon={
            ['complete-a'].includes(actResult) ? faCheckCircle :
            ['complete-r'].includes(actResult) ? faTimesCircle : faExclamationCircle}
          />
          <Box sx={{mt: 4, mb: 3}}>
            {actResult.startsWith('complete-') ?
                "Your response has been recorded." :
                "Unable to record your response."}
          </Box>
          <Box>
            <Button onClick={e => window.location.assign(nextButton.route)}>{nextButton.text}</Button>
          </Box>
        </Grid>
      </Grid>
      :

      <Routes>
        
        <Route path="/account" element={<Account />} />
        <Route path="/admin/:section" element={<Admin />} />
        <Route path="/agreement/:aid" element={<Agreement />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/:tweak" element={<Dashboard />} />
        <Route path="/templates/:tid" element={<Templates />} />
        <Route path="/new" element={<New />} />
        <Route path="/superadmin" element={<SuperAdmin />} />

        <Route path="/createpassword/:createstring" element={<Login />} />
        <Route path="/loginp/:reroutetype/:uid/:id/:modifier" element={<Login />} /* reroute login */ />
        <Route path="/loginc/:customurl/:uid/:aid" element={<Login />} /* customlink login */ />
        <Route path="/" element={<Login />} />
        
      </Routes>
    }

    <Snackbar
      open={Boolean(state.snackbar)}
      autoHideDuration={5000}
      onClose={e => dispatch({ type: "NEW_SNACKBAR", payload: null })}
      message={state.snackbar !== null ? state.snackbar : ""}
    />
    </div>
  )
}

export default Main;
