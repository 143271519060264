const clauseTypesReducer = (state, action) => {

    switch (action.type) {
        case "INIT_CLAUSETYPES":
            return { clauseTypes: action.payload };
        case "ADD_CLAUSETYPE":
            return {
                clauseTypes: [...state.clauseTypes, action.payload]
            };
        case "UPDATE_CLAUSETYPE":
            let newVal = action.payload;
            let newClauseTypes = state.clauseTypes;
            let idx = state.clauseTypes.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newClauseTypes[idx] = newVal;
            }
            return { clauseTypes: newClauseTypes };
        default:
            return { clauseTypes: state.clauseTypes }
    }
};
  
export default clauseTypesReducer