import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { globalStore } from '../../../state/store';
import { ChipButton, DialogAlert } from '../../';
import { pad } from '../../../utils';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar } from '@mui/material';
import { hasRedline, hasMark } from "../utils";
import { APPLY_FILTER_COMMAND } from "../commands";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faClone, faPen,
         faFilePen, faList, faToggleOn, faToggleOff, faTrash, faTrashList } from '@fortawesome/pro-solid-svg-icons';

import axios from 'axios';

export default function TemplateVersionPlugin(props) {

  const navigate = useNavigate();

  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [anchorElTemplateLevel, setAnchorElTemplateLevel] = React.useState(null);
  const [alertDialogType, setAlertDialogType] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {


  }, [])

  const handleOpenTemplateLevelMenu = (event) => {
    setAnchorElTemplateLevel(event.currentTarget);
  };
  
  const handleCloseTemplateLevelMenu = () => {
    setAnchorElTemplateLevel(null);
  };

  const alertConfirm = (isConfirmed) => {

    handleTemplateAction(alertDialogType, isConfirmed)

  }

  const handleTemplateAction = (action, value) => {
    setLoading(true);
    setAnchorElTemplateLevel(null)
    let creationDate = new Date().toISOString();

    if(['viewDraft'].includes(action)) {
      let latestDraft = state.templates
          .filter((t) => !t.active && t.blueprintID === props.template.blueprintID)
          .sort((a, b) => (a.version > b.version) ? -1 : 1)[0]

      if(Boolean(latestDraft) && latestDraft._id !== props.template._id) {
        navigate('/templates/' + latestDraft._id)
      } else {
        setLoading(false)
      }

    } else if(['deactivateVersion'].includes(action)) {

      let templateToDeactivate = props.template;
      templateToDeactivate.lastUpdateBy = state.user._id;
      templateToDeactivate.lastUpdateDate = creationDate;
      templateToDeactivate.active = false;
      pushUpdateTemplateVersions([templateToDeactivate])
      
    } else if(['activateVersion'].includes(action)) {

      let templatesToUpdate = [];
      let hasOpenIssues = false;

      editor.update(() => {

        const nodeMapArray = [...editor.getEditorState()._nodeMap];
        hasOpenIssues = nodeMapArray
        .filter((keyPlusNode) => ['clause'].includes(keyPlusNode[1].getType()))
        .some((keyPlusNode) => hasRedline(keyPlusNode[1]) || hasMark(keyPlusNode[1], 'publicComment'))

      })

      if(hasOpenIssues) {

        dispatch({ type: "NEW_SNACKBAR", payload: 'Please resolve all open issues before activating.' })
        //setSnackOpen("Please resolve all open issues before activating.")
        editor.dispatchCommand(APPLY_FILTER_COMMAND, 'openIssues');

      } else {

        // Deactivate any other template(s) for this blueprintID (if applicable)
        state.templates.filter((t) => t.blueprintID === props.template.blueprintID && t._id !== props.template._id && t.active)
        .forEach((t) => {
          let newT = t;
          newT.lastUpdateBy = state.user._id;
          newT.lastUpdateDate = creationDate;
          newT.active = false;
          templatesToUpdate.push(newT);
        })

        // Activate this template
        let newlyActiveTemplate = props.template;
        newlyActiveTemplate.lastUpdateBy = state.user._id;
        newlyActiveTemplate.lastUpdateDate = creationDate;
        newlyActiveTemplate.active = true;
        templatesToUpdate.push(newlyActiveTemplate)

        pushUpdateTemplateVersions(templatesToUpdate)
      }
      
    } else if(['copyVersion'].includes(action)) {
      
      let latestVersionForBP = 
        Boolean(state.templates.filter((t) => t.blueprintID === props.template.blueprintID)[0]) ?
            state.templates.filter((t) => t.blueprintID === props.template.blueprintID).sort((a, b) => (a.version > b.version) ? -1 : 1)[0].version :
            null

      if(Boolean(latestVersionForBP)) {
        let newTemp = props.template;
        delete newTemp._id
        newTemp.creationBy = state.user._id;
        newTemp.creationDate = creationDate;
        newTemp.lastUpdateBy = state.user._id;
        newTemp.lastUpdateDate = creationDate;
        newTemp.active = false;
        newTemp.version = pad(parseInt(latestVersionForBP) + 1, 3).toString()
  
        axios.post(state.settings.api + "template", { template: newTemp })
        .then((resTemp) => {
          if(resTemp.data.success) { // Add newly created template to the reducer
            dispatch({ type: "ADD_TEMPLATE", payload: resTemp.data.data })
            navigate('/templates/' + resTemp.data.data._id);
            setLoading(false);
            dispatch({ type: "NEW_SNACKBAR", payload: 'Template successfully copied.' })
  
          } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured while creating the template - refresh your browser"}); setLoading(false); }
        }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured while creating the template"}); setLoading(false); })
      } else { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to create a new version"}); setLoading(false); }

    } else if(['deleteVersion'].includes(action) && Boolean(value)) { // Confirmed delete version

      let newTemp = props.template;
      pushDeleteTemplateVersions([newTemp]);
      
    } else if(['deleteTemplate'].includes(action) && Boolean(value)) { // Confirmed delete template - ie. all versions

      axios.get(state.settings.api + "template/blueprint/" + props.template.blueprintID) // First pull all versions for this blueprintID
      .then((resTemplates) => {
        if(resTemplates.data.success) { // Add updated template to the reducer

          pushDeleteTemplateVersions(resTemplates.data.data);

        } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured retrieving all templates for deletion - refresh your browser"}); setLoading(false); }
      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to retrieve all templates for deletion"}); setLoading(false); })

    } else if(['deleteVersion', 'deleteTemplate'].includes(action) && value === null) { // To be confirmed first

      setLoading(false)
      setAlertDialogType(action)
      
    //} //else if (['viewAll'].includes(action)) {

      //handleDrawerOpen("Versions")

    } else { // Fallback scenario - clear everything
      setAlertDialogType(null)
      setLoading(false);
    }

  }

  const pushUpdateTemplateVersions = (versionsToUpdate) => {
    
    let updatedTemplates = [];

    // Execute the updates
    versionsToUpdate.forEach((ttu, i) => {
      axios.put(state.settings.api + "template/" + ttu._id, { template: ttu })
      .then((resTemp) => {
        if(resTemp.data.success) { // Add updated template to the reducer
          updatedTemplates.push(resTemp.data.data)
          dispatch({ type: "UPDATE_TEMPLATE", payload: resTemp.data.data })
          if(versionsToUpdate.length === updatedTemplates.length) {
            dispatch({ type: "NEW_SNACKBAR", payload: "The template was successfully upated."});
            setLoading(false);
          }

        } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured while updating this template - refresh your browser"}); setLoading(false); }
      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured while updating this template"}); setLoading(false); })
    })
  }

  const pushDeleteTemplateVersions = (versionsToDelete) => {

    let creationDate = new Date().toISOString();
    let versionsDeleted = []

    versionsToDelete.forEach((template) => {
      template.active = false;
      template.lastUpdateBy = state.user._id;
      template.lastUpdateDate = creationDate; 
      template.orgID = 'deleted_' + template.orgID;
      template.blueprintID = 'deleted_' + template.blueprintID;
  
      axios.put(state.settings.api + "template/" + template._id, { template: template })
      .then((resTemp) => {
        if(resTemp.data.success) { // Add updated template to the reducer
          dispatch({ type: "UPDATE_TEMPLATE", payload: resTemp.data.data })
          versionsDeleted.push(resTemp.data.data);
          if(versionsDeleted.length === versionsToDelete.length) {
            navigate('/templates/main');
            setLoading(false);
            dispatch({ type: "NEW_SNACKBAR", payload: "Template version" + (versionsToDelete.length > 1 ? "s" : "") + " succesfully deleted."})
          }
  
        } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured while deleting the version - refresh your browser"}); setLoading(false); }
      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "An error occured while deleting the version"}); setLoading(false); })
    })
  }

  return (
    <div>
        <Box>
        <ChipButton
        icon={faCaretDown}
        iconPos="right"
        text={"v" + parseInt(props.template.version) + (props.template.active ? "" : " (draft)")}
        color="primary"
        variant={props.template.active ? "contained" : "outlined"}
        buttonClick={handleOpenTemplateLevelMenu}
        />

        {/*
        <Chip 
        label={<>
            v{parseInt(props.template.version)}
            <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: '3px'}} />
        </>}
        variant={Boolean(props.template) && Boolean(props.template.active) ? "contained" : "outlined"}
        color="primary" 
        size="small"
        sx={{fontWeight: '700', padding: '3px', height: '32px', border: Boolean(props.template) && Boolean(props.template.active) ? '0px' : '1px solid' + theme.palette.grey[300] }} 
        onClick={handleOpenTemplateLevelMenu}
        />*/}
        </Box>

        <Menu
        sx={{ mt: '40px', ml: '0px', }}
        id="menu-appbar-template"
        anchorEl={anchorElTemplateLevel}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorElTemplateLevel)}
        onClose={handleCloseTemplateLevelMenu}
        >

        {[
        ...(Boolean(props.template) && Boolean(props.template.active)) &&
        state.templates.some((t) => t.blueprintID === props.template.blueprintID && !t.active) ? 
                [{ id: 'viewDraft', name: "View latest Draft", icon: faFilePen }] : 
                [],
        //{ id: 'viewAll', name: "View all versions", icon: faList },
        ...(Boolean(props.template) && Boolean(props.template.active)) ? 
                [{ id: 'deactivateVersion', name: "Deactivate this version", icon: faToggleOff }] : 
                [{ id: 'activateVersion', name: "Activate this version", icon: faToggleOn }],
        { id: 'copyVersion', name: "Copy into new version", icon: faClone },
        ...(Boolean(props.template) && Boolean(props.template.active)) ? 
                [] : 
                [{ id: 'deleteVersion', name: "Delete this version", icon: faTrash }],
        { id: 'deleteTemplate', name: "Delete this template", icon: faTrashList },
        ]
        .map((b, i) => (

        <MenuItem key={i} onClick={e => handleTemplateAction(b.id, null)} style={{width: '245px'}}>
            <ListItemIcon>
                {Boolean(b.icon) ?
                <FontAwesomeIcon icon={b.icon} />
                :''}
            </ListItemIcon>
            <ListItemText>{b.name}</ListItemText>
        </MenuItem>
        ))}

        </Menu>
        
        <DialogAlert
          open={Boolean(alertDialogType)}
          alertDialogType={alertDialogType}
          closeDialog={e => setAlertDialogType(null)}
          alertConfirm={alertConfirm}
        />

        {/*
        
        */}
      
    </div>
    
  );
}