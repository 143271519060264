const clauseLibItemsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_CLAUSELIBITEMS":
            return { clauseLibItems: action.payload };
        case "ADD_CLAUSELIBITEM":
            return {
                clauseLibItems: [...state.clauseLibItems, action.payload]
            };
        case "DELETE_CLAUSELIBITEM":
            let filteredItems = state.clauseLibItems.filter((cli) => cli._id !== action.payload._id)
            return { clauseLibItems: filteredItems };

        case "UPDATE_CLAUSELIBITEM":
            let newVal = action.payload;
            let newClauseLibItems = state.clauseLibItems;
            let idx = state.clauseLibItems.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newClauseLibItems[idx] = newVal;
            }
            return { clauseLibItems: newClauseLibItems };
        default:
            return { clauseLibItems: state.clauseLibItems }
    }
};
  
export default clauseLibItemsReducer