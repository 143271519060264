import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { trunc } from '../utils';
import { Autocomplete, Box, Button, CircularProgress, Chip, Grid, 
         IconButton, InputAdornment, List, ListItemButton, 
         ListItemText, Pagination, PaginationItem, Rating, 
         Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { CanveoCircularProgress, TooltipProminent } from '.';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFilter, faStar } from '@fortawesome/pro-solid-svg-icons';

import axios from 'axios';

export default function ClauseLibraryList(props) {

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const pageSizeClauseLib = 5;
  const [state, dispatch] = useContext(globalStore);
  const [filteredClauseLib, setFilteredClauseLib] = React.useState([]);
  const [loadedClauseLibItems, setLoadedClauseLibItems] = React.useState([])
  const [curPageClauseLib, setCurPageClauseLib] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [filters, setFilters] = React.useState({ clauseTypes: [], minRating: 1})

  useEffect(() => {
      if(Boolean(props.initialFilters)) {
          setFilters({...filters, clauseTypes: props.initialFilters})
      }
  }, [props.initialFilters])

  useEffect(() => {
    const fcl = state.clauseLibItems
      //.filter((cli) => cli.orgID === state.org._id) // FILTER: labels
      .filter((cli) => cli.rating >= filters.minRating)
      .filter((cli) => !Boolean(filters.clauseTypes) || filters.clauseTypes.length === 0 || filters.clauseTypes.every((ct) => cli.clauseTypes.includes(ct)))
      .sort((a, b) => (a.rating > b.rating) ? -1 : 1)

    let clisToLoad = []
    fcl.slice((curPageClauseLib - 1) * pageSizeClauseLib, curPageClauseLib * pageSizeClauseLib)
    .forEach((fcl) => { clisToLoad.push(fcl._id) })

    axios.post(state.settings.api + "clauselib/getitems", {cliids: clisToLoad})
    .then((resCLIS) => {
      if(resCLIS.data.success) {

        if(fcl.length <= ((curPageClauseLib-1) * pageSizeClauseLib)) { setCurPageClauseLib(1) }
        setFilteredClauseLib(fcl)
        setLoadedClauseLibItems(resCLIS.data.data)
        setLoading(false);

      } else { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to load the clause library items"}); setLoading(false); }
    }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to load the clause library items" }); setLoading(false); })

  }, [state.clauseLibItems, filters, curPageClauseLib])

  const handleRatingChange = (newRating) => {
      if(parseInt(newRating) >= 1 && parseInt(newRating) <= 5) {
          setFilters({...filters, minRating: parseInt(newRating)})
      }
  }

  const handleClauseTypeFilterChange = (newVal) => {

    let ctids = []
    newVal.forEach((nv) => { ctids.push(nv._id) })
    setFilters({...filters, clauseTypes: ctids})

  }

  return (
    <div>
        {loading ?
        <Grid container direction="column" alignItems="center">
            <Box sx={{my: 10}}>
                <CanveoCircularProgress />
            </Box>
        </Grid>
        :
        <Grid container direction="column" alignItems="center">
            <Grid item sx={{width: isMdUp && ['edit'].includes(props.type) ? '600px' : isSmUp ? '400px' : '300px'}}>

                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <Autocomplete
                        id="filter-agrType"
                        options={
                          state.clauseTypes.filter((ct) => ct.orgID === state.org._id || ct.orgID === 'CANVEO')
                          .sort((a, b) => (a.name > b.name) ? 1 : -1)}
                        value={
                            state.clauseTypes.filter((ct) => ct.orgID === state.org._id || ct.orgID === 'CANVEO')
                            .filter((ct) => Boolean(filters.clauseTypes) && filters.clauseTypes.includes(ct._id))
                        }
                        onChange={(e, newVal) => handleClauseTypeFilterChange(newVal)}
                        getOptionLabel={(option) => trunc(option.name, 35)}
                        multiple
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Clause Topic"
                            placeholder="Clause Topic..."
                            InputProps={
                            {
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start" style={Boolean(filters) && Boolean(filters.clauseTypes) && filters.clauseTypes.length > 0 ?
                                      {display: 'none'} : {}}>
                                    <FontAwesomeIcon icon={faFilter} />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip {...getTagProps({ index })}
                                label={<span style={{fontWeight: '600', fontSize: '14px', padding: '5px 4px 5px 5px'}}>{option.name}</span>}
                                color="primary"
                                />
                            ))
                        }
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                        id="outlined-number"
                        label="Minimum Rating"
                        //value={filters.minRating}
                        //defaultValue={1}
                        value={filters.minRating}
                        onChange={e => handleRatingChange(e.target.value)}
                        type="number"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FontAwesomeIcon icon={faStar} /></InputAdornment>,
                        }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
            {
            filteredClauseLib.length > 0 ? 
            <Grid container direction="row" sx={{mt: 3}}>

                <List sx={{width: isMdUp && ['edit'].includes(props.type) ? '500px' : isSmUp ? '400px' : '300px'}}>
                {filteredClauseLib
                .slice((curPageClauseLib - 1) * pageSizeClauseLib, curPageClauseLib * pageSizeClauseLib)
                .map((cli, i) => {

                let clauseLibItem = Boolean(loadedClauseLibItems) && Boolean(loadedClauseLibItems.filter((lcli) => lcli._id === cli._id)[0]) ? 
                                        loadedClauseLibItems.filter((lcli) => lcli._id === cli._id)[0] : null;
                
                return (
                    <Tooltip title={['edit'].includes(props.type) ? "Click to edit" : "Click to select"} placement="right" key={cli._id}>
                    <ListItemButton
                    sx={{padding: '15px'}}
                    onClick={e => props.onSelect(clauseLibItem)}
                    /*secondaryAction={
                        !Boolean(clauseLibItem) ? 
                            <CircularProgress size={20} /> 
                        :
                        !['edit'].includes(props.type) ?
                            <Tooltip placement="right" title="Update clause">
                            <span>
                            <IconButton edge="end" onClick={e => props.onSelect(cli._id)}
                            disabled={!Boolean(clauseLibItem)}>
                            <FontAwesomeIcon icon={faPen} style={{fontSize: '15px', padding: '5px'}} />
                            </IconButton>
                            </span>
                            </Tooltip>
                        :
                            <Button disableElevation variant="contained" color="secondary">Select</Button>
                    }*/
                    >
                    {/*<ListItemAvatar>
                        {!Boolean(clauseLibItem) ? 
                            <CircularProgress size={20} /> :
                            <Avatar src={state.org.logoURL} 
                            sx={{backgroundColor: theme.palette.primary.contrastText}} />
                        }
                    </ListItemAvatar>*/}
                    <ListItemText
                        primary={!Boolean(clauseLibItem) ? 
                            <Typography style={{fontWeight: '700'}}>Loading...</Typography> :
                            <>
                            <Stack spacing={1}>
                                <Rating 
                                readOnly
                                name="half-rating" 
                                value={clauseLibItem.rating}
                                size="small"
                                precision={0.5} 
                                />
                            </Stack>
                            <Box sx={{mt:2, mb:2}}>
                                {clauseLibItem.clauseTypes.map((ct) => {
                                    let clauseT = state.clauseTypes.filter((sct) => sct._id === ct)[0]
                                    return(
                                    <Chip 
                                    key={ct}
                                    color="primary"
                                    size="small"
                                    style={{ padding: '4px', marginRight: '5px'}}
                                    label={<span style={{fontWeight: '600'}}>{Boolean(clauseT) ? clauseT.name : "Unknown"}</span>}
                                    />)
                                })}
                            </Box>
                            </>}
                        secondary={!Boolean(clauseLibItem) || !Boolean(clauseLibItem.text) ? 
                            '...' :                            
                            <>
                            {trunc(clauseLibItem.text, 190)}
                            &nbsp;&nbsp;<span style={{fontWeight: '700'}}>{clauseLibItem.text.length} chars</span>
                            </>}
                    />
                    </ListItemButton>
                    </Tooltip>
                )
                })}
                </List>
            </Grid>
            :
            <Box sx={{mx:5, my: 5}}>
                <Typography align="center">No clause library items yet.</Typography>
            </Box>
            }

            </Grid>
            <Grid item sx={{ mt: 5}}>
            <Stack spacing={2}>
                <Pagination
                count={Math.ceil(filteredClauseLib.length / pageSizeClauseLib)}
                defaultPage={1}
                page={curPageClauseLib}
                onChange={(e, newVal) => setCurPageClauseLib(newVal)}
                siblingCount={0}
                boundaryCount={2}
                renderItem={(item) => (
                    <PaginationItem {...item} />
                )}
                />
            </Stack>
            </Grid>
        </Grid>
        }
    </div>
  );
}