import React, { useContext } from 'react';
import theme from '../theme/theme';
import { globalStore } from '../state/store';
import { getTimeIndicationForDate, processLongString, trunc } from '../utils';
import { Avatar, Box, Chip, Grid, Tooltip, Typography, styled, useMediaQuery } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from '@fortawesome/pro-solid-svg-icons';


const ThumbAgrTooltip = (props) => {

  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [state] = useContext(globalStore);

  return (
    <div>
        <Typography>Last Update By: {props.ag.lastUpdateBy}</Typography>
        <Typography>Exhibits: ....</Typography>
        <Typography>Expiry Date: ....</Typography>
        <Typography>Deal Value: ...</Typography>
        <Typography>Labels.....</Typography>
    </div>
  );
};
export default ThumbAgrTooltip;