import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { trunc } from '../utils';
import axios from 'axios';
import { Autocomplete, Button, Box, Chip, 
         Dialog, DialogActions, DialogContent, DialogTitle, 
         FormControl, FormControlLabel,
         Grid, IconButton, Radio, RadioGroup, 
         Rating, Stack, TextField, Typography,
         useMediaQuery } from '@mui/material';
import { CanveoCircularProgress, ClauseLibraryList } from '.';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faTag, faTimes, faExclamationTriangle, faArrowLeft, faBook } from '@fortawesome/pro-solid-svg-icons';

import { ASSIGN_TOPICS_COMMAND, SAVE_TO_LIBRARY, INSERT_FROM_LIBRARY } from './editor/commands';

export default function DialogClause(props) {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [guidance, setGuidance] = React.useState({guidance: '', isNew: true});
  const [errMsg, setErrMsg] = React.useState(null);
  const [isCustomClauseType, setIsCustomClauseType] = React.useState(false);
  const [deletingTopic, setDeletingTopic] = React.useState(false);
  const [deletingLibItem, setDeletingLibItem] = React.useState(false);
  const [assignTopics, setAssignTopics] = React.useState(null);
  const [libItem, setLibItem] = React.useState(null);
  const [findClauseFromLibrary, setFindClauseFromLibrary] = React.useState(false);
  const [insertLocation, setInsertLocation] = React.useState('insertBefore')

  useEffect(() => {

    if(props.open) {
        
        let guid = 
            Boolean(props.details) && props.details.type === 'newtopic' ?
                { guidance: '', name: '', isNew: true, isNewTopic: true } :
            Boolean(props.details) && props.details.type === 'updatetopic' ?
                state.clauseTypeGuides.filter((g) => g.ctid === props.details._id)[0] :
                null

        if(Boolean(guid)) { setGuidance(guid) }

        if(Boolean(props.details) && props.details.type === 'updatetopic' && Boolean(props.details.orgID) && props.details.orgID !== 'CANVEO') { 
          setIsCustomClauseType(true) 
        } else if(Boolean(props.details) && props.details.type === 'assigntopic' && Boolean(props.details.activeCTs) && Boolean(props.details.activeClauseKey)) {
          setAssignTopics(props.details.activeCTs);
        } else if(Boolean(props.details) && props.details.type === 'savelibrary' && Boolean(props.details.activeCTs) && Boolean(props.details.clauseText)) {
          setLibItem({
            rating: null,
            clauseTypes: props.details.activeCTs,
            clauseText: props.details.clauseText.replaceAll("\n\n\n\n", "\n\n"),
          })
        } else if(Boolean(props.details) && props.details.type === 'editlibrary' && Boolean(props.details.clitem)) {
          setLibItem({
            rating: props.details.clitem.rating,
            clauseTypes: props.details.clitem.clauseTypes,
            clauseText: props.details.clitem.text.replaceAll("\n\n\n\n", "\n\n"),
          })
        } else if(Boolean(props.details) && ['insertclause','alternativeclause'].includes(props.details.type)) {
          setFindClauseFromLibrary(true);
          if(['alternativeclause'].includes(props.details.type)) {
            setInsertLocation('replaceCurrent');
          }
        }

    }/* else {
        setGuidance({guidance: '', isNew: true}) // Reset upon closure
        setIsCustomClauseType(false);
        setAssignTopics(null);
        setInsertLocation('insertBefore');
    }*/

  }, [props.open])

  const closeDialog = (action) => {
      setLoading(false)
      setErrMsg(null)
      setDeletingTopic(false)
      setDeletingLibItem(false)
      setGuidance({guidance: '', isNew: true}) // Reset upon closure
      setIsCustomClauseType(false);
      setAssignTopics(null);
      setLibItem(null);
      setInsertLocation('insertBefore');
      setFindClauseFromLibrary(false);
      props.closeDialog(action);
  }

  const submitTopicAssign = () => {

    let newClauseTypes = assignTopics;
    let command = ASSIGN_TOPICS_COMMAND;
    let payload = { key: props.details.activeClauseKey, ctids: newClauseTypes }
    closeDialog({ command, payload })

  }

  const submitInsertClause = () => {

    let command = INSERT_FROM_LIBRARY;
    let payload = { key: props.details.activeClauseKey, libItem: libItem, insertLocation: insertLocation }
    closeDialog({ command, payload })

  }

  const submitEditLibraryItem = () => {

    let newLibItem = props.details.clitem
    newLibItem.rating = libItem.rating
    newLibItem.text = libItem.clauseText
    newLibItem.clauseTypes = libItem.clauseTypes
    newLibItem.lastUpdateBy = state.user._id
    newLibItem.lastUpdateDate = new Date().toISOString()

    axios.put(state.settings.api + 'clauselib/' + newLibItem._id, { clauseLibItem: newLibItem })
      .then((resCLI) => {
        if(resCLI.data.success) { // Add newly created agrType to the reducer
            dispatch({ type: "UPDATE_CLAUSELIBITEM", payload: resCLI.data.data })
            closeDialog('clauseLibUpdated')
      } else { setErrMsg("An error occured while updating the library item - refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("An error occured while updating the library item"); setLoading(false); })

  }

  const submitSaveToLibrary = () => {

    let newClauseTypes = libItem.clauseTypes;
    let command = SAVE_TO_LIBRARY;
    let payload = { key: props.details.activeClauseKey, ctids: newClauseTypes, rating: libItem.rating, text: libItem.clauseText }
    closeDialog({command, payload })

  }

  const handleTopicChange = (type, newVal) => {
    let ats = []
    newVal.forEach((nv) => { ats.push(nv._id) })
    if(['library'].includes(type)) {
      setLibItem({...libItem, clauseTypes: ats})
    } else {
      setAssignTopics(ats)
    }

  }

  const submitTopicUpdate = () => {
      setLoading(true)

      let newct = { name: guidance.name, orgID: state.org._id, active: true }

      axios.post(state.settings.api + 'clausetype', { clauseType: newct })
      .then((resCT) => {
        if(resCT.data.success) { // Add newly created clauseType to the reducer
            dispatch({ type: "ADD_CLAUSETYPE", payload: resCT.data.data })
            let newGuide = guidance
            newGuide.ctid = resCT.data.data._id;
            pushCreateGuidance(newGuide) // Now also create the Guidance

        } else { setErrMsg("An error occured while creating the clause type - refresh your browser"); setLoading(false); }
      }).catch((err) => { setErrMsg("An error occured while creating the clause type"); setLoading(false); })
  }

  const submitGuidanceUpdate = () => {
      setLoading(true)

      let newGuide = guidance.isNew ? 
        { orgID: state.org._id, ctid: props.details._id, guidance: guidance.guidance } : guidance

      if(guidance.isNew) { // NEW GUIDANCE

        pushCreateGuidance(newGuide)
        
      } else { // GUIDANCE UPDATE
        axios.put(state.settings.api + 'clausetype/guidance/' + newGuide._id, { clauseTypeGuide: newGuide })
        .then((resGuide) => {
          if(resGuide.data.success) { // Add newly created agrType to the reducer
              dispatch({ type: "UPDATE_CLAUSETYPEGUIDE", payload: resGuide.data.data })
              closeDialog('clauseTypeGuidanceUpdated')
          } else { setErrMsg("An error occured while updating the guidance - refresh your browser"); setLoading(false); }
        }).catch((err) => { setErrMsg("An error occured while updating the guidance"); setLoading(false); })
      }   
  }

  function pushCreateGuidance(newGuide) {

    axios.post(state.settings.api + 'clausetype/guidance', { clauseTypeGuide: newGuide })
    .then((resGuide) => {
        if(resGuide.data.success) { // Add newly created agrType to the reducer
            dispatch({ type: "ADD_CLAUSETYPEGUIDE", payload: resGuide.data.data })
            closeDialog('clauseTypeGuidanceCreated')
        } else { setErrMsg("An error occured while creating the guidance - refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("An error occured while creating the guidance"); setLoading(false); })
  }

  const handleConfirmDelete = () => {

    if(Boolean(props.details) && Boolean(props.details._id) && deletingTopic) { // DELETING TOPIC

        let newt = state.clauseTypes.filter((ct) => ct.orgID === state.org._id && ct._id === props.details._id)[0]
        if(Boolean(newt)) {
            setLoading(true);
            newt.orgID = 'deleted_' + newt.orgID
            newt.active = false;
            axios.put(state.settings.api + 'clausetype/' + newt._id, { clauseType: newt })
            .then((resCT) => {
                if(resCT.data.success) { // Add newly created clauseType to the reducer
                    dispatch({ type: "UPDATE_CLAUSETYPE", payload: resCT.data.data })
                    closeDialog('clauseTypeDeleted')
                } else { setErrMsg("An error occured while deleting the clause type - refresh your browser"); setLoading(false); }
            }).catch((err) => { setErrMsg("An error occured while deleting the clause type"); setLoading(false); })
        }

    } else if(Boolean(props.details) && Boolean(props.details.clitem) && deletingLibItem) { // DELETING LIB ITEM

      let newcli = props.details.clitem
      if(Boolean(newcli)) {
          setLoading(true);
          newcli.orgID = 'deleted_' + newcli.orgID
          axios.put(state.settings.api + 'clauselib/' + newcli._id, { clauseLibItem: newcli })
          .then((resCLI) => {
              if(resCLI.data.success) { // Add newly created clauseType to the reducer
                  dispatch({ type: "DELETE_CLAUSELIBITEM", payload: resCLI.data.data })
                  closeDialog('clauseLibDeleted')
              } else { setErrMsg("An error occured while deleting the clause library item - refresh your browser"); setLoading(false); }
          }).catch((err) => { setErrMsg("An error occured while deleting the clause library item"); setLoading(false); })
      }

    }
  }

  const handleSelectClauseLibItem = (item) => {

    setLibItem({
      rating: item.rating,
      clauseTypes: item.clauseTypes,
      clauseText: item.text.replaceAll("\n\n\n\n", "\n\n"),
      libID: state.org._id + "_" + item._id
    })
    setFindClauseFromLibrary(false);

  }

  const handleClauseLibraryListError = (error) => {
    console.log("handleClauseLibraryListError", error)
  }

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>

            {
            Boolean(props.details) &&
            ['alternativeclause'].includes(props.details.type) ?
                "Replace clause" :
            Boolean(props.details) && 
            ['insertclause'].includes(props.details.type) ?
                "Insert clause" :                
            Boolean(props.details) && 
            ['editlibrary'].includes(props.details.type) ?
                "Edit library item" :
            Boolean(props.details) && 
            ['savelibrary'].includes(props.details.type) ?
                "Save to library" :            
            Boolean(props.details) && Boolean(props.details.name) &&
            ['updatetopic'].includes(props.details.type) ? 
                props.details.name : 
            Boolean(props.details) && 
            ['newtopic'].includes(props.details.type) ?
                "New Topic" :
            Boolean(props.details) && 
            ['assigntopic'].includes(props.details.type) ?
                "Assign Topic" :
                "Clause Options"}
            &nbsp;&nbsp;
            <FontAwesomeIcon
            icon={Boolean(props.details) && ['editlibrary', 'savelibrary', 'insertclause', 'alternativeclause'].includes(props.details.type) ? 
              faBook : faTag} 
            style={{color: theme.palette.primary.main}} />
            
        </DialogTitle>
        <DialogContent sx={{my:3}}>
          <Grid container direction="column" alignItems="center">
            {loading ? 
            <Grid item sx={{my:2}}>
                <CanveoCircularProgress />
            </Grid>
            :
            deletingTopic || deletingLibItem ? 
            <Grid item sx={{width: isSmUp ? '400px' : '300px', py:2}}>
              <Box sx={{textAlign:'center'}}>
                  <FontAwesomeIcon icon={faExclamationTriangle} style={{color: theme.palette.error.main, fontSize: '30px', marginBottom: '30px'}} />
                  <Typography>Are you sure you want to delete this {deletingTopic ? "custom topic" : "clause library item"}?</Typography>
                  <Typography sx={{fontWeight: '700'}}>This action cannot be undone.</Typography>
                  <Box sx={{mt: 3}}>
                      <Button variant="contained" disableElevation color="error"
                      onClick={handleConfirmDelete}>
                          Delete&nbsp;&nbsp;<FontAwesomeIcon icon={faTrash}/>
                      </Button>
                  </Box>
              </Box>
            </Grid>
            :
            findClauseFromLibrary ?
            <Grid item sx={{pt:2}}>

              <ClauseLibraryList
              type={'find'}
              onSelect={handleSelectClauseLibItem}
              handleError={handleClauseLibraryListError}
              initialFilters={Boolean(props.details) && ['alternativeclause'].includes(props.details.type) && Boolean(props.details.activeCTs) ?
                props.details.activeCTs : []}
              />

            </Grid>
            :
            <Grid item sx={{width: isSmUp ? '400px' : '300px', py:2}}>
                {Boolean(errMsg) ?
                <Box sx={{mb:3}}>
                <Typography color="error" align="center">{errMsg}</Typography>
                </Box>
                :''}

                { 
                // Assign Topics to a clause or save to library
                Boolean(props.details) && ['assigntopic', 'savelibrary', 'editlibrary', 'insertclause', 'alternativeclause'].includes(props.details.type) ?
                <>
                <Box sx={{textAlign:'center', my: (Boolean(libItem) && Boolean(libItem.clauseTypes)) ? 1 : 3}}>

                  {// when inserting clauses - just display the chips (ie. don't allow to update)
                  Boolean(props.details) && ['insertclause', 'alternativeclause'].includes(props.details.type) && Boolean(libItem) && Boolean(libItem.clauseTypes) ?
                  <Box>
                    {libItem.clauseTypes.map((ct) => {
                      let clauseT = state.clauseTypes.filter((sct) => sct._id === ct)[0]
                      return(
                        <Chip 
                        key={ct}
                        color="primary"
                        size="small"
                        style={{ padding: '4px', marginRight: '5px'}}
                        label={<span style={{fontWeight: '600'}}>{Boolean(clauseT) ? clauseT.name : "Unknown"}</span>}
                      />)
                    })}
                  </Box>
                  :
                  Boolean(assignTopics) || (Boolean(libItem) && Boolean(libItem.clauseTypes)) ?
                    <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={state.clauseTypes}
                    getOptionLabel={(option) => option.name}
                    value={
                      Boolean(libItem) && Boolean(libItem.clauseTypes) ?
                          state.clauseTypes.filter((ct) => libItem.clauseTypes.includes(ct._id)) :
                          state.clauseTypes.filter((ct) => assignTopics.includes(ct._id))}
                    onChange={(e, value) => handleTopicChange(Boolean(libItem) ? 'library' : 'topics', value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Clause topics"
                        placeholder="Select..."
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                          <Chip {...getTagProps({ index })}
                          label={<span style={{fontWeight: '600', fontSize: '14px', padding: '5px 4px 5px 5px'}}>{option.name}</span>}
                          color="primary"
                          />
                      ))
                    }
                    />
                    :
                    "Unable to assign topics"
                  }

                  {Boolean(libItem) ?
                  <>
                  <Box sx={{py: 4, textAlign: 'left', fontSize: '14px', color: theme.palette.grey[700]}}>

                    {Boolean(props.details) && props.details.type === 'editlibrary' ?
                    <TextField
                      id="standard-multiline-flexible"
                      label="Clause text"
                      multiline
                      fullWidth
                      minRows={4}
                      maxRows={8}
                      value={libItem.clauseText}
                      onChange={e => setLibItem({...libItem, clauseText: e.target.value})}
                    />

                    :
                    
                    trunc(libItem.clauseText, 250)}

                  </Box>
                  {!(Boolean(props.details) && ['insertclause', 'alternativeclause'].includes(props.details.type)) ?

                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Stack spacing={1}>
                        <Rating 
                        name="half-rating" 
                        //defaultValue={null}
                        size="large"
                        precision={0.5} 
                        value={libItem.rating}
                        onChange={(e, newVal) => setLibItem({...libItem, rating: newVal})}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  :''}

                  </>
                  :''}

                  {// when inserting clauses - just display the chips (ie. don't allow to update)
                  Boolean(props.details) && ['insertclause'].includes(props.details.type) ?

                  <Box sx={{mt: 1}}>
                  <FormControl>
                    <RadioGroup row name="insert-location" value={insertLocation} onChange={e => setInsertLocation(e.target.value)}>
                      <FormControlLabel value="insertBefore" control={<Radio />} label="Insert before" />
                      <FormControlLabel value="insertAfter" control={<Radio />} label="Insert after" />
                      <FormControlLabel value="replaceCurrent" control={<Radio />} label="Replace" />
                    </RadioGroup>
                  </FormControl>
                  </Box>
                  :''}


                </Box>
                </>
                : // Creating new topic / or updating guidance for an existing topic
                <>
                  {Boolean(guidance) && Boolean(guidance.isNewTopic) ?
                  <Box sx={{mb:2}}>
                      <TextField
                      label="Topic Name"
                      placeholder="Legal topic name..."
                      fullWidth
                      value={guidance.name}
                      onChange={e => setGuidance({...guidance, name: e.target.value})}
                      autoFocus
                      />
                  </Box>
                  :''}

                  <TextField
                  id="outlined-multiline"
                  label="Topic Guidance"
                  multiline
                  autoFocus={!guidance.isNewTopic}
                  fullWidth
                  minRows={4}
                  maxRows={6}
                  value={guidance.guidance}
                  onChange={e => setGuidance({...guidance, guidance: e.target.value})}
                  placeholder="Legal topic guidance..."
                  />
                </>
                }
            </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
            <Button 
            onClick={
              Boolean(props.details) && ['insertclause'].includes(props.details.type) && !findClauseFromLibrary ?
                  e => { setFindClauseFromLibrary(true); setErrMsg(null) } :
              deletingLibItem ? 
                  e => { setDeletingLibItem(false); setErrMsg(null) } :
              deletingTopic ? 
                  e => { setDeletingTopic(false); setErrMsg(null) } : 
                  e => closeDialog()} 
              sx={{marginRight: 'auto'}}>
                {deletingTopic || deletingLibItem ||
                (Boolean(props.details) && ['insertclause'].includes(props.details.type) && !findClauseFromLibrary) ? 
                    <><FontAwesomeIcon icon={faArrowLeft}/>&nbsp;&nbsp;Go Back</> : 
                    "Cancel"}
            </Button>

            {
            isCustomClauseType && !deletingTopic ?
              <Button color="error" onClick={e => setDeletingTopic(true)}><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;Delete Topic</Button>
            :
            Boolean(props.details) && ['editlibrary'].includes(props.details.type) && !deletingLibItem ?
              <Button color="error" onClick={e => setDeletingLibItem(true)}><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;Delete Clause</Button>
            :''}

            <Button variant="contained" disableElevation
              onClick={
                Boolean(props.details) && ['insertclause', 'alternativeclause'].includes(props.details.type) ?
                    submitInsertClause :
                Boolean(props.details) && ['editlibrary'].includes(props.details.type) ? 
                    submitEditLibraryItem :
                Boolean(props.details) && ['savelibrary'].includes(props.details.type) ? 
                    submitSaveToLibrary :
                Boolean(props.details) && ['assigntopic'].includes(props.details.type) ? 
                    submitTopicAssign :
                Boolean(guidance) && Boolean(guidance.isNewTopic) ? 
                    submitTopicUpdate : 
                    submitGuidanceUpdate}
              disabled={
                (Boolean(guidance) && Boolean(guidance.isNewTopic) && guidance.name.length < 3) ||
                deletingTopic || deletingLibItem || findClauseFromLibrary ||
                (Boolean(libItem) && ((Boolean(libItem.clauseTypes) && libItem.clauseTypes.length < 1) || !Boolean(libItem.rating)))
            }>
                {Boolean(props.details) && ['alternativeclause'].includes(props.details.type) ?
                    "Replace" :
                Boolean(props.details) && ['insertclause'].includes(props.details.type) ?
                    "Insert" :
                Boolean(props.details) && ['editlibrary', 'savelibrary'].includes(props.details.type) ?
                    "Save" :
                Boolean(props.details) && ['assigntopic'].includes(props.details.type) ?
                    "Assign" :
                Boolean(guidance) && guidance.isNew ? 
                    "Create" : 
                    "Update"
                }&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} />
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}