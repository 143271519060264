import * as React from 'react';
import { ThumbAgr } from '.';
import Carousel from 'react-grid-carousel'

const CoverFlow = (props) => {

  return (
    <div>
        <Carousel cols={3} rows={1} gap={0} loop
        containerStyle={{display: 'block'}}>
        {props.templates
        .sort((a, b) => (a.agrTitle > b.agrTitle) ? 1 : -1)
        .map((t,i) => {
            t.agrStatus = "Active";
            return (
                <Carousel.Item key={i}>
                    <div style={{padding: '20px 0px', margin: '0px 0px 0px 10px'}}>
                    <ThumbAgr 
                    ag={t} 
                    actionReq={false}
                    thumbClick={e => props.handleSelect(t._id, props.selectionType)}
                    showLogo={props.logoURL}
                    primaryLegalName={t.reference}
                    secondaryLegalName={'Version ' + t.version}
                    isTemplate={true}
                    />
                    </div>
                </Carousel.Item>
            )
        })}
        </Carousel>
    </div>
  );
};
export default CoverFlow;