import React, { useContext } from 'react';
import theme from '../theme/theme';
import { useNavigate } from "react-router-dom";
import { globalStore } from '../state/store';
import { trunc } from '../utils'
import { Avatar, Box, Button, Grid, IconButton, 
         List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem,
         Tooltip, Typography,
         useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignature, faTimesCircle, faEllipsis, faHourglassHalf } from "@fortawesome/pro-solid-svg-icons";

import CanveoCircularProgress from './CanveoCircularProgress';

const axios = require('axios');

const AgrExecBox = (props) => {

    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    const navigate = useNavigate();

    const [state, dispatch] = useContext(globalStore);
    const [anchorElCancel, setAnchorElCancel] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState(null)

    const signURL = 'https://my.skribble.com/view/' + (Boolean(state.agrExec) ? state.agrExec.signRequestID : 'err')

    const handleCancelSignRequest = () => {

        setLoading(true);
        setErrMsg(null);
        setAnchorElCancel(null)

        axios.post(state.settings.api + "signing/cancel", {
            mainAid: state.agrExec.agrID, 
            signRequestID: state.agrExec.signRequestID
        })
        .then((resAgDetail) => {

            // TODO => Inform all collabs that signing was canceled
            
            dispatch({ type: "INIT_AGRS", payload: resAgDetail.data.data.ags })
            dispatch({ type: "INIT_AVS", payload: resAgDetail.data.data.avs })
            dispatch({ type: "INIT_PARTIES", payload: resAgDetail.data.data.parties })
            dispatch({ type: "UPDATE_AGREXEC", payload: resAgDetail.data.data.agrExec })
            setLoading(false);

        }).catch((err) => { setErrMsg("An error occurred while canceling the sign request"); setLoading(false); })
    }

    return (

    <Box sx={{ 
        mt: 1,
        borderRadius: '20px 20px 0px 0px', 
        border: '1px solid' + theme.palette.grey[300],
        borderBottom: '3px solid' + theme.palette.primary.main,
        //backgroundColor: theme.palette.grey[50],
        padding: '20px',
        width: isMdUp ? '600px' : isSmUp ? '440px' : '320px',
    }}>

    <Grid container direction="column">
        
        <Grid item container direction="row" justifyContent="space-between" sx={{mt:1}}>
            <Grid item sx={{width: '30px'}}>&nbsp;</Grid>
            <Grid item>
                <Box mb={2}><FontAwesomeIcon icon={faSignature} style={{color: theme.palette.primary.main, fontSize: '38px', padding: '1px'}} /></Box>
            </Grid>
            <Grid item sx={{width: '30px'}}>
                {state.user.role !== 'Counterparty' &&
                Boolean(state.agrExec) && Boolean(state.agrExec.signRequestID) ?
                <>
                <Tooltip title="Cancel sign request" placement="left">
                    <IconButton onClick={e => setAnchorElCancel(e.currentTarget)} disabled={loading}>
                        <FontAwesomeIcon icon={faEllipsis} style={{fontSize: '14px', padding: '0px 1px', marginTop: '2px'}} />
                    </IconButton>
                </Tooltip>
                <Menu
                anchorEl={anchorElCancel}
                keepMounted
                open={Boolean(anchorElCancel)}
                onClose={e => setAnchorElCancel(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                >
                <MenuItem
                style={{ fontSize: '15px', fontWeight: '500', padding: '10px 30px' }}
                /*onClick={e => { setCreatingUser({...creatingUser, orgID: p.orgID}); setAnchorElCpty(null); }}*/
                onClick={handleCancelSignRequest}
                >
                    <FontAwesomeIcon icon={faTimesCircle}
                    style={{color: theme.palette.error.main, padding: '5px'}}
                    />
                    &nbsp;&nbsp;&nbsp;Cancel sign request
                </MenuItem>
                    
                </Menu>
                </>
                :''}

            </Grid>
        </Grid>

        {loading ?
        <Grid container justifyContent="center" sx={{my: 3}}>

            <CanveoCircularProgress />
        
        </Grid>
        :Boolean(errMsg) ?
        <Grid container justifyContent="center" sx={{my: 3}}>

            <Typography color="error" align="center">
                {errMsg}<br/>
                Refresh the page and try again or contact Canveo Support if the issue persists.
            </Typography>
        
        </Grid>

        :
        <>
        <Grid item>
            <Box mb={1}>
            <Typography variant="subtitle1" align="center">This agreement requires signatures from:</Typography>
            </Box>
        </Grid>
        <Grid item container justifyContent="center" sx={{ width: '100%', px: isMdUp ? 3 : isSmUp ? 2 : 0 }}>

            {!Boolean(state.agrExec) ?
            <Box sx={{my: 3}}>
                <CanveoCircularProgress />
            </Box>
            :
            <List sx={{width: '100%'}}>
            {state.agrExec.signers
            .sort((a, b) => (a.order > b.order) ? 1 : -1)
            .map((s,i) => {

                let ag = state.agrs.filter((a) => a._id === state.agrExec.agrID)[0]
                let sigOrder = state.agrExec.signers.some((s) => s.order !== undefined && s.order !== null && s.order > -1)
                let sigOrderAssignee = sigOrder && Boolean(state.agrExec.signers.filter((s) => !['SIGNED'].includes(s.status_code)).sort((a, b) => (a.order > b.order) ? 1 : -1)[0]) ?
                    state.agrExec.signers.filter((s) => !['SIGNED'].includes(s.status_code)).sort((a, b) => (a.order > b.order) ? 1 : -1)[0].order :
                    null
                let signerEmail = Boolean(s.signer_identity_data) && Boolean(s.signer_identity_data.email_address) ?
                    s.signer_identity_data.email_address : s.signer_email_address;
                let signerFirstName = Boolean(s.signer_identity_data) && Boolean(s.signer_identity_data.first_name) ?
                    s.signer_identity_data.first_name : '';
                let signerLastName = Boolean(s.signer_identity_data) && Boolean(s.signer_identity_data.last_name) ?
                    s.signer_identity_data.last_name : '';
                let personalSignURL = Boolean(s.signing_url) ? s.signing_url : signURL
                
                let sig = Boolean(ag) ? ag.signers.filter((s) => s.email === signerEmail)[0] : null;
                let entityName = Boolean(sig) ? sig.entityName : '';
                let photoURL = Boolean(state.users.filter((u) => u.email === signerEmail)[0]) ? 
                    state.users.filter((u) => u.email === signerEmail)[0].photoURL : null

                let status = 
                    ['DECLINED'].includes(s.status_code) ?
                        <><span className="bold" style={{color: theme.palette.error.main, fontSize: '14px'}}>
                            Signed&nbsp;&nbsp;<FontAwesomeIcon icon={faTimesCircle} />
                        </span></> :
                    state.agrExec.signers.some((s) => s.status_code === 'DECLINED') ?
                        <><span className="bold" style={{color: theme.palette.grey[500], fontSize: '14px'}}>
                            Cancelled&nbsp;&nbsp;<FontAwesomeIcon icon={faTimesCircle} />
                        </span></> :
                    ['SIGNED'].includes(s.status_code) ?
                        <><span className="bold" style={{color: theme.palette.success.main, fontSize: '14px'}}>
                            Signed&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} />
                        </span></> :
                    signerEmail === state.user.email &&
                    (['OPEN'].includes(s.status_code) || !Boolean(s.status_code)) &&
                    (!sigOrder || sigOrderAssignee === s.order)
                    ?
                        <><Button variant="contained" disableElevation sx={{padding: '5px 21px'}}
                        onClick={e => window.location.assign(
                            personalSignURL + "?exitURL=" + encodeURIComponent(
                                window.location.origin + 
                                '/dashboard/' + 
                                (state.user.role === 'Counterparty' ? '2' : '3')) + 
                            '&redirectTimeout=10&hidedownload=true')}
                        >
                            Sign&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} />
                        </Button></> :
                    sigOrder && s.order > sigOrderAssignee &&
                    (['OPEN'].includes(s.status_code) || !Boolean(s.status_code)) ?
                        <><span className="bold" style={{color: theme.palette.grey[500], fontSize: '14px'}}>
                            Awaiting&nbsp;&nbsp;<FontAwesomeIcon icon={faHourglassHalf} />
                        </span></> :
                    ['OPEN'].includes(s.status_code) || !Boolean(s.status_code) ?
                        <><span className="bold" style={{color: theme.palette.primary.main, fontSize: '14px'}}>
                            Pending&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} />
                        </span></> : '';

                return (
                <ListItem key={i} 
                secondaryAction={status}
                >
                <ListItemAvatar>
                    {Boolean(photoURL) ? <Avatar src={photoURL} /> :<Avatar>{signerFirstName[0] + signerLastName[0]}</Avatar>}
                </ListItemAvatar>
                <ListItemText
                    primary={trunc((sigOrder ? (s.order + 1 + ". ") : '') + signerFirstName + ' ' + signerLastName, 24)}
                    secondary={trunc(entityName, 30)}
                />
                </ListItem>
                )
            })}
            </List>
            }

        </Grid>
        </>
        }
    </Grid>

    </Box>
    )
}

export default AgrExecBox