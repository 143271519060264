import { $getRoot } from 'lexical';
import { useCallback } from 'react';

export function useOnChange(setContent, setCanSubmit, setSubscribers) {
  return useCallback(
    (editorState, _editor) => {
      editorState.read(() => {
        let newContent = Boolean($getRoot) && Boolean($getRoot().getTextContent()) ? $getRoot().getTextContent() : ''
        let subs = [], mentions = [];
        $getRoot().getChildren().forEach((node) => {
          if(node.getType() === 'mention' && !subs.some((s) => s === node.__uid)) { subs.push(node.__uid) }
          else if(Boolean(node.getChildren())) {
            node.getChildren().forEach((child) => {
              if(child.getType() === 'mention' && !subs.some((s) => s === child.__uid)) { 
                subs.push(child.__uid);
              } 
            })
          }
        })
        setSubscribers(subs);
        setContent(newContent);
        setCanSubmit(newContent.length > 2);
      });
    }, [setCanSubmit, setContent],
  );
}