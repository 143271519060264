import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { trunc } from '../utils';
import axios from 'axios';
import { Autocomplete, Button, Box, Checkbox, Collapse,
         Dialog, DialogActions, DialogContent, DialogTitle, 
         FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select,
         useMediaQuery, 
         Typography,
         TextField} from '@mui/material';
import { CanveoCircularProgress, ParamEditable } from '.';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faMagnifyingGlassChart } from '@fortawesome/pro-solid-svg-icons';

import { paramtypes } from '../assets/static';

export default function DialogClause(props) {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const [param, setParam] = React.useState(null)

  useEffect(() => {

    if(props.open && Boolean(props.details) && Boolean(props.details.type)) {
        let p = ['updateDocParam'].includes(props.details.type) && Boolean(state.params.doc.filter((p) => p._id === props.details.paramID)[0]) ?
                state.params.doc.filter((p) => p._id === props.details.paramID)[0] : null;

        setParam(p);
        
    } else {
        setParam(null)
    }

  }, [props.open])

  const closeDialog = (action) => {
      setLoading(false)
      setErrMsg(null)
      props.closeDialog(action);
  }

  const handleParamValueChange = (sfid, valnumber, newval) => {
    if(valnumber === 'val1') {
        setParam({...param, val1: newval})
    } else if(valnumber === 'val2') {
        setParam({...param, val2: newval})
    }
  }

  const submitUpdate = () => {

    let newParam = param

    axios.put(state.settings.api + 'param/' + newParam._id, { param: newParam }) // POST the regular Param
    .then((resParam) => {
      if(resParam.data.success) {
        dispatch({ type: "UPDATE_PARAM", payload: { type: 'doc', item: resParam.data.data } })
        setLoading(false);
        closeDialog();
        //attachWorkflowToDOM(newParam.lid)
      } else { setErrMsg("Unable to create parameter - please refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("Unable to create parameter"); setLoading(false); })

  }

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>

            {Boolean(props.details) && ['updateDocParam'].includes(props.details.type) ?
                "Update parameter" :
                "Parameter options"}
            &nbsp;&nbsp;
            <FontAwesomeIcon
            icon={faMagnifyingGlassChart} 
            style={{color: theme.palette.primary.main}} />
            
        </DialogTitle>
        <DialogContent sx={{my:3}}>
          <Grid container direction="column" alignItems="center">
            {loading ? 
            <Grid item sx={{my:2}}>
                <CanveoCircularProgress />
            </Grid>
            :
            <Grid item>

                {Boolean(errMsg) ? 
                <Box sx={{mb:3}}>
                    <Typography color="error" align="center">
                        {errMsg}
                    </Typography>
                </Box>
                :''}

                {Boolean(param) ?
                
                <Box sx={{width: '260px'}}>
                    <Typography sx={{fontWeight: '700', marginBottom: '15px'}} align="center">Initial Value:</Typography>
                    <Box sx={{mb:1}}>
                    <FormControl variant="outlined" fullWidth>
                        {/*<InputLabel style={{backgroundColor: theme.palette.grey[100], padding: '0px 2px 0px 2px'}}>Parameter Type</InputLabel>
                        */}
                        <Select
                        value={param.type}
                        onChange={e => setParam({...param, type: e.target.value})}
                        renderValue={selected => {
                            let s =  paramtypes.filter((tc) => tc.value === selected)[0] !== undefined ? 
                            paramtypes.filter((tc) => tc.value === selected)[0].type : ""
                            return <span>{s}</span>;
                        }}
                        >
                        {paramtypes
                        //.filter((tc) => props.templating || tc.value !== 'auto')
                        .map((pt,i) => (
                        <MenuItem key={i} value={pt.value}><span style={{fontWeight: '700', marginRight: '10px'}}>{pt.type}</span>{pt.desc}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Box>
                    <Box sx={{mb:2}}>

                    <ParamEditable                        
                    fromClause={false}
                    type={param.type}
                    val1={param.val1}
                    val2={param.val2}
                    onFieldChange={handleParamValueChange}
                    sfid={"newparam"} />

                    </Box>

                    <Typography sx={{fontWeight: '700', marginBottom: '15px'}} align="center">Calculated Value:</Typography>
                    <TextField
                    placeholder="Calculated value..."
                    fullWidth
                    value={param.valCalc}
                    onChange={e => setParam({...param, valCalc: e.target.value})}
                    //value={filters.reference}
                    //onChange={e => setFilters({...filters, reference: e.target.value})}
                    />

                    <Box sx={{mt: 2, textAlign: 'center'}}>
                        <FormControlLabel 
                        control={<Checkbox size="small" color="primary" checked={param.createWizard} 
                            onChange={e => setParam({...param, createWizard: e.target.checked})} />}
                        label={<Typography>Include in Wizard</Typography>}
                        />
                        <Collapse in={param.createWizard}>
                            <Box sx={{mt:2, textAlign: 'left'}}>
                            <TextField
                            label={"Wizard Question"}
                            placeholder={"Provide question..."}
                            value={param.createWizardQuestion}
                            onChange={e => setParam({...param, createWizardQuestion: e.target.value})}
                            style={{width: '100%'}}
                            rows={3}
                            multiline
                            />
                            </Box>
                        </Collapse>
                    </Box>
                </Box>

                :''}

            </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
            <Button sx={{marginRight: 'auto'}} onClick={closeDialog}> 
                Cancel
            </Button>

            <Button variant="contained" disableElevation onClick={submitUpdate}>
                Update&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} />
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}