import React, { useContext, useEffect } from 'react';
import { globalStore } from '../state/store';
import { Avatar, AvatarGroup, Button, Box, CircularProgress, Grid,
         IconButton, Menu, MenuItem, ListItemText, Typography } from '@mui/material';
import { SelectUserForOrg } from '.';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentLines, faThumbsUp, faCaretRight, faMagnifyingGlassChart, faTimes, 
         faCircleInfo, faPaperPlane, faEllipsisVertical, faCommentCheck, faCheck, 
         faArrowLeft, faReply, faBan, faThumbsDown, faNote } from '@fortawesome/pro-solid-svg-icons';
import { trunc, getSmartFieldValue } from '../utils';
import { useOnChange, getClauseNode, getClauseHTML } from './editor/utils';
import { createWorkflow } from './editor/plugins/commenting';
import theme from '../theme/theme';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey, $getSelection } from 'lexical';
import { ADD_WORKFLOW_COMMAND, REMOVE_CLAUSETYPE_COMMAND, REMOVE_WORKFLOW, REMOVE_MARK } from './editor/commands';
import { PlainTextEditor } from './editor/plugins/commenting/PlainTextEditor';
import { createComment } from './editor/plugins/commenting';

import axios from 'axios';

const dayjs = require('dayjs')

const ContextItem = (props) => {

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState(null);
  const [justChanged, setJustChanged] = React.useState(null);
  const [isApproverRequestorAddingComment, setIsApproverRequestorAddingComment] = React.useState(false)
  const [cancellingApprovalRequest, setCancellingApprovalRequest] = React.useState(false);
  const [editing, setEditing] = React.useState(null)
  const [editor] = useLexicalComposerContext();
  const [approverSelected, setApproverSelected] = React.useState(null)
  const [isHover, setIsHover] = React.useState(false);
  const [anchorElCommentOptions, setAnchorElCommentOptions] = React.useState(null);
  const [comments, setComments] = React.useState([])
  const [workflow, setWorkflow] = React.useState(['internalComment', 'publicComment', 'approval'].includes(props.type) && 
        Boolean(state.workflows.filter((wf) => wf.lid === props.id)[0]) ? 
            state.workflows.filter((wf) => wf.lid === props.id)[0] : {_id: null})
  const [doc, setDoc] = React.useState(null);

  const [content, setContent] = React.useState('');
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [subscribers, setSubscribers] = React.useState([])
  const onChange = useOnChange(setContent, setCanSubmit, setSubscribers);

  const clauseType = ['clauseType'].includes(props.type) && Boolean(state.clauseTypes.filter((ct) => ct._id === props.id)[0]) ? 
                    state.clauseTypes.filter((ct) => ct._id === props.id)[0] : null;
  const clauseTypeGuide = ['clauseType'].includes(props.type) && Boolean(clauseType) &&
            Boolean(state.clauseTypeGuides.filter((ctg) => ctg.ctid === clauseType._id)[0]) ? 
                state.clauseTypeGuides.filter((ctg) => ctg.ctid === clauseType._id)[0] : null
  const isDefinition = ['clauseType'].includes(props.type) && Boolean(clauseType) && clauseType.name === 'Definition'
  const approvalAssignee = ['approval'].includes(props.type) && Boolean(workflow) && 
            Boolean(workflow.assignee) && Boolean(workflow.assignee[0]) &&
            Boolean(state.users.filter((u) => u._id === workflow.assignee[0].uid)[0]) ?
                state.users.filter((u) => u._id === workflow.assignee[0].uid)[0] : null
  const approvalCreator = ['approval'].includes(props.type) && Boolean(workflow) && Boolean(workflow.creator) &&
            Boolean(state.users.filter((u) => u._id === workflow.creator.uid)[0]) ? 
                state.users.filter((u) => u._id === workflow.creator.uid)[0] : null
  const isApprovalAssignee = Boolean(approvalAssignee) && approvalAssignee._id === state.user._id
  const isApprovalRequestor = ['approval'].includes(props.type) && Boolean(workflow) && 
            Boolean(workflow.creator) && workflow.creator.uid === state.user._id

  /*const approvalStatus = ['approval'].includes(props.type) && Boolean(workflow.assignee[0]) && Boolean(workflow.assignee[0].status) ? 
                workflow.assignee[0].status : null;*/

  const param = ['param'].includes(props.type) && Boolean(state.params.doc.filter((pd) => pd.lid === props.id)[0]) ? 
                state.params.doc.filter((pd) => pd.lid === props.id)[0] : null

  useEffect(() => {

    if(props.isExpanded && ['internalComment', 'publicComment', 'approval'].includes(props.type) && 
    Boolean(workflow) && Boolean(workflow._id)) {

        axios.get(state.settings.api + "workflow/" + workflow._id)
        .then((resWF) => {
  
          if(resWF.data.success) {
  
            setWorkflow(resWF.data.data)
            setComments(resWF.data.data.comments)
            setLoading(false)
  
          } else { setErrMsg('snackOpenError'); setLoading(false) }
        }).catch((err) => { setErrMsg('snackOpenError'); setLoading(false) })

    /*} else if(props.isExpanded && ['internalComment', 'publicComment', 'approval'].includes(props.type) && 
    (!Boolean(workflow) || !Boolean(workflow._id))) { // Workflow not found

        setLoading(false)
        setEnableResolve(true)*/
    
    } else {
        setLoading(!props.isExpanded) //&& !['internalComment', 'publicComment', 'approval'].includes(props.type)
        setComments([])
        setErrMsg(null);
        setJustChanged(null);
        setApproverSelected(null);
    }

  }, [props.isExpanded, workflow._id])

  useEffect(() => { // Set AGR which is Template or Agr

    setDoc(
        Boolean(workflow) && Boolean(workflow._id) && props.isTemplating ? 
            state.templates.filter((t) => t._id === workflow.docID)[0] : 
        Boolean(workflow) && Boolean(workflow._id) ?
            state.agrs.filter((a) => a._id === workflow.docID)[0] :
            null)

  }, [workflow.docID])

  const removeClauseType = (key, ctid) => {
    editor.dispatchCommand(REMOVE_CLAUSETYPE_COMMAND, { key: key, ctid: ctid });
  };

  const onEscape = (event) => {
    event.preventDefault();
    return true;
  };

  const handleEditComment = () => {
    if(Boolean(anchorElCommentOptions) && Boolean(anchorElCommentOptions.id) &&
    Boolean(comments.filter((c) => c.id === anchorElCommentOptions.id))) {
        let comm = comments.filter((c) => c.id === anchorElCommentOptions.id)[0];
        if(Boolean(comm)) {
            setEditing(comm);
            setAnchorElCommentOptions(null);
            setJustChanged(null);
        }
    }
  }

  const deleteComment = () => {
    if(Boolean(anchorElCommentOptions) && Boolean(anchorElCommentOptions.id)) {
        let newWF = workflow;
        newWF.comments = comments.filter((c) => c.id !== anchorElCommentOptions.id);
        setAnchorElCommentOptions(null);
        pushWorkflowUpdate(newWF, null, []);
    }
  }

  const removeWorkflow = () => {
    if(['internalComment', 'approval'].includes(props.type)) {
        editor.dispatchCommand(REMOVE_WORKFLOW, {key: props.activeClauseKey, wfid: workflow.lid });
    } else {
        editor.dispatchCommand(REMOVE_MARK, workflow.lid);
    }
  }

  const resolveThread = () => {
    let newWF = workflow;
    let newComms = comments
    newComms.push(createComment('===RESOLVED===', { uid: state.user._id, displayName: state.user.displayName }))
    newWF.comments = newComms
    newWF.wfStatus = 'resolved';

    pushWorkflowUpdate(newWF, null, []);
    if(['internalComment', 'approval'].includes(props.type)) {
        editor.dispatchCommand(REMOVE_WORKFLOW, {key: props.activeClauseKey, wfid: workflow.lid });
    } else {
        editor.dispatchCommand(REMOVE_MARK, workflow.lid);
    }
  }

  const removeParam = () => {

    setLoading(true);
    let newParam = param;
    newParam.orgID = 'deleted_' + param.orgID
    newParam.docID = 'deleted_' + param.docID

    axios.put(state.settings.api + 'param/' + param._id, { param: newParam })
    .then((resParam) => {
        if(resParam.data.success) { // Add newly created agrType to the reducer
            editor.dispatchCommand(REMOVE_MARK, param.lid)
            dispatch({ type: "UPDATE_WORKFLOW", payload: { type: 'doc', item: resParam.data.data } })
            setLoading(false);
        } else { setErrMsg("An error occured while removing the param - refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("An error occured while removing the param"); setLoading(false); })
    
  }

  const handleApprovalRequestAction = (action) => {

    let newWF = workflow;
    let newComms = comments
    let commString = 
            ['cancelled'].includes(action) ? '===CANCELLED===' :
            ['approved'].includes(action) ? '===APPROVED===' :
            ['rejected'].includes(action) ? '===REJECTED===' : null

    // TODO 3: then incorporate approved and rejected

    if(Boolean(commString)) {
        newComms.push(createComment(commString, { uid: state.user._id, displayName: state.user.displayName }))
        newWF.comments = newComms
        newWF.wfStatus = ['cancelled', 'approved', 'rejected'].includes(action) ? 'resolved' : 'open';

        let newAssignee = workflow.assignee
        newAssignee[0] = {...newAssignee[0], status: action}

        let clauseHTML = generateClauseHTML()

        let emailRecipients = [{ // Inform the assignee that the request has been cancelled
            doc: doc,
            whiteLabel: props.isTemplating ? null : null, // TODO 
            partyFullString: props.isTemplating ? null : null, // TODO 
            recipient: ['cancelled'].includes(action) ? approvalAssignee : approvalCreator,
            isPublic: false,
            isTemplating: props.isTemplating,
            isApproval: true,
            isApprover: true,
            content: commString,
            clauseHTML: clauseHTML,
            recentComments: newWF.comments.sort((a, b) => (a.date > b.date) ? -1 : 1).slice(0,3)
        }]

        pushWorkflowUpdate(newWF, null, emailRecipients);
        
        if(['cancelled', 'approved'].includes(action)) {
            //editor.dispatchCommand(REMOVE_MARK, workflow.lid);
            editor.dispatchCommand(REMOVE_WORKFLOW, { key: props.activeClauseKey, wfid: workflow.lid})
        }
    }

  }

  const createWorkflowAndAttachToClause = () => {

    if(canSubmit) {
        setLoading(true);

        let wfType = ['createInternalComment'].includes(props.type) ? 'ithread' : 'approval'
        let prefix = (['createInternalComment'].includes(props.type) ? 'cp' : 'ap') + props.partyID.substring(5) + '_'
        let assignee = [];
        let allSubs = subscribers;
        if(!allSubs.some((s) => s === state.user._id)) { allSubs.unshift(state.user._id) } // Insert creator as subscriber

        // Prepare emails to be sent
        let emailRecipients = []
        let doc = props.isTemplating ? 
                state.templates.filter((t) => t._id === props.docID)[0] : 
                state.agrs.filter((a) => a._id === props.docID)[0]

        let clauseHTML = editor.getEditorState().read(() => {
            const clauseNode = Boolean(props.activeClauseKey) ? $getNodeByKey(props.activeClauseKey) : null;
            const html = Boolean(clauseNode) ? getClauseHTML(clauseNode) : null
            return Boolean(html) ? html : null;
        });

        if(['ithread'].includes(wfType)) {
          subscribers.forEach((s) => {
            let recipient = state.users.filter((u) => u._id === s)[0];
            if(Boolean(doc) && Boolean(recipient)) { emailRecipients.push({ doc, recipient, content, isInternal: true, isTemplating: props.isTemplating, clauseHTML }) }
          })
        } else if(['approval'].includes(wfType) && Boolean(approverSelected)) {
          let recipient = approverSelected;
          emailRecipients.push({ doc, recipient, content, isInternal: true, isTemplating: props.isTemplating, clauseHTML })
          assignee = [{ uid: approverSelected._id, displayName: approverSelected.displayName, status: 'pending' }]
        }

        // Now generate Workflow before sending it to the server for creation
        let newWF = createWorkflow(wfType, prefix, null, content, state.user, assignee, allSubs, props.docID);


        axios.post(state.settings.api + 'workflow', { workflow: newWF })
        .then((resWF) => {
            if(resWF.data.success) { // Add newly created WF to the reducer
                let createdWF = resWF.data.data;

                editor.dispatchCommand(ADD_WORKFLOW_COMMAND, { key: props.activeClauseKey, wfid: newWF.lid });
                dispatch({ type: "ADD_WORKFLOW", payload: createdWF })

                // Now send emails to recipients
                emailRecipients.forEach((r) => {
                    axios.post(state.settings.api + 'mail/informcomm', {
                        doc: r.doc, 
                        whiteLabel: r.isTemplating ? null : null, // TODO 
                        partyFullString: r.isTemplating ? null : null, // TODO 
                        recipient: r.recipient, 
                        isPublic: !r.isInternal,
                        isTemplating: r.isTemplating,
                        isApproval: ['approval'].includes(newWF.wfType),
                        comment: r.content,
                        clauseHTML: r.clauseHTML,
                        wfid: createdWF._id, 
                        lid: createdWF.lid,
                    })
                })
            
                setLoading(false);
                props.expandItem(null);
            }
        })
    }
  }

  const submitComment = () => {
    if (canSubmit) {
      let newWF = workflow;
      let newComms = workflow.comments;
      let newSubs = workflow.subscribers;
      let isInternal = ['ithread'].includes(workflow.wfType);
      let isApproval = ['approval'].includes(workflow.wfType);

      let clauseHTML = generateClauseHTML()

      if(!Boolean(editing)) { // Inserting a new comment
        newComms.push(createComment(content, { uid: state.user._id, displayName: state.user.displayName }))
        
      } else { // Updating an existing comment
        let idx = newComms.findIndex((c) => c.id === editing.id)
        newComms[idx] = {...newComms[idx], content: content, isEdited: true}
      }

      subscribers.forEach((s) => { if(!newSubs.some((ns) => ns === s)){ newSubs.push(s) }})
      if(!newSubs.some((ns) => ns === state.user._id)){ newSubs.push(state.user._id) } // Also potentially add current commenter as subscriber

      newWF.comments = newComms;
      newWF.subscribers = newSubs;

      // Prepare emails for anyone that is newly @mentioned ("subscribers")
    
      let emailRecipients = [];
      let recentComments = newWF.comments.sort((a, b) => (a.date > b.date) ? -1 : 1).slice(0,3)
      if(['internalComment', 'publicComment', 'approval'].includes(props.type)) {
        subscribers.forEach((s) => {
            let recipient = state.users.filter((u) => u._id === s)[0]
            if(Boolean(doc) && Boolean(recipient)) {
                let isApprover = ['approval'].includes(props.type) && approvalAssignee._id === recipient._id;
                emailRecipients.push({
                  doc, recipient, content, isInternal, isTemplating: props.isTemplating, isApproval, isApprover, clauseHTML, recentComments
                })
            }
        })
      } 
      
      if(['approval'].includes(props.type)) {

        // If the current approver assignee is replying - then set status (back) to open
        if(isApprovalAssignee) {
            let newAssignee = newWF.assignee
            newAssignee[0].status = 'pending';
            newWF.assignee = newAssignee;
            newWF.wfStatus = 'open';
        }
        
        // Determine e-mail recipients of the comment notification on the approval thread
        let recipients = []

        if(!isApprovalAssignee && !subscribers.some((s) => s === approvalAssignee._id)) { // Inform assignee if it's not the current user
            recipients.push(approvalAssignee);
        }
        if(!isApprovalRequestor && !subscribers.some((s) => s === workflow.creator.uid)) { // Inform creator if it's not the current user
            recipients.push(state.users.filter((u) => u._id === workflow.creator.uid)[0])
        }
        recipients.forEach((recipient) => {
            let isApprover = approvalAssignee._id === recipient._id;
            emailRecipients.push({
                doc, recipient, content, isInternal, isTemplating: props.isTemplating, isApproval, isApprover, clauseHTML, recentComments
            })
        })
      }

      pushWorkflowUpdate(newWF, Boolean(editing) ? 'update' : 'insert', emailRecipients)

    }
  };

  const generateClauseHTML = () => {
    let h = editor.getEditorState().read(() => {
        const anchor = Boolean($getSelection()) ? $getSelection().anchor : null
        const clauseNode = Boolean(anchor) ? getClauseNode(anchor.getNode()) : null;
        const html = Boolean(clauseNode) ? getClauseHTML(clauseNode) : null
        return Boolean(html) ? html : null;
    });
    return h;
  }

  const pushWorkflowUpdate = (newWF, justChanged, emailRecipients) => {
    setLoading(true);
    axios.put(state.settings.api + 'workflow/' + workflow._id, { workflow: newWF })
    .then((resWF) => {
        if(resWF.data.success) { // Add newly created agrType to the reducer
            dispatch({ type: "UPDATE_WORKFLOW", payload: resWF.data.data })

            emailRecipients.forEach((r) => {
                axios.post(state.settings.api + 'mail/informcomm', {
                  doc: r.doc, 
                  whiteLabel: r.isTemplating ? null : null, // TODO 
                  partyFullString: r.isTemplating ? null : null, // TODO 
                  recipient: r.recipient, 
                  isPublic: !r.isInternal,
                  isTemplating: r.isTemplating,
                  isApproval: r.isApproval,
                  isApprover: r.isApprover,
                  comment: r.content,
                  clauseHTML: r.clauseHTML,
                  recentComments: r.recentComments,
                  wfid: newWF._id, 
                  lid: newWF.lid,
                })
            })

            setWorkflow(resWF.data.data)
            setComments(resWF.data.data.comments)
            setJustChanged(justChanged);
            setIsApproverRequestorAddingComment(false);
            setEditing(null);
            setLoading(false);
        } else { setErrMsg("An error occured while inserting the comment - refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("An error occured while inserting the comment"); setLoading(false); })
  }

  const handleOpenCommentOptionsMenu = (event, id) => {
    setAnchorElCommentOptions({target: event.currentTarget, id: id});
  };
  
  const handleCloseCommentOptionsMenu = () => {
    setAnchorElCommentOptions(null);
  };

  const RenderTitle = ({type}) => {
    return (
        <Typography variant="subtitle2" style={{
            fontWeight: '700', 
            color: 
                ['internalComment', 'createInternalComment'].includes(type) ? 
                    'rgba(247, 140, 0, 1)' :
                ['publicComment'].includes(props.type) ?
                    'rgba(255, 212, 0, 1)' :
                ['approval', 'createInternalApproval'].includes(props.type) ?
                    'rgba(0, 250, 230, 1)' :
                ['param'].includes(props.type) ?
                    'rgba(75, 140, 245, 1)' : 
                ['clauseType'].includes(props.type) ?
                    'rgba(114, 67, 221, 1)' : 
                    ''
            }}>
            {['internalComment', 'createInternalComment'].includes(type) ? 
                    <>Internal&nbsp;&nbsp;<FontAwesomeIcon icon={faNote} /></> :
            ['publicComment'].includes(props.type) ?
                    <>
                    Comment&nbsp;&nbsp;<FontAwesomeIcon icon={faCommentLines} />
                    {/*
                    <Button size="small" sx={{ fontWeight: '300', fontSize: '12px', padding: '2px 4px', margin: '0 10px 2px 15px', color: theme.palette.grey[600],
                    '&:hover': { backgroundColor: theme.palette.grey[100] }}}>
                        Subscribed
                    </Button>
                    <IconButton size="small">
                        <FontAwesomeIcon icon={faEllipsisH} style={{padding: '4px 2px', }} />
                    </IconButton>*/}
                    </> :
            ['approval', 'createInternalApproval'].includes(props.type) ?
                    <>Approval&nbsp;&nbsp;<FontAwesomeIcon icon={faThumbsUp} /></> :
            ['param'].includes(props.type) ?
                    <>Param&nbsp;&nbsp;<FontAwesomeIcon icon={faMagnifyingGlassChart} /></> :
            //isDefinition ?
            //        <>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuoteRight} /></> :
            ['clauseType'].includes(props.type) ?
                    <>Topic&nbsp;&nbsp;<FontAwesomeIcon icon={faCircleInfo} /></> :
                    ''}
        </Typography>
    )
  }

  const RenderMiniAvatar = ({user}) => {

    let u = Boolean(user) && Boolean(user.displayName) ? user : { displayName: "?"}

    return (
    <Avatar 
    alt={u.displayName}
    src={Boolean(u.photoURL) ? u.photoURL : null} 
    sx={{width: '25px', height: '25px', color: theme.palette.primary.contrastText, fontSize: '12px',
    backgroundColor: Boolean(u.photoURL) ? theme.palette.primary.contrastText : theme.palette.grey[800]}}>
        {(u.displayName.split(' ')[0] !== undefined ?
            u.displayName.split(' ')[0].substring(0,1) :
            '?') + 
         (u.displayName.split(' ')[1] !== undefined ?
         u.displayName.split(' ')[1].substring(0,1) :
            '')}
    </Avatar>
    )
  }

  return (
    <div>
    {!isDefinition ?
    <Grid container direction="row" alignItems="center"
    sx={{
        width: "100%",
        marginBottom: '10px',
        marginLeft: '0px',
        borderRadius: '20px',
        backgroundColor: 'rgba(255,255,255,0.9)', 
        backdropFilter: 'blur(10px)',
        zIndex: '1000',
        padding: props.isExpanded ? '15px 0px 15px 0px' : '20px 22px 15px 25px',
        border: '1px solid' + theme.palette.grey[200],
        boxShadow: isHover && !props.isExpanded ? 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px' : 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px',
        '&:hover': props.isExpanded ? {} : { cursor: 'pointer' }
    }}
    onMouseOver={e => setIsHover(true)}
    onMouseLeave={e => setIsHover(false)}
    onClick={!props.isExpanded ? e => props.expandItem(props.id) : e => null}
    >
        
        {props.isExpanded ? 
        <Grid item sx={{width: '100%'}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item sx={{ml: 2}}>
                    <RenderTitle type={props.type} />
                </Grid>
                <Grid item sx={{ margin: '0px 5px' }}>
                    <IconButton size="small" onClick={e => props.expandItem(null)}>
                        <FontAwesomeIcon icon={faTimes} style={{padding: '3px 6px'}} />
                    </IconButton>
                </Grid>
            </Grid>

            <Box sx={{
                overflowY: 'auto',
                maxHeight: '420px',
            }}>
                {loading ?
                <Box sx={{my:5, pb:2, textAlign: 'center'}}>
                    <CircularProgress size={24} />
                </Box>
                :
                ['clauseType'].includes(props.type) && Boolean(clauseType) ?
                <Box sx={{padding: '10px 20px 5px 20px'}}>
                    <Typography style={{fontWeight: '700'}} align="center">
                        {clauseType.name}
                    </Typography>
                    <Box sx={{mt:2}}>
                        <Typography variant="body2">
                            {Boolean(clauseTypeGuide) && Boolean(clauseTypeGuide.guidance) ?
                                clauseTypeGuide.guidance.split('\n').map((gpar,i) => (
                                    <span key={i}>{gpar}<br/></span>
                                ))
                                :''}
                        </Typography>
                    </Box>
                    <Grid container direction="row" justifyContent="space-between" sx={{mt:2}}>
                        <Grid item>
                            <Button size="small" onClick={e => removeClauseType(props.activeClauseKey, clauseType._id)}>
                                Remove topic
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={props.keyAction}>
                                Alternatives
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                :
                (['internalComment', 'publicComment', 'approval'].includes(props.type) && Boolean(workflow) && Boolean(workflow._id)) 
                ||
                ['createInternalComment', 'createInternalApproval'].includes(props.type) ?

                <Box sx={{padding: '10px 0px 5px 0px'}}>

                    { // To confirm whether you'd like to cancel your own approval request
                    cancellingApprovalRequest ?
                        <Box sx={{textAlign: 'center', pb: '25px', px: '20px'}}>

                            <FontAwesomeIcon icon={faBan} style={{color: theme.palette.grey[600], fontSize: '28px', marginBottom: '10px'}} />
                            <Typography variant="body2" color="textSecondary" style={{marginBottom: '15px'}}>Are you sure you want to cancel your approval request</Typography>
                            <Button size="small" onClick={e => setCancellingApprovalRequest(false)}>Go back</Button>&nbsp;&nbsp;
                            <Button size="small" variant="contained" disableElevation onClick={e => handleApprovalRequestAction('cancelled')}>Confirm</Button>
                        </Box>
                    :
                    // "Just added" a new comment/edit
                    Boolean(justChanged) ?
                        <Box sx={{textAlign: 'center', pb: '15px'}}>

                            <FontAwesomeIcon icon={['internalComment'].includes(props.type) ? faNote: faCommentCheck} style={{color: theme.palette.success.main, fontSize: '28px', marginBottom: '10px'}} />
                            <Typography variant="body2" color="textSecondary">{['internalComment'].includes(props.type) ? "Note" : "Comment"} was {['update'].includes(justChanged) ? 'edited' : 'added'}</Typography>
                            <Button onClick={e => setJustChanged(null)} size="small" style={{marginTop: '5px'}}>{['approval'].includes(props.type) ? "Go back..." : "Add another..."}</Button>

                        </Box>
                    :
                    !['approval'].includes(props.type) || // Only allow to comment if it's NOT an approval request
                    workflow.subscribers.some((s) => s === state.user._id) ? // OR if the user is subscribed to the Approval request
                    <>

                        {
                        ['resolved'].includes(workflow.wfStatus) && !isApproverRequestorAddingComment ?
                        <Box sx={{textAlign: 'center'}}>

                            <Button onClick={e => removeWorkflow()}>Remove item&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></Button>

                        </Box>
                        :
                        isApprovalAssignee && !isApproverRequestorAddingComment ? 
                        <Box sx={{textAlign: 'center', mb: 1}}>
                            <Typography variant="body2" color="textSecondary" align="center" style={{marginBottom: '15px'}}>Pending your approval</Typography>
                            <Button variant="contained" color="success" disableElevation size="small" sx={{color: theme.palette.primary.contrastText, marginRight: '5px'}}
                            onClick={e => handleApprovalRequestAction('approved')}>
                                Approve
                            </Button>
                            <Button variant="contained" color="error" disableElevation size="small"
                            onClick={e => handleApprovalRequestAction('rejected')}>
                                Reject
                            </Button>
                        </Box>
                        :
                        isApprovalRequestor && !isApproverRequestorAddingComment ?
                        <Box sx={{textAlign: 'center', mb: 1}}>
                            <Typography variant="body2" color="textSecondary" align="center" style={{marginBottom: '15px'}}>Your request is pending approval:</Typography>

                            <Typography variant="subtitle2">
                                {Boolean(approvalAssignee) ? trunc(approvalAssignee.displayName, 25) : "Unknown User"}
                            </Typography>

                        </Box>
                        :
                        ['createInternalApproval'].includes(props.type) ?
                        <Box sx={{padding: '0px 15px 10px 15px'}}>
                            <SelectUserForOrg
                            orgID={state.org._id}
                            handleSelectUser={e => setApproverSelected(e)}
                            hiddenUsers={state.users.filter((u) => props.isTemplating && ['Business'].includes(u.role) )}
                            userSelected={approverSelected}
                            />
                        </Box>
                        :''}
                        
                        {(!['resolved'].includes(workflow.wfStatus) && !isApprovalAssignee && !isApprovalRequestor) 
                        || 
                        isApproverRequestorAddingComment
                        ||
                        ['createInternalComment', 'createInternalApproval'].includes(props.type) ? 
                        <>
                        <Box>    
                            <PlainTextEditor
                            onEscape={onEscape}
                            onChange={onChange}
                            placeholder={
                                ['createInternalComment'].includes(props.type) ? 
                                    "Add internal note..." :
                                ['createInternalApproval'].includes(props.type) ?
                                    "Add message for approver..." :
                                    "Reply on thread..."}
                            isTemplating={props.isTemplating}
                            isReply={['createInternalComment', 'createInternalApproval'].includes(props.type) ? false : true}
                            editing={editing}
                            />
                        </Box>
                        <Box sx={{padding: ['createInternalComment', 'createInternalApproval'].includes(props.type) ? '0px 15px 0px 15px' : '0px 15px 15px 15px', width: '100%'}}>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    {!['createInternalComment', 'createInternalApproval'].includes(props.type) ?
                                    <Button
                                        sx={{color: theme.palette.grey[600], '&:hover': { backgroundColor: theme.palette.grey[100] }}}
                                        onClick={
                                            isApproverRequestorAddingComment ?
                                                e => setIsApproverRequestorAddingComment(false) :
                                            isApprovalRequestor ?
                                                e => setCancellingApprovalRequest(true) :
                                            Boolean(editing) ?
                                                e => setEditing(null) :
                                                e => resolveThread()}
                                        >
                                        {Boolean(editing) || isApproverRequestorAddingComment || isApprovalRequestor ?
                                            <><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel</> :
                                            <>Resolve&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} /></>}
                                    </Button>
                                    : ' '}
                                </Grid>
                                <Grid item>
                                    <Button
                                    //sx={{padding: '2px 10px'}}
                                    onClick={['createInternalComment', 'createInternalApproval'].includes(props.type) ? createWorkflowAndAttachToClause : submitComment}
                                    disabled={!canSubmit || (['createInternalApproval'].includes(props.type) && !Boolean(approverSelected))}
                                    color={Boolean(editing) ? "secondary" : "primary"}
                                    >
                                    {Boolean(editing) ? "Update" : "Submit"}
                                    &nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        </>
                        :
                        <Box sx={{textAlign: 'center', mb:2}}>
                            {!['resolved'].includes(workflow.wfStatus) && isApprovalRequestor ?
                            <Button sx={{color: theme.palette.grey[600], '&:hover': { backgroundColor: theme.palette.grey[100] }}}
                            onClick={e => setCancellingApprovalRequest(true)}>
                                <FontAwesomeIcon icon={faBan} />&nbsp;&nbsp;Cancel
                            </Button>
                            :''}
                            <Button onClick={e => setIsApproverRequestorAddingComment(true)}>Reply
                            {!isApprovalRequestor || ['resolved'].includes(workflow.wfStatus) ? " on thread":""}&nbsp;&nbsp;<FontAwesomeIcon icon={faReply} /></Button>
                        </Box>
                        }
                    </>
                    :
                    <>
                    <Typography variant="body2" color="textSecondary" align="center">Pending approval from {approvalAssignee.displayName}</Typography>
                    </>
                    }

                    <Grid container direction="column" sx={{width: '100%'}}>
                    {
                    //<br/>resolve, subscribe<br/><br/><br/>
                    //<Divider variant="inset" component="li" />
                    comments
                    .filter((c) => !Boolean(editing) || editing.id !== c.id)
                    .sort((a, b) => (a.date > b.date) ? -1 : 1)
                    .map((comm,i) => {

                        let creator = Boolean(state.users.filter((u) => u._id === comm.creator.uid)[0]) ? 
                            state.users.filter((u) => u._id === comm.creator.uid)[0] : comm.creator

                        return (
                        <Grid item key={i} sx={{borderTop: '1px solid' + theme.palette.grey[200], padding: '20px 20px',
                        backgroundColor: 
                            ['===RESOLVED==='].includes(comm.content) ?
                                theme.palette.grey[100] :
                            ['===APPROVED==='].includes(comm.content) ?
                                '#e3f0e3' :
                            ['===REJECTED==='].includes(comm.content) ?
                                '#f8e9ea' :
                            Boolean(editing) && editing.id !== comm.id ? 
                                theme.palette.grey[100] : 
                                theme.palette.primary.contrastText}}>
                            <Grid container direction="row">
                                <Grid item xs={2}>
                                    <RenderMiniAvatar user={creator} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container direction="row" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body2" color="textSecondary">
                                                <span style={{display: 'block', fontWeight: '700', color: theme.palette.grey[800]}}>
                                                    {trunc(comm.creator.displayName,18)}
                                                </span>
                                                {dayjs(comm.date).format(
                                                    (dayjs(comm.date).year() === dayjs(new Date()).year() ? 'h[:]mA ' : '') +
                                                    'MMM[/]DD' + 
                                                    (dayjs(comm.date).year() !== dayjs(new Date()).year() ? ' YYYY' : '')) +
                                                (comm.isEdited ? ' - edited' : '')
                                                }
                                            </Typography>
                                        </Grid>
                                        {state.user._id === comm.creator.uid &&
                                        !['===APPROVED===', '===REJECTED===', '===RESOLVED==='].includes(comm.content) ?
                                        <Grid item sx={{marginRight: '-5px'}}>
                                            <IconButton size="small" disabled={Boolean(editing)}
                                            onClick={e => handleOpenCommentOptionsMenu(e, comm.id)}>
                                                <FontAwesomeIcon icon={faEllipsisVertical} style={{fontSize: '14px', padding: '2px 7px', marginRight: '0px'}} />
                                            </IconButton>
                                        </Grid>
                                        :''}
                                    </Grid>
                                    <Typography style={{fontSize: '14px', marginTop: '10px', lineHeight: '17px'}}>
                                        {['===APPROVED===', '===REJECTED===', '===RESOLVED==='].includes(comm.content) ?
                                        <>
                                        <b>{
                                        ['===RESOLVED==='].includes(comm.content) ? 
                                                "Resolved" :
                                        ['===APPROVED==='].includes(comm.content) ? 
                                                "Approved" : 
                                                "Rejected"}</b>&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={
                                        ['===RESOLVED==='].includes(comm.content) ?
                                                faCheck :
                                        ['===APPROVED==='].includes(comm.content) ? 
                                                faThumbsUp : faThumbsDown} 
                                        style={{color: 
                                        ['===RESOLVED==='].includes(comm.content) ?
                                                theme.palette.grey[700] :
                                        ['===APPROVED==='].includes(comm.content) ? 
                                                theme.palette.success.main : 
                                                theme.palette.error.main}} />
                                        </>
                                        :
                                        comm.content.split('\n').map((cpar, j) => (
                                            <span key={j}>{cpar}<br/></span>
                                        ))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        )
                    })}
                    </Grid>
                    <Menu
                    sx={{ mt: '32px', zIndex: '2000' }}
                    id="menu-comment-options"
                    anchorEl={Boolean(anchorElCommentOptions) && Boolean(anchorElCommentOptions.target) ? 
                        anchorElCommentOptions.target : null}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElCommentOptions)}
                    onClose={handleCloseCommentOptionsMenu}
                    disableScrollLock={true}
                    >
                    <MenuItem dense onClick={handleEditComment}>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    <MenuItem dense onClick={deleteComment}>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    </Menu>
                </Box>
                :
                ['param'].includes(props.type) && Boolean(param) ?
                <Box sx={{padding: '10px 20px 5px 20px'}}>
                    <Typography style={{fontWeight: '700'}} align="center">
                        {trunc(param.name,25)}
                    </Typography>
                    <Typography variant="body2" align="center" style={{color: theme.palette.grey[700], fontSize: '12px'}}>
                        {trunc(param.ref, 30)}
                    </Typography>
                    <Box sx={{mt:2}}>
                        {Boolean(param.note) ?
                        <Typography variant="body2" style={{ marginBottom: '20px' }}>
                            {param.note.substring(0,180).split('\n').map((npar,i) => (
                                <span key={i}>
                                    {npar}
                                    {(param.note.length > 180 && (param.note.substring(0,180).split('\n').length - 1 === i) ? 
                                            "..." : "")}
                                    <br/>
                                </span>
                            ))}
                        </Typography>
                        :''}

                        <Grid container direction="column" sx={{fontSize: '14px; '}}>
                            <Grid item container direction="row">
                                <Grid item sx={{fontWeight: '700'}} xs={5}>
                                Initial:
                                </Grid>
                                <Grid item xs={7}>
                                {trunc(getSmartFieldValue(param.type, param.val1, param.val2), 15)}
                                </Grid>
                            </Grid>
                            <Grid item container direction="row">
                                <Grid item sx={{fontWeight: '700'}} xs={5}>
                                Calculated:
                                </Grid>
                                <Grid item xs={7}>
                                {Boolean(param.calcVal) ? 
                                    trunc(param.calcVal, 15) : 
                                    'n/a'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container direction="row" justifyContent="space-between" sx={{mt:2}}>
                        <Grid item>
                            <Button size="small" onClick={removeParam}
                            /*onClick={e => removeClauseType(props.activeClauseKey, clauseType._id)}*/>
                                Remove
                            </Button>
                        </Grid>
                        <Grid item>
                            {param.ref !== 'Canveo' ? // Cannot update "Canveo Managed" parameters
                            <Button size="small" onClick={e => props.keyAction(param._id)}>
                                Update
                            </Button>
                            :''}
                        </Grid>
                    </Grid>
                </Box>
                :
                // Workflow not found - enable ability to remove
                ['internalComment', 'publicComment', 'approval'].includes(props.type) && 
                (!Boolean(workflow) || !Boolean(workflow._id)) ?

                <Box>
                    <Grid container direction="row" justifyContent="flex-end" sx={{mt:1, px: 2}}>
                        <Grid item>
                            <Button size="small" onClick={e => editor.dispatchCommand(REMOVE_MARK, props.id)}>
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                : ''
                }

            </Box>

        </Grid>
        : 
        <>
        <Grid item sx={{width: '100%', mb: 1}}>    
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    {
                    ['internalComment', 'publicComment'].includes(props.type) && Boolean(workflow) && Boolean(workflow._id) ?
                        <AvatarGroup max={4} total={workflow.subscribers.length}
                        componentsProps={{additionalAvatar: { sx: { backgroundColor: theme.palette.grey[200], color: theme.palette.grey[800], height: '25px', width: '25px'}}}}>
                        {workflow.subscribers.map((s) => {

                            let user = 
                                Boolean(state.users.filter((u) => u._id === s)[0]) ?
                                    state.users.filter((u) => u._id === s)[0] : 
                                Boolean(workflow.creator) && Boolean(workflow.creator.displayName) ? 
                                    workflow.creator :
                                    { displayName: '?'} // TODO => collabs for cpty - state.collabs?

                            return (
                                <RenderMiniAvatar user={user} key={s} />
                            )

                        })}
                        </AvatarGroup>
                    :
                    ['approval'].includes(props.type) ?
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <RenderMiniAvatar user={
                                    Boolean(workflow) && Boolean(workflow.creator) &&
                                    Boolean(state.users.filter((u) => u._id === workflow.creator.uid)[0]) ?
                                            state.users.filter((u) => u._id === workflow.creator.uid)[0] : workflow.creator} />
                            </Grid>
                            <Grid item>
                                <FontAwesomeIcon icon={faCaretRight} style={{fontSize: '12px', color: theme.palette.grey[500], margin: '0px 5px'}} />
                            </Grid>
                            <Grid item>
                                <RenderMiniAvatar user={
                                    Boolean(workflow) && Boolean(workflow.assignee) && Boolean(workflow.assignee[0]) &&
                                    Boolean(state.users.filter((u) => u._id === workflow.assignee[0].uid)[0]) ?
                                            state.users.filter((u) => u._id === workflow.assignee[0].uid)[0] : 
                                    Boolean(workflow) && Boolean(workflow.assignee) && Boolean(workflow.assignee[0]) ?
                                            workflow.assignee[0] :
                                            {displayName: '?'}} />
                            </Grid>
                        </Grid>
                    :
                    ['param', 'clauseType'].includes(props.type) ?
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <Typography style={{fontWeight: '700'}}>
                                    {['clauseType'].includes(props.type) ?
                                        trunc(clauseType.name, 16) :
                                    Boolean(param) && Boolean(param.name) ?
                                        trunc(param.name, 16) : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    :''}
                </Grid>
                <Grid item>
                    <RenderTitle type={props.type} />
                </Grid>
            </Grid>
        </Grid>
        {
        (((['clauseType'].includes(props.type) && Boolean(clauseTypeGuide) && Boolean(clauseTypeGuide.guidance))) ||
        (['internalComment', 'publicComment'].includes(props.type) && Boolean(workflow) && Boolean(workflow.note))
        /*!['clauseType'].includes(props.type)*/) ?
        <Grid item sx={{mb:1, mt:1}}>
            <Typography variant="body2" color="textSecondary">
                {trunc(
                    ['clauseType'].includes(props.type) && Boolean(clauseTypeGuide) && Boolean(clauseTypeGuide.guidance) ?
                        clauseTypeGuide.guidance :
                    ['internalComment', 'publicComment'].includes(props.type) ?
                        workflow.note :
                        ""
                , 60)}
            </Typography>
        </Grid>
        :''}
        </>
        }

    </Grid>
    :''}

    </div>
  );
};
export default ContextItem;
