import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { Button, Box, Dialog, DialogActions, DialogContent, 
         DialogTitle, IconButton, Typography } from '@mui/material';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

export default function DialogAlert(props) {

  const closeDialog = () => {
      props.closeDialog();
  }

  const alertConfirm = (confirm) => {
    props.alertConfirm(confirm);
  }

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>

            Are you sure?
            
        </DialogTitle>
        <DialogContent sx={{my:3}}>
          <Box sx={{textAlign: 'center', mb:2, fontSize: '30px', color: ['deleteTemplate'].includes(props.alertDialogType) ? theme.palette.error.main : theme.palette.warning.main}}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
          </Box>
          <Box sx={{mb:2}}>
          {['deleteVersion'].includes(props.alertDialogType) ? 
          <Typography align="center">Are you sure you want to delete this template version?</Typography>
          :
          ['deleteTemplate'].includes(props.alertDialogType) ?
          <Typography align="center">Are you sure you want to delete this <span style={{color: theme.palette.error.main, fontWeight: '700'}}>entire template</span>?</Typography>
          :
          ''}
          </Box>
          <Typography variant="subtitle1" align="center">This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={e => alertConfirm(false)} sx={{marginRight: 'auto'}}>
                Cancel
            </Button>
            <Button onClick={e => alertConfirm(true)} variant="contained" disableElevation>
                Confirm
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}