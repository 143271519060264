import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import axios from 'axios';
import { Button, Box, CircularProgress, Dialog, DialogActions, DialogContent, 
         DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { getAddressForEntity, randomString as generateRandomString } from '../utils';
import { UploadImg } from '.';
import { EntForm } from './forms';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faImage, faStoreSlash, faTimes, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

///import defaultLogo from '../assets/img/defaultlogo.png';

// type: "newsub" => Setting up a new Subsidiary for an Org (Admin Module)
// type: "updsub" => Updating an existing Subsidiary for an Org (Admin Module)
// type: "newcp" => Setting up a new Counterparty, inside the New Flow
// type: "updcp" => Updating an existing Counterparty
// type: "signup" => Signing up (SuperAdmin)

export default function DialogEntity(props) {

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false)
  const [confirmDeactivate, setConfirmDeactivate] = React.useState(false)
  const [stagedEnt, setStagedEnt] = React.useState(null)
  const [stagedPerson, setStagedPerson] = React.useState(null)
  const [logoUpdating, setLogoUpdating] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(null);
  const [logoCanvas, setLogoCanvas] = React.useState(false)

  const isCreatingEntityForCpty = ['newcp'].includes(props.type) && 
    props.ent !== undefined && props.ent !== null &&
    props.ent.orgID !== undefined && props.ent.orgID !== null

  const uid = state.user._id
  const orgID = state.org._id
  const { logoURL } = state.org
  const { settings } = state

  useEffect(() => {
    setLoading(true);

    if(['newcp', 'updcp'].includes(props.type) && props.ent.shortName !== undefined) {
      if(['updcp'].includes(props.type) && ['Person'].includes(props.ent.entityCategory)) {
        axios.get(state.settings.api + "user/org/" + props.ent.orgID) // Pull user for entity type "Person"
        .then((resUsers) => {
          if(resUsers.data.success && resUsers.data.data[0] !== undefined) {
            let user = resUsers.data.data.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : -1)[0]
            setStagedPerson(user)
            setStagedEnt(props.ent);
            setLoading(false);
          } else {
            setErrMsg("Unable to retrieve user for counterparty - please try again"); setLoading(false)
          }
        }).catch((err) => { setErrMsg("Unable to retrieve user for counterparty"); setLoading(false) })

      } else {
        setStagedEnt(props.ent);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

  }, [props.type])

  const reInitalize = () => {
    setLoading(false)
    setConfirmDeactivate(false)
    setLogoUpdating(false)
    setStagedEnt(null)
  }

  const closeDialog = (snack) => {
    props.closeDialog(snack);
    reInitalize()
  };

  const handleSubmitImage = (child) => {

    if(child !== null && child !== undefined) {
      setErrMsg(null);
      setLoading(true);

      let newent = {...stagedEnt, logoURL: child}
      let newpers = null

      setStagedEnt(newent)

      if(['Person'].includes(stagedEnt.entityCategory)) { // If entity is a person, also update photo for User
        newpers = {...stagedPerson, photoURL: child}
        setStagedPerson(newpers)
      }

      if(['updsub, updcp'].includes(props.type)) {
        axios.put(state.settings.api + "org/orglogo/" + stagedEnt.orgID, { newLogo: child })
        .then((res) => {
  
          dispatch({ type: "UPDATE_CPENT", payload: newent })
          closeDialog({ent: newent, person: newpers})
          setLoading(false);
  
        }).catch((err) => { setErrMsg("Unable to update the logo"); setLoading(false); })
      } else {
        setLoading(false);
      }
    }
  }

  const handleSubmit = (child) => {

    setLoading(true)
    let creationDate = new Date().toISOString()

    // TODO: for the entity creatd/updated
    // CHECK if already exists in DB - return
    // Potential: 
    // - Org exists as CP, Ent not => ent to be added for ownerOrg
    // - Org exists as CP, Ent also => ent to be duplicated for ownerOrg
    // - Org exists as CUST => select their org/ent, otherwise contact them 
    // - User exists (email)

    const { shortName, legalName, streetAndNo, address2, city, 
            zip, state, country, entityCategory, 
            email, firstName, lastName, displayName, title } = child

    let address = [{
      addressType: 'billto',
      streetAndNo: streetAndNo,
      address2: address2,
      zip: zip,
      city: city,
      state: state,
      country: country,
    }]

    if(['newsub'].includes(props.type)) { // CREATE NEW SUB

      let newEnt = {
        orgID: orgID,
        ownerOrgID: null,
        entityCategory: entityCategory,
        shortName: shortName,
        legalName: legalName,
        address: address,
        logoURL: logoURL,
        active: true,
        sourceData: null,
        defaultSigners: [],
        creationBy: uid,
        creationDate: creationDate
      }
      axios.post(settings.api + "entity", {  entity: newEnt })
      .then((resEnt) => {
        if(resEnt.data.success) { // Add newly created entity to the reducer
          dispatch({ type: "ADD_SUB", payload: resEnt.data.data })
          setLoading(false);
          closeDialog('snackEntCreated')
        } else { setErrMsg("An error occured while creating the entity - refresh your browser"); setLoading(false); }
      }).catch((err) => { console.log("err", err); setErrMsg("An error occured while creating the entity"); setLoading(false); })

    } else if(['updsub'].includes(props.type)) { // UPDATE EXISTING SUB
      
      let newent = props.ent;
      newent.lastUpdateBy = uid;
      newent.lastUpdateDate = creationDate;

      if(child === 'deactivate') { // You are deactivating
        newent.active = false;

      } else if (shortName !== undefined) { // There actually was a child => You're updating
        newent.entityCategory = entityCategory;
        newent.shortName = shortName;
        newent.legalName = legalName;
        newent.address = address;
      }

      pushEntity(newent, null);

    } else if(['newcp'].includes(props.type)) { // CREATE NEW COUNTERPARTY

      if(child === "finalize" || isCreatingEntityForCpty){ // You're completing the creation for a new ORG / ENT

        let person = 
          ['Person'].includes(stagedEnt.entityCategory) && stagedPerson !== undefined && 
          stagedPerson !== null && stagedPerson.displayName !== undefined ?
              stagedPerson : null;

        if(stagedEnt.orgID === null || stagedEnt.orgID === undefined) { // Need to first create an ORG
          
          axios.post(settings.api + "org", { org: {
            shortName: stagedEnt.shortName,
            logoURL: stagedEnt.logoURL,
            orgType: 'cpty',
            creationDate: creationDate
          }})
          .then((resOrg) => {
            if(resOrg.data.success) { 
              let e = stagedEnt;
              e.orgID = resOrg.data.data._id
              pushEntity(e, person);

            } else { setErrMsg("An error occured while creating the org - refresh your browser"); }
          }).catch((err) => { console.log("err", err); setErrMsg("An error occured while creating the org"); })
        
        } else if(isCreatingEntityForCpty) { // ORG already exists, create a child entity
          
          let newEntForCp = stagedEnt;
          newEntForCp.entityCategory = entityCategory;
          newEntForCp.shortName = shortName;
          newEntForCp.legalName = legalName;
          newEntForCp.address = address;
          newEntForCp.active = true;
          newEntForCp.sourceData = null;
          newEntForCp.defaultSigners = [];
          newEntForCp.creationBy = uid;
          newEntForCp.creationDate = creationDate;
          pushEntity(newEntForCp, person)

        }      

      } else { // You're staging and moving on to the LogoUpdating

        setStagedEnt({...stagedEnt,
          //_id: generateRandomString(20), 
          //orgID: generateRandomString(20),
          ownerOrgID: orgID,
          entityCategory: entityCategory,
          shortName: shortName,
          legalName: legalName,
          address: address,
          logoURL: null,
          active: true,
          sourceData: null,
          defaultSigners: [],
          creationBy: uid,
          creationDate: creationDate
        })
        if(['Person'].includes(entityCategory)) { // If you're creating a person, stage the User Record
          setStagedPerson({
            email: email, 
            password: generateRandomString(20),
            firstName: firstName,
            lastName: lastName,
            displayName: displayName,
            title: title,
            phone: '',
            role: 'Counterparty',
            readOnly: false,
            creationBy: uid,
            creationDate: creationDate,
            active: true,
          })
        }
        setLogoUpdating(true);
        setLoading(false);
      }

    } else if(['updcp'].includes(props.type)) { // UPDATE EXISTING COUNTERPARTY

      let newcp = stagedEnt
      newcp.shortName = shortName;
      newcp.legalName = legalName;
      newcp.address = address;
      
      let newpers = null; // todo - update based on updates
      if(['Person'].includes(newcp.entityCategory) && stagedPerson !== null && stagedPerson._id !== undefined) {
        newpers = stagedPerson;
        newpers.email = email;
        newpers.firstName = firstName;
        newpers.lastName = lastName;
        newpers.displayName = displayName;
        newpers.title = title;
      }
      pushEntity(newcp, newpers)

    } else if(['signup'].includes(props.type)) { // SIGNUP A NEW ORG / USER

      if(child.shortName !== '' && child.legalName !== '' && child.email !== '' &&
      child.firstName !== '' && child.lastName !== '' && child.title !== '') {

        let creationDate = new Date().toISOString();
        let passwordString = generateRandomString(20)
        axios.post(settings.api + 'auth/signup', {
          org: {
            shortName: child.shortName,
            logoURL: null,
            orgType: 'cust',
            creationDate: creationDate,
            stripecusid: null,
            orgSettings: {
              headerInPdf: true,
              signMethod: 'hellosign',
            },
            integrations: null,
          },
          user: {
            email: child.email,
            password: passwordString,
            firstName: child.firstName,
            lastName: child.lastName,
            displayName: child.displayName,
            title: child.title,
            phone: '',
            photoURL: null,
            role: 'Admin',
            readOnly: false,
            creationBy: uid,
            creationDate: creationDate,
            active: true,
          }
        })
        .then(function(res) {

          // TODO FROM BACKEND - SEND EMAILS / CREATE NOTIFICATION
          console.log("signup completed!", res)
          closeDialog()

        }).catch((err) => { setErrMsg('Unable to complete the signup')

        })

      } else {
        setLoading(false)
      }

    }

  }

  const pushEntity = (ent, pers) => {
    // type => props.type
    // ent => entity object
    // pers => person object

    // Create route : "entity", Update route : "entity/:eid"
    if(['updsub', 'updcp', 'newcp'].includes(props.type)) {
      axios({
        method: ['updsub', 'updcp'].includes(props.type) ? 'put' : 'post',
        url: settings.api + "entity" + (['updsub', 'updcp'].includes(props.type) ? ("/" + ent._id) : ""),
        data: { entity: ent }
      })
      .then((resEnt) => {
        if(resEnt.data.success) { // Completed the Entity update
          
          dispatch({ 
            type: 
              ['updsub'].includes(props.type) ? "UPDATE_SUB" : 
              ['updcp'].includes(props.type) ? "UPDATE_CPENT" : "ADD_CPENT", 
            payload: resEnt.data.data 
          })
  
          if(['Person'].includes(ent.entityCategory) && pers !== null) { // You've created / updated an entity type "person"
  
            pers.orgID = ent.orgID; // Update to a newly generated orgID
            
            axios({
              method: ['updsub', 'updcp'].includes(props.type) ? 'put' : 'post',
              url: settings.api + "user" + (['updsub', 'updcp'].includes(props.type) ? ("/" + pers._id) : ""),
              data: { user: pers }
            })
            .then((resUser) => {
              if(resUser.data.success) { // Completed the Person update
                setLoading(false);
                closeDialog(
                  ['updsub'].includes(props.type) ?
                      'snackEntUpdated' : // Update: close with Snack
                      {ent: resEnt.data.data, person: resUser.data.data}) // Create : Close with Entity and User
  
              } else { setErrMsg("An error occured while updating the user - refresh your browser"); setLoading(false); }
            }).catch((err) => { console.log("err", err); setErrMsg("An error occured while updating the user"); setLoading(false); })
  
          } else { // You've created / updated an entity type "company"
            setLoading(false);
            closeDialog(
              ['updsub'].includes(props.type) ?
                  'snackEntUpdated' : // Update: close with Snack
                  {ent: resEnt.data.data, person: null}) // Create: close with Entity
  
          }
        } else { setErrMsg("An error occured while updating the entity - refresh your browser"); setLoading(false); }
      }).catch((err) => { console.log("err", err); setErrMsg("An error occured while updating the entity"); setLoading(false); })
    }
  }

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>
            {loading ? "Loading..." :
            ['newsub'].includes(props.type) ?
                "Create a new entity" :
            ['updsub'].includes(props.type) ? 
                "Update an entity" :
            ['newcp'].includes(props.type) ?
                "Create a new counterparty" :
            ['updcp'].includes(props.type) ?
                "Update a counterparty" :
            ['signup'].includes(props.type) ?
                "Sign Up" :
                ""}
        </DialogTitle>
        <DialogContent>
          
            {loading ? 
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 20}}>

                <CircularProgress size={24} />

            </Box>
            :
            // Updating the Logo
            logoUpdating ? 
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 8, mb: logoCanvas ? 2 : 6}}>

              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="body2" color="textSecondary" align="center">Click to upload a {['Person'].includes(stagedEnt.entityCategory) ? "photo" : "logo"}</Typography>
                </Grid>
                <Grid item>
                  <UploadImg
                  type={['Person'].includes(stagedEnt.entityCategory) ? "avatar" : "logo"}
                  handleSubmitImage={handleSubmitImage}
                  setLogoCanvas={setLogoCanvas}
                  initialImg={stagedEnt.logoURL} />
                </Grid>
              </Grid>
              
            </Box>
            :
            // Ask whether they really want to deactivate
            confirmDeactivate ?
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 12}}>
              <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                  <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: '40px', color: theme.palette.error.main}} />
                </Grid>
                <Grid item sx={{mt: 2, pb:2}}>
                  <Typography align="center">Are you sure you want to deactivate this entity?</Typography>
                  <Typography align="center">This action cannot be undone.</Typography>
                </Grid>
                <Grid item>
                  <Button color="error" variant="contained" disableElevation sx={{mr: 1}}
                  onClick={e => handleSubmit('deactivate')}>Confirm</Button>
                  <Button variant="contained" disableElevation
                  onClick={e => setConfirmDeactivate(false)}>Cancel</Button>
                </Grid>
              </Grid>
            </Box>
            :
            // Default: Show the FORM
            ['newsub', 'updsub', 'newcp', 'updcp', 'signup'].includes(props.type) ?
            <Box sx={{mt: 2}}>
                {errMsg !== null ? // An error exists - show it
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 4}}>
                    <Typography align="center" color="error">{errMsg}</Typography>
                </Box>:''}

                <EntForm
                handleSubmit={handleSubmit}
                buttonName={['newcp'].includes(props.type) && !isCreatingEntityForCpty ? "Next" : "Submit"}
                optionalAddress={['newcp', 'updcp', 'signup'].includes(props.type)}
                isSignup={['signup'].includes(props.type)}
                hideFields={
                    ['newsub', 'updsub', 'updcp', 'signup'].includes(props.type) ?
                        ['entityCategory'] :
                        []
                }
                disableFields={
                    // todo => ability to update shortname for CP, would need to change across ents/org and test it's unique
                    // todo => ability to update email for CP, would need to change across agrs (collab/signer) and test it's unique
                    ['newsub', 'updsub', 'updcp'].includes(props.type) || isCreatingEntityForCpty ?
                        ['shortName', 'email'] :
                        []
                }
                initialValues={
                    { 
                        shortName: 
                            stagedEnt !== null && stagedEnt.shortName !== undefined ?
                                stagedEnt.shortName : // Any previously provided value
                            ['newsub'].includes(props.type) ?
                                state.org.shortName : // Creating a new sub => org shortname
                            ['updsub'].includes(props.type) && props.ent.shortName !== undefined ?
                                props.ent.shortName : // As provided by the parent component
                                '',
                        legalName: 
                            stagedEnt !== null && stagedEnt.legalName !== undefined ?
                                stagedEnt.legalName :
                            ['updsub'].includes(props.type) && props.ent.legalName !== undefined ?
                                props.ent.legalName :
                                '',
                        streetAndNo:
                            stagedEnt !== null && getAddressForEntity(stagedEnt, 'billto').streetAndNo !== undefined ?
                                getAddressForEntity(stagedEnt, 'billto').streetAndNo :
                            ['updsub'].includes(props.type) && getAddressForEntity(props.ent, 'billto').streetAndNo !== undefined ?
                                getAddressForEntity(props.ent, 'billto').streetAndNo :
                                '',
                        address2: 
                            stagedEnt !== null && getAddressForEntity(stagedEnt, 'billto').address2 !== undefined ?
                                getAddressForEntity(stagedEnt, 'billto').address2 :
                            ['updsub'].includes(props.type) && getAddressForEntity(props.ent, 'billto').address2 !== undefined ?
                                getAddressForEntity(props.ent, 'billto').address2 :
                                '',
                        city: 
                            stagedEnt !== null && getAddressForEntity(stagedEnt, 'billto').city !== undefined ?
                                getAddressForEntity(stagedEnt, 'billto').city :
                            ['updsub'].includes(props.type) && getAddressForEntity(props.ent, 'billto').city !== undefined ?
                                getAddressForEntity(props.ent, 'billto').city :
                                '',
                        zip: 
                            stagedEnt !== null && getAddressForEntity(stagedEnt, 'billto').zip !== undefined ?
                                getAddressForEntity(stagedEnt, 'billto').zip :
                            ['updsub'].includes(props.type) && getAddressForEntity(props.ent, 'billto').zip !== undefined ?
                                getAddressForEntity(props.ent, 'billto').zip :
                                '',
                        state: 
                            stagedEnt !== null && getAddressForEntity(stagedEnt, 'billto').state !== undefined ?
                                getAddressForEntity(stagedEnt, 'billto').state :
                            ['updsub'].includes(props.type) && getAddressForEntity(props.ent, 'billto').state !== undefined ?
                                getAddressForEntity(props.ent, 'billto').state :
                                '',
                        country: 
                            stagedEnt !== null && getAddressForEntity(stagedEnt, 'billto').country !== undefined ?
                                getAddressForEntity(stagedEnt, 'billto').country :
                            ['updsub'].includes(props.type) && getAddressForEntity(props.ent, 'billto').country !== undefined ?
                                getAddressForEntity(props.ent, 'billto').country :
                                '',
                        entityCategory: 
                            stagedEnt !== null && stagedEnt.entityCategory !== undefined ?
                                stagedEnt.entityCategory :
                                'Company',
                        firstName: 
                            stagedPerson !== null && stagedPerson.firstName !== undefined && stagedPerson.firstName !== null ? 
                                stagedPerson.firstName : '',
                        lastName:
                            stagedPerson !== null && stagedPerson.lastName !== undefined && stagedPerson.lastName !== null ? 
                                stagedPerson.lastName : '',
                        email: 
                            stagedPerson !== null && stagedPerson.email !== undefined && stagedPerson.email !== null ? 
                                stagedPerson.email : '',
                        title: 
                            stagedPerson !== null && stagedPerson.title !== undefined && stagedPerson.title !== null ? 
                                stagedPerson.title : '',
                    }}
                />
            </Box>
            :''
            }
        </DialogContent>
        <DialogActions>
          {logoUpdating ?
          <Button sx={{marginRight: 'auto'}} onClick={e => setLogoUpdating(false)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back</Button>
          :
          <Button sx={{marginRight: 'auto'}} onClick={closeDialog}>Cancel</Button>}

          {// DEACTIVATE BUTTON
          ['updsub'].includes(props.type) && // You are Updating the entity
          !confirmDeactivate // AND you're not yet deactivating
          ?
          <Button color="error" onClick={e => setConfirmDeactivate(true)}>
            Deactivate&nbsp;&nbsp;<FontAwesomeIcon icon={faStoreSlash} />
          </Button>
          :
          // FINALIZE BUTTON
          ['newcp'].includes(props.type) && // Creating a new counterparty
          logoUpdating && // You're in step 2: Logo Updating
          stagedEnt !== null && stagedEnt.legalName !== undefined // Staged values are defined
          ?
          <Button variant="contained" disableElevation onClick={e => handleSubmit('finalize')}
          disabled={logoCanvas}>
            Finalize&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} />
          </Button>
          : // UPDATE LOGO BUTTON
          ['updcp'].includes(props.type) &&
          stagedEnt !== null && ['Company', 'Person'].includes(stagedEnt.entityCategory) &&
          !logoUpdating
          ?
          <Button variant="text" disableElevation onClick={e => setLogoUpdating(true)}>
            Update {['Person'].includes(stagedEnt.entityCategory) ? "Photo" : "Logo"}&nbsp;&nbsp;<FontAwesomeIcon icon={faImage} />
          </Button>
          :''}
        </DialogActions>
      </Dialog>
    </div>
  );
}