import * as React from 'react';
import theme from '../theme/theme';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material'
import { trunc } from '../utils';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export default function CoreDrawerToc(props) {

  const TocRow = ({ index, style }) => (
        <ListItemButton
        key={index}
        style={style}
        onClick={e => props.toc[index].element.scrollIntoView({ block: 'center', behavior: 'smooth'})}>
            <ListItemText
            disableTypography
            sx={{padding: '2px 5px'}}
            primary={
                <Typography 
                style={
                [3,4].includes(props.toc[index].level) ? 
                    { paddingLeft: '20px', fontSize: '14px', color: theme.palette.grey[700] } :
                [2].includes(props.toc[index].level) ? 
                    { paddingLeft: '10px', fontSize: '15px', color: theme.palette.grey[800], fontWeight: '500' } :
                    { paddingLeft: '0px', fontSize: '16px', color: theme.palette.grey[900], fontWeight: '600' }
                }>
                {trunc(props.toc[index].item, props.toc[index].level === 1 ? 22 : 24)}
                </Typography>} />
        </ListItemButton>
  );
    
  function getTocItemSize(index) {
        return props.toc[index].level === 1 ? 48 : props.toc[index].level === 2 ? 43 : [3,4].includes(props.toc[index].level) ? 32 : 0;
  };

  return (
    <Box sx={{ width: '100%', height: '100%', maxWidth: 240, bgcolor: theme.palette.primary.contrastText }}>
        <AutoSizer>
            {({height, width}) => (
            <VariableSizeList
            height={height}
            itemCount={props.toc === undefined || props.toc === null ? 0 : props.toc.length}
            itemSize={getTocItemSize}
            width={width}
            >
            {TocRow}
            </VariableSizeList>
            )}
        </AutoSizer>
    </Box>
  );
}