//import { editorTheme } from '.';
//import { TextNode } from 'lexical'
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ClauseNode, MarkNode, RedlineNode } from '../nodes';

const editorTheme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem"
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem"
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code"
  }
}

export const editorConfig = {
  theme: editorTheme, // The editor theme
  onError(error) { console.log("Lexical Error: ", error); }, // Handling of errors during update
  nodes: [HeadingNode, ListNode, ListItemNode, 
    TableNode, TableCellNode, TableRowNode, AutoLinkNode, LinkNode, 
    ClauseNode, RedlineNode, MarkNode
  ],
};