import React, { useContext, useEffect } from 'react';
import { globalStore } from '../../../state/store';
import { $getRoot, $getNodeByKey, COMMAND_PRIORITY_EDITOR, $createParagraphNode } from 'lexical';
//import { Snackbar } from '@mui/material'
import { mergeRegister } from "@lexical/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ADD_WORKFLOW_COMMAND, ASSIGN_TOPICS_COMMAND, APPLY_FILTER_COMMAND, INSERT_FROM_LIBRARY,
         REMOVE_CLAUSETYPE_COMMAND, SAVE_TO_LIBRARY, REMOVE_WORKFLOW } from '../commands';
import { handleDelRedLineForSelection, hasMark, hasRedline } from '../utils';

import { $createClauseNode } from '../nodes/ClauseNode';
import { $createRedlineNode } from '../nodes/RedlineNode';

import axios from 'axios';

function useClauses(editor, props) {

  const [state, dispatch] = useContext(globalStore);
  //const [snackOpen, setSnackOpen] = React.useState(null)

  useEffect(() => {
   
    return mergeRegister(
      editor.registerCommand(
        REMOVE_CLAUSETYPE_COMMAND,
        (payload) => { 

          editor.update(() => {
            let n = $getNodeByKey(payload.key);
            n.deleteClauseType(payload.ctid)
          })

        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        SAVE_TO_LIBRARY,
        (payload) => { 

          let creationDate = new Date().toISOString();
          let newLibItem = {
            orgID: state.org._id,
            text: payload.text,
            clauseTypes: payload.ctids,
            rating: payload.rating,
            creationBy: state.user._id,
            creationDate: creationDate,
            lastUpdateBy: state.user._id,
            lastUpdateDate: creationDate, 
          }
          // Create the new Agreement type
          axios.post(state.settings.api + 'clauselib', { clauseLibItem: newLibItem })
          .then((resCLI) => {
            if(resCLI.data.success) { // Add newly created CLI to the reducer

              dispatch({ type: "ADD_CLAUSELIBITEM", payload: resCLI.data.data })

              editor.update(() => {
                let n = $getNodeByKey(payload.key);
                n.addLibID(state.org._id + '_' + resCLI.data.data._id)
              })
              
              editor.dispatchCommand(ASSIGN_TOPICS_COMMAND, { key: payload.key, ctids: payload.ctids })

            }
          }).catch((err) => { console.log("err saving to lib", err) })
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        ASSIGN_TOPICS_COMMAND,
        (payload) => { 
          editor.update(() => {
            let n = $getNodeByKey(payload.key);
            n.assignClauseTypes(payload.ctids)
          })
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        ADD_WORKFLOW_COMMAND,
        (payload) => { 
          editor.update(() => {
            let n = $getNodeByKey(payload.key);
            n.addWorkflow(payload.wfid);
          })
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        REMOVE_WORKFLOW,
        (payload) => { 
          editor.update(() => {
            let n = $getNodeByKey(payload.key);
            n.deleteWorkflow(payload.wfid);
          })
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        INSERT_FROM_LIBRARY,
        (payload) => {

          let curClauseNode = $getNodeByKey(payload.key);

          editor.update(() => {
            // Create clauseNode from details
            let creationDate = new Date().toISOString()
            let clauseNode = $createClauseNode({
              clauseTypes: payload.libItem.clauseTypes, 
              workflows: [],
              libIDs: [payload.libItem.libID], 
              filter: 'none', 
              lock: 'none'
            })
            // Create an "empty" clause node for (potentially) inbetween when inserting before / after
            let dummyClauseNode = $createClauseNode({ clauseTypes: [], workflows: [], libIDs: [], filter: 'none', lock: 'none' })
          
            payload.libItem.clauseText.split('\n') // For every paragraph:
            .forEach((par) => {
              let parNode = $createParagraphNode()
              clauseNode.append(parNode)
              let redlineNode = $createRedlineNode({
                redlineType: 'add', 
                partyID: props.partyID, 
                creator: state.user.displayName,
                date: creationDate, 
                text: par
              })
              parNode.append(redlineNode)
            })

            // Now insert the clause node - either replace or insert bf/af
            if(['insertAfter'].includes(payload.insertLocation)) {
              curClauseNode.insertAfter(clauseNode);
              curClauseNode.insertAfter(dummyClauseNode);
            } else if(['insertBefore'].includes(payload.insertLocation)) {
              curClauseNode.insertBefore(clauseNode);
              curClauseNode.insertBefore(dummyClauseNode);
            } else if(['replaceCurrent'].includes(payload.insertLocation)) {

              // Select current node entirely
              let selection = curClauseNode.select()
              selection.anchor.offset = 0;
              selection.focus.offset = curClauseNode.getTextContent().length

              const newRedline = {
                partyID: props.partyID,
                creator: state.user.displayName,
                date: new Date().toISOString(),
              }
              handleDelRedLineForSelection(newRedline, props.partyID, selection, 'selection') // Propose delete current selection
              curClauseNode.insertAfter(clauseNode); // insert new clause node after
            }
          })

        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(APPLY_FILTER_COMMAND, (filter) => { // FILTER CHANGE

        editor.update(() => {

          const nodeMapArray = [...editor.getEditorState()._nodeMap];

          nodeMapArray
          .filter((keyPlusNode) => ['clause'].includes(keyPlusNode[1].getType()))
          .forEach((keyPlusNode) => {
            let clauseNode = keyPlusNode[1];
            if(['openIssues'].includes(filter)) {
              if(hasRedline(clauseNode) || hasMark(clauseNode, 'publicComment')) {
                clauseNode.changeFilter('in');
              } else {
                clauseNode.changeFilter('out');
              }
            } else if(['internalComments'].includes(filter)) { 
              if(hasMark(clauseNode, 'internalComment')) {
                clauseNode.changeFilter('in');
              } else {
                clauseNode.changeFilter('out');
              }
            
            } else if(['publicComments'].includes(filter)) { 
              if(hasMark(clauseNode, 'publicComment')) {
                clauseNode.changeFilter('in');
              } else {
                clauseNode.changeFilter('out');
              }
            
            } else if(!Boolean(filter)) { // Removal of filter
              clauseNode.changeFilter('none');
            }
          })
        })

        dispatch({ type: "CHANGE_SESSION_FILTER", payload: filter })

      }, COMMAND_PRIORITY_EDITOR )
    )
  }, [editor])

}

export default function ClausePlugin(props) {
  const [editor] = useLexicalComposerContext();
  useClauses(editor, props);
  return null;
}