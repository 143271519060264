function getEditModeOption(val) {
    let s = { title: '', desc: '', desc1: '', desc2: '' };

    if(val === 'read') {
        s.title = 'Read-only';
        s.desc1 = 'Counterparty can only read and ';
        s.desc2 = '(if applicable) sign the agreement';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'comment') {
        s.title = 'Comment-only';
        s.desc1 = 'Counterparty can add comments ';
        s.desc2 = 'but not make edits';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'edit') {
        s.title = 'Limited editing';
        s.desc1 = 'Counterparty can make changes ';
        s.desc2 = 'but *not accept* their own edits';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'full') {
        s.title = 'Full editing';
        s.desc1 = 'Counterparty can make changes, ';
        s.desc2 = 'and also accept or reject all edits';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'currentOrg') {
        s.title = 'Full editing';
        s.desc1 = 'This party is the current owner, ';
        s.desc2 = 'collaborators will be informed';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'none') {
        s.title = 'Not involved';
        s.desc1 = 'Counterparty is not involved, ';
        s.desc2 = 'collaborators will not be informed';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'tbd') {
        s.title = 'To be confirmed';
        s.desc1 = 'Please select an edit access mode, ';
        s.desc2 = 'for the counterparty';
        s.desc = s.desc1 + s.desc2;

    } else if(val === 'copy') {
        s.title = 'Copy-only';
        s.desc1 = 'Counterparty has access to a copy ';
        s.desc2 = 'of the shared agreement';
        s.desc = s.desc1 + s.desc2;

    }
    return s;
}

export default getEditModeOption