import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { TextNode, $getNodeByKey, $getRoot, $createParagraphNode, $createTextNode } from "lexical";
import { RedlineNode, $createRedlineNode } from '../nodes/RedlineNode.js';
import { $getLexicalContent } from '@lexical/clipboard';
import { simpleDiffWithCursor, handleDelRedLineForSelection } from '../utils';
import { RedlineMenu } from '../../'
import { globalStore } from '../../../state/store';
import { INSERT_TEXT_COMMAND, KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND, 
         PASTE_COMMAND, CUT_COMMAND, COMMAND_PRIORITY_HIGH,
         $getSelection, $setSelection } from "lexical";
import { mergeRegister } from "@lexical/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

/*
Scenarios to create a redline:

(1) Newly proposed text:
    a. Newly typed - TBD HOW TO SOLVE: TODO
    b. Pasted Text - PASTE_COMMAND: DONE
(2) Proposed removal of text:
    a. Backspace key - KEY_BACKSPACE_COMMAND: DONE
    b. Delete key - KEY_DELETE_COMMAND: DONE
    c. Cut text - CUT_COMMAND: DONE
    d. Overwrite - ie. selection plus key/enter/space - TBD HOW TO SOLVE: TODO

Once a redline is in place:
(1) Ability to accept 
    a. Accept of your own partyID - (whether you can accept your own redlines depends on edit right configuration)
        i. type 'del' : remove redlineNode
        ii. type 'add' : change redlineNode to regular textNode
    b. Accept of another partyID:
        i. same as above

(2) Ability to reject
    a. Reject of your own partyID: 
        i. type 'del' : change redlineNode to regular textNode
        ii. type 'add' : remove redlineNode
    b. Reject of another partyID:
        i. type 'del' : change redlineNode to type 'xdel'
        ii. type 'add' : change redlineNode to type 'xadd'

Todo: 'xdel' and 'xadd' 
=> rejection of another partyID's redline
=> Add attribute for rejecting party in the redlineNode
*/

function useRedlines(editor, props) {

  const [state] = useContext(globalStore);

  const newRedline = {
    partyID: props.partyID, //partyID: 'party2',
    creator: state.user.displayName,
    date: new Date().toISOString(),
  }

  // TODO - TEST / EVENT HANDLING FOR ENTER KEY WHEN OVERWRITING STUFF

  // EVENT LISTENER TO AVOID TYPING OVER MULTIPLE PARAGRAPHS
  useEffect(() => {
    const handler = (e) => {
      editor.getEditorState().read(() => {
        let selection = $getSelection();
        if( // https://www.toptal.com/developers/keycode/table-of-all-keycodes
          Boolean(selection) &&
          !(e.ctrlKey || e.altKey || e.metaKey) && // You do not hold a modifier key (excl. SHIFT)
          ([12,13,32,226].includes(e.keyCode) || (e.keyCode >= 48 && e.keyCode <= 90) ||  (e.keyCode >= 96 && e.keyCode <= 111) || 
           (e.keyCode >= 160 && e.keyCode <= 173) || (e.keyCode >= 186 && e.keyCode <= 223)) && // You've clicked a key to insert a character 
          selection.getNodes().length > 1 && // You've selected multiple nodes
          selection.getNodes().some((n) => ['heading','paragraph','list'].includes(n.getType())) // With at least one blockNode
        ) {
          e.preventDefault()
        }
      })
    };
    
    window.addEventListener('keydown', handler, true);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [editor]);

  useEffect(() => {
   
    return mergeRegister(
      
      // TEXT NODE TRANSFORM - USED FOR INSERTING TEXT AND REDLINING IT
      editor.registerNodeTransform(TextNode, (textNode) => {

        let selection = $getSelection();
        let newText = textNode.getTextContent();
        //const isMark1 = ['mark'].includes(textNode.getParent().getType())
        //let newParentText = isMark1 ? textNode.getParent().getParent().getTextContent() : textNode.getParent().getTextContent();

        let newParentText = getBlockText(textNode);

        let prevText = null, prevParentText = null;
        
        // Find out what the text was before the Node Change - for diff/comparison purposes
        editor.getEditorState().read(() => {
          if(Boolean($getNodeByKey(textNode.getKey()))) {
            prevText = $getNodeByKey(textNode.getKey()).getTextContent()
            prevParentText = getBlockText(textNode);
            //const isMark2 = ['mark'].includes(textNode.getParent().getType())
            //prevParentText = isMark2 ? textNode.getParent().getParent().getTextContent() : textNode.getParent().getTextContent()
          }
        }) 

        let diff = Boolean(prevText) ? simpleDiffWithCursor(prevText, newText, 0) : null
        let parentDiff = Boolean(prevParentText) ? simpleDiffWithCursor(prevParentText, newParentText, 0) : null

        // THERE IS AN UPDATE
        if(Boolean(diff) && Boolean(parentDiff) && ((diff.remove > 0 && parentDiff.remove > 0) || (diff.insert.length > 0 && parentDiff.insert.length > 0))) {

          editor.update(() => {
            let newNodes = []
            // TEXT REMOVAL: If a node has a removal AND it's parent has a removal
            if(Boolean(diff) && diff.remove > 0 && Boolean(parentDiff) && parentDiff.remove > 0) {
              let removedText = prevText.substring(diff.index, diff.index + diff.remove)
              newNodes.push($createRedlineNode({...newRedline, redlineType: 'del', text: removedText }))
            }

            // TEXT INSERTION: If a node has an insertion AND it's parent has an insertion
            // TODO: Exclude paste command
            if(Boolean(diff) && diff.insert.length > 0 && Boolean(parentDiff) && parentDiff.insert.length > 0) {

              let insertedText = newText.substring(diff.index, diff.index + diff.insert.length)
              newNodes.push($createRedlineNode({...newRedline, redlineType: 'add', text: insertedText }))

              let textNodeAdjustedString = newText.substring(0, diff.index) + newText.substring(diff.index + diff.insert.length)
              textNode.setTextContent(textNodeAdjustedString)
              let insertSelection = selection;
              insertSelection.anchor.offset = diff.index;
              insertSelection.focus = insertSelection.anchor;
              $setSelection(insertSelection);

              //console.log("INSERTION OF CHARACTERS", insertedText)
              //const redlineNode = $createRedlineNode({...newRedline, redlineType: 'add', text: insertedText })
              /*
              let insertSelection = selection;
              insertSelection.anchor.offset = diff.index;
              insertSelection.focus = insertSelection.anchor;
              $setSelection(insertSelection);
              insertSelection.insertNodes([redlineNode]);*/

            }
            selection.insertNodes(newNodes)

          })
        } else if(Boolean(diff) && diff.insert.length === 0 && diff.remove === 0 && Boolean(parentDiff) && parentDiff.insert.length > 0 && // The Parent Node changed
        Boolean(textNode.getPreviousSibling()) && // There is a previous Sibling - where the character was inserted
        Boolean(textNode.getPreviousSibling().getPreviousSibling()) && ['redline'].includes(textNode.getPreviousSibling().getPreviousSibling().getType())) { // The one before is a redline
          // SPECIAL HANDLING FOR TYPING INSIDE A FORMATTED NODE
          // SELECTION SEEMS TO BE THROWN AFTER AFTER INSERTION
          // NEED TO PULL THE CHARACTER FROM THE PREVIOUS SIBBLING
          // NEED TO INSERT THE CHARACTER INTO THE PREVIOUS PREVIOUS SIBBLING
          console.log("SPECIAL HANDLING FOR TYPING INSIDE A FORMATTED NODE")
          let siblingNode = textNode.getPreviousSibling()
          let prevRedlineNode = textNode.getPreviousSibling().getPreviousSibling()

          // Text was inserted as the first character of the current textNode
          // Subtract newly inserted character from the textNode
          const insertedChar = siblingNode.getTextContent().substr(0,1)
          const newNodeText = siblingNode.getTextContent().substr(1);
          siblingNode.setTextContent(newNodeText)

          // Insert newly inserted character on to the redlineNode

          const newRedlineNode =  $createRedlineNode({
            redlineType: prevRedlineNode.getRedlineType(), 
            partyID: prevRedlineNode.getPartyID(), 
            creator: prevRedlineNode.getCreator(), 
            date: prevRedlineNode.getDate(), 
            text: prevRedlineNode.getTextContent() + insertedChar})

          prevRedlineNode.replace(newRedlineNode)

          // Shift selection one step back
          let newSelection = $getSelection();
          newSelection.anchor.key = newRedlineNode.getKey()
          newSelection.anchor.offset = prevRedlineNode.getTextContent().length + 1
          newSelection.focus = newSelection.anchor
          $setSelection(newSelection)

        }
      }),

      // REDLINE NODE TRANSFORM - USED FOR DETECTING CHANGES TO A REDLINE
      editor.registerNodeTransform(RedlineNode, (redlineNode) => {

        let selection = $getSelection();
        let newText = redlineNode.getTextContent()
        let prevText = null, prevNode = null;

        editor.getEditorState().read(() => {
          if(Boolean($getNodeByKey(redlineNode.getKey()))) {
            prevNode = $getNodeByKey(redlineNode.getKey());
            prevText = $getNodeByKey(redlineNode.getKey()).getTextContent();
          }
        })
        let diff = Boolean(prevText) ? simpleDiffWithCursor(prevText, newText, 0) : null

        // It's prohibited to add to redlines that are not yours or of type 'del' - Revert any prohibited changes
        if(Boolean(prevNode) &&
        Boolean(diff) && Boolean(diff.insert) && diff.insert.length > 0 && // Detect the insertion
        (props.partyID !== redlineNode.getPartyID() || // It's a counterparty's redline
        ['del'].includes(redlineNode.getRedlineType()))) { // It's a 'del' redline

          let isAtNodeEnd = selection.isCollapsed() && redlineNode.getTextContent().length <= selection.anchor.offset

          editor.update(() => {

            console.log("REVERT ANY PROHIBITED REDLINE CHANGE")

            const fixedNode = $createRedlineNode({
              redlineType: prevNode.getRedlineType(), 
              partyID: prevNode.getPartyID(), 
              creator: prevNode.getCreator(), 
              date: prevNode.getDate(), 
              text: prevText})

            redlineNode.replace(fixedNode); // Revert new redline node to the previous one

            if(isAtNodeEnd) { // Insert at the end of the redline 

              let insertedText = newText.substring(diff.index, diff.index + diff.insert.length)
              const redlineNode = $createRedlineNode({...newRedline, redlineType: 'add', text: insertedText })
              selection.insertNodes([redlineNode]);

            }
          })
        }
      }),

      // PASTE COMMAND
      editor.registerCommand(PASTE_COMMAND, (payload) => {
        if(payload.type === 'paste') {
          let clipboardData = payload.clipboardData;
          let interim = clipboardData.getData('Text')
          let pastedData = interim.replace(/(?:\\[rn]|[\r\n])/g, "\n");
          let newBlocks = pastedData.split('\n')

          let selection = $getSelection();

          console.log("selection.getNodes()",selection.getNodes())

          // Can not paste over a redline
          if(Boolean(selection.getNodes()) && !selection.getNodes().some((n) => ['redline'].includes(n.getType()))) {

            if(!selection.isCollapsed()) { // There is a selection over which you are pasting, redline it first
              handleDelRedLineForSelection(newRedline, props.partyID, selection, selection.isCollapsed() ? 'pointBack' : 'selection')
            }

            let newNodes = []
            const root = $getRoot();
            newBlocks.forEach((nb, i) => {

              const nodeForInsertion = nb.length === 0 ?
                  $createTextNode("") :
                  $createRedlineNode({...newRedline, redlineType: 'add', text: nb })

              if(i > 0) {
                const paragraph = $createParagraphNode();
                paragraph.append(nodeForInsertion);
                newNodes.push(paragraph)
              } else {
                newNodes.push(nodeForInsertion)
              }
            })
            selection.insertNodes(newNodes)
          }
        }
        return true;

      }, COMMAND_PRIORITY_HIGH),

      // BACKSPACE COMMAND
      
      editor.registerCommand(KEY_BACKSPACE_COMMAND, (payload) => {

        let letBrowserDoItsThing = false;
        editor.getEditorState().read(() => {

          // Let the browser do it's thing if you're backspacing a BLOCK
          const selection = $getSelection();
          const isCollapsed = selection.isCollapsed();
          const anchorNode = Boolean(selection.anchor) ? selection.anchor.getNode() : null
          const anchorOffset = Boolean(selection.anchor) ? selection.anchor.offset : null
          const prevSib = Boolean(anchorNode) ? anchorNode.getPreviousSibling() : null

          if(isCollapsed && anchorOffset === 0 && Boolean(anchorNode) && 
          (prevSib === null || ['paragraph', 'heading', 'list'].includes(prevSib.getType()))) {
            letBrowserDoItsThing = true;
          }
        })

        if(!letBrowserDoItsThing) {
          payload.preventDefault();
          editor.update(() => {
            let selection = $getSelection();
            handleDelRedLineForSelection(newRedline, props.partyID, selection, selection.isCollapsed() ? 'pointBack' : 'selection')
          })
        }
        return true;

      }, COMMAND_PRIORITY_HIGH ),

      // FWD DELETE COMMAND
      editor.registerCommand(KEY_DELETE_COMMAND, (payload) => {

        let letBrowserDoItsThing = false;
        editor.getEditorState().read(() => {

          // Let the browser do it's thing if you're fwd deleting a BLOCK
          const selection = $getSelection();
          const isCollapsed = selection.isCollapsed();
          const anchorNode = Boolean(selection.anchor) ? selection.anchor.getNode() : null
          const anchorOffset = Boolean(selection.anchor) ? selection.anchor.offset : null
          const nextSib = Boolean(anchorNode) ? anchorNode.getNextSibling() : null

          if(isCollapsed && Boolean(anchorNode) && 
          ((Boolean(anchorNode.getTextContent()) && anchorOffset === anchorNode.getTextContent().length) ||
           (!Boolean(anchorNode.getTextContent()))) && 
          (nextSib === null || ['paragraph', 'heading', 'list'].includes(nextSib.getType()))) {
            letBrowserDoItsThing = true;
          }
        })

        if(!letBrowserDoItsThing) {
          payload.preventDefault();
          editor.update(() => {
            let selection = $getSelection();
            handleDelRedLineForSelection(newRedline, props.partyID, selection, selection.isCollapsed() ? 'pointForward' : 'selection')
          })
        }
        return true;

      }, COMMAND_PRIORITY_HIGH ),

      // CUT COMMAND
      editor.registerCommand(CUT_COMMAND, (payload) => {
        payload.preventDefault();

        editor.update(() => {
          let selection = $getSelection();
          if(!selection.isCollapsed()) {
            const clipboardData = payload.clipboardData;
            if (clipboardData != null) {
              const lexicalString = $getLexicalContent(editor);
              if (lexicalString !== null) { clipboardData.setData('application/x-lexical-editor', lexicalString); }
              clipboardData.setData('text/plain', selection.getTextContent());
            }
            handleDelRedLineForSelection(newRedline, props.partyID, selection, 'selection')
          }
        })
        return true;

      }, COMMAND_PRIORITY_HIGH ),
      /*
      // TEXT NODE TRANSFORM - USED FOR INSERTING TEXT AND REDLINING IT
      editor.registerNodeTransform(TextNode, (textNode) => {

        let newText = textNode.getTextContent()
        let newParentText = textNode.getParent().getTextContent()
        let prevText = null, prevParentText = null;
        
        // Find out what the text was before the Node Change - for diff/comparison purposes
        editor.getEditorState().read(() => {
          if(Boolean($getNodeByKey(textNode.getKey()))) {
            prevText = $getNodeByKey(textNode.getKey()).getTextContent()
            prevParentText = textNode.getParent().getTextContent()
          }
        })

        let diff = Boolean(prevText) ? simpleDiffWithCursor(prevText, newText, 0) : null
        let parentDiff = Boolean(prevParentText) ? simpleDiffWithCursor(prevParentText, newParentText, 0) : null

        //console.log("some change", textNode.getTextContent().substring(0,10))

        let selection = $getSelection();
        console.log("selection", textNode, selection.getNodes());

        if(Boolean(diff) && diff.insert.length > 0 && Boolean(parentDiff) && parentDiff.insert.length > 0) {
          editor.update(() => {
            
            
            let withRemoval = false

            // If you also need to perform a proposed removal (i.e. when you are overwriting stuff)
            if(Boolean(diff) && diff.remove > 0) {
              console.log("INSERTING, BUT FIRST: DELETE TEXT AS SELECTED", textNode, diff, parentDiff)
              //handleDelRedLineForSelection(selection, 'selection')

              // todo
              
              let removedText = prevText.substring(diff.index, diff.index + diff.remove)
              const redlineNode = $createRedlineNode({...newRedline, redlineType: 'del', text: removedText })
              selection.insertNodes([redlineNode]);
              let newSelection = $getSelection();
              newSelection.anchor.offset = 0
              newSelection.focus = newSelection.anchor;
              $setSelection(newSelection);
              withRemoval = true;
            }

            
            let insertedText = newText.substring(diff.index, diff.index + diff.insert.length)
            console.log("INSERTION OF CHARACTERS", insertedText)
            
            const redlineNode = $createRedlineNode({...newRedline, redlineType: 'add', text: insertedText })

            if(selection.getNodes().some((n) => n.getType() !== 'redline') || withRemoval) {
              // TODO: Review if selection is larger and multiple node types may exist
              if(!withRemoval) { textNode.setTextContent(prevText); }
              let insertSelection = $getSelection();
              insertSelection.anchor.offset = insertSelection.anchor.offset - diff.insert.length
              insertSelection.focus = insertSelection.anchor;
              $setSelection(insertSelection);
              insertSelection.insertNodes([redlineNode]);
            }
          })

        } else if(Boolean(diff) && diff.insert.length === 0 && diff.remove === 0 && Boolean(parentDiff) && parentDiff.insert.length > 0 && // The Parent Node changed
        Boolean(textNode.getPreviousSibling()) && // There is a previous Sibling - where the character was inserted
        Boolean(textNode.getPreviousSibling().getPreviousSibling()) && ['redline'].includes(textNode.getPreviousSibling().getPreviousSibling().getType())) { // The one before is a redline
          // SPECIAL HANDLING FOR TYPING INSIDE A FORMATTED NODE
          // SELECTION SEEMS TO BE THROWN AFTER AFTER INSERTION
          // NEED TO PULL THE CHARACTER FROM THE PREVIOUS SIBBLING
          // NEED TO INSERT THE CHARACTER INTO THE PREVIOUS PREVIOUS SIBBLING
          console.log("SPECIAL HANDLING FOR TYPING INSIDE A FORMATTED NODE")
          let siblingNode = textNode.getPreviousSibling()
          let prevRedlineNode = textNode.getPreviousSibling().getPreviousSibling()

          // Text was inserted as the first character of the current textNode
          // Subtract newly inserted character from the textNode
          const insertedChar = siblingNode.getTextContent().substr(0,1)
          const newNodeText = siblingNode.getTextContent().substr(1);
          siblingNode.setTextContent(newNodeText)

          // Insert newly inserted character on to the redlineNode

          const newRedlineNode =  $createRedlineNode({
            redlineType: prevRedlineNode.getRedlineType(), 
            partyID: prevRedlineNode.getPartyID(), 
            creator: prevRedlineNode.getCreator(), 
            date: prevRedlineNode.getDate(), 
            text: prevRedlineNode.getTextContent() + insertedChar})

          prevRedlineNode.replace(newRedlineNode)

          // Shift selection one step back
          let newSelection = $getSelection();
          newSelection.anchor.key = newRedlineNode.getKey()
          newSelection.anchor.offset = prevRedlineNode.getTextContent().length + 1
          newSelection.focus = newSelection.anchor
          $setSelection(newSelection)

        }

      }),

      // REDLINE NODE TRANSFORM - USED FOR DETECTING CHANGES TO A REDLINE
      editor.registerNodeTransform(RedlineNode, (redlineNode) => {

        let selection = $getSelection();
        let anchorKey = selection.anchor.getNode().getKey()
        let redlineKey = redlineNode.getKey()

        let newText = redlineNode.getTextContent()
        let prevText = null, prevNode = null;

        editor.getEditorState().read(() => {
          if(Boolean($getNodeByKey(redlineNode.getKey()))) {
            prevNode = $getNodeByKey(redlineNode.getKey());
            prevText = $getNodeByKey(redlineNode.getKey()).getTextContent()
          }
        })
        let diff = Boolean(prevText) ? simpleDiffWithCursor(prevText, newText, 0) : null

        // It's prohibited to add to redlines that are not yours or of type 'del' - Revert any prohibited changes
        if(Boolean(prevNode) &&
        Boolean(diff) && Boolean(diff.insert) && diff.insert.length > 0 && // Detect the insertion
        (props.partyID !== redlineNode.getPartyID() || // It's a counterparty's redline
        ['del'].includes(redlineNode.getRedlineType()))) { // It's a 'del' redline

          let isAtNodeEnd = selection.isCollapsed() && redlineNode.getTextContent().length <= selection.anchor.offset

          editor.update(() => {

            console.log("REVERT ANY PROHIBITED REDLINE CHANGE")

            const fixedNode = $createRedlineNode({
              redlineType: prevNode.getRedlineType(), 
              partyID: prevNode.getPartyID(), 
              creator: prevNode.getCreator(), 
              date: prevNode.getDate(), 
              text: prevText})

            redlineNode.replace(fixedNode); // Revert new redline node to the previous one

            if(isAtNodeEnd) { // Insert at the end of the redline 

              let insertedText = newText.substring(diff.index, diff.index + diff.insert.length)
              const redlineNode = $createRedlineNode({...newRedline, redlineType: 'add', text: insertedText })
              selection.insertNodes([redlineNode]);

            }
          })
        }
        //}
      })
      */
    )

  }, [editor])

  const getBlockText = (node) => {

    let textContent = null;

    if(['heading', 'paragraph', 'list'].includes(node.getParent().getType())) {
      textContent = node.getParent().getTextContent();

    } else if(['root'].includes(node.getParent().getType())) {
      return

    } else {
      textContent = getBlockText(node.getParent());

    }

    return textContent;

  }
  
}

export default function RedlinePlugin(props) {
  const [editor] = useLexicalComposerContext();
  const [showRedlineMenu, setShowRedlineMenu] = useState(false);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          const selection = $getSelection();
          setShowRedlineMenu(Boolean(selection) && selection.getNodes().some((n) => ['redline'].includes(n.getType())))
        });
      }),
    );
  }, [editor]);


  useRedlines(editor, props);

  return (
    <>
    {showRedlineMenu &&
     createPortal(
          <RedlineMenu visible={true} partyID={props.partyID} />,
          document.body,
    )}
    
    </>
  );
}

/*
          editor.update(() => {
            let selection = $getSelection();
            console.log(selection.anchor.offset, selection.focus.offset)
            
            // REMOVAL OF CHARACTERS
            if(Boolean(diff) && diff.remove > 0) {
              let removedText = prevText.substring(diff.index, diff.index + diff.remove)
              const redlineNode = $createRedlineNode({...newRedline, redlineType: 'del', text: removedText })
              selection.insertNodes([redlineNode]);
              if(diff.remove === 1 && isBackspace) { // For backspace, move selection one back
                let newSelection = $getSelection();
                newSelection.anchor.offset = newSelection.anchor.offset - 1
                newSelection.focus = newSelection.anchor;
                $setSelection(newSelection)   
              }
            }

            if(Boolean(diff) && diff.insert.length > 0) {
                
              let insertedText = newText.substring(diff.index, diff.index + diff.insert.length)
              console.log("INSERTION OF CHARACTERS", insertedText)
              
              const redlineNode = $createRedlineNode({...newRedline, redlineType: 'add', text: insertedText })
              //editor._updateTags.add('redline');

              if(selection.getNodes().some((n) => n.getType() !== 'redline')) {
                // TODO: Review if selection is larger and multiple node types may exist
                textNode.setTextContent(prevText);
                selection.insertNodes([redlineNode]);
                //newNode.spliceText(diff.index, diff.insert.length, '') // reverse regular text insertion
              }
            }
          })
        }*/




  //useEffect(() => {

    //editor.registerTextContentListener(() => { textContentChange = true; })

    /*

    let diffs = []
    let textContentChange = false;
    editor.registerUpdateListener(({editorState, dirtyElements, dirtyLeaves, prevEditorState, tags}) => {

      console.log("tags", tags)
      //if(tags.has('redline')) { console.log("redline update") }

      if(!tags.has('redline') && dirtyElements.size > 0 && dirtyLeaves.size > 0) {

        console.log("updating editor in response to content change", dirtyElements, dirtyLeaves)

        editor.update(() => {
          
          let redlineUpdate = false;
          

          dirtyLeaves.forEach((leaf) => {

            let prevNode = prevEditorState._nodeMap.get(leaf);
            let newNode = editorState._nodeMap.get(leaf);
    
            let diff = Boolean(prevNode) && Boolean(newNode) ? simpleDiffWithCursor(prevNode.__text, newNode.__text, 0) : null

            console.log("diff", diff)
            // REMOVAL OF CHARACTERS
            if(Boolean(diff) && diff.remove > 0) {
              let removedText = prevNode.__text.substring(diff.index, diff.index + diff.remove)
              const redlineNode = $createRedlineNode(
                "del",
                newRedline.partyID, 
                newRedline.creator, 
                newRedline.date, 
                removedText
              )

              let selection = $getSelection();
              console.log(selection.anchor.offset, selection.focus.offset)
              selection.insertNodes([redlineNode]);

              redlineUpdate = true;

              console.log("removeText", removedText)
            }

            
            if(redlineUpdate) { editor._updateTags.add('redline'); }

          })
        })
      }

    })*/

    /*
            // INSERTION OF CHARACTERS
            if(Boolean(diff) && diff.insert.length > 0) {
              
              let insertedText = newNode.__text.substring(diff.index, diff.index + diff.insert.length)
              console.log("INSERTION OF CHARACTERS", insertedText)
              const redlineNode = $createRedlineNode(
                "add",
                newRedline.partyID, 
                newRedline.creator, 
                newRedline.date, 
                insertedText
              )
              //editor._updateTags.add('redline');
              redlineUpdate = true;
              if(selection.getNodes().some((n) => n.getType() !== 'redline')) {
                // TODO: Review if selection is larger and multiple node types may exist
                selection.insertNodes([redlineNode]);
                newNode.spliceText(diff.index, diff.insert.length, '') // reverse regular text insertion
              }
            }
            */


          /*
          let elsMutated = []
          for (let [key] of dirtyElements.entries()) { 
            if(key !== 'root') { 
              elsMutated.push(key)
              //elsMutated.push(key) 
            } 
          } 
          console.log("elsMutated",elsMutated)
          dirtyElements.forEach((el) => {
            console.log("el", el)
            //editor.getElementByKey()
          })*/

          
          //
    
      /*if(dirtyElements.size > 0) {
        console.log("dirtyElements", dirtyElements)
      }
      if(dirtyLeaves.size > 0) {
        console.log("dirtyLeaves", dirtyLeaves)
      }*/

      //console.log("tags", tags)


      //console.log("dirtyElements", dirtyElements)
      //console.log("dirtyLeaves", dirtyLeaves)

      /*
      dirtyElements.forEach((el) => {
        console.log("el", el, prevEditorState._nodeMap.get('8'))
      })*/


      /*for (let [key, value] of dirtyElements.entries()) {

        if(key !== 'root') {
          elsUpdated.push(key)
        }

      } 
      

      console.log("elsUpdated", elsUpdated)*/
      /*
      dirtyElements.forEach((el) => {
        console.log("el", el)
      })*/


      //dirtyLeaves.forEach((leaf) => {

        //let hasTextChange = false;

        //console.log("p",prevEditorState._nodeMap.get(leaf).__text)
        //console.log("n",editorState._nodeMap.get(leaf).__text)

        //et prevNode = prevEditorState._nodeMap.get(leaf);
        //let newNode = editorState._nodeMap.get(leaf);

        //console.log("dirtyElements", dirtyElements)
        //console.log("dirtyLeaves", dirtyLeaves)

        
        //let diff = Boolean(prevNode) ? simpleDiffWithCursor(prevNode.__text, newNode.__text, 0) : null
      
        //if(Boolean(diff) &&
          //(diff.remove > 0 || diff.insert.length > 0)) {
          
          //console.log("there is a diff", diff)
          //console.log("prevNode",prevNode)
          //console.log("newNode",newNode)

          //let textRemoved = '';
          //if(diff.remove > 0) { textRemoved = prevNode.__text.substring(diff.index, diff.index + diff.remove) }

          //console.log("pushing", { leaf: leaf, index: diff.index, insert: diff.insert, remove: textRemoved })
          //diffs.push({ leaf: leaf, index: diff.index, insert: diff.insert, remove: textRemoved })  
        //}

      //})

      //if(diffs.length > 0 && !tags.has('redline') && textContentChange) { // && 
        //console.log("diffs", diffs)
        /*editor.update(() => {

          let selection = $getSelection();  

          diffs.forEach((d) => {

            if(d.remove.length > 0) {

              let node = $getNodeByKey(d.leaf);


              const redlineNode = $createRedlineNode(
                "del",
                newRedline.partyID, 
                newRedline.creator, 
                newRedline.date, 
                d.remove
              )
              editor._updateTags.add('redline');
              selection.insertNodes([redlineNode]);

              //let splittedNodes = node.splitText(d.diff.index)
              //console.log("node", splittedNodes)

            }

          })

          diffs = []
        })
      }
      elsUpdated = [];
      textContentChange = false;

      //diffs = [];



      //prevEditorState.read(() => {

      //});

      /*
      console.log(prevNodes)

      editorState.read(() => {

        dirtyLeaves.forEach((leaf) => {
          //console.log(leaf, typeof leaf)

          let node = editorState._nodeMap.get(leaf);
          console.log("x", node)
        })
        // Just like editor.update(), .read() expects a closure where you can use
        // the $ prefixed helper functions.
      });
      */
    //});

  //}, [editor])


  /*
  useEffect(() => {
    return mergeRegister(

      editor.registerTextContentListener(
        (textContent) => {
          // The latest text content of the editor!
          console.log("textContent",editor.getEditorState());
        },
      ),*/
      // Mutation listener to help detect that "a" change happened to a specific node
      /*
      editor.registerMutationListener(
        TextNode,
        (mutatedNodes) => {
          //console.log("pre", editorCache, editor)
          // mutatedNodes is a Map where each key is the NodeKey, and the value is the state of mutation.
          for (let [nodeKey, mutation] of mutatedNodes) {
            //console.log("editor", editor)
            console.log("Node", nodeKey, mutation)
            console.log("$new",$getNodeByKey(nodeKey))


            //setEditorCache(editor.getEditorState())
          }
        },
      ),*/

      /*
      "NEW CHARACTER", // can overwrite if selection
      KEY_ENTER_COMMAND, // can overwrite if selection
      KEY_SPACE_COMMAND, // can overwrite if selection
      */
      
      //useEffect(() => {
        /*
        return mergeRegister(
          editor.registerMutationListener(
            TextNode,
            (mutatedNodes) => {
              for (let [nodeKey, mutation] of mutatedNodes) {
              
                //console.log("textChange",textChange)
                //console.log("Node", nodeKey, mutation)
                console.log("old", state.editorState)
                console.log("$new",$getNodeByKey(nodeKey))
  
              }
            },
          ),*/
          /*
          editor.registerTextContentListener((currentText) => {
            //console.log("prevText", text)
            //text = currentText;
            //console.log("newText", text)
            //textChange = true;

            for (let [nodeKey, mutation] of mutNodes) {
              
              //console.log("textChange",textChange)
              //console.log("Node", nodeKey, mutation)
              console.log("$new",$getNodeByKey(nodeKey))

            }

            mutNodes = null;
          }),*/
          /*
          editor.registerUpdateListener(({dirtyLeaves}) => {
            const hasDirtyLeaves = dirtyLeaves.size > 0;

            if(textChange && hasDirtyLeaves) {

              editor.read()
              dirtyLeaves.forEach((leaf) => {

                console.log("leaf", $getNodeByKey(leaf))

              })
              
              
            }

            textChange = false;*/
            /*console.log("pre", editorCache)
              console.log(dirtyLeaves)
              console.log("post" , editor.getEditorState())
              console.log(editor.$getNodeByKey())*/
            /*
            const isComposing = editor.isComposing();
            const hasDirtyLeaves = dirtyLeaves.size > 0;
            if (isComposing || !hasDirtyLeaves) {
              return;
            }
            const textLength = strlen(text);
            const textLengthAboveThreshold =
              textLength > maxCharacters ||
              (lastComputedTextLength !== null &&
                lastComputedTextLength > maxCharacters);
            const diff = maxCharacters - textLength;
            remainingCharacters(diff);
            if (lastComputedTextLength === null || textLengthAboveThreshold) {
              const offset = findOffset(text, maxCharacters, strlen);
              editor.update(
                () => {
                  $wrapOverflowedNodes(offset);
                },
                {
                  tag: 'history-merge',
                },
              );
            }
            lastComputedTextLength = textLength;*/
          //}),
        //);
      //}, [editor]);



      /*
      editor.registerCommand(
        INSERT_TEXT_COMMAND,
        (payload) => {
          console.log("TODO INSERT_TEXT_COMMAND", payload)
          return true;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        PASTE_COMMAND,
        (payload) => {

          if(payload.type === 'paste') {
            let clipboardData = payload.clipboardData;
            let pastedData = clipboardData.getData('Text');
  
            let selection = $getSelection();

            if(!selection.isCollapsed()) { // There is a selection over which you are pasting, redline it first
              createRedLineForSelection('del', selection, selection.isCollapsed() ? 'pointBack' : 'selection')
            }
  
            const redlineNode = $createRedlineNode(
              "add",
              newRedline.partyID, 
              newRedline.creator, 
              newRedline.date, 
              pastedData
            )
            selection.insertNodes([redlineNode]);
          }
          return true;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        (payload) => {
          payload.preventDefault();
          
          editor.update(() => {
            let selection = $getSelection();
            createRedLineForSelection('del', selection, selection.isCollapsed() ? 'pointBack' : 'selection')
          })
          return true;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        (payload) => {
          payload.preventDefault();

          editor.update(() => {
            let selection = $getSelection();
            createRedLineForSelection('del', selection, selection.isCollapsed() ? 'pointForward' : 'selection')
          })
          return true;
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        CUT_COMMAND,
        (payload) => {
          payload.preventDefault();

          editor.update(() => {
            let selection = $getSelection();
            //console.log("selection.getFormat()", selection.)

            //selection.formatText(format)
            if(!selection.isCollapsed()) {
              const clipboardData = payload.clipboardData;
              if (clipboardData != null) {
                const lexicalString = $getLexicalContent(editor);
                if (lexicalString !== null) {
                  clipboardData.setData('application/x-lexical-editor', lexicalString);
                }
                clipboardData.setData('text/plain', selection.getTextContent());
              }
              createRedLineForSelection('del', selection, 'selection')
            }
          })
          return true;
        },
        COMMAND_PRIORITY_HIGH
      )
      */
    //);
  //}, [editor]);