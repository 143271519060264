import React from 'react';
import theme from '../theme/theme';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentLines, faMagnifyingGlassChart, faNewspaper, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

const ContextMenu = (props) => {

  const styles = {
      iconB: {
        display: 'block',
        color: theme.palette.grey[700],
        fontSize: '22px',
      },
      iconBprim: {
        display: 'block',
        color: theme.palette.primary.main,
        fontSize: '22px',
      },
  }

  return (
    <div style={props.visible ? {} : {display: 'none'}}>
        <Grid container direction="column" alignItems="center"
        sx={{
            width: '68px',
            position: 'fixed',
            right: '20px',
            top: '90px',
            borderRadius: '30px',
            backgroundColor: 'rgba(255,255,255,0.7)', 
            backdropFilter: 'blur(10px)',
            zIndex: '1000',
            padding: '13px 8px',
            border: '1px solid' + theme.palette.grey[200],
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px',
        }}>
        {[
        { 
          title: "Add comment", 
          icon: faCommentLines, 
          style: styles.iconB, 
          padding: '2px 4px', 
          click: props.onAddComment 
        },
        /*{ 
          title: "Send for Internal approval", 
          icon: faThumbsUp, 
          style: styles.iconB, 
          padding: '3px 4px', 
          click: props.onAddApproval,
        },*/
        //{ title: "Define Obligation", icon: faAlarmClock, style: styles.iconB },
        { 
          title: "Create Parameter", 
          icon: faMagnifyingGlassChart, 
          style: styles.iconB, 
          padding: '3px 4px', 
          click: props.onAddParam, 
        },
        /*{ 
          title: "Clause Options",
          icon: faNewspaper, 
          style: styles.iconB, 
          padding: '3px 4px', 
          click: props.onCreateClause, 
        },*/

        //{ title: "Add cross-reference", icon: faRotate, style: styles.iconB },
        //{ title: "Create Lock", icon: faLockKeyhole, style: styles.iconB },
        ].map((b, i) => (
        <Grid item key={i}>
        <Tooltip title={b.title} placement={"left"}>
        <IconButton sx={b.style} onClick={b.click}>
            <FontAwesomeIcon icon={b.icon} style={{padding: b.padding}} />
        </IconButton>  
        </Tooltip>
        </Grid>
        ))}

        </Grid>
    </div>
  );
};
export default ContextMenu;


