const agrTypesReducer = (state, action) => {

    switch (action.type) {
        case "INIT_AGRTYPES":
            return { agrTypes: action.payload };
        case "ADD_AGRTYPE":
            return {
                agrTypes: [...state.agrTypes, action.payload]
            };
        case "UPDATE_AGRTYPE":
            let newVal = action.payload;
            let newAgrTypes = state.agrTypes;
            let idx = state.agrTypes.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newAgrTypes[idx] = newVal;
            }
            return { agrTypes: newAgrTypes };
        default:
            return { agrTypes: state.agrTypes }
    }
};
  
export default agrTypesReducer