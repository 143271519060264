import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { useNavigate } from "react-router-dom";
import { Avatar, Autocomplete, Button, Box, Checkbox, Chip, 
         Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, 
         Menu, MenuItem, ListItemIcon, ListItemText, 
         MobileStepper, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { trunc, pad, getEditModeOption, getColorForPartyID, randomString as generateRandomString,
         getAddressForEntity } from '../utils';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CanveoCircularProgress, SelectUserForOrg } from '.';
import { UserForm } from './forms';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes, faArrowRight, faArrowLeft, faPenCircle, faCircle, faCheckCircle, 
         faComment, faInfoCircle, faCircleBookOpen, faCircleQuestion, faStarCircle, faUserPlus,
         faCircleXmark, faCaretDown, faClone, faPlusCircle, faTimesCircle, faSquare, faThumbsUp, faSignature } from '@fortawesome/pro-solid-svg-icons';
//import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';

const icon = <FontAwesomeIcon icon={faCircle} />;
const checkedIcon = <FontAwesomeIcon icon={faCheckCircle} />;

function Column(props){
  const { id, signers, parties, sigConfig, activeUser, handleSignBoxAction } = props;

  return (

      <Droppable droppableId={id}>
      {provided => (

         <div {...provided.droppableProps} ref={provided.innerRef}>
           {signers
           .map((signer,index)=>{
              return (
                <Task 
                id={signer._id} 
                key={index} 
                index={index}
                signer={signer}
                parties={parties}
                sigConfig={sigConfig}
                activeUser={activeUser}
                handleSignBoxAction={handleSignBoxAction}
                />)
           })}

          {provided.placeholder}
         </div>
       )
      }
      </Droppable>
  )
}

function Task(props){

  const { id, index, signer, parties, sigConfig, activeUser, handleSignBoxAction } = props;

  return (
     <Draggable draggableId={id} key={id} index={index} isDragDisabled={activeUser.role === 'Counterparty'}>

       {(provided) => (
          <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps} 
          align="center">

              <Grid container direction="row" alignItems="center" justify="center">
                  <Grid item xs={1}><Box >{index + 1}.</Box></Grid>
                  <Grid item xs={10} sm={6}>

                      <RenderSignBox 
                        key={signer._id}
                        signer={signer}
                        partyID={Boolean(parties.filter((p) => p.orgID === signer.orgID)[0]) ? 
                          parties.filter((p) => p.orgID === signer.orgID)[0].partyID : null}
                        sigConfig={sigConfig}
                        canRemove={signer.orgID === activeUser.orgID ||
                          (Boolean(parties.filter((p) => p.orgID === signer.orgID)[0]) &&
                          parties.filter((p) => p.orgID === signer.orgID)[0].ownerOrgID === activeUser.orgID)}
                        handleSignBoxAction={handleSignBoxAction}
                        />

                  </Grid>
                  <Grid item xs={1}></Grid>
              </Grid>
          </Box>
      )}
     </Draggable>
  )
}

const RenderSignBox = ({signer, partyID, canRemove, sigConfig, handleSignBoxAction}) => {

  return (
    <Box sx={{
      border: '1px solid' + theme.palette.grey[200],
      borderBottom: '2px solid' + getColorForPartyID(partyID),
      //borderRadius: '0px 20px 20px 0px',
      borderRadius: '20px 20px 0px 00px',
      padding: '15px 20px',
      width: '240px',
      backgroundColor: theme.palette.grey[50],
      margin: '5px 0px 10px 0px',
    }}>
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item>
          <Avatar src={signer.photoURL} alt={signer.displayName} 
          //style={{ border: '3px solid' + getColorForPartyID(signer.partyID), height: '40px', width: '40px'}} 
          />
        </Grid>
        <Grid item sx={{pl:3, width: '150px'}}>

          {canRemove ?
          <Box sx={{textAlign: 'right', marginTop: '-10px', marginRight: '-18px', marginBottom: '-14px'}}>
            <FontAwesomeIcon 
            icon={faTimesCircle} 
            onClick={e => handleSignBoxAction('remove', signer._id)} 
            style={{ color: getColorForPartyID(partyID), cursor: 'pointer' }} 
            />
          </Box>
          :''}
          <Typography sx={{fontWeight: '700'}}>{trunc(signer.displayName, 14)}</Typography>
          <Typography variant="body2">{trunc(signer.title, 19)}</Typography>
          <Typography variant="body2">{trunc(signer.entityName, 19)}</Typography>
          <Typography variant="body2" color="textSecondary">{trunc(signer.email, 19)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );

}

function StyledCheckbox(props) {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<FontAwesomeIcon icon={faThumbsUp} style={{fontSize: '30px'}} />}
      icon={<FontAwesomeIcon icon={faSquare} style={{fontSize: '30px', color: theme.palette.grey[300]}} />}
      {...props} />
  );
}

export default function DialogSend(props) {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const styles = {
    optionEmail: {
      color: theme.palette.grey[600],
      fontWeight: '300',
      fontSize: '15px',
      marginLeft: '10px',
    },
    addCollabTextField: {
      width: '100%',
      '& .MuiOutlinedInputRoot': {
          backgroundColor: theme.palette.primary.contrastText,
          '& fieldset': {
              borderColor: theme.palette.grey[400],
          },
      },
    },
    addCollabBox: {
      backgroundColor: theme.palette.grey[50],
      //boxShadow: 'rgba(0, 0, 0, 0.45) 0px 0px 1px 0px',
      border: '1px solid' + theme.palette.grey[200],
      borderRadius: '20px',
      padding: '12px 20px',
      textAlign: 'center',
    },
    sigQualityBox: {
      width: '260px',
      cursor: 'pointer',
      textAlign: 'center'
    },
    sigQualityCaret: {
      color: theme.palette.grey[700],
      marginLeft: '15px'
    },
    smallButBolder: {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.grey[800],
      padding: '0px',
    },
    menuOption2: {
      fontWeight: '500',
      paddingTop: '7px',
      paddingBottom: '7px',
      width: '260px'
    },
    menuOption3: {
        width: '260px',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '20px',
    },
    lighter: {
      color: theme.palette.grey[500],
      marginLeft: '10px',
      fontSize: '13px',
      fontWeight: '400',
    },
    signBox: {
      maxWidth: '380px',
      borderRadius: '20px',
      border: '1px solid ' + theme.palette.grey[200],
      backgroundColor: theme.palette.grey[50],
      padding: '15px 30px',
      '&:hover': {
          cursor: 'pointer',
      }
    },
  }

  const notReceivingColor = theme.palette.grey[300];

  function userSelectChipStyle(color) {
    return {
      border: '1px solid' + color, 
      margin: '2px 2px 2px 0px',
      height: '36px',
      backgroundColor: theme.palette.primary.contrastText,
      color: color === notReceivingColor ? notReceivingColor: theme.palette.grey[800],
      "& .MuiChip-deleteIcon": { color: color, '&:hover': { color: theme.palette.grey[800] } },
    }
  }

  const navigate = useNavigate();

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState(null)
  const [activeStep, setActiveStep] = React.useState(0);
  const [collabs, setCollabs] = React.useState(null);
  const [signers, setSigners] = React.useState(null);
  const [collabOrSignerUpdate, setCollabOrSignerUpdate] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState(null);
  const [stagedForInvitationEmail, setStagedForInvitationEmail] = React.useState([])
  const [creatingUser, setCreatingUser] = React.useState(null)
  const [addingSigner, setAddingSigner] = React.useState(null)
  const [signingOrder, setSigningOrder] = React.useState(false)
  const [readyToSign, setReadyToSign] = React.useState(false)
  const [partyFullString, setPartyFullString] = React.useState('');
  const [oidsForWhichYouCanAddUser, setOidsForWhichYouCanAddUser] = React.useState([])
  const [message, setMessage] = React.useState('');
  const [anchorElEditMode, setAnchorElEditMode] = React.useState(null);
  const [anchorElCpty, setAnchorElCpty] = React.useState(null);
  const [anchorElSig, setAnchorElSig] = React.useState(null);

  const currentParty = state.parties.filter((p) => p.orgID === state.org._id)[0]
  const mainAv = Boolean(state.avs) ? state.avs.filter((av) => av.agrID === props.mainAg._id)[0] : null
  const signable = 
      Boolean(mainAv) && Boolean(currentParty) && currentParty.side === "primary" ? mainAv.secReady : 
      Boolean(mainAv) && Boolean(currentParty) && currentParty.side === "secondary" ? mainAv.primReady : false

  useEffect(() => {
    if(props.trigger === 'initiatesigning') { 
      setReadyToSign(true); 
      setActiveStep(1);
    }
  }, [props.trigger])

  useEffect(() => {
    
    if(state.parties.length > 0 && allUsers === null && props.open) { // Define initial parties and allUsers arrays
      /*
      let ps = []
      let partyCount = props.mainAg.ents.length
      props.mainAg.ents.forEach((e) => {
        let p = 
          state.subs.filter((s) => s._id === e.entID)[0] !== undefined ?
              state.subs.filter((s) => s._id === e.entID)[0] :
          state.cpents.filter((cpe) => cpe._id === e.entID)[0] !== undefined ?
              state.cpents.filter((cpe) => cpe._id === e.entID)[0] : null

        if(p !== null) {
          p.editMode = state.user.orgID === p.orgID ? 'currentOrg' : partyCount > 2 ? 'tbd' : 'edit';
          p.partyID = e.partyID
          p.side = e.side
          ps.push(p)
        }
      })*/

      let cs = [], ss = [], ofwycau = []
      let aus = state.users

      // Define orgs for which you can add a user
      state.parties.forEach((p) => {
        if((state.org._id === p.orgID && state.org.orgType === 'cpty') || state.org._id === p.ownerOrgID) {
          ofwycau.push(p.orgID)
        }
      })

      // Now pull all users for counterparties
      // TODO: Need to consider performance if user bases are starting to grow - can't pull ALL users for ALL parties
      let orgString = '';
      state.parties.filter((p) => p.orgID !== state.org._id).forEach((p) => { orgString = orgString + p.orgID + "," })
      orgString = orgString.slice(0, -1);

      axios.get(state.settings.api + "user/org/" + orgString)
      .then((resUsers) => {

        if(Boolean(resUsers.data.success)) {

          aus = aus.concat(resUsers.data.data)

          // With all Users - you can define a collabs and signers array that has actual User Detail
          props.mainAg.collabs.forEach((c) => {
            if(aus.filter((u) => u._id === c.uid)[0] !== undefined){
              cs.push(aus.filter((u) => u._id === c.uid)[0])
            }
          })

          props.mainAg.signers.forEach((s) => {
            if(aus.filter((u) => u._id === s.uid)[0] !== undefined) {
              let tempSigner = aus.filter((u) => u._id === s.uid)[0];
              tempSigner.partyID = s.partyID
              tempSigner.order = s.order;
              tempSigner.entityName = s.entityName;
              ss.push(tempSigner)
            }
          })

          // Define the "partyFullString" constant, relevant for emails (e.g. share agr or adding collab)
          let pfs = '';
          if(state.parties.length === 2) { pfs = state.parties[0].legalName + ' and ' + state.parties[1].legalName; } 
          else if(state.parties.length > 2) {
            state.parties.forEach((p) => { pfs = pfs + p.legalName + ", " })
            pfs = pfs.slice(0,-2)
          }

          console.log("props.mainAg.signers", props.mainAg.signers)
          setSigningOrder(props.mainAg.signers.some((s) => Boolean(s.order)))
          setAllUsers(aus)
          setCollabs(cs)
          setSigners(ss)
          setOidsForWhichYouCanAddUser(ofwycau);
          setPartyFullString(pfs)
          setLoading(false)

        }

      }).catch((e) => setErrMsg('Unable to retrieve all users - please reload or contact Canveo Support if the issue persists'))
    }

  }, [props.mainAg.ents, props.open])

  const reInitalize = () => {
    setLoading(true);
    setActiveStep(0);
    //setParties(null);
    setAllUsers(null);
    setCollabOrSignerUpdate(false);
    setStagedForInvitationEmail([])
    setMessage('')
    setReadyToSign(false)
    setSigningOrder(false)
  }

  const closeDialog = () => {
    if(stagedForInvitationEmail.length > 0) {
      // Send invitation email for users when you close the dialog without sending the agreement
      let mailConfig = {
        type: 'newCollab', 
        whiteLabel: props.mainAg.whiteLabel, 
        agr: props.mainAg, 
        senderLegalName: currentParty.legalName, 
        toEntString: '', 
        partyFullString: partyFullString,
        readyToSign: false, 
        requestComment: null,
      }
  
      // Send "newCollab" email
      stagedForInvitationEmail.forEach((recipient) => {
        mailConfig.recipient = recipient;
        axios.post(state.settings.api + "mail/informagr", mailConfig)
      })
    }

    if(collabOrSignerUpdate) { // If collabs or signers have been updated, ensure to update the DB and trigger a refresh

      let newMainAg = props.mainAg;
      newMainAg.collabs = getDBUserArray('collabs')
      newMainAg.signers = getDBUserArray('signers')
      newMainAg.lastUpdateBy = state.user._id
      newMainAg.lastUpdateDate = new Date().toISOString();

      axios.put(state.settings.api + "agr/" + newMainAg._id, { agr: newMainAg})
      .then((res) => {

        dispatch({ type: "UPDATE_AGR", payload: res.data.data}) // Update state with new collab/signer array
        props.closeDialog()
        reInitalize()

      }).catch((err) => { setErrMsg("Unable to update the changed collaborators / signers"); setLoading(false) })

    } else { // Otherwise just close and reinit
      props.closeDialog()
      reInitalize()
    }
  };

  function getDBUserArray(type) {
    let newarr = []
    if(type === 'collabs') {
        collabs.forEach((c) => { 
          let party = state.parties.filter((p) => p.orgID === c.orgID)[0]
          if(Boolean(party)) {
            newarr.push({
              uid: c._id,
              email: c.email,
              orgID: c.orgID,
              partyID: party.partyID
            })
          }
        })
    } else if (type === 'signers') {
        signers.forEach((s) => {
          let party = state.parties.filter((p) => p.orgID === s.orgID)[0]
          if(Boolean(party)) {
            newarr.push({
              uid: s._id,
              email: s.email,
              order: s.order,
              partyID: party.partyID,
              entityName: s.entityName,
            })
          }
        })
    }
    return newarr;
  }

  const handleNext = () => {
    if(activeStep < 2) {
      setActiveStep(activeStep + 1)
    }
  }

  const handleBack = () => {
    if(activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const handleSend = () => {
    
    setLoading(true)

    console.log("send to cpty")
    console.log("msg", message)
    console.log("parties", state.parties)
    console.log("collabs", collabs)
    console.log("signers", signers)
    console.log("readyToSign", readyToSign)

    if(!Boolean(mainAv)) { setErrMsg("Unable to retrieve the correct agreement version, reload and try again"); return; }

    // DETERMINE THE NEW AGREEMENT VERSION VERSION
    let newFinalVersion = '', newMajorVersion = ''
    if(readyToSign && signable) { // New Final Agreement version (to be Signed)
        newFinalVersion = pad(parseInt(mainAv.version.substring(0, 3)) + 1, 3).toString()
        newMajorVersion = "00001"
    } else { // New Major Agreement version (to be sent to cpty)
        newFinalVersion = mainAv.version.substring(0, 3).toString()
        newMajorVersion = pad(parseInt(mainAv.version.substring(4)) + 1,5).toString()
    }
    const newAgrVersion = newFinalVersion + "." + newMajorVersion

    //console.log("newAgrVersion", newAgrVersion)
    // DETERMINE THE NEW OWNER(S), WHO'S CC'ED, AND THE EDITMODE
    
    let newOwners = [], newCopied = [], editMode = null, toEntString = '', ccEntString = '';
    state.parties.forEach((p) => {
      if(readyToSign && signable) { // If you're initiating signing -- all parties will be owner of the last version
        newOwners.push(p.orgID);
        editMode = 'full';
      
      } else if(['copy'].includes(p.editMode)) {
        newCopied.push(p.orgID);
        ccEntString = ccEntString + p.legalName + ", ";
      } else if(['read','comment','edit','full'].includes(p.editMode)) {
        newOwners.push(p.orgID);
        editMode = p.editMode;
        toEntString = toEntString + p.legalName + ", ";
      }
    })
    if(ccEntString !== '') { ccEntString = ccEntString.slice(0,-2)}
    toEntString = toEntString.slice(0,-2)

    // DETERMINE THE NEW AGREEMENT STATUS
    let newAgrStatus = 
        (readyToSign && signable) ? 'Execution' : // both are marking as ready to sign - status change to Execution
        props.mainAg.agrStatus === 'Draft' ? 'Review' : // sending to Cpty from "Draft"
        'Negotiation'; // Any other scenario

    // DETERMINE THE NEW READY TO SIGN FLAGS
    let primReady = Boolean(currentParty) && currentParty.side === "primary" ? readyToSign : signable;
    let secReady = Boolean(currentParty) && currentParty.side === "secondary" ? readyToSign : signable;

    // LOOP THROUGH ALL APPLICABLE AGREEMENTS/EXHIBITS AND UPDATE + DUPLICATE INTO NEW VERSIONS
    state.agrs.forEach((ag) => {

      // Update the ag
      let newAg = ag;
      if(ag._id === props.mainAg._id) { // Update key parameters for the Main Body
        newAg.agrStatus = newAgrStatus 
        newAg.collabs = getDBUserArray('collabs')
        newAg.signers = getDBUserArray('signers')
      } 
      newAg.avOwners = newOwners
      newAg.lastUpdateBy = state.user._id

      // Order the backend to update applicable Agreement/Exhibits
    
      axios.put(state.settings.api + "agr/" + newAg._id, { agr: newAg}) // Update the Agreement/Exhibit in the Database
      .then((res) => { dispatch({ type: "UPDATE_AGR", payload: res.data.data}) // Update the Agreenent/Exhibit in state
      }).catch((err) => { // TODO: Serious issue - one of the agreements was not updated succesfully
      })

      let curav = state.avs.filter((av) => av.agrID === ag._id)[0];
      if(!Boolean(curav)) { // TODO : serious issue handling
    }

      let newAvDetail = {
        version: newAgrVersion,
        owner: newOwners,
        primReady: primReady,
        secReady: secReady,
        editMode: editMode,
      }

      // Order the Backend to duplicate the applicable Agreement Version with characteristics as provided
      axios.post(state.settings.api + 'agrv/duplicate', { oldAvid: curav._id, newAvDetail: newAvDetail })
      // TODO => Serious issue handling, when duplication fails, need to send email notification to support@ to investigate

      if(newCopied.length > 0) { // There is also at least one party in CC
        newAvDetail.owner = newCopied;
        newAvDetail.editMode = 'copy';
        // Order the backend to duplicate into a "copy" version of the new AV
        axios.post(state.settings.api + 'agrv/duplicate', { oldAvid: curav._id, newAvDetail: newAvDetail })
      }

      // TODO: REVIEW, DO WE STILL NEED THIS? YES I THINK SO @ 14-May
      // Set "CURRENT" AV for sign if applicable - above statement only creates and updates the "NEW" AV
      //if(sign) {
        //console.log("x2c - mark current as signReady")
        //props.updateAgrvMarkAsEdited(props.activeAgrVersion._id, true, primReady, secReady)
      //}

    })

    // TODO : Create comment and store - need some way to link back to the pen turn so it can be shown for CPTY
    // message
    //if(requestComment !== '' && requestComment.length > 1) {
    //props.createComment(

    // PREPARE EMAILS TO BE SEND
    let recipientsTo = collabs.filter((c) => newOwners.includes(c.orgID))
    let recipientsCC = collabs.filter((c) => newCopied.includes(c.orgID))
    let recipientsOwnFYI = collabs.filter((c) => currentParty.orgID === c.orgID && state.user._id !== c._id)

    if(readyToSign && signable) { // INITIATING SIGNING

      let paper = 
        Boolean(state.parties.filter((p) => p.partyID === 'party1')[0]) &&
        ['CA','US'].includes(getAddressForEntity(state.parties.filter((p) => p.partyID === 'party1')[0], 'billto').country) ?
            'LETTER' : 'A4'

      // Send request to backend to create a signRequest
      axios.post(state.settings.api + "signing/request", {
        mainAid: props.mainAg._id,
        avv: newAgrVersion,
        paper: paper, // Paper Format for the PDF
        provider: Boolean(props.mainAg.sigConfig) && Boolean(props.mainAg.sigConfig.provider) ? 
            props.mainAg.sigConfig.provider : 'skribble', // sigProvider
        quality: Boolean(props.mainAg.sigConfig) && Boolean(props.mainAg.sigConfig.quality) ? 
            props.mainAg.sigConfig.quality : 'SES', // sigQuality
      })
      .then((res) => {

        console.log("res from signReq", res)
        if(Boolean(res.data.success)) {

          // TODO: Upon reply from above, w/ signRequest succeeeded:

          // Send all collaborators and signers an FYI email
          //recipientsTo = allUsers.filter((au) => collabs.some((c) => c._id === au._id) || signers.some((s) => s._id === au._id))

          // Send appropriate signer(s) the request signature email
          //let recipientsSigners = ...todo

          // TODO : Smart field (re)calculation when the agreement is going to exec/inForce

          dispatch({ type: "UPDATE_AGREXEC", payload: res.data.data })
          reInitalize();
          props.closeDialog();
        } else { setErrMsg("Unable to execute Sign Request"); setLoading(false); }

      }).catch((err) => { setErrMsg("Unable to create Sign Request"); setLoading(false); })

    } else { // SEND TO COUNTERPARTY

      let mailConfig = {
        type: newAgrStatus === 'Review' ? 'informCptyUser' : 'penTurn', 
        whiteLabel: props.mainAg.whiteLabel, 
        agr: props.mainAg, 
        senderLegalName: currentParty.legalName, 
        toEntString: toEntString, 
        partyFullString: partyFullString,
        readyToSign: readyToSign, 
        requestComment: message,
      }
  
      // SHARE AGR
      recipientsTo.forEach((recipient) => {
        mailConfig.recipient = recipient;
        axios.post(state.settings.api + "mail/informagr", mailConfig)
      })
  
      // SHARE A COPY OF AGR
      mailConfig.type = 'informCopyRecipient';
      mailConfig.requestComment = null;
      recipientsCC.forEach((recipient) => {
        mailConfig.recipient = recipient;
        axios.post(state.settings.api + "mail/informagr", mailConfig)
      })
  
      // INFORM COLLEAGUES OF SHARING
      mailConfig.type = newAgrStatus === 'Review' ? 'informCptyUserFyi' : 'penTurnFyi';
      mailConfig.requestComment = message;
      recipientsOwnFYI.forEach((recipient) => {
        mailConfig.recipient = recipient;
        axios.post(state.settings.api + "mail/informagr", mailConfig)
      })

      setLoading(false);
      reInitalize();
      props.closeDialog();
      navigate('/dashboard/' + (state.user.role === 'Counterparty' ? '0' : '1'))

    }
  }

  const handleSubmitUserForm = (child) => {

    if(Boolean(creatingUser) && Boolean(creatingUser.orgID)) {

      setErrMsg(null);
      setLoading(true);

      createUser(child)
      .then((resUser) => {

        if(['collaborator'].includes(creatingUser.type)) { // Add newly created user as a collab
          let orgCollabs = collabs.filter((c) => c.orgID === creatingUser.orgID)
          orgCollabs.push(resUser);
          handleChangeCollabs(creatingUser.orgID, orgCollabs)

        } else if(['signer'].includes(creatingUser.type)) { // Add newly created user as a signer

          console.log("Add newly created user as a signer")
          handleSelectNewSigner(resUser)

        }

        setCreatingUser(null);
        setLoading(false)

      }).catch((err) => { setErrMsg(err); setLoading(false) } )

    } else { setErrMsg('Please complete all fields' + (state.parties.length > 2 ? " and select a counterparty" : "")) }

  }

  async function createUser(userInputData) {

    return new Promise(async (resolve, reject) => {

      if (!allUsers.some(au => au.email === userInputData.email)) { // Does not yet exist in the allUser Base

        axios.get(state.settings.api + "user/email/" + userInputData.email)
        .then((resUserVerify) => {

          // To verify if the user already exists in the database
          if(!Boolean(resUserVerify.data)) {
            return reject("Unable to verify if the user already exists - please provide a valid email")
          } else if(Boolean(resUserVerify.data.data) && resUserVerify.data.data.role === 'Counterparty') {
            return reject("This user is already setup for a different organisation, please contact canveo support for help.")
          } else if (Boolean(resUserVerify.data.data)) {
            return reject("This user is setup for a Canveo customer, please contact canveo support for help.")
          } else { // User not found - thus proceed to add

            let userParty = Boolean(state.parties.filter((p) => p.orgID === creatingUser.orgID)[0]) ?
                              state.parties.filter((p) => p.orgID === creatingUser.orgID)[0] : null
                
            if(Boolean(userParty)) {

              let newUser = {
                firstName: userInputData.firstName,
                lastName: userInputData.lastName,
                displayName: userInputData.firstName + ' ' + userInputData.lastName,
                email: userInputData.email.toLowerCase().trim(),
                password: generateRandomString(20),
                title: userInputData.title,
                phone: Boolean(userInputData.phone) ? userInputData.phone : '',
                orgID: userParty.orgID,
                photoURL: '',
                role: 'Counterparty',
                readOnly: false,
                creationBy: state.user._id,
                creationDate: new Date().toISOString(),
                active: true,
                sourceData: Boolean(userInputData.sourceData) && Boolean(userInputData.sourceData.source) &&
                    Boolean(userInputData.sourceData.sourceID) ? userInputData.sourceData : null
              }

              axios.post(state.settings.api + "user", { user: newUser})
              .then((resUser) => { // got a msg from the server

                if(Boolean(resUser.data.success)) {

                  let createdUser = resUser.data.data;
                  // TODO : Create notification

                  setAllUsers(aUsers => [...aUsers, createdUser]) // Add to local allUsers array
                  if(createdUser.orgID === state.org._id) { // Add to state is of the current Org
                    dispatch({ type: "ADD_USERS", payload: createdUser })
                    setStagedForInvitationEmail(stagedForInv => [...stagedForInv, createdUser])
                  }

                  return resolve(createdUser) // resolve the promise with the newly created user

                } else { return reject("Unable to succesfully create the new user") }
              }).catch(function (err) { return reject("An error occurred while creating the user") })
            } else { return reject("Unable to identify the company for the user") }
          }
        }).catch((err) => { return reject("An error occurred while verifying the user") }) 
      } else { return reject("This user (email address) is already created, you can select it instead.") }
    })
  }

  const handleSelectNewSigner = (newSigner) => {
    
    console.log("newSigner", newSigner)

    let party = state.parties.filter((p) => p.orgID === addingSigner)[0]
    newSigner.partyID = party.partyID
    newSigner.order = signingOrder ? signers.length : null;
    newSigner.entityName = Boolean(party) ? party.legalName : 'ENTITY NAME';

    setSigners(s => [...s, newSigner])
    setAddingSigner(null)
    setCreatingUser(null)
    setCollabOrSignerUpdate(true)

  }

  const handleChangeCollabs = (orgID, selectedCollabs) => {

    let newCollabs = collabs.filter((c) => c.orgID !== orgID)
    newCollabs = newCollabs.concat(selectedCollabs)
    setCollabs(newCollabs)
    setCollabOrSignerUpdate(true);

  }

  const getIconForEditMode = (editMode, color) => {

    let i = 
      ['currentOrg'].includes(editMode) ? faInfoCircle :
      ['full'].includes(editMode) ? faStarCircle :
      ['edit'].includes(editMode) ? faPenCircle :
      ['comment'].includes(editMode) ? faComment :
      ['read'].includes(editMode) ? faCircleBookOpen : 
      ['copy'].includes(editMode) ? faClone : 
      ['none'].includes(editMode) ? faCircleXmark : faCircleQuestion

    let c = ['currentOrg', 'tbd'].includes(editMode) ? theme.palette.grey[400] : 
      ['none'].includes(editMode) ? theme.palette.grey[200] : color

    return (
      <FontAwesomeIcon icon={i} 
      style={{ padding: '10px', fontSize: '34px', color: c}} />
    )

  }

  // Changing the edit mode for a specific party - this may have implications for multi-party
  const handleEditModeChange = (entid, editMode) => {

    let newParties = state.parties

    if(state.parties.length > 2 && state.parties.every((p) => ['tbd','currentOrg', 'none'].includes(p.editMode))) {
      // This is the first selection - set all parties to "none"
      newParties.forEach((np) => {
        if(['tbd'].includes(np.editMode)) {
          np.editMode = 'none';
        }
      })
    } else if (state.parties.length > 2 && ['read', 'comment', 'edit', 'full'].includes(editMode) &&
    state.parties.some((p) => p._id !== entid && ['read', 'comment', 'edit', 'full'].includes(p.editMode))) {

      // You had multiple receiving parties, a change to editMode for one need to replicate to the others
      newParties.filter((p) => p._id !== entid && ['read', 'comment', 'edit', 'full'].includes(p.editMode))
      .forEach((np) => {
        np.editMode = editMode;
      })

    } else if (state.parties.length > 2 && editMode === 'none' && 
    state.parties.every((p) => p._id === entid || ['tbd','currentOrg', 'none', 'copy'].includes(p.editMode)) &&
    state.parties.some((p) => p._id !== entid && ['copy'].includes(p.editMode))) {

      // You are changing editMode to 'none' and only partie(s) with 'copy' remain, change those to 'none' as well
      newParties.filter((p) => p._id !== entid && ['tbd', 'copy'].includes(p.editMode))
      .forEach((np) => {
        np.editMode = editMode;
      })

    }

    let idx = newParties.findIndex((np) => np._id === entid)
    newParties[idx].editMode = editMode

    if(newParties.every((p) => ['currentOrg', 'none', 'copy'].includes(p.editMode))) {
      // If the remaining situation is: only none or currentOrg => change back to 'tbd'
      newParties.filter((np) => np.orgID !== state.org._id).forEach((np) => {
        np.editMode = 'tbd';
      })

    }

    //setParties(newParties);
    dispatch({ type: "INIT_PARTIES", payload: newParties }) // Update State
    setAnchorElEditMode(null);

  }

  const handleSignBoxAction = (actionType, actionDetail) => {

    if(['remove'].includes(actionType)) {
      let newSigners = signers.filter((s) => s._id !== actionDetail);
      setSigners(newSigners);
    }
  }

  const handleSigQualitySelect = (newSigQuality) => {
    console.log("todo new sig quality", newSigQuality)
    setAnchorElSig(null);
  }
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const onDragEnd = (result) => { // Loop through the ordered list of signatories and assign to the respective objects
      if (!result.destination) { return; }
      if (result.destination.index === result.source.index) { return; }

      let ol = signers.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
      const reorderedSigners = reorder(ol, result.source.index, result.destination.index);
      
      let newSigners = []
      reorderedSigners.forEach((signer, i) => {
          newSigners.push({...signer, order: i})
      })
      setSigners([...newSigners]);
      setCollabOrSignerUpdate(true);
  }

  const handleSigningOrderChange = () => {
    let newSigners = []

    if(signingOrder) { // Changing from Signing Order to no Signing order - remove the order fields
        signers.forEach((signer) => {
            newSigners.push({...signer, order: null})
        })
    } else { // Changing from no Signing Order to Signing order - define the default order fields
        signers
        .sort((a,b) => (a.orgID > b.orgID ? 1 : a.orgID < b.orgID ? -1 : 0))
        .forEach((signer, i) => {
            newSigners.push({...signer, order: i})
        })
    }
    setSigners(newSigners)
    setSigningOrder(!signingOrder)
    setCollabOrSignerUpdate(true);
}

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>
            {loading ? "Loading..." :
                "Send Agreement"}
        </DialogTitle>
        <DialogContent>
          
            {loading ? 
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 12}}>

                <CanveoCircularProgress />

            </Box>
            :
            <Box sx={{my: 2}}>

              <Grid container direction="column" justifyContent={"center"} alignItems="center" sx={{minHeight: '360px'}}>
                <Grid item sx={{mt:0, mb:1}}>
                    <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    />
                </Grid>
                <Grid item sx={{
                  textAlign: 'center',
                  mb: Boolean(state.parties) && state.parties.length > 2 ? 3 : 4
                }}>
                  <Typography variant="h4">
                    {Boolean(creatingUser) ? 
                      "Create " + creatingUser.type :
                    [0].includes(activeStep) ?
                      "Manage Recipients" :
                    [1].includes(activeStep) ?
                      "Manage Signers" :
                    [2].includes(activeStep) ?
                      "Add Message" : ""}
                  </Typography>

                  {errMsg !== null ? // An error exists - show it
                  <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3}}>
                      <Typography align="center" color="error">{errMsg}</Typography>
                  </Box>:''}

                </Grid>
                <Grid item sx={{mb:0, px:1, width: '100%'}}>

                  {// Creating a new User *************************************************
                  Boolean(creatingUser) ?
                  <Box sx={{px: isSmUp ? 3 : 0}}>

                    <Grid container direction="column" alignItems="center" justifyContent="center">

                      {oidsForWhichYouCanAddUser.length > 1 ?
                      <Grid item sx={{mb:2}}>

                        <Box style={styles.sigQualityBox}>
                        <Typography variant="body1" onClick={e => setAnchorElCpty(e.currentTarget)}>
                            {/*parties.filter((p) => p.ent.entityType !== 'Subsidiary' && (p.partyID === addingCollaborator || p.partyID === addingSignatory))[0] !== undefined ?
                                trunc(parties.filter((p) => p.ent.entityType !== 'Subsidiary' && (p.partyID === addingCollaborator || p.partyID === addingSignatory))[0].ent.legalName, 30) :
                              */
                            Boolean(state.parties.filter((p) => oidsForWhichYouCanAddUser.includes(p.orgID) && p.orgID === creatingUser.orgID)[0]) ?
                              state.parties.filter((p) => oidsForWhichYouCanAddUser.includes(p.orgID) && p.orgID === creatingUser.orgID)[0].legalName :                          
                              "Select the counterparty"}
                            <FontAwesomeIcon icon={faCaretDown} style={styles.sigQualityCaret} />
                        </Typography>
                        </Box>
                        <Menu
                        anchorEl={anchorElCpty}
                        keepMounted
                        open={Boolean(anchorElCpty)}
                        onClose={e => setAnchorElCpty(null)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        >
                        {state.parties // Show only parties where the current user is the owning org or when the current is a cpty and its for themselves
                        .filter((p) => oidsForWhichYouCanAddUser.includes(p.orgID))
                        .map((p) => (
                            <MenuItem key={p._id} 
                            onClick={e => { setCreatingUser({...creatingUser, orgID: p.orgID}); setAnchorElCpty(null); }}>
                              {p.legalName}
                            </MenuItem>
                        ))}
                            
                        </Menu>


                      </Grid>
                      :''}

                      <Grid item sx={{mt:1}}>

                        <UserForm
                        handleSubmit={handleSubmitUserForm}
                        hideFields={['phone','userRole']}
                        disableFields={[]}
                        initialValues={{
                            title: '',
                            email: '',
                            firstName: '',
                            lastName: '',
                            phone: '',
                            role: 'Counterparty',
                            readOnly: false,
                        }}
                        />
                      </Grid>

                    </Grid>
                  </Box>
                  :
                  // Adding a signer ****************************************************
                  Boolean(addingSigner) ?
                  <Box sx={{px: isSmUp ? 3 : 0}}>

                    <Grid container direction="column" alignItems="center" justifyContent="center">

                      <SelectUserForOrg
                        orgID={addingSigner}
                        cpUsers={Boolean(addingSigner) ? allUsers.filter((au) => au.orgID === addingSigner) : []}
                        handleSelectUser={e => handleSelectNewSigner(e)}
                        hiddenUsers={allUsers.filter((au) => signers.some((s) => s._id === au._id))}
                        userSelected={null}
                      />
                      
                      {state.parties.filter((p) => p.orgID === addingSigner && oidsForWhichYouCanAddUser.includes(p.orgID))[0] !== undefined/* &&
                      (state.parties.filter((p) => p.orgID === addingSigner)[0].orgID === state.org._id ||
                      state.parties.filter((p) => p.orgID === addingSigner)[0].ownerOrgID === state.org._id)*/
                      ? 
                      <Box align="center" mt={2}>
                      <Button color={getColorForPartyID(state.parties.filter((p) => p.orgID === addingSigner)[0].partyID, 'theme')} 
                      onClick={e => setCreatingUser({type:'signer', orgID: addingSigner})}>
                          <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp;Create a signer for {trunc(state.parties.filter((p) => p.orgID === addingSigner)[0].legalName, 25)}
                      </Button>
                      </Box>
                      : '' }

                    </Grid>

                    
                  </Box>
                  : 
                  // Manage Recipients ***************************************************
                  [0].includes(activeStep) ?
                  <>
                  {state.parties
                  .map((p) => {
                    return(
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{width: '100%', my:2}} key={p._id}>
                      <Grid item xs={2}>
                        <Tooltip title={getEditModeOption(p.editMode).desc} placement="left">
                        <span>
                        <IconButton 
                        onClick={e => setAnchorElEditMode({target: e.currentTarget, pid: p._id})}
                        disabled={p.editMode === 'currentOrg'}
                        >
                          {getIconForEditMode(p.editMode, getColorForPartyID(p.partyID))}
                        </IconButton>
                        </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={10}>

                        {Boolean(allUsers) && allUsers.filter((u) => u.active && u.orgID === p.orgID).length > 0 ?

                        <Autocomplete
                        multiple
                        disableClearable
                        openOnFocus
                        onChange={(e, value) => handleChangeCollabs(p.orgID, value)}
                        options={Boolean(allUsers) ? allUsers.filter((u) => u.active && u.orgID === p.orgID) : []}
                        value={Boolean(collabs) ? collabs.filter((c) => c.orgID === p.orgID) : []}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.displayName}
                        renderOption={(props, option) => (
                        //renderOption={(option, { selected }) => 
                        <Box component="li" {...props}>
                            <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={collabs.some((c) => c._id === option._id)}
                            />
                            <Typography variant="body1" style={{fontWeight: '500'}}>
                            {trunc(option.displayName + " (" + option.title + ")", 28)}<span style={styles.optionEmail}>{trunc(option.email,20)}</span>
                            </Typography>
                        </Box>)
                        }
                        style={{width: '100%'}} 
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip {...getTagProps({ index })}
                                //variant="outlined"
                                sx={userSelectChipStyle(p.editMode === 'none' ? notReceivingColor : getColorForPartyID(p.partyID))}
                                label={<Typography style={{padding: '5px', fontWeight: '600'}}>{trunc(option.displayName,22)}</Typography>}
                                avatar={
                                <Avatar 
                                src={option.photoURL} 
                                style={{
                                  opacity: p.editMode === 'none' ? 0.2 : 1.0,
                                  backgroundColor: p.editMode === 'none' ? theme.palette.grey[600] : theme.palette.grey[800], 
                                  color: theme.palette.primary.contrastText, 
                                  width: '28px', height: '28px'
                                }} >
                                  {option.firstName[0] + option.lastName[0]}
                                </Avatar>}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} 
                            variant="outlined" 
                            label={p.legalName} 
                            style={styles.addCollabTextField} 
                            placeholder="Add collaborators" 
                            color={"primary"} 
                            />
                        )}
                        />
                        :
                        <Box sx={styles.addCollabBox}>
                          <Button variant="contained" disableElevation color={getColorForPartyID(p.partyID, 'theme')}
                          style={['none'].includes(p.editMode) ? { backgroundColor: notReceivingColor, color: '#ffffff' } : {}}
                          onClick={e => setCreatingUser({ type: 'collaborator', orgID: p.orgID })}>
                            <FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;
                            Create {isSmUp ? "collaborator " : ""}for {trunc(p.legalName, 20)}
                          </Button>
                        </Box>
                        }

                      </Grid>
                    </Grid>)
                    
                  })}
                  </>
                  :
                  // Manage Signers ******************************************************
                  [1].includes(activeStep) ?
                  <>
                  <Box>
                    {/*
                      <Grid item>
                      <span style={styles.smallButBolder}>Provider:</span>
                    </Grid>*/}

                    {Boolean(props.mainAg.sigConfig) ?

                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
                      <Grid item>
                        <Box sx={styles.sigQualityBox}>
                        <Typography variant="body1" onClick={e => setAnchorElSig(e.currentTarget)}
                        
                        /*onClick={handleSigQualityOpen}*/>
                            {/*activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined && 
                            activeAgr.sigQuality === "SES" ? "Standard Signature (SES)" :
                            activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined  && 
                            activeAgr.sigQuality === "AES" ? "Advanced Signature (" + activeAgr.sigQuality + ")" :
                            activeAgr !== undefined && activeAgr !== null && activeAgr.sigQuality !== undefined  && 
                            activeAgr.sigQuality.startsWith("QES") ? "Qualified Signature (" + activeAgr.sigQuality + ")" :*/
                            Boolean(props.mainAg.sigConfig.quality) && ['SES'].includes(props.mainAg.sigConfig.quality) ? 
                                "Standard Signature (SES)" :
                            Boolean(props.mainAg.sigConfig.quality) && ['AES'].includes(props.mainAg.sigConfig.quality.substring(0,3)) ? 
                                "Advanced Signature (SES)" :
                            Boolean(props.mainAg.sigConfig.quality) && ['QES'].includes(props.mainAg.sigConfig.quality.substring(0,3)) ? 
                                "Qualified Signature (SES)" :
                                "SES"}
                            <FontAwesomeIcon icon={faCaretDown} style={styles.sigQualityCaret} />
                        </Typography>
                        </Box>
                        <Menu
                        anchorEl={anchorElSig}
                        keepMounted
                        open={Boolean(anchorElSig)}
                        onClose={e=> setAnchorElSig(null)}
                        >
                          <MenuItem onClick={e => handleSigQualitySelect('SES')} style={styles.menuOption2}>
                            Standard Signature (SES)
                          </MenuItem>
                          {/*['skribble', 'swisscom'].includes(signMethod) ?
                          <MenuItem onClick={e => handleSigQualitySelect('AES')} style={styles.menuOption2}>Advanced Signature (AES)</MenuItem>:''}
                          {['skribble', 'swisscom'].includes(signMethod) ?
                          <Typography style={{margin: '8px 0px 5px 16px', fontWeight: '500'}}>Qualified Signature (QES)</Typography>: ''}
                          {['skribble', 'swisscom'].includes(signMethod) ?
                          <MenuItem onClick={e => handleSigQualitySelect('QES-EU')} style={styles.menuOption3}>
                              <ListItemAvatar>
                                  <Avatar src={flag_eu} style={{width: '20px', height: '20px'}} />
                              </ListItemAvatar>
                              <ListItemText>EU<span style={styles.lighter}>eIDAS</span></ListItemText>
                          </MenuItem>:''}
                          {['skribble', 'swisscom'].includes(signMethod) ?
                          <MenuItem onClick={e => handleSigQualitySelect('QES-CH')} style={styles.menuOption3}>
                              <ListItemAvatar>
                                  <Avatar src={flag_ch} style={{width: '20px', height: '20px'}} />
                              </ListItemAvatar>
                              <ListItemText>Switzerland<span style={styles.lighter}>ZertES</span></ListItemText>
                          </MenuItem>:''*/}
                        </Menu>                    
                      </Grid>
                      {/*
                      <Grid item>  
                        <img src={
                          ['canveo'].includes(props.mainAg.sigConfig.provider) ? canveo :
                          ['docusign'].includes(props.mainAg.sigConfig.provider) ? docusign :
                          ['hellosign'].includes(props.mainAg.sigConfig.provider) ? hellosign :
                          ['skribble'].includes(props.mainAg.sigConfig.provider) ? skribble : hellosign} 
                        alt="Signing provider" width={"90"} />
                      </Grid>*/}
                    </Grid>
                    :'Unable to display signing config'}
                  </Box>

                  {signingOrder ? 
                  <Grid item container justifyContent="center" alignItems="flex-start" sx={{mt: 3, minHeight: '120px'}}>
                      <Box sx={{my:2}}>
                      <DragDropContext onDragEnd={onDragEnd}>

                          <Column id="1" 
                          signers={signers}
                          parties={state.parties}
                          sigConfig={props.mainAg.sigConfig}
                          activeUser={state.user}
                          handleSignBoxAction={handleSignBoxAction} />

                      </DragDropContext>
                      </Box>
                      {/*
                      <Box mb={1}>
                        <Box sx={{mt:1, textAlign: 'center'}}>
                        {// Display add signer button for parties
                        state.parties
                        .filter((p) => p.side === "secondary")
                        .map((p) => (
                          <Button key={p._id}
                          color={getColorForPartyID(p.partyID, 'theme')} style={{marginBottom: '5px'}}
                          onClick={e => setAddingSigner(p.orgID)}>
                            <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp;
                            Add signer for {trunc(p.legalName, 13)}
                          </Button>
                        ))}
                        </Box>
                      </Box>
                      */}
                  </Grid>
                  : 
                  // SignBlocks for scenario without a signing order
                  <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{mt: 3, minHeight: '120px'}}>
                    <Grid item xs={12} sm={6} container justifyContent="center">
                      {// Display Signers on primary side that have been setup
                      signers
                      .sort((a, b) => (a.partyID > b.partyID) ? 1 : -1)
                      .filter((s) => state.parties.filter((p) => p.orgID === s.orgID)[0].side === "primary")
                      .map((signer) => (
                        <RenderSignBox 
                        key={signer._id}
                        signer={signer}
                        partyID={Boolean(state.parties.filter((p) => p.orgID === signer.orgID)[0]) ? 
                          state.parties.filter((p) => p.orgID === signer.orgID)[0].partyID : null}
                        sigConfig={props.mainAg.sigConfig}
                        canRemove={signer.orgID === state.user.orgID ||
                          (Boolean(state.parties.filter((p) => p.orgID === signer.orgID)[0]) &&
                          state.parties.filter((p) => p.orgID === signer.orgID)[0].ownerOrgID === state.user.orgID)}
                        handleSignBoxAction={handleSignBoxAction}
                        // TODO: ability to update entity name
                        // TODO: ability to update phone in position
                        />
                      ))}

                      <Box sx={{mt:1, textAlign: 'center'}}>

                        {// Display add signer button for parties
                        state.parties
                        .filter((p) => p.side === "primary")
                        .map((p) => (
                          <Button key={p._id}
                          color={getColorForPartyID(p.partyID, 'theme')} style={{marginBottom: '5px'}}
                          onClick={e => setAddingSigner(p.orgID)}>
                            <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp;
                            Add signer for {trunc(p.legalName, 13)}
                          </Button>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} container justifyContent="center">
                      {// Display Signers on secondary side that have been setup
                      signers
                      .sort((a, b) => (a.partyID > b.partyID) ? 1 : -1)
                      .filter((s) => state.parties.filter((p) => p.orgID === s.orgID)[0].side === "secondary")
                      .map((signer) => (
                        <RenderSignBox 
                        key={signer._id} 
                        signer={signer}
                        partyID={Boolean(state.parties.filter((p) => p.orgID === signer.orgID)[0]) ? 
                          state.parties.filter((p) => p.orgID === signer.orgID)[0].partyID : null}
                        sigConfig={props.mainAg.sigConfig}
                        canRemove={signer.orgID === state.user.orgID ||
                          (Boolean(state.parties.filter((p) => p.orgID === signer.orgID)[0]) &&
                          state.parties.filter((p) => p.orgID === signer.orgID)[0].ownerOrgID === state.user.orgID)}
                        handleSignBoxAction={handleSignBoxAction}
                        // TODO: ability to update entity name
                        // TODO: ability to update phone in position
                        />
                      ))}

                      <Box sx={{mt:1, textAlign: 'center'}}>

                        {// Display add signer button for parties
                        state.parties
                        .filter((p) => p.side === "secondary")
                        .map((p) => (
                          <Button key={p._id}
                          color={getColorForPartyID(p.partyID, 'theme')} style={{marginBottom: '5px'}}
                          onClick={e => setAddingSigner(p.orgID)}>
                            <FontAwesomeIcon icon={faPlusCircle} />&nbsp;&nbsp;
                            Add signer for {trunc(p.legalName, 13)}
                          </Button>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  }
                  </>
                  :
                  // Add Message *********************************************************
                  [2].includes(activeStep) ?
                  <>
                  <Grid container direction="column" justifyContent="center" alignItems="center" sx={{px: isMdUp ? 6 : isSmUp ? 4 : 0, width: '100%'}}>

                    <Grid item xs={12} >
                      <Tooltip title={"Once both sides are ready, the agreement can be sent for e-signature."}>
                        <Box align="left" sx={styles.signBox}>
                          <FormControlLabel
                          onChange={e => setReadyToSign(!readyToSign)}
                          control={<StyledCheckbox checked={readyToSign} color={getColorForPartyID(currentParty.partyID, 'theme')} />}
                          label={
                              <Typography variant="subtitle2" style={{fontWeight: '700', marginLeft: readyToSign ? '20px' : '24px', lineHeight: 1.3, color: readyToSign ? theme.palette.grey[900] : theme.palette.grey[500]}}>
                              {currentParty.legalName + " is "}<span>ready to sign</span><span style={{fontWeight: '300', marginLeft: '4px'}}>{" this version of the agreement"}</span>
                              </Typography>}
                          labelPlacement="end" />
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item sx={{mt: 2, width: '100%'}} xs={12}>

                      <TextField
                        id="outlined-textarea"
                        placeholder="Add message to counterparty..."
                        multiline
                        autoFocus
                        rows={5}
                        style={{width: '100%'}}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />

                    </Grid>

                  </Grid>
                  </>
                  :''}

                </Grid>
              </Grid>
              <Menu
              id="menu-editMode"
              anchorEl={Boolean(anchorElEditMode) ? anchorElEditMode.target : null}
              keepMounted
              open={Boolean(anchorElEditMode) && Boolean(anchorElEditMode.target)}
              onClose={e => setAnchorElEditMode(null)}
              >
              {[
                ...(state.parties.length > 2 && state.parties.some((p) => ['read', 'comment', 'edit', 'full'].includes(p.editMode))) ? 
                    [{ id: 'none', icon: faCircleXmark }] : [],
                ...(state.parties.length > 2 && state.parties.some((p) => ['read', 'comment', 'edit', 'full'].includes(p.editMode)) &&
                !(Boolean(anchorElEditMode) && Boolean(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0]) && // Not when this party has read/comment/edit/full
                ['read', 'comment', 'edit', 'full'].includes(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode))
                ) ? 
                    [{ id: 'copy', icon: faClone }] : [],

                ...(state.parties.length === 2 || // For simple two-party agreement
                state.parties.every((p) => ['currentOrg', 'tbd', 'none'].includes(p.editMode)) || // Nothing has been selected yet
                state.parties.some((p) => ['read'].includes(p.editMode)) || // When another party is in Read-Mode
                (Boolean(anchorElEditMode) && Boolean(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0]) && // When this party has read/comment/edit/full
                ['read', 'comment', 'edit', 'full'].includes(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode))) ? 
                    [{ id: 'read', icon: faCircleBookOpen }] : [],

                ...(state.parties.length === 2 || // For simple two-party agreement
                state.parties.every((p) => ['currentOrg', 'tbd', 'none'].includes(p.editMode)) || // Nothing has been selected yet
                state.parties.some((p) => ['comment'].includes(p.editMode)) || // When another party is in Comment-Mode
                (Boolean(anchorElEditMode) && Boolean(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0]) && // When this party has read/comment/edit/full
                ['read', 'comment', 'edit', 'full'].includes(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode))) ? 
                    [{ id: 'comment', icon: faComment }] : [],

                ...(state.parties.length === 2 || // For simple two-party agreement
                state.parties.every((p) => ['currentOrg', 'tbd', 'none'].includes(p.editMode)) || // Nothing has been selected yet
                state.parties.some((p) => ['edit'].includes(p.editMode)) || // When another party is in Edit-Mode
                (Boolean(anchorElEditMode) && Boolean(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0]) && // When this party has read/comment/edit/full
                ['read', 'comment', 'edit', 'full'].includes(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode))) ?
                    [{ id: 'edit', icon: faPenCircle }] : [],

                ...(state.parties.length === 2 || // For simple two-party agreement
                state.parties.every((p) => ['currentOrg', 'tbd', 'none'].includes(p.editMode)) || // Nothing has been selected yet
                state.parties.some((p) => ['full'].includes(p.editMode)) || // When another party is in Full-Mode
                (Boolean(anchorElEditMode) && Boolean(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0]) && // When this party has read/comment/edit/full
                ['read', 'comment', 'edit', 'full'].includes(state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode))) ?
                    [{ id: 'full', icon: faStarCircle }] : []]
              .map((item) => (
                <MenuItem style={{width: '310px'}} key={item.id} 
                onClick={e => handleEditModeChange(anchorElEditMode.pid, item.id)}
                selected={
                  Boolean(anchorElEditMode) &&
                  state.parties.filter((p) => p._id === anchorElEditMode.pid)[0] !== undefined &&
                  state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode === item.id
                }>
                <ListItemIcon style={{width: '46px'}}>
                    <FontAwesomeIcon icon={item.icon} style={{fontSize: '22px', 
                    color: Boolean(anchorElEditMode) &&
                    state.parties.filter((p) => p._id === anchorElEditMode.pid)[0] !== undefined &&
                    state.parties.filter((p) => p._id === anchorElEditMode.pid)[0].editMode === item.id ?
                        theme.palette.primary.main : theme.palette.grey[600] }} />
                </ListItemIcon>
                <ListItemText>
                  <span style={{fontWeight: '700'}}>{getEditModeOption(item.id).title}</span>
                  <span style={{display: 'block', width: '200px', fontSize: '14px', color: theme.palette.grey[600]}}>
                    {getEditModeOption(item.id).desc1}<br/>
                    {getEditModeOption(item.id).desc2}
                  </span>
                </ListItemText>
              </MenuItem>
              ))}
              </Menu>
            </Box>
            }
        </DialogContent>
        <DialogActions>

          {[0,1,2].includes(activeStep) ?
          <Box sx={{marginRight: 'auto'}}>
            <Button 
            disabled={loading}
            onClick={
              Boolean(creatingUser) ?
                  e => { setCreatingUser(null); setAddingSigner(null) } :
              Boolean(addingSigner) ? 
                  e => setAddingSigner(null) :
              [1,2].includes(activeStep) && props.trigger !== 'initiatesigning' ? 
                  handleBack : 
                  closeDialog
            }>
              {Boolean(creatingUser) ||
              Boolean(addingSigner) || 
              ([1,2].includes(activeStep) && props.trigger !== 'initiatesigning') ?
                <><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Back</>
              :
              <>Cancel</>}
            </Button>
          </Box>
          :''}

          {[0,1,2].includes(activeStep) ?
          <Box>
            { // Add Collaborator Button
            [0].includes(activeStep) && Boolean(state.parties) && 
            oidsForWhichYouCanAddUser.length > 0 &&
            !Boolean(creatingUser)
            ?
              <Button 
              disabled={loading} // Only allow adding collab if cpty is not a subscriber
              onClick={e => setCreatingUser({
                type: 'collaborator',
                orgID: state.parties.filter((p) => oidsForWhichYouCanAddUser.includes(p.orgID)).length === 1 ?
                    state.parties.filter((p) => oidsForWhichYouCanAddUser.includes(p.orgID))[0].orgID : 'tbd'
              })}>
                <FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;Create collaborator
              </Button>
            : // Signing Order checkbox
            [1].includes(activeStep) && Boolean(state.parties) 
            ?
              <FormControlLabel 
                control={<Checkbox size="small" color="primary" checked={signingOrder} onChange={handleSigningOrderChange} />}
                disabled={!['cust'].includes(state.org.orgType)} 
                label={<Typography variant="subtitle2">Signing order</Typography>}
              />
            :''}

            <Button 
            variant="contained" 
            disableElevation
            style={{marginLeft: '12px'}}
            disabled={
              loading || 
              Boolean(creatingUser) ||
              // conditions for the first step (Manage recipients)
              ([0].includes(activeStep) && 
                  (
                  !state.parties.some((p) => ['read', 'comment', 'edit', 'full'].includes(p.editMode)) || // Needs to have at least one "editMode" config
                  !state.parties.every((p) => 
                    (['read', 'comment', 'edit', 'full', 'copy'].includes(p.editMode) && // The party is a recipient
                    collabs.some((c) => c.orgID === p.orgID)) // Then at least one collab exists
                    ||
                    (['none', 'currentOrg'].includes(p.editMode))) // The party is not a recipient
                  )
              )
            }
            onClick={
              [0,1].includes(activeStep) && props.trigger !== 'initiatesigning' ? 
                  handleNext : 
                  handleSend}
            >
              {signable && readyToSign ?
                  <>Initiate Signing&nbsp;&nbsp;<FontAwesomeIcon icon={faSignature} /></> :

              [0,1].includes(activeStep) ?
                  <>Next&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} /></> :
                  <>Send to counterparty&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></>}
              
            </Button>
          </Box>
          :''}
        </DialogActions>
      </Dialog>
    </div>
  );
}