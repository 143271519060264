import { $setSelection } from "lexical";
import { $createRedlineNode } from '../nodes/RedlineNode.js';

// Creates a redline for a specific selection, if selection is collapsed, specific handling occurs for backspace/delete
export function handleDelRedLineForSelection(newRedline, partyID, selection, selectionType) {

    const anchor = selection.anchor;
    const focus = selection.focus;
    const isBackward = selection.isBackward();

    const startKey = isBackward ? focus.key : anchor.key;
    let startOffset = (isBackward ? focus.offset : anchor.offset) - (['pointBack'].includes(selectionType) ? 1 : 0);
    const endKey = isBackward ? anchor.key : focus.key;
    let endOffset = (isBackward ? anchor.offset : focus.offset) + (['pointForward'].includes(selectionType) ? 1 : 0);

    let createdNode = null;
    let updatedRedlineNode = null;
    let isTextRemoval = false; // When you're deleting your own (party's) "add" redline - impacts selection handling
    let usingNextNode = null; // Declaring here, as relevant for the deselection at the end

    selection.getNodes().forEach((node) => {

      let isAtNodeStart = 0 === startOffset
      let isAtNodeEnd = node.getTextContent().length < endOffset

      let prevSibling = node.getPreviousSibling();
      let nextSibling = node.getNextSibling();

      // When you have a point selection and press fwd delete: it may need to consider the current Node to possibly MERGE
      usingNextNode = null
      if(['pointForward'].includes(selectionType) && isAtNodeEnd && !Boolean(nextSibling)) {
        return;
      } else if(['pointForward'].includes(selectionType) && isAtNodeEnd) {
        prevSibling = node;
        node = nextSibling;
        startOffset = 0;
        endOffset = 1;
        isAtNodeStart = true;
        usingNextNode = nextSibling;
      }

      if(['text'].includes(node.getType())) {

        let mergeBefore = false, mergeAfter = false;

        // Check for adjacent redlines that need to be merged
        if(isAtNodeStart && Boolean(prevSibling) && 
        ['redline'].includes(prevSibling.getType()) &&
        isMatchingRedline({...newRedline, redlineType: 'del'}, prevSibling)) { // NODE BEFORE
          mergeBefore = true;
        }

        if(isAtNodeEnd && Boolean(nextSibling) &&
        ['redline'].includes(nextSibling.getType()) &&
        isMatchingRedline({...newRedline, redlineType: 'del'}, nextSibling)) { // NODE AFTER
          mergeAfter = true;
        }

        let nodeToReplace = null;
        const nodeKey = node.getKey();

        if(
          (nodeKey !== startKey || startOffset === 0) && 
          (nodeKey !== endKey || endOffset >= node.getTextContent().length) && 
          ['selection'].includes(selectionType)) {
          nodeToReplace = node; // node is selected entirely - replace entirely

        } else {
          const isStartKey = ['pointBack', 'pointForward'].includes(selectionType) || nodeKey === startKey;
          const isEndKey = ['pointBack', 'pointForward'].includes(selectionType) || nodeKey === endKey;

          let startPos = isStartKey ? startOffset : 0
          let endPos = isEndKey ? endOffset : node.getTextContent().length

          let splittedNodes = node.splitText(startPos, endPos)

          nodeToReplace = 
            (['pointBack'].includes(selectionType) && startOffset === 0) ||
            (['selection'].includes(selectionType) && startOffset === 0 && isStartKey && isEndKey) ||
            (['selection'].includes(selectionType) && isEndKey && !isStartKey) ||
            Boolean(usingNextNode) ? 
                splittedNodes[0] : 
                splittedNodes[1]

        }

        // Replace the applicable part of the node        
        if(Boolean(nodeToReplace)) {

          const redlineNode = $createRedlineNode({...newRedline, 
            redlineType: 'del', 
            text: 
              (mergeBefore ? prevSibling.getTextContent() : "") +
                nodeToReplace.getTextContent() + 
                  (mergeAfter ? nextSibling.getTextContent() : "") })

          // Remove nodes that were merged in
          if(mergeBefore) { prevSibling.remove() }
          if(mergeAfter) { nextSibling.remove() }

          nodeToReplace.replace(redlineNode);
          createdNode = redlineNode;

        }
      } else if(['redline'].includes(node.getType()) && partyID === node.getPartyID() && 
      ['add'].includes(node.getRedlineType())) {
      // If you're (partially) deleting your own (party's) new insertion - that should be removed

        const selectionStartsBeforeNode = startKey !== node.getKey() && ['selection'].includes(selectionType)
        const selectionEndsAfterNode = endKey !== node.getKey() && ['selection'].includes(selectionType)
        if( // Node is entirely covered by selection
        (selectionStartsBeforeNode || startOffset === 0) && 
        (selectionEndsAfterNode || endOffset >= node.getTextContent().length)) { 
          node.remove()
        } else { // Partial removal - update the text
          
          isTextRemoval = !['pointBack'].includes(selectionType) || endOffset === node.getTextContent().length;
          let startPosOfRemoval = selectionStartsBeforeNode ? 0 : startOffset;
          let endPosOfRemoval = selectionEndsAfterNode ? node.getTextContent().length : endOffset;

          // A string can be cut in the middle
          let part1 = node.getTextContent().substring(0, startPosOfRemoval);
          let part2 = node.getTextContent().substring(endPosOfRemoval, node.getTextContent().length)

          updatedRedlineNode = $createRedlineNode({
            redlineType: node.getRedlineType(), 
            partyID: node.getPartyID(), 
            creator: node.getCreator(), 
            date: newRedline.date, 
            text: part1 + part2})

          node.replace(updatedRedlineNode); // Revert new redline node to the previous one

        }
      }
    })

    // Arrange new selection - deselect
    let newSelection = selection

    let newKey = 
          createdNode !== null ? 
              createdNode.getKey() :
          updatedRedlineNode !== null ? 
              updatedRedlineNode.getKey() :
          Boolean(usingNextNode) ?
              usingNextNode.getKey() :
              selection.anchor.key

    let newOffset = 
          createdNode !== null  && ['pointForward','selection'].includes(selectionType) ? 
              createdNode.getTextContent().length : 
          createdNode !== null ? 
              0 :
          ['pointBack'].includes(selectionType) & !isTextRemoval ? 
              selection.anchor.offset - 1 :
          ['pointForward'].includes(selectionType) && Boolean(usingNextNode) && !isTextRemoval ?
              1 :
          ['pointForward'].includes(selectionType) && !isTextRemoval ? 
              selection.anchor.offset + 1 :
              selection.anchor.offset

    newSelection.anchor.key = newKey
    newSelection.anchor.offset = newOffset
    newSelection.focus = newSelection.anchor;
    $setSelection(newSelection)

}

// Compares a new intended redline vs. an existing Redline Node, returns Boolean
const isMatchingRedline = (newRedline, redlineNode) => {
  let isMatching = false;

  if(newRedline.redlineType === redlineNode.getRedlineType() && // It's of the same type
  newRedline.partyID === redlineNode.getPartyID() && // It's for the same party
  newRedline.creator === redlineNode.getCreator()){ // It was by the same creator
    isMatching = true;
  }
  return isMatching
}