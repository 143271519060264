import React, { useContext } from 'react';
import theme from '../theme/theme';
import { globalStore } from '../state/store';
import { useNavigate } from "react-router-dom";
import { AppBar, Avatar, Box, CircularProgress, IconButton, ListItemIcon, ListItemText,
         Menu, MenuItem, Skeleton, Toolbar, Tooltip, useMediaQuery } from '@mui/material';
import { AvatarGroup, ChipButton, DialogDiff, DialogSend, FilterMenuButton } from '.'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCircleXmark, faClone, faGear, faHeading, faHome, faPaperPlane, 
         faRightFromBracket, faSignature, faUser, faUserSecret, faUserTie } from '@fortawesome/pro-solid-svg-icons';

import logo from '../assets/img/ct-logo.png'
import axios from 'axios';

const Header = (props) => {

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const styles = {
    avatar: {width: '30px', height: '30px'}
  }

  const navigate = useNavigate();
  const [state, dispatch] = useContext(globalStore);
  const [showToolbar, setShowToolbar] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElModule, setAnchorElModule] = React.useState(null);
  const [dialogSendOpen, setDialogSendOpen] = React.useState(null);
  const [dialogDiffOpen, setDialogDiffOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const hasModuleSelector = ['Admin', 'Dashboard', 'Templates'].includes(props.page) && ['Admin', 'Legal'].includes(state.user.role);
  const userHasPassword = state.user.role !== 'Counterparty' || state.user.password.length > 20

  const handleLogout = () => {
    setLoading(true)
    axios.get(state.settings.api + 'auth/logout')
    .then(() => {
      
      handleCloseUserMenu()
      setLoading(false)
      navigate('/')

    }).catch((err) => console.log("unable to logout", err))
  }

  const settings = [
    { name: userHasPassword ? 'Account' : 'Profile', route: '/account', icon: userHasPassword ? faGear : faUser },
    //{ name: 'Help', route: '/dashboard', icon: faCircleQuestion },
    { name: userHasPassword ? 'Logout' : 'Exit', click: handleLogout, icon: faRightFromBracket }]

  const currentModule = ['Admin', "Templates"].includes(props.page) ? props.page : "Dashboard"
  const modules = [
    { name: "Dashboard", displayName: 'Dashboard', icon: faHome, route: "/dashboard" },
    ...['Admin', 'Legal'].includes(state.user.role) ? [{ name: "Templates", displayName: 'Legal', icon: faUserTie, route: "/templates/main" }] : [],
    ...['Admin'].includes(state.user.role) ? [{ name: "Admin", displayName: 'Admin', icon: faUserSecret, route: "/admin/entities" }] : [],
  ]

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenModuleMenu = (event) => {
    setAnchorElModule(event.currentTarget);
  };
  
  const handleCloseModuleMenu = () => {
    setAnchorElModule(null);
  };

  return (
    <AppBar position={"fixed"} elevation={0}
    sx={{backgroundColor: 'rgba(255,255,255,0.7)', backdropFilter: 'blur(10px)', zIndex: Boolean(props.toolbar) ? '1250' : '1000'}}>

      {props.loading ?
      <Toolbar disableGutters sx={{ml: 3, mr: 3}}>
        <Skeleton variant="circular" width={35} height={35} style={{marginRight: 'auto'}} />
        <Skeleton variant="circular" width={30} height={30} />
      </Toolbar>      
      :
      <Toolbar disableGutters sx={{ml: 3, mr: 3}}>

        <Box 
        sx={{ flexGrow: 
              (['Agreement', 'Template'].includes(props.page) && isMdUp) || // Grow when the Agreement Header exists OR
              hasModuleSelector // when the "module selector" exists
              ? 0 : 1, display: 'flex' }}
        onClick={e => navigate(['Template'].includes(props.page) ? 
            '/templates/main' : // Logo Click to Template overview if inside a Template, otherwise back to Dashboard
            '/dashboard')}>
        <img src={logo} style={{ marginRight: '20px', height: '30px', cursor: 'pointer' }} />
        </Box>
        
        {// Header Body specific to the Agreement Screen
        ['Agreement', 'Template'].includes(props.page) ?
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

          {props.isOwner ?
            <div style={showToolbar ? {} : {visibility: 'hidden', height: '0px', width: '0px'}}>
            {props.toolbar}
            </div>
          :''}

          {showToolbar ? 
          <Tooltip title="Close toolbar" placement="bottom" arrow>
          <IconButton onClick={e => setShowToolbar(false)}>
            <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: '18px', padding: '4px', marginTop: '1px', color: theme.palette.secondary.main}} />
          </IconButton>
          </Tooltip>
          :
          Boolean(props.toolbar) ?
            <>
              {props.isOwner ?
                <Box sx={{mr:1, marginTop: '3px'}}>
                  <ChipButton
                  icon={faHeading}
                  iconPos="left"
                  text={"Toolbar"}
                  color="lightGrey"
                  variant="text"
                  buttonClick={e => setShowToolbar(true)}
                  />
                </Box>
              :''}
              <Box sx={{mr:1, marginTop: '3px'}}>
                <ChipButton
                icon={faClone}
                iconPos="left"
                text={"Compare"}
                color="lightGrey"
                variant="text"
                buttonClick={e => setDialogDiffOpen(true)}
                />
              </Box>
              <Box sx={{marginTop: '3px'}}>            
                <FilterMenuButton />
              </Box>
            </>
          :''
          }
        </Box>
        :
        // Pages where to show the "MODULE" selector, ie. page navigator
        hasModuleSelector ?
        <Box sx={{ flexGrow: 1, marginTop: '2px' }}>
            <ChipButton
            icon={faCaretDown}
            iconPos="right"
            text={currentModule === 'Templates' ? 'Legal' : currentModule}
            color="lightGrey"
            variant="text"
            buttonClick={handleOpenModuleMenu}
            />
            <Menu
              sx={{ mt: '45px', zIndex: '2000' }}
              id="menu-appbar-module"
              anchorEl={anchorElModule}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElModule)}
              onClose={handleCloseModuleMenu}
            >
            {modules.map((m, i) => (
            <MenuItem key={i} onClick={e => navigate(m.route)} selected={m.name === currentModule} style={{width: '170px'}}>
              <ListItemIcon>
                  <FontAwesomeIcon icon={m.icon} style={m.name === currentModule ? {} : { visibility: 'hidden' }} />
              </ListItemIcon>
              <ListItemText><span style={{fontWeight: m.name === currentModule ? '600' : '300'}}>{m.displayName}</span></ListItemText>
              {/*
              <ListItemText sx={{ textAlign: 'center'}}>
                <Typography style={{fontWeight: '600', padding: '4px 15px'}}>{m.name}</Typography>
              </ListItemText>*/}
            </MenuItem>
            ))}
          </Menu>
        </Box>
        :''}

        <Box sx={{ flexGrow: 0 }}>
          {['Agreement', 'Template'].includes(props.page) && !showToolbar && props.isOwner ?
          <>
            <Box sx={{mr:2, display: { xs: 'none', md: 'inline-block' } }}>
              <AvatarGroup
              />
            </Box>

            { // Determine whether "signable" - if yes, show Initiate signing button
            Boolean(state.avs.filter((av) => av.agrID === props.mainAg._id)[0]) &&
            !state.avs.filter((av) => av.agrID === props.mainAg._id)[0].isEdited &&
            Boolean(state.parties.filter((p) => p.orgID === state.org._id)[0]) &&
            (
              (state.avs.filter((av) => av.agrID === props.mainAg._id)[0].primReady &&
              state.parties.filter((p) => p.orgID === state.org._id)[0].side === 'secondary') 
              ||
              (state.avs.filter((av) => av.agrID === props.mainAg._id)[0].secReady &&
              state.parties.filter((p) => p.orgID === state.org._id)[0].side === 'primary')
            ) ?
              <Box sx={{mr:1, display: 'inline-block'}}>
                <ChipButton
                  icon={faSignature}
                  iconPos="right"
                  text={"Initiate Signing"}
                  color="secondary"
                  variant="contained"
                  buttonClick={e => setDialogSendOpen('initiatesigning')}
                  />
              </Box>
            : '' }

            {['Agreement'].includes(props.page) ?
            <Box sx={{mr:2, display: 'inline-block'}}>
              <ChipButton
                icon={faPaperPlane}
                iconPos="right"
                text={"Send"}
                color="secondary"
                variant="contained"
                buttonClick={e => setDialogSendOpen('sendtocpty')}
                />
            </Box>
            :
            ['Template'].includes(props.page) && Boolean(props.templateVersion) ?
            <Box sx={{mr:2, display: 'inline-block'}}>
              {props.templateVersion}
            </Box>
            :''}
          </>
          :''}

          {loading ?
          <CircularProgress size={15} sx={{mr:2}} />
          :''}

          <Tooltip title="Open settings" placement="bottom" arrow>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar 
              sx={styles.avatar}
              alt="Logged in User" 
              src={state.user.photoURL} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px', zIndex: '2000' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, i) => (
              <MenuItem key={i} onClick={setting.click !== undefined && setting.click !== null ? 
                setting.click :
                e => navigate(setting.route)}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={setting.icon} />
                </ListItemIcon>
                <ListItemText>{setting.name}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      }
      
      {['Agreement'].includes(props.page) ?
      <>
        <DialogSend
        open={Boolean(dialogSendOpen)}
        closeDialog={e => setDialogSendOpen(null)}
        trigger={dialogSendOpen}
        mainAg={props.mainAg}
        />

        <DialogDiff
        open={Boolean(dialogDiffOpen)}
        closeDialog={e => setDialogDiffOpen(false)}
        trigger={dialogDiffOpen}
        //mainAg={props.mainAg}
        />
      </>      
      :''}
    </AppBar>
    
  );
};
export default Header;