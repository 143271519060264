const workflowsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_WORKFLOWS":
            return { workflows: action.payload };
        case "ADD_WORKFLOW":
            return {
                workflows: [...state.workflows, action.payload]
            };
        case "UPDATE_WORKFLOWS":
            let newVal = action.payload;
            let newWorkflows = state.workflows;
            let idx = state.workflows.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newWorkflows[idx] = newVal;
            }
            return { workflows: newWorkflows };
        default:
            return { workflows: state.workflows }
    }
};

export default workflowsReducer