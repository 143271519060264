export const states = [
    { country: 'US', label: 'Alabama', code: 'AL'},
    { country: 'US', label: 'Alaska', code: 'AK'},
    { country: 'US', label: 'Arizona', code: 'AZ'},
    { country: 'US', label: 'Arkansas', code: 'AR'},
    { country: 'US', label: 'California', code: 'CA'},
    { country: 'US', label: 'Colorado', code: 'CO'},
    { country: 'US', label: 'Connecticut', code: 'CT'},
    { country: 'US', label: 'Delaware', code: 'DE'},
    { country: 'US', label: 'Florida', code: 'FL'},
    { country: 'US', label: 'Georgia', code: 'GA'},
    { country: 'US', label: 'Hawaii', code: 'HI'},
    { country: 'US', label: 'Idaho', code: 'ID'},
    { country: 'US', label: 'Illinois', code: 'IL'},
    { country: 'US', label: 'Indiana', code: 'IN'},
    { country: 'US', label: 'Iowa', code: 'IA'},
    { country: 'US', label: 'Kansas', code: 'KS'},
    { country: 'US', label: 'Kentucky', code: 'KY'},
    { country: 'US', label: 'Louisiana', code: 'LA'},
    { country: 'US', label: 'Maine', code: 'ME'},
    { country: 'US', label: 'Maryland', code: 'MD'},
    { country: 'US', label: 'Massachusetts', code: 'MA'},
    { country: 'US', label: 'Michigan', code: 'MI'},
    { country: 'US', label: 'Minnesota', code: 'MN'},
    { country: 'US', label: 'Mississippi', code: 'MS'},
    { country: 'US', label: 'Missouri', code: 'MO'},
    { country: 'US', label: 'Montana', code: 'MT'},
    { country: 'US', label: 'Nebraska', code: 'NE'},
    { country: 'US', label: 'Nevada', code: 'NV'},
    { country: 'US', label: 'New Hampshire', code: 'NH'},
    { country: 'US', label: 'New Jersey', code: 'NJ'},
    { country: 'US', label: 'New Mexico', code: 'NM'},
    { country: 'US', label: 'New York', code: 'NY'},
    { country: 'US', label: 'North Carolina', code: 'NC'},
    { country: 'US', label: 'North Dakota', code: 'ND'},
    { country: 'US', label: 'Ohio', code: 'OH'},
    { country: 'US', label: 'Oklahoma', code: 'OK'},
    { country: 'US', label: 'Oregon', code: 'OR'},
    { country: 'US', label: 'Pennsylvania', code: 'PA'},
    { country: 'US', label: 'Rhode Island', code: 'RI'},
    { country: 'US', label: 'South Carolina', code: 'SC'},
    { country: 'US', label: 'South Dakota', code: 'SD'},
    { country: 'US', label: 'Tennessee', code: 'TN'},
    { country: 'US', label: 'Texas', code: 'TX'},
    { country: 'US', label: 'Utah', code: 'UT'},
    { country: 'US', label: 'Vermont', code: 'VT'},
    { country: 'US', label: 'Virginia', code: 'VA'},
    { country: 'US', label: 'Washington', code: 'WA'},
    { country: 'US', label: 'West Virginia', code: 'WV'},
    { country: 'US', label: 'Wisconsin', code: 'WI'},
    { country: 'US', label: 'Wyoming', code: 'WY'},
]