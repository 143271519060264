import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, UNDO_COMMAND, SELECTION_CHANGE_COMMAND,
         FORMAT_TEXT_COMMAND, FORMAT_ELEMENT_COMMAND, $getSelection, $isRangeSelection, $createParagraphNode,
         $getNodeByKey } from "lexical";
import { IconButton } from '@mui/material';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $wrapLeafNodesInElements, $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, $isListNode, 
         ListNode } from "@lexical/list";
import { createPortal } from "react-dom";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faH1, faH2, faH3, faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faBold, 
         faFont, faImage, faItalic, faLink, faListOl, faListUl, faNoteSticky, faRedo, faTable, faUnderline, 
         faUndo } from "@fortawesome/pro-solid-svg-icons";
import theme from "../../../theme/theme";
import { getClauseNode, wrapLeafNodesInElements } from "../utils";

const LowPriority = 1;

function Divider() { return <div className="divider" />; }

// Creates the Box for the Link editor
function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

// Handles the link editor
function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export default function ToolbarPlugin() {

  const styles = {
    toolbar: {marginTop: '1px', padding: '3px 8px'}
  }

  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [activeTextAlign, setActiveTextAlign] = useState(0);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
        setActiveTextAlign(
          [4].includes(element.getFormat()) ? 'justify' : 
          [3].includes(element.getFormat()) ? 'right' : 
          [2].includes(element.getFormat()) ? 'center' : 'left')
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));


      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);


  const formatBlock = (newType) => {

    if (
      (newType === "paragraph" && blockType !== "paragraph") ||
      (newType === "h1" && blockType !== "h1") ||
      (newType === "h2" && blockType !== "h2") ||
      (newType === "h3" && blockType !== "h3")
    ) {
      editor.update(() => {
        const selection = $getSelection();

        /*console.log(selection.getNodes());
        
        selection.getNodes().forEach((n) => {
          if(['paragraph'].includes(n.getType())) {

            let newNode = 
                newType === "paragraph" ? $createParagraphNode() :
                newType === "h1" ? $createHeadingNode("h1") :
                newType === "h2" ? $createHeadingNode("h2") :
                newType === "h3" ? $createHeadingNode("h3") : null 

            //console.log("replace node", n)
            //console.log("by", newNode)

          }
        })*/

        if ($isRangeSelection(selection)) {
          if(newType === "paragraph") { wrapLeafNodesInElements(selection, () => $createParagraphNode(), null, 'clause'); }
          else if(newType === "h1") { wrapLeafNodesInElements(selection, () => $createHeadingNode("h1"), null, 'clause'); }
          else if(newType === "h2") { wrapLeafNodesInElements(selection, () => $createHeadingNode("h2"), null, 'clause'); }
          else if(newType === "h3") { wrapLeafNodesInElements(selection, () => $createHeadingNode("h3"), null, 'clause'); }
        }

      });

    }

  }

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  return (
    <div className="toolbar" ref={toolbarRef} style={styles.toolbar}>

      {[
        { name: "Undo", icon: faUndo, isActive: false, click: e => editor.dispatchCommand(UNDO_COMMAND), disabled: !canUndo, },
        { name: "Redo", icon: faRedo, isActive: false, click: e => editor.dispatchCommand(REDO_COMMAND), disabled: !canRedo, hasDivider: true},
        { name: "Paragraph", icon: faFont, isActive: blockType === "paragraph", click: e => formatBlock("paragraph") },
        { name: "H1", icon: faH1, isActive: blockType === "h1", click: e => formatBlock("h1") },
        { name: "H2", icon: faH2, isActive: blockType === "h2", click: e => formatBlock("h2") },
        //{ name: "H3", icon: faH3, isActive: blockType === "h3", click: e => formatBlock("h3") },
        { name: "UL", icon: faListUl, isActive: blockType === "ul", click: formatBulletList },
        { name: "OL", icon: faListOl, isActive: blockType === "ol", click: formatNumberedList, hasDivider: true },
        { name: "Bold", icon: faBold, isActive: isBold, click: e => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold") },
        { name: "Italic", icon: faItalic, isActive: isItalic, click: e => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic") },
        { name: "Underline", icon: faUnderline, isActive: isUnderline, click: e => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline"), hasDivider: true },
        //{ name: "Link", icon: faLink, isActive: isLink, click: insertLink, hasDivider: true },
        { name: "Left Align", icon: faAlignLeft, isActive: activeTextAlign === "left", click: e => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left") },
        { name: "Center Align", icon: faAlignCenter, isActive: activeTextAlign === "center", click: e => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center") },
        { name: "Right Align", icon: faAlignRight, isActive: activeTextAlign === "right", click: e => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right") },
        { name: "Justify Align", icon: faAlignJustify, isActive: activeTextAlign === "justify", click: e => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify"), hasDivider: true },
        { name: "Table", icon: faTable, isActive: false, click: e => null },
        { name: "Image", icon: faImage, isActive: false, click: e => null },
        //{ name: "Sticky Note", icon: faNoteSticky, isActive: false, click: e => null }
      ]
      .map((b,i) => (
        <React.Fragment key={i}>

          <IconButton
          key={i}
          disabled={b.disabled}
          onClick={b.click}
          aria-label={b.name}
          //className={"toolbar-item spaced " + (b.isActive ? "active" : "")}
          sx={{ 
            fontSize: '15px',
            padding: '10px',
            marginRight: b.hasDivider ? '20px' : '3px',
            border: '1px solid' + theme.palette.grey[300],
            color: b.disabled ? theme.palette.grey[500] : theme.palette.grey[800],
            backgroundColor: b.isActive ? theme.palette.grey[200] : theme.palette.primary.contrastText,
            borderRadius: '5px' }}
          >
            <FontAwesomeIcon icon={b.icon} />
          </IconButton>
          
          {/*b.hasDivider ?
            <Divider />
          :''*/}

        </React.Fragment>

      ))
      }

      {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
      
    </div>
  );
}
