import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { globalStore } from '../state/store';
import { Box, Grid, Skeleton, Snackbar, TextField, Typography, useMediaQuery } from "@mui/material";
import { FabStandard, Header, SearchBar, ThankYouScreen, ThumbAgr } from '../components';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileCirclePlus, faHourglass, faSignature, faUserPen,
         faFilterSlash, faRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
//import { faClock, faEye, faFileCirclePlus, faFilter, faHourglass, faSignature, faUserPen } from '@fortawesome/fontawesome-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Dashboard = () => {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  
  const styles = {
    searchBar: {my: isSmUp ? 19 : 10,
      [theme.breakpoints.only('xs')]: { width: '300px' },
      [theme.breakpoints.only('sm')]: { width: '360px' },
      [theme.breakpoints.up('md')]: { width: '440px' },
    }
  }

  const navigate = useNavigate();

  const { tweak } = useParams();
    // 0 / 1 => Counterparty / Subscriber user sent agreement
    // 2 / 3 => Counterparty / Subscriber user signed agreement  
  const [state, dispatch] = useContext(globalStore);
  const [isSearching, setIsSearching] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const [agrs, setAgrs] = React.useState([])
  const [agrEnts, setAgrEnts] = React.useState([])
  const [agrFilter, setAgrFilter] = React.useState('none')
  const [agrQty, setAgrQty] = React.useState(4)
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    setLoading(true);
    axios.get(state.settings.api + "agr/relevant/" + state.org._id + "/" + agrFilter + "/" + agrQty)
    .then((resAgs) => {
      if(resAgs.data.success) {
        setAgrs(resAgs.data.data.ags);
        setAgrEnts(resAgs.data.data.ents);
        setLoading(false)
      } else { setErrMsg("An issue occurred while loading the latest agreements"); setLoading(false) }
    }).catch((err) => { setErrMsg("Unable to load the latest agreements"); setLoading(false) })
  }, [state.org._id, agrFilter, agrQty])

  useEffect(() => {

    if(['0', '1', '2', '3'].includes(tweak)) {
      dispatch({ 
        type: "NEW_SNACKBAR", 
        payload: 
          ['0','1'].includes(tweak) ?
              "The agreement was sent to your counterparty." :
          ['2','3'].includes(tweak) ?
              "Your signature response has been recorded. It may take a few minutes to process." :
              ""
      });
    }
  }, tweak)

  const handleFilterSelect = (filter) => {
    if(agrFilter !== 'none') {
      setAgrQty(4);
      setAgrFilter('none');
    } else {
      setAgrQty(8);
      setAgrFilter(filter);
    }
  }

  const handleLogout = () => {
    setLoading(true)
    axios.get(state.settings.api + 'auth/logout')
    .then(() => {
      
      setLoading(false)
      navigate('/')

    }).catch((err) => console.log("unable to logout", err))
  }

  return (
    <div>

      <Header page={"Dashboard"} loading={loading} />

      {!loading ?
      <FabStandard
        click={
          state.user.role === 'Counterparty' ?
          e => handleLogout() :
          e => navigate('/new')}
        text={state.user.role === 'Counterparty' ? "Exit" : "New"}
        icon={state.user.role === 'Counterparty' ? faRightFromBracket : faFileCirclePlus}
      />
      :''}

      {loading ?
      <Box sx={{ my: 8, mx: 3 }}>

      <Grid container direction="column" alignItems="center">
          <Grid item>
            <Box sx={styles.searchBar}>
              <Skeleton variant="text" height={90} sx={{width: '100%'}} />
            </Box>
          </Grid>
          <Grid item container direction="row" alignItems="flex-start" justifyContent="center"
          sx={{width: isMdUp ? '780px' : isSmUp ? '340px' : '300px'}}>
            {[1,2,3,4].map((i) => (
              <Grid item container direction="column" xs={6} md={3} alignItems="center" key={i}>
                <Grid item>
                  <Skeleton variant="text" height={20} width={isMdUp ? 150 : isSmUp ? 135 : 120} sx={{mb:1}} />
                </Grid>
                <Grid item>
                  <Skeleton variant="rectangle" 
                    width={isMdUp ? 180 : isSmUp ? 160 : 140} 
                    height={isMdUp ? 210 : isSmUp ? 185 : 160} 
                    sx={{borderRadius: '20px', marginBottom: '30px'}} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        
      </Grid>
      </Box>
      :
      ['0','2'].includes(tweak) ?
      <>
      <Box sx={{ my: 8, mx: 3 }}>
        <ThankYouScreen />
      </Box>
      </>
      :
      <>
      <Box sx={{ my: 8, mx: 3 }}>

        <Grid container direction="column" alignItems="center">
          {state.user.role !== 'Counterparty' ?
          <Grid item>
            <Box sx={styles.searchBar}>

              <SearchBar
                setIsSearching={setIsSearching}
              />

            {/*
            Search Bar, can search:<br/>
            - cpty = Filter all ags (recent 16) for that cpty + show button to edit, edit via cpty card<br/>
            - agr type = Filter all ags (recent 16) for the agr type<br/>
            - dates = e.g. Oct-2020 (inbetween creation and lastUpdate)<br/>
            - country = Filters entities, (subs/cpies) in that country<br/>
            - subs, agr title, text inside agr<br/>
            - Tasks (Appr/ Review / @Comment / Obligation)<br/>
            - Smart Fields / Obligations etc.
            - Templates
            */}
            </Box>
          </Grid>
          :''}

          {!isSearching ? // Show 4 super-relevant ags - Hide when you're searching
          <Grid item sx={{mt: state.user.role === 'Counterparty' ? 15 : 0}}>

            <Grid container direction="row" spacing={2} alignItems="center" justifyContent={Boolean(agrs) && agrs.length > 4 ? "flex-start" : "center"}
            sx={{width: isMdUp ? '880px' : isSmUp ? '400px' : '330px'}}>

              {errMsg !== null ? // An error exists - show it
              <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 4}}>
                  <Typography align="center" color="error">{errMsg}</Typography>
              </Box>
              :
              Boolean(agrs) && agrs.length > 0 ?

                agrs.map((ag,i) => {
                  // TODO => Pull relevant agr details directly from DB vs. from local state (to keep state loosely coupled)
                  // Pull 16 ags (max), sorted based on recent update
                  // Details to get: legalName 1 and 2 and logoURL
                  // Details to get: "recently viewed", "recently updated", "waiting for cpty", "pending signature"
                  let ent1id = ag.ents.filter((e) => e.partyID === 'party1')[0].entID;
                  let ent2id = ag.ents.filter((e) => e.partyID === 'party2')[0].entID;
                  let ent1 = agrEnts.filter((e) => e._id === ent1id)[0] !== undefined ?
                      agrEnts.filter((e) => e._id === ent1id)[0] : {}
                  let ent2 = agrEnts.filter((e) => e._id === ent2id)[0] !== undefined ?
                      agrEnts.filter((e) => e._id === ent2id)[0] : {}

                  return (
                    <Grid item key={ag._id} xs={6} md={3} sx={{mb: 2}}>
                    <Typography align="center" color="textSecondary" variant="body2"
                    sx={{mb: 1.5, '&:hover': { color: theme.palette.primary.main, cursor: 'pointer' }}}
                    onClick={e => handleFilterSelect('viewed')}
                    >
                      {
                      agrFilter !== 'none' && i === 0 ? 
                          <span style={{color: theme.palette.primary.main, fontWeight: '600'}}><FontAwesomeIcon icon={faFilterSlash} />&nbsp;&nbsp;Recently Viewed</span> :
                      agrFilter !== 'none' && i !== 0 ? 
                          <>&nbsp;&nbsp;</> :
                      i === 0 ? <><FontAwesomeIcon icon={faEye} />&nbsp;&nbsp;Recently Viewed</> :
                      i === 1 ? <><FontAwesomeIcon icon={faUserPen} />&nbsp;&nbsp;Recently Updated</> :
                      i === 2 ? <><FontAwesomeIcon icon={faHourglass} />&nbsp;&nbsp;Waiting for {isSmUp ? "counterparty" : "cpty"}</> :
                      i === 3 ? <><FontAwesomeIcon icon={faSignature} />&nbsp;&nbsp;Pending signature(s)</> : ""}
                    </Typography>
                    <ThumbAgr 
                    ag={ag} 
                    actionReq={ag.avOwners.includes(state.org._id)}
                    thumbClick={e => navigate('/agreement/' + ag._id)}
                    showLogo={state.subs.some((s) => s._id === ent1id) ? ent2.logoURL : ent1.logoURL}
                    primaryLegalName={ent1.legalName !== undefined ? ent1.legalName : ''} 
                    // TODO PULL CPENT FOR THIS AG IF YOU'RE NOT THE OWNER (e.g. subscriber or when you're the CP)
                    secondaryLegalName={ent2.legalName !== undefined ? ent2.legalName : ''}
                    additionalParties={ag.ents.filter((e) => e.entID !== ent1._id && e.entID !== ent2._id)}
                    />
                    </Grid>
                  )
                })
              :''}

            </Grid>
            
          </Grid>
          :''}
          
        </Grid>
      </Box>
      </>
      }

    </div>
  );
};
export default Dashboard;
