import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import { useNavigate, useParams } from "react-router-dom";
import { globalStore } from '../state/store';
import { countries } from '../assets/static'
import { countryToFlag, getAddressForEntity, trunc } from '../utils';

import { Box, Button, Grid } from "@mui/material";

import { DialogEntity, Header } from '../components';

const SuperAdmin = () => {

  const [state, dispatch] = useContext(globalStore);

  const [dialogEntityOpen, setDialogEntityOpen] = React.useState(null)


  const closeEntityDialog = () => {
    setDialogEntityOpen(null)
  }

  return (
    <div>

      <Box sx={{display: 'flex', //justifyContent: 'center',
            width: '100%', margin: '0px', padding: '0px'/*==, backgroundColor: '#ffeeff'*/ }}>

        {['rweg@getcanveo.com','dbuser@getcanveo.com'].includes(state.user.email) ?
        <>
        <Header page={"SuperAdmin"} />

        <Box sx={{ my: 20, mx: 3, width: '100%' }}>

            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <Button onClick={e => setDialogEntityOpen({type: 'signup'})}>
                        Sign Up new Org
                    </Button>
                </Grid>
                <Grid item>
                    <Button>
                        Update logo for ORG
                    </Button>
                </Grid>
            </Grid>

        </Box>
        </>
        :
        'no access'
        }

        <DialogEntity
          open={Boolean(dialogEntityOpen)}
          closeDialog={closeEntityDialog}
          type={dialogEntityOpen !== null && dialogEntityOpen.type !== undefined ? dialogEntityOpen.type : ''}
          ent={dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined ? dialogEntityOpen.ent : ''}
        />

      </Box>

    </div>
  );
};
export default SuperAdmin;