import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect, useContext } from "react";
import { analyzeNode } from "../utils";
import { CoreDrawerDefinitions } from '../../'
import { globalStore } from '../../../state/store';

export default function ToolbarPlugin() {

  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [definitions, setDefinitions] = React.useState([])

  useEffect(() => {

    let definitionItems = [] // List of definitions (that have the clauseType)
    let definitionCTID = Boolean(state.clauseTypes.filter((ct) => ct.name === 'Definition')[0]) ? 
      state.clauseTypes.filter((ct) => ct.name === 'Definition')[0]._id : 'notapplicable';

    editor.getEditorState().read(() => {
        const nodeMapArray = [...editor.getEditorState()._nodeMap];
        const root = nodeMapArray.filter((n) => n[1].getType() === 'root')[0];
        root[1].__children.forEach((nodeKey) => {

            let node = Boolean(nodeMapArray.filter((nma) => nma[0] === nodeKey)[0]) ? 
                nodeMapArray.filter((nma) => nma[0] === nodeKey)[0][1] : null

            if(Boolean(node.__children) && node.__children.length > 0 && node.getTextContent().trim().length > 3) {

                let nodeDetails = analyzeNode(node.getTextContent())

                let endPos = nodeDetails.firstDotPositionAfterLead > -1 && nodeDetails.firstDotPositionAfterLead < 50 ? nodeDetails.firstDotPositionAfterLead : 50
                
                let item = 
                    nodeDetails.isDefinition ? nodeDetails.clauseTitle :
                    node.getTextContent().substring(0, endPos)

                const element = editor.getElementByKey(node.getKey())

                if(Boolean(node.__clauseTypes) && node.__clauseTypes.includes(definitionCTID)) {
                    definitionItems.push({ element: element, text: node.getTextContent(),
                    item: item.replace("\"", "").replace("“", "").replace("”", "") })
                }
            }
        })
    })
    setDefinitions(definitionItems)

  }, [])

  return (
    <CoreDrawerDefinitions definitions={definitions} />
  );
}