const usersReducer = (state, action) => {

    switch (action.type) {
        case "INIT_USERS":
            return { users: action.payload };
        case "ADD_USERS":
            return {
                users: [...state.users, action.payload]
            };
        case "UPDATE_USERS":
            let newVal = action.payload;
            let newUsers = state.users;
            let idx = state.users.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newUsers[idx] = newVal;
            }
            return { users: newUsers };
        default:
            return { users: state.users }
    }
};
  
export default usersReducer