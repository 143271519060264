

import React, { useContext } from 'react';
import { globalStore } from '../state/store';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { ChipButton } from '.';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faCircleExclamation, faCommentLines, faBullhorn, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { APPLY_FILTER_COMMAND } from './editor/commands';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';

const filters = [
    { id: 'openIssues', name: "Open Issues", icon: faCircleExclamation },
    { id: 'internalComments', name: "Internal Comments", icon: faCommentLines },
    { id: 'publicComments', name: "Public Comments", icon: null },
    { id: 'approvalCreation', name: "Approval Requests (by me)", icon: faThumbsUp },
    { id: 'approvalAssignee', name: "Approval Requests (for me)", icon: null }
]

const FilterMenuButton = (props) => {

  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  
  /*
  useEffect(() => { // UseEffect => If there is a filter, you cannot select across clauses => automatically deselect

    if(Boolean(activeFilter)) {
        editor.getEditorState().read(() => {
            let selection = $getSelection();
            console.log("SEL", selection.getNodes())
        })
    }

  }, [editor])*/

  const handleOpenFilterMenu = (event) => {
    setAnchorElFilter(event.currentTarget);
  };
  
  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const applyFilter = (filter) => {
    //setActiveFilter(filter)
    editor.dispatchCommand(APPLY_FILTER_COMMAND, filter);
    handleCloseFilterMenu();
  }

  return (
    <div>
        
        {Boolean(state.userSession.filter) ? 
            <ChipButton
            icon={faFilter}
            iconPos="left"
            text={Boolean(filters.filter((f) => f.id === state.userSession.filter)[0]) ? 
                    filters.filter((f) => f.id === state.userSession.filter)[0].name : 
                    "Filter"}
            color="secondary"
            variant="contained"
            buttonClick={e => applyFilter(null)}
            />
        :
            <ChipButton
            icon={faFilter}
            iconPos="left"
            text={"Filter"}
            color="lightGrey"
            variant="text"
            buttonClick={handleOpenFilterMenu}
            />
        }

        <Menu
        sx={{ mt: '32px', zIndex: '2000' }}
        id="menu-appbar-filter"
        anchorEl={anchorElFilter}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        open={Boolean(anchorElFilter)}
        onClose={handleCloseFilterMenu}
        >

        {filters.map((m, i) => (

        <MenuItem key={m.id} onClick={e => applyFilter(m.id)} style={{width: '280px'}}>
            <ListItemIcon>
                {Boolean(m.icon) ?
                <FontAwesomeIcon icon={m.icon} />
                :''}
            </ListItemIcon>
            <ListItemText>{m.name}</ListItemText>
        </MenuItem>
        ))}

        </Menu>
    </div>
  );
};
export default FilterMenuButton;