import React, { useContext } from 'react';
import theme from '../theme/theme';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { globalStore } from '../state/store';
import { initializeUser } from '../state/initializeUser';
import { Avatar, Box, Button, Chip, CircularProgress, Grid, IconButton, InputAdornment, 
         Snackbar, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faEye, faEyeSlash, faKey } from '@fortawesome/pro-solid-svg-icons';

import logo from '../assets/img/ct-logo.png'
import google from '../assets/img/icon_google.png'
import msoft from '../assets/img/icon_msoft.png'
import slack from '../assets/img/icon_slack.png'

function Login() {

  const styles = {
    hiddenBox: {
      display: 'none',
    },
    resizeBox: { 
      [theme.breakpoints.only('xs')]: { width: '320px' },
      [theme.breakpoints.up('sm')]: { width: '480px' },
      marginBottom: '20px',
    },
    avIcon: {
      backgroundColor: 'transparent',
      borderRadius: '0px',
      margin: '3px 1px 3px 3px',
    },
    signinChip: {
      padding: '13px 2px',
      margin: '0px 0px 2px 3px',
      borderRadius: '3px',
      //border: '1px solid' + theme.palette.grey[300],
      border: '1px solid' + theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
        //border: '1px solid' + theme.palette.primary.main,
        cursor: 'pointer',
      }
    }
  }

  const navigate = useNavigate();
  const [state, dispatch] = useContext(globalStore);
  const { createstring } = useParams();

  const [showPassword, setShowPassword] = React.useState(false)
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const [settingPassword, setSettingPassword] = React.useState( // If the createstring is defined, default to Step 1
    createstring !== undefined && createstring !== null && createstring.length === 20 ? "step1" : null)
  const [email, setEmail] = React.useState({valid: false, value: ''});
  const [password, setPassword] = React.useState({valid: false, value: ''});
  const [loading, setLoading] = React.useState(false);

  const reInitialize = () => {
    setShowPassword(false);
    setForgotPassword(false);
    setSettingPassword(null);
    setEmail({valid: false, value: ''});
    setPassword({valid: false, value: ''});
    setLoading(false);
  }

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailUpdate = (newVal) => {
    const isValid = /\b\w+\@\w+\.\w+(?:\.\w+)?\b/.test(newVal)
    setEmail({ valid: isValid, value: newVal })
  }

  const handlePasswordUpdate = (newVal) => {

    const isValid = newVal.length >= 8 && newVal.length <= 30 && // Password length
                    /[a-z]/g.test(newVal) && // lowerCase
                    /[A-Z]/g.test(newVal) && // uperCase
                    /[0-9]/g.test(newVal) // number

    setPassword({ valid: isValid, value: newVal })

  }

  const handleSubmit = () => {

    setLoading(true);
    
    if(!email.valid) {
      dispatch({ type: "NEW_SNACKBAR", payload: "Please provide a valid e-mail address" })
      setLoading(false);
    } else if(!password.valid && !forgotPassword && !['step1'].includes(settingPassword)) {
      dispatch({ type: "NEW_SNACKBAR", payload: "Please provide a valid password" })
      setLoading(false);
    } else if(
      forgotPassword || // Asking to reset password
      ['step1'].includes(settingPassword) || // Asking to verify email before creating a new password
      ['step2'].includes(settingPassword) // Creating a new password
    ) {
      axios.post(state.settings.api + "auth/createpassword", { // Resetting your password, sending link to user
        un: email.value,
        createString: ['step1', 'step2'].includes(settingPassword) ? createstring : null,
        isReset: ['step1', 'step2'].includes(settingPassword) ? false : true,
        newPw: ['step2'].includes(settingPassword) ? password.value : null,
      }).then((res) => {

        if(['step1'].includes(settingPassword)) { // Your email was verified succesfully

          setSettingPassword('step2');
          dispatch({ type: "NEW_SNACKBAR", payload: "Your email was verified succesfully" })
          setLoading(false);

        } else if(['step2'].includes(settingPassword)) { // Your email was verified succesfully

          setSettingPassword(null);
          setShowPassword(false)
          setPassword({valid: false, value: ''})          
          dispatch({ type: "NEW_SNACKBAR", payload: "New password created, continue to login" })
          setLoading(false);

        } else { // Step 0: You've reset your password to a createString
          setEmail({valid: false, value: ''})
          dispatch({ type: "NEW_SNACKBAR", payload: "Password reset completed, please check your inbox for instructions" })
          setLoading(false);
          setForgotPassword(false)
        }

      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "Password reset completed, please check your inbox for instructions" }); setLoading(false); })

    } else { // Attempt Logging In

      // TODO: OAuth type login
      let path = '/dashboard'

      axios.post(state.settings.api + "auth/login", {
        un: email.value, 
        pw: password.value,
      }).then((res) => { // TODO: limit frequency of login

        if(res.data !== undefined && res.data.success && res.data.data !== undefined && res.data.data !== null &&
        res.data.data.org !== undefined && res.data.data.org._id !== undefined &&
        res.data.data.user !== undefined && res.data.data.user._id !== undefined && res.data.data.user.email !== undefined) {

          initializeUser(dispatch, res.data.data)
          navigate(path)
          setLoading(false);
        }

      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "Authorization not successful" }); setLoading(false); })
    }

  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div onKeyDown={_handleKeyDown}>
      <Box sx={{
        position: 'fixed',
        right: '20px',
        bottom: '20px',
        fontSize: '13px', 
        fontWeight: '700', 
        margin: '2px 0px 0px 2px', 
        color: theme.palette.primary.main,
        '&:hover': { color: theme.palette.secondary.main, cursor: 'pointer'}
      }} 
      onClick={settingPassword !== null ? 
        e => { reInitialize(); navigate('/'); } : 
        e => setForgotPassword(!forgotPassword)}>
      {forgotPassword || settingPassword !== null ? 
        "Back to Login" : 
        "Forgot password?"}
      </Box>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{height: '100vh'}}>
        <Grid item container direction="column" alignItems="center" sx={{mb:5}}>
          <Grid item>
            <img src={logo} style={{ marginBottom: '28px', height: '50px', cursor: 'pointer' }} />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center"
            sx={{mb: ['step2'].includes(settingPassword) ? '20px' : '35px'}}>
              {settingPassword !== null ?
                "Create password: " + (['step1'].includes(settingPassword) ? "Provide email" : "New Password" ) :
               forgotPassword ? 
                "Reset password" : 
                "Sign in to Canveo"}
            </Typography>
            {// Indication of new Password and whether it matches criteria
            ['step2'].includes(settingPassword) ?
            <Typography color="textSecondary" variant="subtitle2" align="center" sx={{mb: 3}}>
              <span style={password.value.length >= 8 && password.value.length <= 30 ? { color: theme.palette.success.main } : {}}>
                8-30 characters
              </span>&nbsp;&nbsp;+&nbsp;&nbsp;
              <span style={/[A-Z]/g.test(password.value) ? { color: theme.palette.success.main } : {}}>
                Uppercase
              </span>&nbsp;&nbsp;+&nbsp;&nbsp;
              <span style={/[a-z]/g.test(password.value) ? { color: theme.palette.success.main } : {}}>
                Lowercase
              </span>&nbsp;&nbsp;+&nbsp;&nbsp;
              <span style={/[0-9]/g.test(password.value) ? { color: theme.palette.success.main } : {}}>
                Number
              </span>
            </Typography>
            :''}
          </Grid>
          <Grid item sx={['step2'].includes(settingPassword) ? styles.hiddenBox : styles.resizeBox}>
            <TextField
            autoFocus
            fullWidth
            variant="outlined"
            id="email"
            placeholder="Your username..."
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email.value}
            onChange={e => handleEmailUpdate(e.target.value.toLowerCase())}
            InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <FontAwesomeIcon 
                  icon={/*email.valid ? faCheckCircle : */faEnvelope} 
                  color={/*email.valid ? theme.palette.success.main : */theme.palette.primary.main} />
              </InputAdornment>
              ),
            }}
            />  
          </Grid>
          <Grid item sx={forgotPassword || settingPassword === "step1" ? styles.hiddenBox : styles.resizeBox}>
            <TextField
            fullWidth
            variant="outlined"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            placeholder="Your password..."
            autoComplete="current-password"
            value={password.value}
            onChange={e => handlePasswordUpdate(e.target.value)}
            InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <FontAwesomeIcon 
                  icon={/*password.valid ? faCheckCircle : */faKey} 
                  color={/*password.valid ? theme.palette.success.main : */theme.palette.primary.main} />
              </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={e => handleToggleShowPassword()}
                    onMouseDown={handleMouseDownPassword}
                    style={{fontSize: '16px', padding: '7px 6px 7px 6px'}}
                  >
                    {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          </Grid>
          <Grid item sx={styles.resizeBox}>
            <Button
            onClick={handleSubmit}
            type="submit"
            disableElevation
            variant="contained"
            fullWidth
            disabled={loading}
            style={{padding: '5px 15px', fontWeight: '600', fontSize: '17px', marginBottom: '5px'}}>
            {loading ?
            <>Submitting...&nbsp;&nbsp;<CircularProgress size={15} /></>
            : "Submit"}
          </Button>
          </Grid>
          <Grid item container direction="row" justifyContent="center" 
          sx={forgotPassword || settingPassword !== null ? styles.hiddenBox : styles.resizeBox}>
            <Grid item sx={{fontSize: '13px'}}>
              <span style={{fontWeight: '600', color: theme.palette.grey[700], marginRight: '14px'}}>Sign in with:</span>
              <Chip 
              sx={styles.signinChip}
              variant="outlined"
              avatar={<Avatar style={styles.avIcon} alt={"Google"} src={google} />}
              label={"Google"}
              size="small" />
              <Chip 
              sx={styles.signinChip}
              variant="outlined"
              avatar={<Avatar style={styles.avIcon} alt={"Microsoft"} src={msoft} />}
              label={"Microsoft"}
              size="small" />
              <Chip 
              sx={styles.signinChip}
              variant="outlined"
              avatar={<Avatar style={styles.avIcon} alt={"Slack"} src={slack} />}
              label={"Slack"}
              size="small" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}

export default Login;
