import { TextNode } from "lexical";
import { getColorForPartyID } from '../../../utils';

export class RedlineNode extends TextNode {
  //__type;
  __redlineType;
  __partyID;
  __creator;
  __date;

  constructor(redlineType, partyID, creator, date, text, key) {
    super(text, key);
    //super(key);
    //this.__text = text;
    this.__redlineType = redlineType; // add, del, xadd, xdel
    this.__partyID = partyID; // party1, party2, ...
    this.__creator = creator; // creator DisplayName
    this.__date = date;
  }

  static getType() {
    return 'redline';
  }

  static clone(node) {
    return new RedlineNode(node.__redlineType, node.__partyID, node.__creator, node.__date, node.__text, node.__key);
  }

  createDOM(config) {
    const element = super.createDOM(config);
    const withUnderscore = this.__partyID.indexOf('_') > -1
    const firstParty = withUnderscore ? this.__partyID.substr(0, this.__partyID.indexOf('_')) : this.__partyID;
    const secondParty = withUnderscore ? this.__partyID.substr(this.__partyID.indexOf('_') + 1) : this.__partyID;

    if(['add','del','xadd'].includes(this.__redlineType)) {
      element.style.color = getColorForPartyID(firstParty);
    }
    if(['add', 'xdel'].includes(this.__redlineType)) {
      element.style.borderBottom = '1px dotted' + getColorForPartyID(['xdel'].includes(this.__redlineType) ? secondParty : firstParty);
    } 
    if (['del', 'xadd'].includes(this.__redlineType)) {
      element.style.textDecoration = 'line-through';
      element.style.textDecorationColor = getColorForPartyID(['xdel'].includes(this.__redlineType) ? firstParty : secondParty)
    }
    return element;
  }

  updateDOM() {
    return false;
  }

  importDOM() {
    return null;
  }

  /*
  static importJSON(serializedNode: SerializedLinkNode): LinkNode {
    const node = $createLinkNode(serializedNode.url);
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON(): SerializedLinkNode {
    return {
      ...super.exportJSON(),
      type: 'link',
      url: this.getURL(),
    };
  }*/

  setRedlineType(redlineType) {
    const self = this.getWritable();
    self.__redlineType = redlineType;
  }

  getRedlineType() {
    const self = this.getLatest();
    return self.__redlineType;
  }

  setCreator(creator) {
    const self = this.getWritable();
    self.__creator = creator;
  }

  getCreator() {
    const self = this.getLatest();
    return self.__creator;
  }

  setPartyID(partyID) {
    const self = this.getWritable();
    self.__partyID = partyID;
  }

  getPartyID() {
    const self = this.getLatest();
    return self.__partyID;
  }

  setDate(date) {
    const self = this.getWritable();
    self.__date = date;
  }

  getDate() {
    const self = this.getLatest();
    return self.__date;
  }

  getTextContent() {
    const self = this.getLatest();
    return self.__text;
  }

  isInline() {
    return true;
  }

  /*
  splitText(start, end) {
    const self = this.getLatest();
    let returnNodes = []

    const newNode = {
      redlineType: self.__redlineType, 
      partyID: self.__partyID, 
      creator: self.__creator, 
      date: self.__date, 
      parent: self.__parent
    }
    const actualStart = 
      end !== undefined && end !== null && start !== undefined && start !== null && end < start ? 
          end :
      start !== undefined && start !== null ? 
          start : 
          0;    
    const actualEnd = 
      end !== undefined && end !== null && start !== undefined && start !== null && end < start ? 
          start : 
      end !== undefined && end !== null && end > start && end < self.__text.length ?
          end : 
          null
    
    console.log("actualStart", actualStart, actualEnd)

    if(actualStart > 0) {
      returnNodes.push($createRedlineNode({...newNode, text: self.__text.substring(0, actualStart) }))
    }

    if(actualEnd !== null) {
      returnNodes.push($createRedlineNode({...newNode, text: self.__text.substring(actualStart, actualEnd) }))
      returnNodes.push($createRedlineNode({...newNode, text: self.__text.substring(actualEnd) }))
    } else {
      returnNodes.push($createRedlineNode({...newNode, text: self.__text.substring(actualStart) }))
    }
    
    if(returnNodes.length === 0) {
      returnNodes.push(self)
    }

    return returnNodes;

  }*/

  static importJSON(serializedNode) {
    const node = $createRedlineNode(serializedNode); //clauseTypes, workflows, libIDs, filter, lock
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'redline',
      redlineType: this.getRedlineType(),
      partyID: this.getPartyID(),
      creator: this.getCreator(),
      date: this.getDate(),
      version: 1,
    };
  }

}

export function $createRedlineNode({redlineType, partyID, creator, date, text}) {
  return new RedlineNode(redlineType, partyID, creator, date, text);
}

export function $isRedlineNode(node) {
  return node instanceof RedlineNode;
}
