import React, { useContext } from 'react';
import { globalStore } from '../state/store';
import { Button, ListItemText, Menu, MenuItem } from '@mui/material';
import theme from '../theme/theme';
import { trunc, countryToFlag, getColorForPartyID } from '../utils'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/pro-solid-svg-icons";

export default function MenuPopEntities(props) {
    // variant: "smallicon" and "bigbutton"

    const styles = {
        removeIcon: {
            fontSize: '14px',
            color: Boolean(props.partyID) ? getColorForPartyID(props.partyID) : theme.palette.primary.main,
            '&:hover': {
                color: Boolean(props.partyID) ? getColorForPartyID(props.partyID, 'dark') : theme.palette.primary.dark,
                cursor: 'pointer',
            }
        },
        /*
        removeIconPrimary: {
            fontSize: '14px',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.dark,
                cursor: 'pointer',
            }
        },
        removeIconSecondary: {
            fontSize: '14px',
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.secondary.dark,
                cursor: 'pointer',
            }
        },*/
    }

    const [state] = useContext(globalStore);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleSwitchClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectEntity = (id) => {
        props.selectEntity(id)
        handleClose()
    }
  
    return (
    <div>
        {['bigbutton'].includes(props.variant) ?

            <Button variant="contained" color={props.type} disableElevation size="large"
            sx={{padding: '7px 30px'}}
            onClick={handleSwitchClick}
            >Select&nbsp;&nbsp;<FontAwesomeIcon icon={faClone} /></Button>
        :
            
            <FontAwesomeIcon icon={faClone} 
            style={styles.removeIcon} 
            onClick={handleSwitchClick} />

        }

        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {props.entities
            .sort((a,b) => (a.legalName > b.legalName) ? 1 : ((b.legalName > a.legalName) ? -1 : 0))
            .map((ent, i) => (
            <MenuItem key={i} onClick={e => selectEntity(ent._id)} style={{width: 280}}>
                <ListItemText 
                    //primary={trunc(ent.shortName, 30)} 
                    primary={
                        <>{trunc(ent.legalName, 27)}&nbsp;&nbsp;{(ent.address !== undefined && ent.address[0] !== undefined && ent.address[0].country !== undefined ? 
                        countryToFlag(ent.address[0].country) : '')}</>} />
            </MenuItem>
            ))}
        </Menu>
    </div>
    );
}