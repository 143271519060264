import { $isElementNode, $isRangeSelection, $isTextNode, ElementNode, $getSelection } from 'lexical';
import { $createRedlineNode } from "./RedlineNode";
import { addClassNamesToElement, removeClassNamesFromElement } from '@lexical/utils';

export class MarkNode extends ElementNode {
  __ids;

  static getType() {
    return 'mark';
  }

  static clone(node) {
    return new MarkNode(Array.from(node.__ids), node.__key);
  }

  constructor(ids, key) {
    super(key);
    this.__ids = ids || [];
  }

  static importJSON(serializedNode) {
    const node = $createMarkNode(serializedNode.ids); //clauseTypes, workflows, libIDs, filter, lock
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'mark',
      ids: this.getIDs(),
      version: 1,
    };
  }

  createDOM(config) {
    const element = document.createElement('mark');

    if(this.__ids.length > 1 && this.__ids.some((i) => i.startsWith('cp_'))) {
      addClassNamesToElement(element, '__markOverlapC');
    } else if(this.__ids.length > 1 && this.__ids.some((i) => i.startsWith('cp'))) {
      addClassNamesToElement(element, '__markOverlapIC');
    } else if(this.__ids.length > 1 && this.__ids.some((i) => i.startsWith('ap'))) {
      addClassNamesToElement(element, '__markOverlapAP');
    } else if(this.__ids.length > 1 && this.__ids.some((i) => i.startsWith('pa'))) {
      addClassNamesToElement(element, '__markOverlapPA');
    } else if(this.__ids.some((i) => i.startsWith('cp_'))) {
      addClassNamesToElement(element, '__markComment');
    } else if(this.__ids.some((i) => i.startsWith('cp'))) {
      addClassNamesToElement(element, '__markCommentInternal');
    } else if(this.__ids.some((i) => i.startsWith('ap'))) {
      addClassNamesToElement(element, '__markApproval');
    } else if(this.__ids.some((i) => i.startsWith('pa'))) {
      addClassNamesToElement(element, '__markParam');
    }
    if (this.__ids.length > 1) {
      addClassNamesToElement(element, '__markOverlap');
    }
    return element;
  }

  updateDOM(
    prevNode,
    element,
    config,
  ) {
    const prevIDs = prevNode.__ids;
    const nextIDs = this.__ids;
    const prevIDsCount = prevIDs.length;
    const nextIDsCount = nextIDs.length;
    const overlapTheme = config.theme.markOverlap;

    if (prevIDsCount !== nextIDsCount) {
      if (prevIDsCount === 1) {
        if (nextIDsCount === 2) {
          addClassNamesToElement(element, overlapTheme);
        }
      } else if (nextIDsCount === 1) {
        removeClassNamesFromElement(element, overlapTheme);
      }
    }
    return false;
  }

  importDOM() {
    return null;
  }

  hasID(id) {
    const ids = this.getIDs();
    for (let i = 0; i < ids.length; i++) {
      if (id === ids[i]) {
        return true;
      }
    }
    return false;
  }

  getIDs() {
    const self = this.getLatest();
    return $isMarkNode(self) ? self.__ids : [];
  }

  addID(id) {
    const self = this.getWritable();
    if ($isMarkNode(self)) {
      const ids = self.__ids;
      self.__ids = ids;
      for (let i = 0; i < ids.length; i++) {
        // If we already have it, don't add again
        if (id === ids[i]) return;
      }
      ids.push(id);
    }
  }

  deleteID(id) {
    const self = this.getWritable();
    if ($isMarkNode(self)) {
      const ids = self.__ids;
      self.__ids = ids;
      for (let i = 0; i < ids.length; i++) {
        if (id === ids[i]) {
          ids.splice(i, 1);
          return;
        }
      }
    }
  }

  insertNewAfter(selection) {
    const element = this.getParentOrThrow().insertNewAfter(selection);
    if ($isElementNode(element)) {
      const linkNode = $createMarkNode(this.__ids);
      element.append(linkNode);
      return linkNode;
    }
    return null;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  canBeEmpty() {
    return false;
  }

  isInline() {
    return true;
  }

  extractWithChild(
    child,
    selection,
    destination, // : 'clone' | 'html'
  ) {
    if (!$isRangeSelection(selection) || destination === 'html') {
      return false;
    }
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = anchor.getNode();
    const focusNode = focus.getNode();
    const isBackward = selection.isBackward();
    const selectionLength = isBackward
      ? anchor.offset - focus.offset
      : focus.offset - anchor.offset;
    return (
      this.isParentOf(anchorNode) &&
      this.isParentOf(focusNode) &&
      this.getTextContent().length === selectionLength
    );
  }

  excludeFromCopy(destination) {
    return destination !== 'clone';
  }
}

export function $createMarkNode(ids) {
  return new MarkNode(ids);
}

export function $isMarkNode(node) {
  return node instanceof MarkNode;
}

export function $unwrapMarkNode(node) {
  const children = node.getChildren();
  let target = null;
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (target === null) {
      node.insertBefore(child);
    } else {
      target.insertAfter(child);
    }
    target = child;
  }
  node.remove();
}


export function $wrapSelectionInMarkNode(selection, isBackward, id) {
  const nodes = selection.getNodes();
  const anchorOffset = selection.anchor.offset;
  const focusOffset = selection.focus.offset;
  const nodesLength = nodes.length;
  const startOffset = isBackward ? focusOffset : anchorOffset;
  const endOffset = isBackward ? anchorOffset : focusOffset;
  const startKey = isBackward ?  selection.focus.key : selection.anchor.key;
  const endKey =  isBackward ? selection.anchor.key : selection.focus.key;
  let currentNodeParent;
  let currentMarkNode;

  // We only want wrap adjacent text nodes, line break nodes
  // and inline element nodes. For decorator nodes and block
  // element nodes, we stop out their boundary and start again
  // after, if there are more nodes.
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i];
    if ($isElementNode(currentMarkNode) && currentMarkNode.isParentOf(node)) {
      continue;
    }
    const isFirstNode = i === 0;
    const isLastNode = i === nodesLength - 1;
    let targetNode;

    //console.log("nx", node.getTextContent(), node.getKey(), startKey, startOffset, endKey, endOffset)

    if ($isTextNode(node)) {
      const textContentSize = node.getTextContentSize();
      const startTextOffset = isFirstNode ? startOffset : 0;
      const endTextOffset = isLastNode ? endOffset : textContentSize;
      if (startTextOffset === 0 && endTextOffset === 0) {
        continue;
      }

      if(['redline'].includes(node.getType())) {

        let splittedRedlines = splitCustomNode(node, startTextOffset, endTextOffset)
        node.replace(splittedRedlines[0])
        let selection = $getSelection();
        selection.anchor.key = splittedRedlines[0].getKey();
        selection.anchor.offset = splittedRedlines[0].getTextContent().length
        selection.focus = selection.anchor;
        selection.insertNodes(splittedRedlines.slice(1));

        targetNode = splittedRedlines.length > 1 &&
          (splittedRedlines.length === 3 ||
            (isFirstNode && !isLastNode) ||
            endTextOffset === textContentSize)
            ? splittedRedlines[1]
            : splittedRedlines[0];
  
      } else { // regular textNode
        const splitNodes = node.splitText(startTextOffset, endTextOffset);

        targetNode =
          splitNodes.length > 1 &&
          (splitNodes.length === 3 ||
            (isFirstNode && !isLastNode) ||
            endTextOffset === textContentSize)
            ? splitNodes[1]
            : splitNodes[0];

      }
          
    } else if ($isElementNode(node) && node.isInline()) {

      //console.log("NODE!", node.getChildren())
      if(['mark'].includes(node.getType())){
                
        //let startCursor = node.getKey() !== startKey ? 0 : startOffset
        //let startCursor = node.getChildren().some((c) => c.getKey() === startKey) ? startOffset : 0
        let endCursor = node.getChildren().some((c) => c.getKey() === endKey) ? endOffset : node.getTextContent().length
        //let endCursor = node.getKey() !== endKey ? node.getTextContent().length : endOffset

        
        //const textContentSize = node.getTextContentSize();
        //const startTextOffset = isFirstNode ? startOffset : 0;
        //const endTextOffset = isLastNode ? endOffset : textContentSize;
        //console.log("children", node.getChildren(), node.getTextContent(), startTextOffset, endTextOffset, endOffset)
        
        node.getChildren().forEach((child) => {
          if($isTextNode(child)) {
            //console.log("CHILD", child)
            //if(startCursor !== 0) {} // todo: understand scenario - split whereby the child
            if(endCursor > 0 && endCursor < child.getTextContent().length) {
              let splittedNodes = child.splitText(endCursor)
              targetNode = splittedNodes[0];
            } else {
              endCursor = endCursor - child.getTextContent().length;
            }
          }
        })
        
        //let splittedNodes = node.splitText(0,2);
        //console.log("split!", splittedNodes)
      } else {
        targetNode = node;
      }
    }
    //console.log("cyclex", node, targetNode)
    if (targetNode !== undefined) {
      //console.log("cyclexx")
      if (targetNode && targetNode.is(currentNodeParent)) {
        continue;
      }
      const parentNode = targetNode.getParent();
      if (parentNode == null || !parentNode.is(currentNodeParent)) {
        currentMarkNode = undefined;
      }
      currentNodeParent = parentNode;
      if (currentMarkNode === undefined) {
        currentMarkNode = $createMarkNode([id]);
        targetNode.insertBefore(currentMarkNode);
      }
      currentMarkNode.append(targetNode);
    } else {
      currentNodeParent = undefined;
      currentMarkNode = undefined;
    }
  }
}

function splitCustomNode(node, start, end) {
  let returnNodes = []
  let type = node.getType()

  //type
  //$createMarkNode(ids)
  const newNode = 
    ['redline'].includes(type) ? {
      redlineType: node.getRedlineType(), 
      partyID: node.getPartyID(), 
      creator: node.getCreator(), 
      date: node.getDate()
    }
    :
    {}
  
  const curIDs = ['mark'].includes(type) ? node.getIDs() : []
  console.log("curIDs", curIDs)

  const actualStart = 
    end !== undefined && end !== null && start !== undefined && start !== null && end < start ? 
        end :
    start !== undefined && start !== null ? 
        start : 
        0;    
  const actualEnd = 
    end !== undefined && end !== null && start !== undefined && start !== null && end < start ? 
        start : 
    end !== undefined && end !== null && end > start && end < node.getTextContent().length ?
        end : 
        null

  if(actualStart > 0) {
    returnNodes.push(
      ['redline'].includes(type) ? $createRedlineNode({...newNode, text: node.getTextContent().substring(0, actualStart) }) : 
      $createMarkNode(curIDs)
    )
  }

  if(actualEnd !== null) {
    returnNodes.push(
      ['redline'].includes(type) ? $createRedlineNode({...newNode, text: node.getTextContent().substring(actualStart, actualEnd) }) : 
      $createMarkNode(curIDs)
    )
    returnNodes.push(
      ['redline'].includes(type) ? $createRedlineNode({...newNode, text: node.getTextContent().substring(actualEnd) }) : 
      $createMarkNode(curIDs)
    )
  } else {
    returnNodes.push(
      ['redline'].includes(type) ? $createRedlineNode({...newNode, text: node.getTextContent().substring(actualStart) }) : 
      $createMarkNode(curIDs)
    )
  }
  
  if(returnNodes.length === 0) {
    returnNodes.push(node)
  }

  return returnNodes;

}

export function $getMarkIDs(
  node,
  offset,
) {
  let currentNode = node;
  while (currentNode !== null) {
    if ($isMarkNode(currentNode)) {
      return currentNode.getIDs();
    } else if (
      $isTextNode(currentNode) &&
      offset === currentNode.getTextContentSize()
    ) {
      const nextSibling = currentNode.getNextSibling();
      if ($isMarkNode(nextSibling)) {
        return nextSibling.getIDs();
      }
    }
    currentNode = currentNode.getParent();
  }
  return null;
}

//export {$createMarkNode, $isMarkNode, MarkNode};