import React, { useContext, useEffect } from 'react';
import theme from '../theme/theme';
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import { Autocomplete, Button, Box, Dialog, DialogActions, DialogContent, 
         DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography, Chip,
         createFilterOptions } from '@mui/material';
import { trunc, randomString as generateRandomString } from '../utils';
import { ButtonGroupPills, CanveoCircularProgress, SelectLabels, SelectOrCreateAgrType } from '.';
import { globalStore } from '../state/store';

//import LexicalComposer from '@lexical/react/LexicalComposer';
//import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
//import { editorConfig } from './editor/utils';
//import { ImportPlugin } from './editor/plugins';
import { importHTML } from './editor/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faArrowLeft, faTimes, faTag, faFileLines, faHashtag, faFileCirclePlus, faCircle } from '@fortawesome/pro-solid-svg-icons';

import docx from '../assets/img/docx.png'

export default function DialogTemplate(props) {
  const styles = {
    labelChipSelect: {
        padding: '1px 3px',
        margin: '0px 3px 3px 0px', 
        fontSize: '12px',
        fontWeight: '600',
    }
  }

  //const [editor] = useLexicalComposerContext();
  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const [dropHover, setDropHover] = React.useState(false);
  const [stagedTemplate, setStagedTemplate] = React.useState({ source: 'import', agrType: null, agrTypeID: null, sourceID: null, reference: '', labels: [] });
  const [templateOptions, setTemplateOptions] = React.useState([]);

  useEffect(() => { // Initialize the templateOptions searchBox - assign name from Agreement Type
    const topts = []
    state.templates.forEach((st) => {
        let at = state.agrTypes.filter((at) => at._id === st.agrTypeID)[0] !== undefined &&
            state.agrTypes.filter((at) => at._id === st.agrTypeID)[0].fullName !== undefined ?
                state.agrTypes.filter((at) => at._id === st.agrTypeID)[0] : null
        if(at !== null) {
            let activeString = st.active ? "active" : "draft"
            let labelString = ''
            st.labelIDs.forEach((lid) => {
                let l = state.labels.filter((l) => l._id === lid)[0]
                if(l !== undefined && l.name !== undefined) {
                    labelString = labelString + l.name + ","
                }
            })
            topts.push({
                _id: st._id,
                agrTypeID: at._id,
                name: at.fullName[0],
                shortName: at.shortName, 
                reference: st.reference,
                active: st.active,
                version: st.version,
                labelIDs: st.labelIDs,
                activeString: activeString,
                labelString: labelString,
            })
        }
    })
    setTemplateOptions(topts.sort((a, b) => (a.name > b.name) ? 1 : -1))
  }, [state.templates])

  const reInitalize = () => {
    setLoading(false)
    setErrMsg(null)
    setDropHover(false)
    setStagedTemplate({ source: 'import', agrType: null, agrTypeID: null, sourceID: null, reference: '', labels: [] })
  }

  const closeDialog = (snack) => {
    props.closeDialog(snack);
    reInitalize()
  };

  const handleUploadChange = (file) => {

    setErrMsg(null);
    setLoading(true);

    let fileType =
          (file.name !== undefined && file.name.endsWith(".docx")) || 
          (file.type !== undefined && ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) ? 
              "docx" :
              null

    if(fileType !== null) {
      var formData = new FormData()
      formData.append('file', file, generateRandomString(20));
  
      axios.post(state.settings.api + "upload/file", formData , {
          headers: {
              'accept': 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data;`,
          }
      }).then((res) => {

          if(res.data.success) {

            let filename = res.data.data;

            axios.get(state.settings.api + "download/html/" + filename)
            .then((res) => {

              if(res.data.success && Boolean(res.data.data) && Boolean(res.data.data.html)) {

                //let content = editor.dispatchCommand(IMPORT_HTML_COMMAND, res.data.data.html);
                let importedAg = importHTML(res.data.data.html, state.agrTypes, state.clauseTypes)
                

                console.log("importedAg",importedAg)
                //console.log("content", content)

                let st = {
                  source: 'import',
                  sourceID: filename,
                  agrType: Boolean(importedAg) && Boolean(importedAg.agrTypeID) ? 
                    state.agrTypes.filter((at) => at._id === importedAg.agrTypeID)[0] : null,
                  agrTypeID: Boolean(importedAg) && Boolean(importedAg.agrTypeID) ? 
                    importedAg.agrTypeID : null,
                  reference: '',
                  content: Boolean(importedAg) && Boolean(importedAg.content) ? importedAg.content : [],
                  labels: [],
                }
                setStagedTemplate(st)
                // TODO
                // Trigger from backend
                // Download the file from GCP
                // Convert DocX to HTML (mammoth) => https://www.npmjs.com/package/mammoth
                // Convert HTML to Lexical => https://lexical.dev/docs/concepts/serialization#html---lexical
                // Normalize to Canveo doc => (1) root only has children of type "clause"
                // Normalize to Canveo doc => (2) "clause" only has children of type "paragraph", "list", "heading" or no children ([])
  
                setLoading(false)

              } else { setErrMsg("An error occurred while converting the file (interim step)"); setLoading(false); }
            }).catch((err) => { setErrMsg("Unable to convert file (interim step)"); setLoading(false); })
          } else { setErrMsg("An error occurred while uploading the file"); setLoading(false);  }
      }).catch((err) => { setErrMsg("Unable to upload the file"); setLoading(false); })
      } else { setErrMsg("Unable to recognize the file type"); setLoading(false); }
  };

  const handleLabelSelect = (newLabels) => {
    let newlids = []
    newLabels.forEach((nl) => { newlids.push(nl._id) })
    setStagedTemplate({...stagedTemplate, labels: newlids})
  }

  const handleSourceSelect = (newVal) => {
    let st = {
        source: 'copy',
        sourceID: newVal._id,
        agrType: state.agrTypes.filter((at) => at._id === newVal.agrTypeID)[0],
        agrTypeID: newVal.agrTypeID,
        reference: '',
        labels: newVal.labelIDs,
    }
    setStagedTemplate(st)
  }

  const handleAgrTypeChange = (changeType, value) => {
    if(['newName'].includes(changeType)) { // You're creating a new Agr Type => defining name
        setStagedTemplate({...stagedTemplate, 
            agrType: { 
                newName: value, 
                shortName: stagedTemplate.agrType.newName !== undefined &&
                    stagedTemplate.agrType.shortName !== undefined ? 
                        stagedTemplate.agrType.shortName : ''
            }})

    } else if(['shortName'].includes(changeType)) { // You're creating a new Agr Type => defining short name
        setStagedTemplate({...stagedTemplate, agrType: {...stagedTemplate.agrType, shortName: value}})

    } else if(['agrType'].includes(changeType)) { // You've picked an existing Agr Type
        setStagedTemplate({...stagedTemplate, agrType: value})
    }
  }

  const handleCreateTemplate = () => {
    setLoading(true)

    let newTemplate = {
        blueprintID: generateRandomString(20),
        defaultChildren: [],
        reference: stagedTemplate.reference,
        orgID: state.org._id,
        content: stagedTemplate.content, // TODO => Fill up content based on previous template
        active: false,
        effectiveDateType: 'signoff',
        renewalType: 'none',
        effectiveTerm: null,
        version: '001',
        labelIDs: stagedTemplate.labels,
        creationBy: state.user._id,
        creationDate: new Date().toISOString(),
        lastUpdateBy: state.user._id,
        lastUpdateDate: new Date().toISOString(),
    }

    if(stagedTemplate.agrType !== undefined && stagedTemplate.agrType !== null &&
    stagedTemplate.agrType.newName !== undefined && stagedTemplate.agrType.shortName !== undefined &&
    stagedTemplate.agrType.newName !== null && stagedTemplate.agrType.shortName !== null &&
    stagedTemplate.agrType.newName.length > 1 && stagedTemplate.agrType.shortName.length > 1) {
        // Creating a new Agreement Type
        let newat = {
            fullName: stagedTemplate.agrType.newName,
            shortName: stagedTemplate.agrType.shortName,
            orgID: state.org._id,
            active: true
        }
        // Create the new Agreement type
        axios.post(state.settings.api + 'agrtype', { agrType: newat })
        .then((resAgrt) => {
          if(resAgrt.data.success) { // Add newly created agrType to the reducer
            // Create with the new AgrType
            dispatch({ type: "ADD_AGRTYPE", payload: resAgrt.data.data })
            pushTemplate(resAgrt.data.data, newTemplate)
          } else { setErrMsg("An error occured while creating the agreement type - refresh your browser"); setLoading(false); }
        }).catch((err) => { setErrMsg("An error occured while creating the agreement type"); setLoading(false); })

    } else if(stagedTemplate.agrType !== undefined && stagedTemplate.agrType !== null && 
    stagedTemplate.agrType.fullName !== undefined && stagedTemplate.agrType.shortName !== undefined) {
        // Selected an Agreement Type
        pushTemplate(stagedTemplate.agrType, newTemplate)     

    } else { setErrMsg("Please re-select the agreement type"); setLoading(false) }
  }

  const pushTemplate = (at, newtempl) => {

    newtempl.agrTypeID = at._id;
    newtempl.agrTitle = at.fullName[0];
    newtempl.agrShortName = at.shortName;
    
    axios.post(state.settings.api + "template", { template: newtempl })
    .then((resTemp) => {
      if(resTemp.data.success) { // Add newly created template to the reducer
        dispatch({ type: "ADD_TEMPLATE", payload: resTemp.data.data })
        setLoading(false);
        closeDialog('snackTemplateCreated')
      } else { setErrMsg("An error occured while creating the template - refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("An error occured while creating the template"); setLoading(false); })
  }

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>
            {loading ? "Loading..." : "Create a new Template"}
        </DialogTitle>
        <DialogContent>
          
            {loading ? 
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 14}}>

                <CanveoCircularProgress />

            </Box>
            : 
            // Step 2 - select the target type, ref and labels
            Boolean(stagedTemplate.sourceID) ?
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 8, mb: 6}}>

                <Grid container direction="column" alignItems="center">
                    {errMsg !== null ? // An error exists - show it
                    <Grid item>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 4}}>
                        <Typography align="center" color="error">{errMsg}</Typography>
                    </Box>
                    </Grid>:''}
                    
                    <SelectOrCreateAgrType
                    agrType={stagedTemplate.agrType}
                    setAgrType={handleAgrTypeChange}
                    width={'320px'}
                    />

                    <Grid item sx={{my:2, width: '320px'}}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            id="firstName"
                            label={"New Reference"}
                            autoFocus={Boolean(stagedTemplate.agrType)}
                            placeholder={"e.g. \"DEFAULT\""}
                            value={stagedTemplate.reference}
                            onChange={e => setStagedTemplate({...stagedTemplate, reference: e.target.value})}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faHashtag} />
                                </InputAdornment>
                                ),
                            }}
                        />

                    </Grid>
                    <Grid item sx={{my: 2, width: '320px'}}>
                        <SelectLabels
                        adornIcon={faTag}
                        handleLabelSelect={handleLabelSelect}
                        selectedIDs={stagedTemplate.labels}
                        />
                    </Grid>

                </Grid>
            </Box>
            : 
            // Step 1 - select the source for the template - Default
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 7, mb: 5}}>

              <Grid container direction="column" alignItems="center">
                <Grid item sx={{mb:3}}>
                    <ButtonGroupPills
                    buttons={[
                      { id: "import", title: "Import Template" },
                      { id: "copy", title: "Copy Template" },
                    ]}
                    selected={stagedTemplate.source}
                    click={(e) => setStagedTemplate({...stagedTemplate, source: e})}
                    />
                </Grid>

                {['import'].includes(stagedTemplate.source) ?
                <Grid item>
                  <FileUploader 
                  handleChange={handleUploadChange} 
                  name="uploadfile" 
                  types={["docx"]} 
                  label={"Upload or drop a file here"}
                  maxSize={20}
                  minSize={0}
                  onDraggingStateChange={(dragging) => setDropHover(dragging)}
                  hoverTitle={" "}
                  children={
                    <Box sx={{ width: '320px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
                    border: dropHover ? ('2px solid' + theme.palette.primary.main) : ('1px dotted' + theme.palette.grey[300]), 
                    backgroundColor: dropHover ? theme.palette.grey[200] : theme.palette.grey[50], 
                    padding: '30px', fontSize: '14px', fontWeight: '600', borderRadius: '20px'}}>
                    <Grid container direction="column" alignItems="center">
                    <Grid item>
                    <FontAwesomeIcon icon={faUpload} style={{color: theme.palette.primary.main, fontSize: '30px'}} />
                    </Grid>
                    <Grid item>
                    <Box sx={{mt:1, display:'block', textAlign: 'center'}}>
                        <>{dropHover ? 
                            "Time to let the DOCX go..." : 
                            "Upload or drop DOCX file here..."}<br/>
                        <Grid container direction="row" spacing={1} justifyContent="center" sx={{mt:1}}>
                            <Grid item><img src={docx} alt="docx" width={20} /></Grid>
                        </Grid>
                    </></Box>
                    </Grid>
                    </Grid> 
                    </Box>
                  }
                  />
                </Grid>
                :
                <Grid item>
                    <Autocomplete
                    id="filter-sourceTemplates"
                    sx={{width: '380px', mt: 8, mb:8}}
                    options={templateOptions}
                    onChange={(e, newVal) => handleSourceSelect(newVal)}
                    filterSelectedOptions
                    /*filterOptions={(options, params) => {
                        console.log(params)
                        const filtered = filter(
                            options.filter((o) =>
                                params.inputValue === '' ||
                                o.name.includes(params.inputValue) || 
                                o.shortName.includes(params.inputValue) ||
                                o.reference.includes(params.inputValue) ||
                                o.activeString.includes(params.inputValue) ||
                                o.labelString.includes(params.inputValue)),
                            params);
                        return filtered;
                    }}*/
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component={"li"} {...props} key={option._id} sx={{borderBottom: '1px solid' + theme.palette.grey[200]}}>
                        <Grid container direction="column">
                            <Grid item container direction="row" justifyContent="space-between" alignItems="center" sx={{pt:1}}>
                                <Grid item>
                                    <Typography variant="subtitle1">{option.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography style={{color: option.active ? theme.palette.primary.main : theme.palette.grey[500], fontWeight: '600', fontSize: '13px'}}>
                                        {option.active ? "Active" : "Draft"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems="flex-start">
                                <Typography variant="body2" style={{color: theme.palette.grey[600]}}>
                                    <span>{option.reference}</span>
                                    &nbsp;&nbsp;-&nbsp;&nbsp;v{option.version}
                                </Typography>
                            </Grid>
                            <Grid item container alignItems="flex-start" sx={{pt:1, pb:1}}>
                                {option.labelIDs
                                .filter((li) => state.labels.some((l) => l._id === li))
                                .map((li,j) => {
                                    let label = state.labels.filter((l) => l._id === li)[0]
                                    return (
                                        <Chip
                                        key={j}
                                        size="small"
                                        //variant="outlined"
                                        label={label.name}
                                        style={styles.labelChipSelect}
                                        color={label.colorType}
                                        />
                                    )
                                })
                                }

                            </Grid>
                        </Grid>
                        </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Source Template"
                        placeholder="Source Template..."
                        sx={{width: '100%'}}
                        InputProps={
                        {
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                <FontAwesomeIcon icon={faFileLines} />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    />
                </Grid>
                }
              </Grid>
              
            </Box>
            }
        </DialogContent>
        <DialogActions>
            {Boolean(stagedTemplate.sourceID) ?
            <>
            <Button sx={{marginRight: 'auto'}} onClick={reInitalize}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go back</Button>
            <Button variant="contained" disableElevation
            disabled={
                ((stagedTemplate.agrType === undefined || stagedTemplate.agrType === null || 
                    (stagedTemplate.agrType._id !== undefined && stagedTemplate.agrType.fullName === undefined) ||
                    (stagedTemplate.agrType._id === undefined && 
                     (stagedTemplate.agrType.newName === undefined || stagedTemplate.agrType.newName === null || stagedTemplate.agrType.newName.length < 2 ||
                      stagedTemplate.agrType.shortName === undefined || stagedTemplate.agrType.shortName === null || stagedTemplate.agrType.shortName.length < 2)
                 )) ||
                (stagedTemplate.reference === undefined || stagedTemplate.reference === null || stagedTemplate.reference === '') ||
                (stagedTemplate.agrType !== undefined && stagedTemplate.agrType !== null &&
                 stagedTemplate.agrType._id !== undefined && stagedTemplate.agrType._id !== null && stagedTemplate.agrType._id !== '' &&
                 state.templates.some((st) => st.agrTypeID === stagedTemplate.agrType._id && st.reference === stagedTemplate.reference)))}
            onClick={handleCreateTemplate}
            >
            Create Template&nbsp;&nbsp;<FontAwesomeIcon icon={faFileCirclePlus} />
            </Button>
            </>
            :
            <Button sx={{marginRight: 'auto'}} onClick={closeDialog}>Cancel</Button>
            }
        </DialogActions>
      </Dialog>
    </div>
  );
}