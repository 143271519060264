const agrsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_AGRS":
            return { agrs: action.payload };
        case "UPDATE_AGRS":
            let newVal = action.payload;
            let newAgrs = state.agrs;
            let idx = state.agrs.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newAgrs[idx] = newVal;
            }
            return { agrs: newAgrs };
        default:
            return { agrs: state.agrs }
    }
};
  
export default agrsReducer