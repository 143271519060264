import React from 'react';
import theme from '../theme/theme';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { trunc } from '../utils';
import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan} from '@fortawesome/pro-solid-svg-icons';

import pdf from '../assets/img/pdf.png'
import xlsx from '../assets/img/xlsx.png'
import pptx from '../assets/img/pptx.png'
import docx from '../assets/img/docx.png'

function Column(props){
  const { id, exhibits, handleRemoveExhibit } = props;

  return (

      <Droppable droppableId={id}>
      {provided => (

         <div {...provided.droppableProps} ref={provided.innerRef}>
           {exhibits
           .map((ex,index)=>{
              return (
                <Task 
                id={Boolean(ex.tempKey) ? ex.tempKey : ex._id} // tempKey is needed for new Agreements where not all staged exhibits have an _id yet
                key={index} 
                index={index}
                item={ex}
                handleRemoveExhibit={handleRemoveExhibit}
                />)
           })}

          {provided.placeholder}
         </div>
       )
      }
      </Droppable>
  )
}

function Task(props){

  const { id, index, item, handleRemoveExhibit } = props;

  return (
     <Draggable draggableId={id} key={id} index={index}>

       {(provided) => (
          <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps} 
          align="center">
  
            <RenderBox item={item} handleRemoveExhibit={handleRemoveExhibit} />

          </Box>
      )}
     </Draggable>
  )
}


const RenderBox = ({ item, handleRemoveExhibit }) => {

  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const styles = {
    boxStyle: {
        width: '260px',
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
            cursor: 'pointer',
        }, //props.thumbClick !== null ? : {},
        padding: '2px 0px 2px 0px',
        border: '1px solid' + theme.palette.grey[300],
        borderRadius: '20px',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.contrastText,
        my: 1
    }
  }

  const [isHover, setIsHover] = React.useState(false);
  const isFile = ['pdf', 'pptx', 'xlsx', 'docx'].includes(item.fileType)
  
  return (
      <Box key={item._id} sx={styles.boxStyle} 
      style={item.isNew ? { backgroundColor: theme.palette.grey[50], border: '1px dotted' + theme.palette.grey[400] } : {}}
      onMouseOver={e => setIsHover(true)}
      onMouseLeave={e => setIsHover(false)}
      >
          <Grid container direction="row" alignItems="center" justifyContent="center" sx={{height: '46px'}}>
            <Grid item xs={2}>
              {isHover ?
              <IconButton onClick={e => handleRemoveExhibit(item._id)}>
                <FontAwesomeIcon icon={faTrashCan} style={{fontSize: '14px', color: theme.palette.error.main, padding: '1px 2px'}} />
              </IconButton>:
              ' '}              
            </Grid>
            <Grid item xs={8}>
              <Typography align="center" style={{fontWeight: '700', lineHeight: '15px', fontSize: smUp ? '14px' : '12px'}}>
              {trunc(item.agrTitle, 20)}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{padding: '2px 6px 0px 6px'}}>
            {isFile ? <img src={
              ['pdf'].includes(item.fileType) ? pdf:
              ['pptx'].includes(item.fileType) ? pptx :
              ['xlsx'].includes(item.fileType) ? xlsx : docx} width={15} />: ''}
            </Grid>

          </Grid>
          
      </Box>
  )  
}

const ThumbExhibitView = (props) => {

  /*
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = props.exhibits
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      //setExhibits(data)
      props.handleUpdate(data)
    },
    nodeSelector: 'li',
    handleSelector: 'li',
    lineClassName: 'dropLine',
  };

  const onDragEnd = (result) => { // Loop through the ordered list of signatories and assign to the respective objects
      

      
      
      let newSigners = []
      reorderedSigners.forEach((signer, i) => {
          newSigners.push({...signer, order: i})
      })
      setSigners([...newSigners]);
      setCollabOrSignerUpdate(true);
  }
  */

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const onDragEnd = (result) => {
    if (!result.destination) { return; }
    if (result.destination.index === result.source.index) { return; }

    let ol = props.exhibits.sort((a,b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))
    const reorderedExhibits = reorder(ol, result.source.index, result.destination.index);

    props.handleUpdate(reorderedExhibits)
  }

  const handleRemoveExhibit = (id) => {
    console.log("remove", id)
    let newExhibits = props.exhibits.filter((e) => e._id !== id);
    props.handleUpdate(newExhibits)
  }


  return (
    <div>

        <DragDropContext onDragEnd={onDragEnd}>

          <Column id="1" 
          handleRemoveExhibit={handleRemoveExhibit}
          exhibits={props.exhibits} 
          />

        </DragDropContext>


{/*
        <ReactDragListView {...dragProps} lineClassName="dropLine">
            <ol style={{listStyleType: 'none', margin: '0px', padding: '0px'}}>
            {props.exhibits
            .map((item, index) => (
                <li key={index}>
                    <RenderBox item={item} handleRemoveExhibit={handleRemoveExhibit} />
                </li>
            ))}
            </ol>
        </ReactDragListView>
*/}

        {/*props.exhibits.map((ex,i) => (

            <Box key={i}
            sx={{
                [theme.breakpoints.only('xs')]: { width: '150px' },
                [theme.breakpoints.only('sm')]: { width: '180px' },
                [theme.breakpoints.up('md')]: { width: '200px' },
                '&:hover' : props.thumbClick !== null ? {
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
                    cursor: 'pointer',
                } : {},
                padding: '2px 0px 2px 0px',
                border: '1px solid' + theme.palette.grey[300],
                borderRadius: '20px',
                textAlign: 'center',
                py: 2, px: 2, my: 1
            }}
            onClick={props.thumbClick !== null ? props.thumbClick : e => null}
            >
                <Typography align="center" style={{fontWeight: '700', lineHeight: '15px', fontSize: smUp ? '14px' : '12px'}}>
                {trunc(ex.agrTitle, 20)}
                </Typography>
            </Box>

        ))*/}

    </div>
  );
};
export default ThumbExhibitView;