import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { pad, trunc } from '../utils';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Chip, ClickAwayListener, Grid,
         Menu, MenuItem, List, ListItemIcon, ListItemText, Snackbar, ListItemButton,
         Stack, Pagination, PaginationItem }  from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import { CanveoCircularProgress, DialogAlert } from '.';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faDownload, faHistory, faLightbulb, faFileContract, 
         faList, faQuoteLeft, faCopyright, 
         faRepeat, faUserSecret, faArrowLeft,
         faSignature, faUniversity, faUsers, faCaretDown, 
         faToggleOn, faToggleOff, faClone, faTrash, faTrashList, faFileLines, 
         faTags, faBook, faFilePen } from '@fortawesome/pro-solid-svg-icons';

import axios from 'axios';

const dayjs = require('dayjs')

const drawerMiniWidth = 85;
const drawerWidth = 240;
const versionPageSize = 4;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerMiniWidth,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function CoreDrawer(props) {

  const theme = useTheme();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(globalStore);

  const styles = {
    iconForButton: {fontSize: '24px', marginBottom: '5px' },
    iconForButtonActive: {fontSize: '24px', marginBottom: '5px', color: theme.palette.primary.main},
    gridForButton: {
        mb: 3,
        color: theme.palette.grey[700], 
        fontSize: '11px',
        fontWeight: '600',
        '&:hover': { color: theme.palette.primary.main, cursor: 'pointer', fontWeight: '700' }
    }
  }

  const [open, setOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [curVersionPage, setCurVersionPage] = React.useState(1);
  const [definitions, setDefinitions] = React.useState([])

  const handleDrawerOpen = (type) => {
    if(['Versions'].includes(type) && ['Template'].includes(props.page) && Boolean(props.template) && Boolean(props.template.blueprintID)) {
      setLoading(true);
      axios.get(state.settings.api + "template/blueprint/" + props.template.blueprintID)
      .then((resTemplates) => {
        if(resTemplates.data.success) {
          dispatch({ type: "UPDATE_TEMPLATES_FOR_BPID", payload: {
              tid: props.template._id,
              bpid: props.template.blueprintID,
              templates: resTemplates.data.data.filter((t) => t._id !== props.template._id)
            } 
          })
          setOpen(type)
          setLoading(false);

        } else { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to load versions - contact Canveo Support"}); setLoading(false) }
      }).catch((err) => { dispatch({ type: "NEW_SNACKBAR", payload: "Unable to load versions - contact Canveo Support"}); setLoading(false); });

    } else if(['Contents', 'Definitions'].includes(type)) {
      setOpen(type);
    }
  };

  const handleDrawerClose = () => {
    setOpen(null);
  };

  return (

    <ClickAwayListener onClickAway={handleDrawerClose}>
    <Drawer variant={"permanent"} open={Boolean(open)} sx={{zIndex: '500', display: { xs: 'none', sm: 'block' }}}>
    
        {loading ?
        <Box sx={{position: 'fixed', zIndex: '2000', height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CanveoCircularProgress color="inherit" />
        </Box>                
        :''}

        {
        // Editor (Large Drawer) 
        ['Agreement', 'Template'].includes(props.page) && 
        Boolean(open) ?
        
        <Box sx={{pt:'65px', height: '100vh'}}>
          <Box>
            <Grid container direction="row" justifyContent={"space-between"} alignItems="center"
            sx={{backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, height: '34px', padding: '0px 20px', 
            '&:hover': { backgroundColor: theme.palette.primary.dark, cursor: 'pointer' }}}
            onClick={e => setOpen(null)}>
              <Grid item>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Grid>
              <Grid item>
                <span style={{fontWeight: '700', fontSize: '14px'}}>{open}</span>
              </Grid>
              <Grid item>
                &nbsp;
              </Grid>
            </Grid>
          </Box>
          <Box sx={{height: 'calc(100% - 34px)'}}>
          {['Contents'].includes(open) && Boolean(props.toc) ?

            props.toc

          :['Versions'].includes(open) ?
          <>
            <List style={{paddingTop: '0px'}}>
              {state.templates
              .filter((t) => t.blueprintID === props.template.blueprintID)
              .sort((a, b) => (a.version > b.version) ? -1 : 1)
              .slice((curVersionPage - 1) * versionPageSize, curVersionPage * versionPageSize)
              .map((t) => (
                <ListItemButton key={t._id} sx={{padding: '15px 15px'}}
                selected={t._id === props.template._id} onClick={e => navigate('/templates/' + t._id)}>
                  <ListItemText
                    primary={
                      <Box mb={2}>
                      <FontAwesomeIcon icon={faFileContract} style={{ color: theme.palette.primary.main, marginRight: '20px', fontSize: '15px'}} />
                      <span style={{fontWeight: '700'}}>Version {t.version}</span>
                      {t.active ? <Chip color="primary" size="small" label="Active" style={{ fontWeight: '600', marginLeft: '15px'}} /> : ''}
                      </Box>
                    }
                    secondary={
                      <span style={{fontWeight: '300', fontSize: '13px', color: theme.palette.grey[600]}}>
                      <span style={{fontWeight: '700'}}>Last Update:</span> {dayjs(t.lastUpdateDate).format('MMM D, YYYY')}<br/>
                      <span style={{fontWeight: '700'}}>Update by:</span> {trunc(Boolean(state.users.filter((u) => u._id === t.lastUpdateBy)[0]) ? state.users.filter((u) => u._id === t.lastUpdateBy)[0].displayName : "Unknown User", 20)}<br/>
                      <span style={{fontWeight: '700'}}>Created on:</span> {dayjs(t.creationDate).format('MMM D, YYYY')}<br/>
                      <span style={{fontWeight: '700'}}>Created by</span> {trunc(Boolean(state.users.filter((u) => u._id === t.creationBy)[0]) ? state.users.filter((u) => u._id === t.creationBy)[0].displayName : "Unknown User", 20)}
                      </span>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
            <Grid container direction="column" alignItems="center" sx={{mt: 3}}>
              <Grid item>
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(state.templates.filter((t) => t.blueprintID === props.template.blueprintID).length / versionPageSize)}
                  defaultPage={1}
                  page={curVersionPage}
                  onChange={(e, newVal) => setCurVersionPage(newVal)}
                  siblingCount={0}
                  //boundaryCount={2}
                  renderItem={(item) => (
                    <PaginationItem {...item} />
                  )}
                />
              </Stack>
              </Grid>
            </Grid>
          </>
          :['Definitions'].includes(open) && Boolean(props.definitions) ?

            props.definitions
        
          :""}
          </Box>

        </Box>
    
        : 
        // MiniDrawer - buttons will depend on page
        (['Agreement', 'Template'].includes(props.page)/* && ['default', 'helpers'].includes(props.variant)*/) || // One of the Editor drawers
        ['Admin', 'Templates'].includes(props.page) // The Admin Drawer
        ?
            <Box sx={{pt:11}}>
                
                <Grid container direction="column" alignItems={"center"}>
                {(
                // Editor: Default Drawer
                ['Agreement', 'Template'].includes(props.page)/* &&
                ['default'].includes(props.variant)*/ ? 
                [
                  ...['Agreement'].includes(props.page) ? [{name: "Summary", icon: faLightbulb, click: null}] : [],
                  {name: "Versions", icon: faFileContract, click: e => handleDrawerOpen('Versions')},
                  {name: "Contents", icon: faList, click: e => handleDrawerOpen('Contents')},
                  {name: "Definitions", icon: faQuoteLeft, click: e => handleDrawerOpen('Definitions')},
                  ...['Agreement'].includes(props.page) ? [{name: "Messaging", icon: faComments, click: null}] : [],
                  {name: "Audit History", icon: faHistory, click: null},
                  {name: "Download", icon: faDownload, click: null},
                ]
                :
                // The "Templates Page" drawer
                ['Templates'].includes(props.page) ?
                [
                  {name: "Templates", icon: faFileLines, active: props.active === 'templates', click: e => navigate('/templates/main')},
                  {name: "Clause library", icon: faBook, active: props.active === 'library', click: e => navigate('/templates/library')},
                  {name: "Clause topics", icon: faTags, active: props.active === 'topics', click: e => navigate('/templates/topics')}
                ]
                : 
                // The "Admin Page" drawer
                ['Admin'].includes(props.page) ?
                [
                  {name: "Entities", icon: faUniversity, active: props.active === 'entities', click: e => navigate('/admin/entities')},
                  {name: "Users", icon: faUsers, active: props.active === 'users', click: e => navigate('/admin/users')},
                  {name: "Signers", icon: faSignature, active: props.active === 'signers', click: e => navigate('/admin/signers')},
                  {name: "Branding", icon: faCopyright, active: props.active === 'branding', click: e => navigate('/admin/branding')},
                  {name: "Subscription", icon: faRepeat, active: props.active === 'subscription', click: e => navigate('/admin/subscription')},
                  ...['rweg@getcanveo.com', 'dbuser@getcanveo.com'].includes(state.user.email) ? [{name: "ADMIN", icon: faUserSecret, active: props.active === 'superadmin', click: e => navigate('/superadmin')}] : []]
                : 
                []
                )
                .map((b,i) => (
                    <Grid item container direction="column" alignItems="center" key={i}
                    sx={styles.gridForButton} onClick={e => b.click()}>
                        <Grid item>
                          <FontAwesomeIcon icon={b.icon} 
                          style={
                            b.active || b.active === undefined ? 
                                styles.iconForButtonActive : 
                                styles.iconForButton} />
                        </Grid>
                        <Grid style={b.active ? {color: theme.palette.primary.main} : {}}>
                        {b.name}
                        </Grid>
                    </Grid>
                ))}
                </Grid>

            </Box>
        : '' }

    </Drawer>
    </ClickAwayListener>
      

  );
}