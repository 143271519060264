const labelsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_LABELS":
            return { labels: action.payload };
        case "ADD_LABEL":
            return {
                labels: [...state.labels, action.payload]
            };
        case "UPDATE_LABEL":
            let newVal = action.payload;
            let newLabels = state.labels;
            let idx = state.labels.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newLabels[idx] = newVal;
            }
            return { labels: newLabels };
        default:
            return { labels: state.labels }
    }
};
  
export default labelsReducer