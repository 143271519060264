import React, { useContext } from 'react';
import theme from '../theme/theme';
import { FileUploader } from "react-drag-drop-files";
import { globalStore } from '../state/store';
import { randomString as generateRandomString } from '../utils';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ButtonGroupPills, CanveoCircularProgress, CoverFlow, SelectOrCreateAgrType } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faUpload } from '@fortawesome/pro-solid-svg-icons';

import axios from 'axios';

import pdf from '../assets/img/pdf.png'
import docx from '../assets/img/docx.png'
import xlsx from '../assets/img/xlsx.png'
import pptx from '../assets/img/pptx.png'

export default function SelectOrUploadTemplate(props) {

    const [state, dispatch] = useContext(globalStore);
    const [dropHover, setDropHover] = React.useState(false);
    const [templateSource, setTemplateSource] = React.useState(state.user.role === 'Counterparty' ? 'import' : 'company')
    const [stagedUpload, setStagedUpload] = React.useState(null);
    const [loading, setLoading] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState(null)

    const handleSelectTemplate = (id, type) => {

        setLoading(true);
        //let oat = state.templates.filter((t) => t._id === id)[0]

        axios.get(state.settings.api + "template/" + id)
        .then((resTemp) => {

            if(resTemp.data.success) {
                //dispatch({ type: "INIT_WORKFLOWS", payload: resTemp.data.data.workflows })
                //dispatch({ type: "INIT_PARAMS_DOC", payload: resTemp.data.data.params })
                //setTemplate(resTemp.data.data.template)

                //console.log("resTemp.data", resTemp.data.data.template)

                props.handleSelectOrUploadTemplate(type, resTemp.data.data.template)
                setTemplateSource('company')
                setLoading(false);

            } else { setErrMsg("Unable to retrieve template - refresh browser and try again"); setLoading(false) }
        }).catch((err) => { setErrMsg('Unable to retrieve tremplate'); setLoading(false) })

    }

    const handleUploadChange = (file) => {
        
        setErrMsg(null);
        setLoading(true);

        let fileType = // https://www.ibm.com/docs/en/wkc/cloud?topic=catalog-previews
        (file.name !== undefined && file.name.endsWith(".pdf")) || 
        (file.type !== undefined && ['application/pdf'].includes(file.type)) ? 
            "pdf" :
        (file.name !== undefined && file.name.endsWith(".docx")) || 
        (file.type !== undefined && ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) ? 
            "docx" :
        (file.name !== undefined && file.name.endsWith(".xlsx")) || 
        (file.type !== undefined && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) ? 
            "xlsx" :
        (file.name !== undefined && file.name.endsWith(".pptx")) || 
        (file.type !== undefined && ['application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(file.type)) ? 
            "pptx" :
            null

        if(fileType !== null) {
        var formData = new FormData()
        formData.append('file', file, generateRandomString(20));
    
        axios.post(state.settings.api + "upload/file", formData , {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data;`,
            }
        }).then((res) => {

            if(res.data.success) {

            setStagedUpload({
                fileType: fileType,
                file: res.data.data,
                uploadType: 
                ['docx'].includes(fileType) ? 'import' : // For now DocX is always imported
                ['pdf'].includes(fileType) && props.isMain ? 
                    'backload' : // A PDF file as main means: handled outside Canveo, set to InForce
                    'attach', // A PDF as exhibit means: attachment
                agrType: {},
            })

            setLoading(false)
    
            } else { setErrMsg("An error occurred while uploading the file"); setLoading(false);  }
        }).catch((err) => { setErrMsg("Unable to upload the file"); setLoading(false); })
        } else { setErrMsg("Unable to recognize the file type"); setLoading(false); }

    };

    const handleAgrTypeChange = (changeType, value) => {
        if(['newName'].includes(changeType)) { // You're creating a new Agr Type => defining name
        setStagedUpload({...stagedUpload, 
                agrType: { 
                    newName: value, 
                    shortName: stagedUpload.agrType.newName !== undefined &&
                    stagedUpload.agrType.shortName !== undefined ? 
                        stagedUpload.agrType.shortName : ''
                }})

        } else if(['shortName'].includes(changeType)) { // You're creating a new Agr Type => defining short name
        setStagedUpload({...stagedUpload, agrType: {...stagedUpload.agrType, shortName: value}})

        } else if(['agrType'].includes(changeType)) { // You've picked an existing Agr Type
        setStagedUpload({...stagedUpload, agrType: value})
        }
    }

    const handleFileUploadSubmit = () => {

        // Check if new Agreement Type needs to be created
        if(stagedUpload.agrType._id === undefined && stagedUpload.agrType.newName !== undefined && stagedUpload.agrType.shortName !== undefined) {
        // Creating a new Agreement Type
        let newat = {
            fullName: stagedUpload.agrType.newName,
            shortName: stagedUpload.agrType.shortName,
            orgID: state.org._id,
            active: true
        }

        // Create the new Agreement type
        axios.post(state.settings.api + 'agrtype', { agrType: newat })
        .then((resAgrt) => {
            if(resAgrt.data.success) { // Add newly created agrType to the reducer
                // Create with the new AgrType
                dispatch({ type: "ADD_AGRTYPE", payload: resAgrt.data.data })
                pushUploadToStagedAg(stagedUpload, resAgrt.data.data)
            
            } else { setErrMsg("An error occured while creating the agreement type - refresh your browser"); setLoading(false); }
        }).catch((err) => { setErrMsg("An error occured while creating the agreement type"); setLoading(false); })

        } else if (stagedUpload.agrType._id !== undefined) { // No new AgrType needs to be created
            pushUploadToStagedAg(stagedUpload, stagedUpload.agrType)
        }
    }

    const pushUploadToStagedAg = (upload, agrType) => {
    
        upload.agrTitle = agrType.fullName[0];
        upload.agrTypeID = agrType._id;

        props.handleSelectOrUploadTemplate(props.isMain ? 'main' : 'exhibit', upload)
        setTemplateSource('company')
        setStagedUpload(null)

    }

    //setTemplateSource('company');

    return (
    <div>

    {state.user.role !== 'Counterparty' ? // Counterparty users don't have any company templates

    <Grid item container justifyContent="center">

        <Grid item>
            <Box>
                <ButtonGroupPills
                buttons={[
                    { id: "company", title: "Company Template" },
                    { id: "import", title: "Upload File" },
                ]}
                selected={templateSource}
                click={(e) => setTemplateSource(e)}
                />
            </Box>
        </Grid>
    </Grid>
    :''}
    
    {errMsg !== null ?
    <Grid item sx={{my:4}}>
    <Typography align="center" color="error">{errMsg}</Typography>
    </Grid>
    :''}

    <Grid item container sx={{mt: state.user.role === 'Counterparty' ? 0 : 2}} justifyContent="center">
        {['company'].includes(templateSource) ?
        <Box>
        {loading ? 
        <Box sx={{my: 6}}>
        <CanveoCircularProgress />
        </Box>
        :
        <CoverFlow
        handleSelect={handleSelectTemplate}
        templates={state.templates.filter((t) => t.active)}
        logoURL={state.org.logoURL}
        selectionType={props.isMain ? "main" : "exhibit"}
        />
        }
        </Box>
        :
        <Box sx={{mt: 4}}>

        {loading ?
            <CanveoCircularProgress />
        :stagedUpload !== null ?
            <Grid container direction="column" justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                <img src={
                    ['pdf'].includes(stagedUpload.fileType) ? pdf : 
                    ['xlsx'].includes(stagedUpload.fileType) ? xlsx : 
                    ['pptx'].includes(stagedUpload.fileType) ? pptx : 
                    docx} height={30} />
                </Grid>
                <Grid item sx={{mt:2}}>

                <SelectOrCreateAgrType
                agrType={stagedUpload.agrType}
                setAgrType={handleAgrTypeChange}
                width={'330px'}
                />

                </Grid>
                {/*
                <Grid item sx={{mb:3}}>
                <FormControl>
                    <RadioGroup row
                    style={{paddingLeft: '15px'}}
                    name="fileUploadStatus"
                    //value={userRole}
                    //onChange={e => setUserRole(e.target.value)}
                    >
                    <FormControlLabel value="Draft" control={<Radio />} label="New agreement" />
                    <FormControlLabel value="InForce" control={<Radio />} label="Signed agreement" />
                    </RadioGroup>
                </FormControl>
                </Grid>
                */}
                <Grid item container direction="row" justifyContent="space-between">
                <Grid item>
                    <Button onClick={e => setStagedUpload(null)}
                    ><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" disableElevation
                    disabled={!Boolean(stagedUpload) || !Boolean(stagedUpload.agrType) ||
                        (stagedUpload.agrType._id === undefined &&
                            (stagedUpload.agrType.newName === undefined || stagedUpload.agrType.newName === null || 
                             stagedUpload.agrType.newName.length < 2 || 
                             stagedUpload.agrType.shortName === undefined || stagedUpload.agrType.shortName === null || 
                             stagedUpload.agrType.shortName.length < 2))}
                    onClick={handleFileUploadSubmit}
                    >
                    Submit&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </Grid>

                </Grid>



            </Grid>
        :
            <FileUploader 
            handleChange={handleUploadChange} 
            name="uploadfile" 
            types={
                props.isMain ? ["docx", "pdf"] : ["docx", "pdf", "pptx", "xlsx"]
            } 
            label={"Upload or drop a file here"}
            maxSize={20}
            minSize={0}
            onDraggingStateChange={(dragging) => setDropHover(dragging)}
            hoverTitle={" "}
            children={
                <Box sx={{ width: '320px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
                border: dropHover ? ('2px solid' + theme.palette.primary.main) : ('1px dotted' + theme.palette.grey[300]), 
                backgroundColor: dropHover ? theme.palette.grey[200] : theme.palette.grey[50], 
                padding: '30px', fontSize: '14px', fontWeight: '600', borderRadius: '20px'}}>
                <Grid container direction="column" alignItems="center">
                <Grid item>
                <FontAwesomeIcon icon={faUpload} style={{color: theme.palette.primary.main, fontSize: '30px'}} />
                </Grid>
                <Grid item>
                <Box sx={{mt:1, display:'block', textAlign: 'center'}}>
                    <>{dropHover ? 
                        "Time to let the file go..." : 
                        "Upload or drop your file here..."}<br/>
                    <Grid container direction="row" spacing={1} justifyContent="center" sx={{mt:1}}>
                        <Grid item><img src={pdf} alt="pdf" width={20} /></Grid>
                        <Grid item><img src={docx} alt="docx" width={20} /></Grid>
                        {!props.isMain ? 
                        <>
                        <Grid item><img src={xlsx} alt="xlsx" width={20} /></Grid>
                        <Grid item><img src={pptx} alt="pptx" width={20} /></Grid>
                        </>:''}
                    </Grid>
                </></Box>
                </Grid>
                </Grid> 
                </Box>
            }
            />
        }

        </Box>
    }
    </Grid>
    </div>
    );
}