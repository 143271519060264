const agrExecReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_AGREXEC":
            let newVal = action.payload;
            return { agrExec: newVal };
        default:
            return { agrExec: state.agrExec}
    }
};
  
export default agrExecReducer