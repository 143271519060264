import React, { useContext } from 'react';
import { Box, CircularProgress, Dialog, FormControl, 
         Grid, IconButton, InputLabel, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { randomString as generateRandomString } from '../utils';
import { UserForm } from './forms';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTriangleExclamation, faUserSlash } from '@fortawesome/pro-solid-svg-icons';
import theme from '../theme/theme';
import axios from 'axios';

// type: "newusr" => Setting up a new User for an Org (Admin Module)
// type: "updusr" => Updating an existing User for an Org (Admin Module)

export default function DialogDiff(props) {

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(null);

  const reInitalize = () => {
    setLoading(false)
  }

  const closeDialog = () => {
    props.closeDialog();
    reInitalize()
  };

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullScreen
      >
        <Box sx={{position: 'absolute', top: '12px', right: '15px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '7px 14px', fontSize: '40px'}} />
          </IconButton>
        </Box>
          
        {loading ? 
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 20}}>

            <CircularProgress size={24} />

        </Box>
        :
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 12}}>
            
            <Grid container direction="column" sx={{ width: isMdUp ? '720px' : isSmUp ? '540px' : '320px' }}>
                <Grid item container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                    <Grid item>
                        <FormControl variant="outlined">
                            <Box align="left">
                            <InputLabel>Base for comparison</InputLabel>
                            <Select
                            style={{width: '240px'}}
                            //value={oldValue}
                            renderValue={(v) => 
                                <Typography>
                                    v1
                                    {/*
                                    v{userFriendlyAVV(avs.filter((av) => av._id === v)[0].version)} 
                                    {([0,1,avs.length - 1].includes(avs.findIndex((av) => av._id === v)) ?
                                    <span className={classes.menuDesc}>{avs.findIndex((av) => av._id === v)===0 ? "Current" : avs.findIndex((av) => av._id === v)===1 ? "Previous" : "First"}</span> : '' )}
                                    */}
                                </Typography>}
                            //onChange={handleOldChange}
                            label="Base for comparison">
                                <MenuItem>
                                VERSION!
                                </MenuItem>
                            {/*avs
                            .filter((av) => !av.version.startsWith("copy"))
                            .map((av, i) => (
                            <MenuItem key={av._id} value={av._id} className={av._id === newValue && avs.length > 1 ? classes.hide : ''}>
                                
                                <ListItemAvatar>
                                {av.owner.split(',').length > 1 ?
                                <Avatar 
                                className={classes.multiAvatar}>{av.owner.split(',').length}<FontAwesomeIcon icon={faBuilding} className={classes.coIcon} /></Avatar>
                                :
                                <Avatar 
                                className={classes.menuAvatar}
                                src={
                                    av.owner.split(',')[0] !== undefined &&
                                    props.activeParties.filter((ap) => ap.partyID === av.owner.split(',')[0])[0] !== undefined ?
                                    props.activeParties.filter((ap) => ap.partyID === av.owner.split(',')[0])[0].ent.logoURL : null} />
                                }
                                </ListItemAvatar>
                                
                                <ListItemText 
                                primary={<Typography>
                                v{userFriendlyAVV(av.version)} 
                                {([0,1,avs.length - 1].includes(i) ?
                                <span className={classes.menuDesc}>{i===0 ? "Current" : i===1 ? "Previous" : "First"}</span> : '' )}
                                </Typography>}
                                />
                            </MenuItem>
                                ))*/}
                            </Select>
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item>
                    <FormControl variant="outlined">
                            <Box align="left">
                            <InputLabel>Compare with</InputLabel>
                            <Select
                            style={{width: '240px'}}
                            renderValue={(v) => 
                                <Typography>
                                    v2
                                </Typography>}
                            label="Base for comparison">
                                <MenuItem>
                                VERSION!
                                </MenuItem>
                            </Select>
                            </Box>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item sx={{mt:3}}>
                    AGR COMPARE!
                </Grid>
            </Grid>
        </Box>
        }

      </Dialog>
    </div>
  );
}