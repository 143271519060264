import React, { useEffect } from 'react';
import theme from '../theme/theme';
import { trunc, getAddressForEntity, getColorForPartyID } from '../utils'
import { Avatar, Box, Button, Card, CardContent, Collapse, Menu, 
         MenuItem, ListItemText, Typography } from '@mui/material';
import { DialogEntity, MenuPopEntities } from '.';
//import { EntityForm } from './forms';
//import { countries } from '../assets/static/countries';
import countries from '../assets/static/countries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClone, faPen, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

import editImage from '../assets/img/editimage.png';
import defaultLogo from '../assets/img/defaultlogo.png';
import defaultUser from '../assets/img/defaultuser.png';

const axios = require('axios');

const CardCpty = (props) => {

  const styles = {
    /*cardPrimaryCpty: {
        width: '260px',
        borderRadius: '20px 20px 0px 0px',
        borderBottom: '2px solid ' + theme.palette.primary.main,
    },
    cardSecondaryCpty: {
        width: '260px',
        borderRadius: '20px 20px 0px 0px',
        borderBottom: '2px solid ' + theme.palette.secondary.main,
    },*/
    cardCpty: {
        width: '260px',
        borderRadius: '20px 20px 0px 0px',
        borderBottom: '2px solid ' + (Boolean(props.partyID) ? getColorForPartyID(props.partyID) : theme.palette.primary.main),
    },
    cardContent: {
        margin: '10px 0px 5px 0px',
        "&:firstChild": {
            paddingTop: '15px',
        },
        "&:lastChild": {
            paddingBottom: '15px',
        },
        textAlign: 'center',
    },
    avLogo: {
        position: 'absolute',
        top: '-24px',
        left: '110px',
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.primary.contrastText,
        //cursor: 'pointer'
    },
    removeIcon: {
        fontSize: '14px',
        color: Boolean(props.partyID) ? getColorForPartyID(props.partyID) : theme.palette.primary.main,
        '&:hover': {
            color: Boolean(props.partyID) ? getColorForPartyID(props.partyID, 'dark') : theme.palette.primary.dark,
            cursor: 'pointer',
        }
    },
    /*
    removeIconPrimary: {
        //position: 'absolute',
        fontSize: '14px',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
            cursor: 'pointer',
        }
    },
    removeIconSecondary: {
        //position: 'absolute', 
        //top: '5px', 
        //right: '5px',
        fontSize: '14px',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.dark,
            cursor: 'pointer',
        }
    },*/
  }

  const [showAddress, setShowAddress] = React.useState(props.expand)
  const [dialogEntityOpen, setDialogEntityOpen] = React.useState(null)

  useEffect(() => {
    setShowAddress(props.expand)
  }, [props.expand])

  const closeEntityDialog = (action) => {
    if(action !== undefined && action !== null && action.ent !== undefined) {
        props.handleCardCptyRequest('updateEntity', null, action.ent)
    }
    setDialogEntityOpen(null)
  }

  const selectEntity = (id) => {
    props.handleCardCptyRequest('changeownent', null, id)
  }

  /*
  console.log((props.cpty === null || props.cpty === undefined || props.cpty._id === undefined || props.cpty._id === null)
  props.switch !== undefined && props.switch !== null && props.switch.length > 0)*/

  return (
    <div style={{position: 'relative'}}>
    <Card variant="outlined" style={styles.cardCpty}>
        
        <Avatar 
        src={
            props.cpty !== undefined && props.cpty !== null && props.cpty.logoURL !== undefined && props.cpty.logoURL !== null ? 
                props.cpty.logoURL : 
            props.defaultLogo !== undefined && props.defaultLogo !== null ? 
                props.defaultLogo : 
            props.cpty !== undefined && props.cpty !== null && ['Person'].includes(props.cpty.entityCategory) ?
                defaultUser :
                defaultLogo} 
        style={styles.avLogo}
        />

        <CardContent style={styles.cardContent}>

            {// Show switch or edit icon
            showAddress &&
            /*(props.cpty === null || props.cpty === undefined || props.cpty._id === undefined || props.cpty._id === null) &&*/
            props.switch !== undefined && props.switch !== null && props.switch.length > 0 ?
            
            <span style={{position: 'absolute', top: '5px', right: (props.remove ? '30px' : '5px'), padding: '4px 3px 4px 3px', cursor: 'pointer'}}>
            <MenuPopEntities
            variant="smallicon"
            type={props.partyID}
            entities={props.switch}
            selectEntity={selectEntity}
            />
            </span>

            : 
            showAddress & props.edit && props.cpty.entityType !== 'Subsidiary' ?
            <span style={{position: 'absolute', top: '5px', right: (props.remove ? '30px' : '5px'), padding: '4px 3px 4px 3px', cursor: 'pointer',}}>
            <FontAwesomeIcon icon={faPen} 
            style={styles.removeIcon} 
            onClick={e => setDialogEntityOpen({type: 'updcp', ent: props.cpty})} />
            </span>
            : '' }

            {// Show delete party icon
            props.remove && props.cpty !== undefined && props.cpty !== null && props.cpty._id !== undefined ?
            <span style={{position: 'absolute', top: '5px', right: '5px', padding: '4px 3px 4px 3px', cursor: 'pointer',}}>
            <FontAwesomeIcon icon={faTimesCircle} 
            style={styles.removeIcon} 
            onClick={e => props.handleCardCptyRequest('removeParty', null, props.cpty._id)} />
            </span>
            : '' }


            {!Boolean(props.cpty) && !showAddress ?
            <Box sx={{margin: '10px 5px 2px 5px'}}>
            <MenuPopEntities
            variant="bigbutton"
            type={"primary"}
            entities={props.switch}
            selectEntity={selectEntity}
            />
            </Box>
            :
            <Box onClick={e => setShowAddress(!showAddress)} style={{cursor: 'pointer'}}>
                <Typography variant="subtitle1" gutterBottom style={{fontWeight: '700'}}>
                {props.cpty !== undefined && props.cpty !== null && props.cpty.shortName !== undefined ? 
                trunc(props.cpty.shortName, 25) : <span>&nbsp;</span>}
                </Typography>
                <Typography variant="body2">
                {props.cpty !== undefined && props.cpty !== null && props.cpty.legalName !== undefined ? 
                trunc(props.cpty.legalName, 32) : <span>&nbsp;</span>}
                </Typography>

                <Collapse in={showAddress}>
                    <Typography variant="body2">
                    {getAddressForEntity(props.cpty, 'billto').streetAndNo !== undefined ?
                            trunc(getAddressForEntity(props.cpty, 'billto').streetAndNo, 32) : 
                            ''}
                    </Typography>
                    
                    <Typography variant="body2">
                    {getAddressForEntity(props.cpty, 'billto').address2 !== undefined &&
                     getAddressForEntity(props.cpty, 'billto').address2 !== null ?
                            trunc(getAddressForEntity(props.cpty, 'billto').address2, 32) :
                            ''}
                    </Typography>


                    <Typography variant="body2">
                    {getAddressForEntity(props.cpty, 'billto').city !== undefined ||
                     getAddressForEntity(props.cpty, 'billto').zip !== undefined ||
                    (getAddressForEntity(props.cpty, 'billto').state !== undefined &&
                     getAddressForEntity(props.cpty, 'billto').state !== null) ?
                    
                        trunc(
                            (getAddressForEntity(props.cpty, 'billto').city !== undefined ? 
                                    (getAddressForEntity(props.cpty, 'billto').city + " ") : 
                                    '') +
                            (getAddressForEntity(props.cpty, 'billto').zip !== undefined ? 
                                    (getAddressForEntity(props.cpty, 'billto').zip + " ") :
                                    '') +
                            (getAddressForEntity(props.cpty, 'billto').state !== undefined &&
                            getAddressForEntity(props.cpty, 'billto').state !== null ?
                                    (getAddressForEntity(props.cpty, 'billto').state) :
                                    '')        
                            , 32)
                        : ''}

                    </Typography>
                    <Typography variant="body2">
                    {getAddressForEntity(props.cpty, 'billto').country !== undefined ?
                    countries.filter((c) => c.code === getAddressForEntity(props.cpty, 'billto').country)[0].label : ''}
                    </Typography>

                </Collapse>
            </Box>
            }

        </CardContent>
    </Card>
    <DialogEntity
        open={Boolean(dialogEntityOpen)}
        closeDialog={closeEntityDialog}
        type={dialogEntityOpen !== null && dialogEntityOpen.type !== undefined ? dialogEntityOpen.type : ''}
        ent={dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined ? dialogEntityOpen.ent : ''}
    />
    </div>
  );
}

export default CardCpty