const avsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_AVS":
            return { avs: action.payload };
        case "UPDATE_AVS":
            let newVal = action.payload;
            let newAvs = state.avs;
            let idx = state.avs.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newAvs[idx] = newVal;
            }
            return { avs: newAvs };
        default:
            return { avs: state.avs }
    }
};
  
export default avsReducer