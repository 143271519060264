export function analyzeNode(s) {

    let stringLength = s.length;

    // Determine the Lead (eg: 1.a.1.c) - if any
    let firstLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/)
    //let leadFirst = firstLeaderLead && firstLeaderLead[0] !== 'o ' ? this.getLeadSummary(firstLeaderLead[0]) : null;
    let secondLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/) // e.g. "1.16. text" or "16.4 text"
    //let leadSecond = secondLeaderLead ? this.getLeadSummary(secondLeaderLead[0].replace(firstLeaderLead[0],'')) : null
    let thirdLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/) // e.g. "1.16.2. text" or "16.4.2 text"
    //let leadThird = thirdLeaderLead ? this.getLeadSummary(thirdLeaderLead[0].replace(secondLeaderLead[0],'')) : null
    let fourthLeaderLead = s.match(/^\(?(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})\.(\d{1,2}|[a-zA-Z]{1,1}|[ivxIVX]{1,3})(\.|\)| )/) // e.g. "1.16.2.6. text" or "16.4.2.6 text"
    //let leadFourth = fourthLeaderLead ? this.getLeadSummary(fourthLeaderLead[0].replace(thirdLeaderLead[0],'')) : null
    let endPositionOfLead = 
          fourthLeaderLead ? fourthLeaderLead[0].length :
          thirdLeaderLead ? thirdLeaderLead[0].length :
          secondLeaderLead ? secondLeaderLead[0].length  :
          firstLeaderLead ? firstLeaderLead[0].length : 0

    let hasSectionKeywordAtTheStart = ( // Has a keyword art(icle), sec(tion) or clause AND has a number and is not too long 
            s.toLowerCase().trim().startsWith('sec') || s.toLowerCase().trim().startsWith('clause') || 
            s.toLowerCase().trim().startsWith('art') || s.toLowerCase().trim().startsWith('§')) && 
            s.substr(0,11).match(/\d/) !== null && s.length < 110 ? 1 : 0;
    let hasExhibitAtTheStart = !firstLeaderLead && // Agreement / Exhibit starts
            (s.substring(0,11).toLowerCase().includes('exhibit') || s.substring(0,11).toLowerCase().includes('schedule') ||
            s.substring(0,11).toLowerCase().includes('annex') || s.substring(0,11).toLowerCase().includes('appendix')) ? 1 : 0;

    let hasSignBlockAnnouncement = s.toLowerCase().includes('in witness whereof') || s.toLowerCase().includes('in witness thereof') ||
            s.toLowerCase().includes('in witness hereof') || s.toLowerCase().includes('in testimony whereof') || 
            s.toLowerCase().includes('by signing below') ? 1 : 0

    // Find the first dot after the lead and count words - this is used to identify clause titles, which typically end with a dot and are short (1~5 words)
    //var str = "scissors";
    var dotSpaces = [];
    for(var j=endPositionOfLead; j<s.substring(endPositionOfLead).length;j++) {
        if (s[j] === "." && s[j + 1] !== undefined && s[j+1] === " " &&
        !(j > 2 && ['e.g.','i.e.'].includes(s.substr(j-3, 4))) && // skip i.e. and e.g.
        !(j > 1 && ['eg.','ie.'].includes(s.substr(j-2, 3))) && // skip ie. and eg.
        !(j > 2 && !isNaN(s[j-3]) && s[j-2] === "." && !isNaN(s[j-1])) // skip 2.1. etc.
        ) { 
            dotSpaces.push(j); 
        }
    }

    let firstDotPositionAfterLead = dotSpaces.length > 0 ? dotSpaces[0] : -1

    if(firstDotPositionAfterLead > -1 && s.substr(firstDotPositionAfterLead + 1, 1) >= '0' && s.substr(firstDotPositionAfterLead + 1, 1) <= '9'){
        let newFirstDotPositionAfterLead = -1;
        var indices = [];
        for(var counter=0; counter<s.length;counter++) { if (s[counter] === ".") { indices.push(counter); } }
        indices.filter((i) => i > firstDotPositionAfterLead).forEach((newi) => {
            if(newFirstDotPositionAfterLead === -1 && // new dot was not yet found
            (s[newi + 1] === undefined || s.substr(firstDotPositionAfterLead + 1, 1) < '0' || s.substr(firstDotPositionAfterLead + 1, 1) > '9')) {
            newFirstDotPositionAfterLead = newi // this would be an appropriate dot
            }
        })
        firstDotPositionAfterLead = newFirstDotPositionAfterLead;
    }
    let hasOnlyOneDotAtTheEnd = s.substring(endPositionOfLead).trim().match(/\./g) !== null && s.substring(endPositionOfLead).trim().match(/\./g).length === 1 && s.substring(endPositionOfLead).trim().endsWith('.') ? 1 : 0
    let wordsBeforeFirstDot = firstDotPositionAfterLead > 0 ? s.substring(endPositionOfLead, firstDotPositionAfterLead).trim().split(' ').length : s.substring(endPositionOfLead, s.length).trim().split(' ').length

    // Ocassionally, clause titles end with a colon ":"
    let colonBeforeDot = s.indexOf(":") > -1 && s.indexOf(":") < s.indexOf(".") ? 1 : 0;
    let colonPosition = s.indexOf(":") > -1 ? s.indexOf(":") + 0 : -1;

    // Characteristics that can help to find Definitions, such as "quotes" and the word .. means ..
    let startsWithQuote = s.substring(endPositionOfLead).trim().match(/^("|“)/) ? 1 : 0
    let firstQuotePosition = s.indexOf("\"") > -1 ? s.indexOf("\"") : s.indexOf("“");

    let closingQuotePosition = 
        s.substring(firstQuotePosition + 1).indexOf("”") > -1 ? s.substring(firstQuotePosition + 1).indexOf("”") + firstQuotePosition + 1 :
        s.substring(firstQuotePosition + 1).indexOf("”") > -1 ? s.substring(firstQuotePosition + 1).indexOf("”") + firstQuotePosition + 1 : -1
    
    let wordsBeforeMeans = firstLeaderLead === null && s.toLowerCase().includes(' means ') ? s.substring(0, s.toLowerCase().indexOf(' means ')).split(' ').length : -1
    let isDefinition = (startsWithQuote === 1 && closingQuotePosition > 0) || (wordsBeforeMeans > 0 && wordsBeforeMeans < 6)

    let clauseTitle = // todo => put into its standalone functino also for TOC generation
        startsWithQuote === 1 && closingQuotePosition > 0 ?
            s.substring(endPositionOfLead, closingQuotePosition + 1) :
        wordsBeforeMeans > 0 & wordsBeforeMeans < 6 ?
            s.substring(endPositionOfLead, s.toLowerCase().indexOf(' means ') + 1) :
        colonBeforeDot === 1 && colonPosition < 80 ?
            s.substring(endPositionOfLead, colonPosition) :
        wordsBeforeFirstDot <= 6 ?
            s.substring(endPositionOfLead, firstDotPositionAfterLead) :
            s.substring(endPositionOfLead, 50);

    return {
        stringLength,
        firstLeaderLead,
        secondLeaderLead,
        thirdLeaderLead,
        fourthLeaderLead,
        endPositionOfLead,
        hasSectionKeywordAtTheStart,
        hasExhibitAtTheStart,
        hasSignBlockAnnouncement,
        firstDotPositionAfterLead,
        hasOnlyOneDotAtTheEnd,
        wordsBeforeFirstDot,
        colonBeforeDot,
        colonPosition,
        startsWithQuote,
        closingQuotePosition,
        wordsBeforeMeans,
        isDefinition,
        clauseTitle
    }

}