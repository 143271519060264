import React, { useEffect, useContext } from 'react';
import { globalStore } from '../state/store';
import { Avatar, Autocomplete, Box, Chip, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from '../theme/theme';
import { trunc } from '../utils'; 

export default function SelectLabels(props) {

  const [state] = useContext(globalStore);
  //const [userOptions, setUserOptions] = React.useState([])

  return (
    <Autocomplete
    id="filter-label"
    options={state.labels
        .filter((l) => l.active)
        .sort((a, b) => (a.name > b.name) ? 1 : -1)
        .sort((a, b) => (a.colorType > b.colorType) ? 1 : -1)} // labels
    onChange={(e, newVal) => props.handleLabelSelect(newVal)}
    //value={state.labels !== undefined ? state.labels.filter((sl) => props.labels.includes(sl._id)) : []}
    value={state.labels.filter((sl) => props.selectedIDs.includes(sl._id))}
    getOptionLabel={(option) => option.name}
    filterSelectedOptions
    disableCloseOnSelect
    multiple
    renderInput={(params) => (
    <TextField
        {...params}
        label="Labels"
        placeholder="Labels..."
        InputProps={
        props.selectedIDs.length > 0 ? 
        {
            ...params.InputProps,
            autoComplete: 'new-password',
        }
        :    
        {
            ...params.InputProps,
            autoComplete: 'new-password',
            startAdornment: (
                <InputAdornment position="start">
                <FontAwesomeIcon icon={props.adornIcon} />
                </InputAdornment>
            ),
        }}
    />
    )}
    renderOption={(props, option) => (
    <Box component={"li"} {...props}>
    <Chip 
    variant="filled" 
    sx={{fontWeight: '600', '&:hover': {cursor: 'pointer'} }}
    color={option.colorType}
    label={option.name} />
    </Box>

    )}
    renderTags={(value, getTagProps) =>
    value.map((option, index) => (
        <Chip variant="filled" 
        sx={{fontWeight: '600'}}
        color={option.colorType}
        label={option.name} {...getTagProps({ index })} />
    ))
    }
/>
  );
}