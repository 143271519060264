function createUID() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
}

export function createComment(content, creator) {
  return {
    id: createUID(),
    creator: creator,
    content: content.trim(),
    date: new Date().toISOString(),
    isEdited: false,
  };
}

export function createWorkflow(wfType, prefix, quote, content, user, assignee, subscribers, docID) {

  let creator = { uid: user._id, displayName: user.displayName }
  let newWF = {
    wfType: wfType,
    orgID: user.orgID,
    lid: prefix + createUID(),    
    docID: docID,
    comments: [createComment(content, creator)],
    quote: quote,
    note: content.substring(0, 125),
    subscribers: subscribers,
    creator: creator,
    assignee: assignee,
    wfStatus: 'open'
  }

  return newWF
}

  // PUSH to DB
  // STORE IN REDUX
  /*
  return {
    comments: [createComment(content, user)],
    id: prefix + createUID(),
    quote,
    wfType: wfType,
    subscribers: subscribers,
    creator: { uid: user._id, displayName: user.displayName }
  };*/

/*
export function cloneWorkflow(thread) {
  return {
    comments: Array.from(thread.comments),
    id: thread.id,
    quote: thread.quote,
    wfType: thread.wfType,
    subscribers: thread.subscribers,
    creator: thread.creator,
  };
}*/