import React, { useContext, useEffect } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, 
         DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { randomString as generateRandomString } from '../utils';
import { CanveoCircularProgress, SelectOrUploadTemplate, ThumbExhibitView } from '.';
import { globalStore } from '../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faMinusCircle, faPlusCircle, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import theme from '../theme/theme';
import axios from 'axios';

export default function DialogExhibits(props) {

  const [state] = useContext(globalStore);
  const [loading, setLoading] = React.useState(false)
  const [addingExhibit, setAddingExhibit] = React.useState(false)
  const [stagedExhibits, setStagedExhibits] = React.useState(props.ags.filter((a) => a.parentAID !== null).sort((a, b) => (a.priority > b.priority) ? 1 : -1))
  const [isDirty, setIsDirty] = React.useState(false)

  const originalExhibits = props.ags.filter((a) => a.parentAID !== null)

  useEffect(() => { // Add the filetype to existing exhibits 

    if(Boolean(stagedExhibits)) {
        let newExs = [];
        stagedExhibits.forEach((se) => {
            let newEx = se
            if(props.avs.filter((av) => av.agrID === se._id)[0] !== undefined){
                newEx.fileType = props.avs.filter((av) => av.agrID === se._id)[0].versionType;
            }
            newExs.push(newEx)
        })
        setStagedExhibits(newExs)
    }

  }, [props.avs])
  

  const reInitalize = () => {
    setLoading(false)
    setIsDirty(false)
    setAddingExhibit(false)
    setStagedExhibits(props.ags.filter((a) => a.parentAID !== null))
  }

  const closeDialog = () => {
    props.closeDialog()
    reInitalize()
  };

  const handleSelectOrUploadTemplate = (type, newAg) => { // type: main, exhibit

    let newExhibits = stagedExhibits
    newAg.isNew = true;
    newAg.tempRef = generateRandomString(20)
    newExhibits.push(newAg)
    // TODO something with priority
    setStagedExhibits(newExhibits);
    setAddingExhibit(false);
    setIsDirty(true);
  }

  const handleFinalizeUpdateExhibits = () => {

    console.log(stagedExhibits)
    setLoading(true)

    let creationDate = new Date().toISOString();
    let deletedExhibits = [], changedExhibits = []

    originalExhibits.forEach((oex, i) => {
        if(stagedExhibits.filter((uex) => uex._id === oex._id)[0] === undefined) {
            deletedExhibits.push(oex._id) // Deleted exhibits
        } else {
            let sexIdx = stagedExhibits.findIndex((sex) => sex._id === oex._id)
            let sexPrio = (sexIdx + 1) * 10;
            console.log("oexPrio", oex.agrTitle, oex.priority, sexPrio)
            if(oex.priority !== sexPrio) {
                let newEx = stagedExhibits.filter((sex) => sex._id === oex._id)[0]
                if(Boolean(newEx)) {
                    newEx.priority = sexPrio;
                    changedExhibits.push(newEx);
                }
            }
        }
    })

    let newExhibits = stagedExhibits.filter((uex) => uex.isNew) // Newly added exhibits

    deletedExhibits.forEach((dex) => {
        axios.delete(state.settings.api + "agr/" + dex)
    })

    changedExhibits.forEach((cex) => {
        console.log("changedEx", cex)
        axios.put(state.settings.api + "agr/" + cex._id, { agr: cex })
    })

    let mainAg = props.ags.filter((a) => a.parentAID === null)[0]
    if(Boolean(mainAg)) {
        newExhibits.forEach((nex) => {

            let nexIdx = stagedExhibits.findIndex((sex) => sex.tempRef === nex.tempRef)
            let nexPrio = (nexIdx + 1) * 10;
            let avv = state.avs.filter((av) => av.agrID === mainAg._id)[0].version

            let newEx = {    
                versionType: ['pdf','docx', 'xlsx', 'pptx'].includes(nex.fileType) ? // AgreementVersion related
                    nex.fileType : // This is an attachment
                    'canveo', // This is a Canveo Doc
                content: ['pdf','docx', 'xlsx', 'pptx'].includes(nex.fileType) ? // AgreementVersion related
                    { file: nex.file, creationBy: state.user._id, creationDate, creationDate } : // Link to the attachment
                    nex.content, // Content of the Canveo Doc
                avv: avv, // AgreementVersion related
                agrTypeID: nex.agrTypeID,
                agrTitle: nex.agrTitle,
                agrStatus: "Exhibit",
                orgs: mainAg.orgs,
                ents: mainAg.ents,
                collabs: [],
                signers: [],
                parentAID: mainAg._id,
                priority: nexPrio,
                amendment: null,
                avOwners: [],
                effectiveDate: null,
                renewalType: 'n/a',
                effectiveTerm: null,
                expiryDate: null,
                sigConfig: null,
                customURL: null,
                whiteLabel: null,
                creationBy: state.user._id,
                creationDate: creationDate,
                lastUpdateBy: state.user._id,
                lastUpdateDate: creationDate,
            }
    
    
            console.log("newEx",newEx)
    
            axios.post(state.settings.api + "agr", { agr: newEx })
        })
    }

    setTimeout(function() {
        closeDialog()
        window.location.reload(true);
    }, 2000); // Wait for 2 seconds
  }

  return (
    <div>
      <Dialog 
      open={props.open} 
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      >
        <Box sx={{position: 'absolute', top: '11px', right: '12px'}}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon icon={faTimes} style={{padding: '4px 7px', fontSize: '20px'}} />
          </IconButton>
        </Box>
        <DialogTitle>
            {loading ? "Loading..." :
                "Manage Exhibits"}
        </DialogTitle>
        <DialogContent>
          
            {loading ? 
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 15}}>

                <CanveoCircularProgress />

            </Box>
            :
            <Box sx={{mt: 4, mb:2}}>


            <Grid container direction="column" justifyContent="center" sx={{minHeight: '300px'}}>

                {!addingExhibit ?
                <>
                <Grid item container justifyContent="center">
                    <Box sx={{
                    width: '260px',
                    padding: '15px 0px',
                    border: '1px solid' + theme.palette.grey[300],
                    borderRadius: '20px',
                    textAlign: 'center',
                    color: theme.palette.grey[800],
                    backgroundColor: theme.palette.grey[200],
                    fontWeight: '700',
                    fontSize: '15px',
                    my: 0
                    }}>
                    Main Body
                    </Box>

                </Grid>
                <Grid item container justifyContent="center" sx={{mb: 1}}>
                    <ThumbExhibitView
                    exhibits={stagedExhibits}
                    handleUpdate={(newExhibits) => { 
                        //console.log("newExhibits", newExhibits);
                        setStagedExhibits([...newExhibits]); setIsDirty(true) }
                        //console.log("ex", newExhibits)
                        //setStagedAgreement({...stagedAgreement,  exhibits: newExhibits })
                    }
                    />
                </Grid>
                </>
                :''}
                
                <Grid item container justifyContent="center">
                    <Button variant="text" sx={{padding: '5px 25px'}} onClick={e => setAddingExhibit(!addingExhibit)}
                    color={addingExhibit ? "secondary" : "primary"}>
                        <FontAwesomeIcon icon={addingExhibit ? faMinusCircle : faPlusCircle} />&nbsp;&nbsp;Add{addingExhibit ? "ing": ""} Exhibit{addingExhibit ? "...": ""}
                    </Button>
                </Grid>

                {addingExhibit ?
                <Grid item container justifyContent={"center"} sx={{my:3}}>
                    <SelectOrUploadTemplate
                    isMain={false}
                    handleSelectOrUploadTemplate={handleSelectOrUploadTemplate}
                    />
                </Grid>
                :''}


            </Grid>

            </Box>
            }
        </DialogContent>
        <DialogActions>
          {addingExhibit ?
          <Button sx={{marginRight: 'auto'}} onClick={e => setAddingExhibit(false)}><FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Go back</Button>
          :  
          <Button sx={{marginRight: 'auto'}} onClick={closeDialog}>Cancel</Button>}

          <Button variant="contained" disableElevation disabled={addingExhibit || !isDirty || loading} onClick={handleFinalizeUpdateExhibits}>
            Update Exhibits&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}