import React, { useContext } from 'react';
import theme from '../theme/theme';
import { useNavigate } from "react-router-dom";
import { globalStore } from '../state/store';

import { getAddressForEntity, randomString as generateRandomString } from '../utils';
import { Box, Button, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, 
         Grid, MobileStepper, Radio, RadioGroup, Typography } from '@mui/material';
import { ButtonGroupPills, CanveoCircularProgress, CardCpty, CoverFlow, Header, 
         MenuPopEntities, SelectLabels, SelectOrUploadTemplate, SelectOrCreateCpty, ThumbAgr, 
         ThumbExhibitView } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowLeft, faPlusCircle, faTag } from '@fortawesome/pro-solid-svg-icons';

import axios from 'axios';

const New = () => {

  const styles = {
    backNextButton: { color: theme.palette.grey[800], padding: '5px 15px', '&:hover': {backgroundColor: theme.palette.grey[100], color: theme.palette.grey[900]}}
  }

  const navigate = useNavigate();
  const [state, dispatch] = useContext(globalStore);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedCounterparties, setSelectedCounterparties] = React.useState([]);
  const [addingParty, setAddingParty] = React.useState(null);
  const [activeOwnEnt, setActiveOwnEnt] = React.useState(null);
  const [stagedAgreement, setStagedAgreement] = React.useState(null);
  const [isCustomURL, setIsCustomURL] = React.useState(true);
  const [addingExhibit, setAddingExhibit] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const reInitialize = () => {
    setSelectedCounterparties([]);
    setAddingParty(null);
    setActiveOwnEnt(null);
    setActiveStep(0);
    setStagedAgreement(null);
    setIsCustomURL(true);
    setAddingExhibit(false)
  }

  const handleSelectOrUploadTemplate = (type, newAg) => { // type: main, exhibit

    if(["main"].includes(type) && newAg !== undefined) {

      setStagedAgreement({ main: newAg, exhibits: [] })
      handleNext()
  
    } else if(("exhibit").includes(type) && newAg !== undefined) {

      let sa = stagedAgreement
      let exs = sa.exhibits !== undefined && sa.exhibits !== null && sa.exhibits.length > 0 ? sa.exhibits : []
      newAg.tempKey = generateRandomString(20)
      exs.push(newAg)
      sa.exhibits = exs;

      setStagedAgreement(sa)
      setAddingExhibit(false)

    }
  }

  const handleNext = () => {
      setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    
    if(addingExhibit) {
      setAddingExhibit(false)
    } else if(addingParty !== null){
      setAddingParty(null)
    } else if(activeStep === 0) {
      navigate('/dashboard')
    } else if(activeStep === 1) { // Going back to the start - re-initialize
      reInitialize();
    } else if(activeStep === 2) {
      setStagedAgreement(null)
      setAddingExhibit(false)
    }
    setActiveStep(Math.max(0, activeStep - 1))
  }

  const completeSelectOrCreate = (org, ent, pers) => {

    let scps = selectedCounterparties
    let isMainCpty = scps.length === 0;
    scps.push({ 
      //isMainCpty: isMainCpty, 
      org:org, 
      ent: ent, 
      pers: pers,
      side: addingParty !== null ? addingParty.side : "secondary",
        //addingParty !== undefined && addingParty !== null ? addingParty.side : null,
    })

    // If you're selecting the main counterparty, review if you can define the "own entity" automatically
    if(isMainCpty && ent !== undefined && ent !== null && ent.address !== undefined) { 
      // Check the Country for the selected cpty
      let valCountry = getAddressForEntity(ent, 'billto').country !== undefined ? getAddressForEntity(ent, 'billto').country : null
      // Automatically select your own entity (where possible)
      if (state.org !== undefined && (activeOwnEnt === null || activeOwnEnt._id === undefined) && state.subs.filter((s) => s.active).length === 1) {
        // If only one active subsidiary - select it
        setActiveOwnEnt(state.subs.filter((s) => s.active)[0]);
      } else if(state.org !== undefined && (activeOwnEnt === null || activeOwnEnt._id === undefined) && 
      ent.address !== undefined && valCountry !== undefined && valCountry !== null &&
      state.subs.filter((s) => s.active && s.address !== undefined && 
      getAddressForEntity(s, 'billto').country !== undefined && 
      getAddressForEntity(s, 'billto').country === valCountry)[0] !== undefined) { 
        // If no own cpty yet, automatically select a corresponding one from the same country (if exists)
        setActiveOwnEnt(state.subs.filter((s) => getAddressForEntity(s, 'billto').country === valCountry)[0]);
      }
    }
    setSelectedCounterparties(scps)
    
    if(addingParty) { setAddingParty(null) } // Complete selection of additional party
    else { handleNext() } // Complete selection of initial ("Main") party

  }

  const handleCardCptyRequest = (action, counterpart, value) => {
    
    if(action === 'changeownent') {
      setActiveOwnEnt(state.subs.filter((s) => s._id === value)[0])

    } else if(action === 'addParty') { // Request to initiate adding of a party
      let newPartyID = "party" + (selectedCounterparties.length + 2)
      setAddingParty({ partyID: newPartyID, side: counterpart })

    } else if(action === 'removeParty') { // Request to remove an additional party
      let newpts = selectedCounterparties;
      setSelectedCounterparties(newpts.filter((p) => p.ent._id !== value))

    } else if(action === 'updateEntity') {
      let newpts = selectedCounterparties;
      let idx = newpts.findIndex((np) => np.ent._id === value._id)
      if(idx > -1) { newpts[idx].ent = value; }
      setSelectedCounterparties([...newpts])

    }
  }

  const handleCreateAgreement = () => {
    setLoading(true);
    setErrMsg(null);

    console.log("createAg", stagedAgreement)
    console.log("selectedCounterparties", selectedCounterparties)
    console.log("isCustomURL", isCustomURL)

    // Create Main AG
    let isBackload = ['backload'].includes(stagedAgreement.main.uploadType);
    let creationDate = new Date().toISOString();
    let orgs = [activeOwnEnt.orgID] // add Creator Org
    let ents = [{entID: activeOwnEnt._id, side: 'primary', partyID: 'party1'}] // Add Creator as Party1
    let collabs = [{uid: state.user._id, email: state.user.email, orgID: state.user.orgID, partyID: 'party1'}] // Add current user as Collab
    let signers = [];

    // Define Labels as selected
    let labels = []
    stagedAgreement.main.labelIDs.forEach((lid) => { labels.push({orgID: activeOwnEnt.orgID, labelID: lid })});

    // Add any default signers based on the selected Entity
    if(activeOwnEnt.defaultSigners !== undefined && activeOwnEnt.defaultSigners !== null &&
    activeOwnEnt.defaultSigners.length > 0 && !isBackload) {
      activeOwnEnt.defaultSigners.forEach((ds) => {
        let u = state.users.filter((u) => u._id === ds)[0];
        if(u !== undefined && u.email !== undefined) {
          signers.push({
            uid: ds,
            partyID: 'party1',
            entityName: activeOwnEnt.legalName,
            email: u.email,
            order: null,
          })
        }
      })
    }

    // Now loop through the selected parties to fill up the respective arrays
    selectedCounterparties.forEach((scp, i) => {
      let pid = ('party' + (i+2))
      orgs.push(scp.org._id);
      ents.push({entID: scp.ent._id, side: scp.side, partyID: pid})
      if(scp.pers !== null && !isBackload) { // Add any person automatically as a collab and signer
        collabs.push({ uid: scp.pers._id, email: scp.pers.email, orgID: scp.pers.orgID, partyID: pid})
        signers.push({ uid: scp.pers._id, partyID: pid, entityName: scp.ent.legalName, email: scp.pers.email, order: null })
      }
    })

    let wLabel = !isBackload && state.org.orgSettings !== undefined &&
        state.org.orgSettings !== null && state.org.orgSettings.whiteLabel !== undefined &&
        state.org.orgSettings.whiteLabel !== null && 
        ['email'].includes(state.org.orgSettings.whiteLabel.level) &&
        state.org.orgSettings.whiteLabel.color !== undefined ? 
            state.org.orgSettings.whiteLabel : 
            null

    let cusURL = isCustomURL && !isBackload ? generateRandomString(20) : null;
    let avv = '000.00001';
  
    let mainAg = {
      versionType: ['pdf','docx', 'xlsx', 'pptx'].includes(stagedAgreement.main.fileType) ? // AgreementVersion related
          stagedAgreement.main.fileType : // This is an attachment
          'canveo', // This is a Canveo Doc
      content: ['pdf','docx', 'xlsx', 'pptx'].includes(stagedAgreement.main.fileType) ? // AgreementVersion related
          {file: stagedAgreement.main.file, creationBy: state.user._id, creationDate, creationDate} : // Link to the attachment
          stagedAgreement.main.content, // Content of the Canveo Doc
      avv: avv, // AgreementVersion related
      agrTypeID: stagedAgreement.main.agrTypeID,
      agrTitle: stagedAgreement.main.agrTitle,
      agrStatus: isBackload ? "InForce" : "Draft",
      orgs: orgs,
      ents: ents,
      collabs: collabs,
      signers: signers,
      parentAID: null,
      priority: 0,
      amendment: null,
      avOwners: [state.org._id],
      effectiveDate: null, // todo
      renewalType: 'auto', // todo
      effectiveTerm: null, // todo
      expiryDate: null, // todo
      sigConfig: {
        provider: Boolean(state.org.signMethod) ? state.org.signMethod : 'skribble',
        quality: 'SES',
      },
      dealValue: [], 
      labels: labels,
      customURL: cusURL,
      whiteLabel: wLabel,
      creationBy: state.user._id,
      creationDate: creationDate,
      lastUpdateBy: state.user._id,
      lastUpdateDate: creationDate,
    }

    // Create Exhibits
    let exhibitsToCreate = []
    stagedAgreement.exhibits.forEach((ex, i) => {
      exhibitsToCreate.push({    
        versionType: ['pdf','docx', 'xlsx', 'pptx'].includes(ex.fileType) ? // AgreementVersion related
            ex.fileType : // This is an attachment
            'canveo', // This is a Canveo Doc
        content: ['pdf','docx', 'xlsx', 'pptx'].includes(ex.fileType) ? // AgreementVersion related
            { file: ex.file, creationBy: state.user._id, creationDate, creationDate } : // Link to the attachment
            ex.content, // Content of the Canveo Doc
        avv: avv, // AgreementVersion related
        agrTypeID: ex.agrTypeID,
        agrTitle: ex.agrTitle,
        agrStatus: "Exhibit",
        orgs: mainAg.orgs,
        ents: mainAg.ents,
        collabs: [],
        signers: [],
        parentAID: 'tobedefined', // This will be updated through the backend upon creation
        priority: ((i+1) * 10),
        amendment: null,
        avOwners: [],
        effectiveDate: null,
        renewalType: 'n/a',
        effectiveTerm: null,
        expiryDate: null,
        sigConfig: null,
        customURL: null,
        whiteLabel: null,
        creationBy: state.user._id,
        creationDate: creationDate,
        lastUpdateBy: state.user._id,
        lastUpdateDate: creationDate,
      })
    })
    mainAg.exhibits = exhibitsToCreate; // Assign any exhibits for creation

    axios.post(state.settings.api + 'agr', { agr: mainAg })
    .then((res) => {
      setLoading(false)
      navigate('/agreement/' + res.data.data._id)
    }).catch((err) => {
      setErrMsg("Unable to create the agreement, try again or contact Canveo Support if the issue persists")
    })
    
    console.log("newAgr", mainAg)
    console.log("exhibits", exhibitsToCreate)

  }

  const handleLabelSelect = (newLabels) => {
    let newIDs = []
    newLabels.forEach((nl) => { newIDs.push(nl._id) });
    setStagedAgreement({...stagedAgreement,
      main: {...stagedAgreement.main, labelIDs: newIDs}
    })
  }

  const selectEntity = (id) => {
    handleCardCptyRequest("changeownent", null, id)
  }

  return (
    <div>

      <Header page={"New"} />

      <Box sx={{ my: 8, mx: 3 }}>

        <Grid container direction="column" justify="flex-start" alignItems="stretch" sx={{px:2}}>

          <Grid item>
              <Box sx={{mt:7, mb: 4}}>
              <Typography align="center" variant="h4">
                  Create a new Agreement
              </Typography>
              </Box>
          </Grid>
          <Grid item>
              <MobileStepper
                  variant="dots"
                  steps={3}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                  <Button onClick={handleNext} disabled={true} sx={styles.backNextButton}>
                      Next&nbsp;&nbsp;<FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                  }
                  backButton={
                  <Button onClick={handleBack} sx={styles.backNextButton}>
                      <FontAwesomeIcon icon={faChevronLeft} />&nbsp;&nbsp;Back
                  </Button>
                  }
              />
          </Grid>
          {errMsg !== null ?
          <Grid item sx={{my:4}}>
            <Typography align="center" color="error">{errMsg}</Typography>
          </Grid>
          :''}

          {// Step 1: Select Counterparty
          activeStep === 0 || addingParty !== null ?          
          <Grid item container justifyContent="center">
            
              <SelectOrCreateCpty
              selectedCounterparties={selectedCounterparties}
              completeSelectOrCreate={completeSelectOrCreate}
              />
          </Grid>
          :
          // Step 2: Select Template
          activeStep === 1 ?
          <Grid item container direction="column" justifyContent="center">
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={2} sx={{mt: 4}}>
              <Grid item>
                <CardCpty
                  cpty={activeOwnEnt}
                  defaultLogo={state.org.logoURL}
                  //type="primary"
                  partyID={"party1"}
                  remove={false}
                  switch={state.subs.filter((s) => s.active)}
                  edit={false}
                  expand={false}
                  handleCardCptyRequest={handleCardCptyRequest}
                />

                {selectedCounterparties
                .filter((scp) => scp.side === 'primary')
                .map((scp,i) => (
                  <Box mt={5} key={i}>
                  <CardCpty
                    cpty={scp.ent}
                    type="primary"
                    partyID={"party" + (selectedCounterparties.indexOf(scp) + 2)}
                    remove={selectedCounterparties.filter((scp) => scp.side === 'primary').length > 0}
                    switch={false}
                    edit={true}
                    expand={false}
                    handleCardCptyRequest={handleCardCptyRequest}
                  />
                  </Box>
                ))}

                <Grid container justifyContent="flex-end" sx={{mt: 0.5}}>
                  <Button
                  variant="text" 
                  color={selectedCounterparties.length > 1 ? "info" : "primary"} 
                  style={selectedCounterparties.length > 1 ? {color: theme.palette.grey[600]} : {}}
                  size="small" 
                  onClick={e => handleCardCptyRequest('addParty', 'primary', null)}
                  disabled={activeOwnEnt === null}
                  sx={{fontWeight: '600'}}>
                    Add party..
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {
                selectedCounterparties
                .filter((scp) => scp.side === 'secondary')
                .map((scp,i) => (
                  <Box mt={i === 0 ? 0 : 5} key={i}>
                  <CardCpty
                    cpty={scp.ent}
                    //type="secondary"
                    partyID={"party" + (selectedCounterparties.indexOf(scp) + 2)}
                    remove={selectedCounterparties.filter((scp) => scp.side === 'secondary').length > 1}
                    switch={false}
                    edit={true}
                    expand={false}
                    handleCardCptyRequest={handleCardCptyRequest}
                  />
                  </Box>
                ))}
                
                <Grid container justifyContent="flex-end" sx={{mt:0.5}}>
                  <Button
                  variant="text" 
                  color={selectedCounterparties.length > 1 ? "info" : "secondary"}
                  style={selectedCounterparties.length > 1 ? {color: theme.palette.grey[600]} : {}}
                  size="small" 
                  onClick={e => handleCardCptyRequest('addParty', 'secondary', null)}
                  disabled={activeOwnEnt === null}
                  sx={{fontWeight: '600'}}>
                    Add party..
                  </Button>  
                </Grid>
                
              </Grid>
            </Grid>

            {activeOwnEnt === null ?
            <Grid item container direction="column" sx={{mt: 8}} alignItems="center" justifyContent="center">

              <Grid item>
              <Typography variant="subtile1">
              Please select your own entity to continue.
              </Typography>
              </Grid>

            </Grid>              
            :
            <Box sx={{mt: 7}}>
              <SelectOrUploadTemplate
              isMain={true}
              handleSelectOrUploadTemplate={handleSelectOrUploadTemplate}
              />
            </Box>
            }
            
          </Grid>
          :
          // Step 3: Configure Agreement (Exhibits & SmartFields)
          activeStep === 2 && 
          selectedCounterparties.filter((scp) => scp.side === "secondary")[0] !== undefined && // You have selected a main cpty
          selectedCounterparties.filter((scp) => scp.side === "secondary")[0].ent !== undefined &&
          activeOwnEnt !== null // You have selected your own entity
          ?
          <Grid item container direction="column" justifyContent="center">
            {!addingExhibit ?
            <>
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" sx={{mt: 4, mb: 0}}>

              <ThumbAgr // Show the Template Structure with Moveable exhibits
              ag={{
                agrTitle: stagedAgreement.main.agrTitle,
                agrStatus: ['backload'].includes(stagedAgreement.main.uploadType) ? "InForce" : "Draft",
                lastUpdateDate: "To be created"
              }} 
              actionReq={['backload'].includes(stagedAgreement.main.uploadType) ? false : true}
              thumbClick={null}
              showLogo={selectedCounterparties.filter((scp) => scp.side === "secondary")[0].ent.logoURL}
              primaryLegalName={activeOwnEnt !== null ? activeOwnEnt.legalName : ''}
              secondaryLegalName={selectedCounterparties.filter((scp) => scp.side === "secondary")[0].ent.legalName}
              additionalParties={selectedCounterparties.filter((scp) => scp.ent._id !== 
                selectedCounterparties.filter((scpa) => scpa.side === "secondary")[0].ent._id)}
              />

            </Grid>

            {stagedAgreement.exhibits !== undefined && stagedAgreement.exhibits !== null &&
            stagedAgreement.exhibits.length > 0 ?

            <Grid item container justifyContent="center" sx={{mb: 1}}>
                <ThumbExhibitView
                  exhibits={stagedAgreement.exhibits}
                  handleUpdate={(newExhibits) => 
                    setStagedAgreement({...stagedAgreement,  exhibits: newExhibits })
                  }
                />
            </Grid>
            :''}
            </>
            :''}

            {// Enable adding exhibits if you're creating an agreement for negotiation purposes
            !['backload'].includes(stagedAgreement.main.uploadType) ? 
            <Grid item container justifyContent="center" sx={{mt: addingExhibit ? 3 : 1}}>

              <Button variant="text" sx={{padding: '5px 25px'}} onClick={e => setAddingExhibit(!addingExhibit)}
              color={addingExhibit ? "secondary" : "primary"}>
                <FontAwesomeIcon icon={addingExhibit ? faArrowLeft : faPlusCircle} />&nbsp;&nbsp;{addingExhibit ? "Cancel adding exhibit": "Add Exhibit"}
              </Button>

            </Grid>
            :''}


            {addingExhibit && stagedAgreement !== null && stagedAgreement.main !== undefined && stagedAgreement.exhibits !== undefined && stagedAgreement.exhibits !== null ?
            <Grid item container justifyContent="center" sx={{mt:4}}>
                <SelectOrUploadTemplate
                isMain={false}
                handleSelectOrUploadTemplate={handleSelectOrUploadTemplate}
                />
            </Grid>
            :''}

            {!addingExhibit ?
            <Grid item container justifyContent="center" sx={{mt:5}}>
              <Box style={{maxWidth: '390px', width: '100%'}}>
                <SelectLabels
                adornIcon={faTag}
                handleLabelSelect={handleLabelSelect}
                selectedIDs={stagedAgreement.main.labelIDs}
                />
              </Box>
            </Grid>
            :''}

            {// Configure access / whiteLabel if you're creating an agreement for negotiation purposes
            !['backload'].includes(stagedAgreement.main.uploadType) && !addingExhibit ? 
            <Grid item container justifyContent="center" sx={{mt:5}}>

              <Box style={{maxWidth: '390px'}}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={isCustomURL} onChange={e => setIsCustomURL(!isCustomURL)} name="isCustomURL" />}
                    label={
                      <Typography>
                        Access via a unique individualized link (url){/*&nbsp;&nbsp;<InformationalTooltip msg={"Decide how your counterparty will access the agreement(s) for this relationship.\n\nEnabling this checkbox allows users to access via a link that is sent to them via email, the link is tokenized and tailored to each specific user and agreement.\n\nIf not enabled, then the user will be required to create a password and login.\n\nA unique link will be more user-friendly while a username and password will be more secure."} />*/}
                      </Typography>}
                  />
                  
                  {/*
                  <FormControlLabel
                    control={<Checkbox checked={isWhiteLabeled} onChange={e => setIsWhiteLabeled(!isWhiteLabeled)} name="isWhiteLabeled" />}
                    label={
                      <Typography>
                        Enable company branding for the agreement
                      </Typography>}
                  />*/}
                </FormGroup>
              </FormControl>
              </Box>

            </Grid>
            :''}

            {!addingExhibit ?
            <Grid item container justifyContent="center" sx={{mt:2}}>

              <Button variant="contained" disableElevation fullWidth sx={{maxWidth: '390px', mt: 1, mb: 6}}
              onClick={handleCreateAgreement}>
                {1 === 2 ? // TODO => SmartFields workflow
                  "Configure Agreement" :
                  "Create Agreement" }
              </Button>

            </Grid>
            :''}

          </Grid>
          : ''}

        </Grid>

      </Box>

    </div>
  );
};
export default New;