import { createEditor, $getRoot, 
         $createRangeSelection, $getSelection, $setSelection,
         $createParagraphNode, $createTextNode } from 'lexical';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from '@lexical/html';
import { editorConfig as config, analyzeNode } from '.';
import { $createClauseNode } from '../nodes/ClauseNode';

/*
function EditorRefPlugin({
  editorRef,
}) {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    editorRef.current = editor;
    return () => {
      editorRef.current = null;
    };
  }, [editor, editorRef]);

  return null;
}*/


export default function importHTML(html, agrTypes, clauseTypes) {

    const editor = createEditor(config);

    let agrTypeID = null;    
    let newNodes = [];

    editor.update(() => {  

        let root = $getRoot();
        
        const parser = new DOMParser();
        const dom = parser.parseFromString(html, 'text/html');

        //let root = $getRoot();
        let nodes = $generateNodesFromDOM(editor, dom);

        // Initial Clause Node
        let clauseNode = $createClauseNode({clauseTypes: [], workflows: [], libIDs: [], filter: 'none', lock: 'none'})
        clauseNode.__parent = 'root';

        let signBlockAnnounced = false;

        // Check if you need to "flatten" the top level list nodes - e.g. when you're clause numbering is part of a list
        let replaceLists = []
        nodes.filter((n) => n.getType() === 'list' && ['number'].includes(n.getListType()))
        .forEach((n, i) => {
            let totalSize = 0;
            n.getChildren().forEach((child) => { totalSize = totalSize + child.getTextContent().length })
            if(totalSize / n.getChildren().length > 300) { // If avgLength of listItems > 300 => replace "ListNode" by individual ParagraphNodes
                let newPars = []
                n.getChildren().forEach((child, i) => {
                    let newPar = $createParagraphNode();
                    newPar.__children = child.__children
                    newPar.__parent = null
                    if(Boolean(newPar.getChildren()[0])) {
                        newPar.getChildren()[0].__text = (i + 1) + ". " + newPar.getChildren()[0].__text;
                    }
                    newPars.push(newPar);
                })
                let idx = nodes.findIndex((nd) => nd === n);
                replaceLists.push({ idx: idx, replaceWith: newPars});
            }
        })
        replaceLists
        .sort((a, b) => (a.idx > b.idx) ? -1 : 1) // Revert the replacement - otherwise the idx will be "off" from earlier replacements
        .forEach((replaceItem) => {
            console.log("replaceItem", replaceItem)
            nodes.splice(replaceItem.idx, 1, ...replaceItem.replaceWith)
        })

        nodes.forEach((node, i) => {

            let s = node.getTextContent().trim();

            if(i === 0 && agrTypes.some((at) => at.fullName.some((n) => s.toLowerCase().includes(n.toLowerCase())))) {
                // The first line matches the agreement type - assign type (no need to include this paragraph as a newNode)
                agrTypeID = agrTypes.filter((at) => at.fullName.some((n) => s.toLowerCase().includes(n.toLowerCase())))[0]._id
            } else {

                // TODO: TOC ANALYSIS
                // TODO: EXHIBIT STARTS
                // TODO CLAUSES AS PART OF A LIST => Backtest2

                let nodeDetails = analyzeNode(s);

                if(Boolean(s) && // ie. ignore "empty lines", we're inserting our own spacing between paragraphs/clauses
                !signBlockAnnounced) { // Skip any blocks after the signblock announcement (until the next AgrSlice)

                    if(Boolean(nodeDetails.firstLeaderLead) || // Blocks starts with lead (eg. 1.)
                    (nodeDetails.isDefinition) // Block is a definition
                    ) {
                        // PUSH OLD CLAUSE, CREATE NEW CLAUSE
                        newNodes.push([clauseNode.getKey(), clauseNode]) // insert previous clause
                        root.__children.push(clauseNode.getKey())
                        clauseNode = $createClauseNode({clauseTypes: [], workflows: [], libIDs: [], filter: 'none', lock: 'none'}) // insert empty clause inbetween
                        newNodes.push([clauseNode.getKey(), clauseNode])
    
                        let cts = []; // Assign Clause Types
                        if(nodeDetails.isDefinition && Boolean(clauseTypes) && 
                        Boolean(clauseTypes.filter((ct) => ct.name === 'Definition')[0])) { // Assign Definition ClauseType
                            cts.push(clauseTypes.filter((ct) => ct.name === 'Definition')[0]._id)
                        } else { // Else find clause types based on mapping
                            cts = findClauseTypes(nodeDetails.clauseTitle, clauseTypes);
                        }
                        root.__children.push(clauseNode.getKey())
                        clauseNode = $createClauseNode({clauseTypes: cts, workflows: [], libIDs: [], filter: 'none', lock: 'none'}) // create new clause
    
                    } else if(Boolean(clauseNode.__children) && clauseNode.__children.length > 0) {
                        // INSERT EMPTY PARAGRAPH BETWEEN PARAGRAPHS INSIDE A SINGLE CLAUSE
                        let parNode = $createParagraphNode();
                        clauseNode.__children.push(parNode.getKey());
                        parNode.__parent = clauseNode.getKey();
                        newNodes.push([parNode.getKey(), parNode])
                    }
    
                    // Push the current Node
                    clauseNode.__children.push(node.getKey());
                    node.__parent = clauseNode.getKey();
                    newNodes.push([node.getKey(), node])
                    let childArray = Boolean(node.__children) ? getChildArray(node.getChildren()) : [];
                    newNodes = newNodes.concat(childArray);

                    if(nodeDetails.hasSignBlockAnnouncement) { signBlockAnnounced = true; }
    
                }
            }

        })
        // Push the final clause
        newNodes.push([clauseNode.getKey(), clauseNode])
        root.__children.push(clauseNode.getKey())

        // Push the Root to the front of the array
        newNodes.unshift([root.getKey(), root])

    })

    //console.log("newNodes", newNodes);

    let serializedNodes = []
    newNodes.forEach((nodeKey) => {
        //console.log("nodeKey", nodeKey, nodeKey[0], nodeKey[1]);
        //console.log("x", nodeKey[1].toJSON())

        let curNode = nodeKey[1]
        let newNode = {};
        for (var p in curNode) {
            newNode[p.substring(2)] = curNode[p];
        }
        //serializedNodes.push([nodeKey[0], newNode])
        serializedNodes.push(newNode);
        //console.log("newNode", newNode)
    })
    console.log("serializedNodes", serializedNodes)

    let newRoot = getNodeWithChildren(serializedNodes.filter((n) => n.type === 'root')[0], serializedNodes)

    console.log("newRoot", newRoot)

    return {
        agrTypeID: agrTypeID,
        content: { root: newRoot }, // JSON.stringify(
        //content: serializedNodes
    };
}

const topicToTitleMapping = [
    { topic: 'Assignment', titles: [
        'assignment'
    ]},
    { topic: 'Commercials', titles: [
        'commercials'
    ]},
    { topic: 'Data Protection', titles: [
        'data protection',
        'protection of counterparty data'
    ]},
    { topic: 'Deliverables', titles: [
        'deliverables'
    ]},
    { topic: 'Fees', titles: [
        'fees'
    ]},
    { topic: 'Force Majeure', titles: [
        'force majeure'
    ]},
    { topic: 'Governing Law', titles: [
        'governing law',
        'governing law and jurisdiction'
    ]},
    { topic: 'Indemnification', titles: [
        'indemnification',
        'indemnification by company',
        'indemnification by counterparty'
    ]},
    { topic: 'Intellectual Property', titles: [
        'intellectual property'
    ]},
    { topic: 'Jurisdiction', titles: [
        'jurisdiction',
        'governing law and jurisdiction'
    ]},
    { topic: 'Limitation of Liability', titles: [
        'limitation of liability',
        'unlimited liability',
        'limited liability'
    ]},
    { topic: 'Notices', titles: [
        'notices'
    ]},
    { topic: 'Payment', titles: [
        'payment',
        'invoicing'
    ]},
    { topic: 'Representations & Warranties', titles: [
        'representations & warranties',
        'representations and warranties'
    ]},
    { topic: 'SLA', titles: [
        'sla',
        'service level agreement',
    ]},
    { topic: 'Support', titles: [
        'support'
    ]},
    { topic: 'Term', titles: [
        'term'
    ]},
    { topic: 'Termination', titles: [
        'termination'
    ]}
]

function findClauseTypes(title, clauseTypes) {

    let cts = []
    topicToTitleMapping.forEach((ttt) => {
        if(ttt.titles.some((t) => t === title.toLowerCase()) && // Title exists in the mapping
        Boolean(clauseTypes.filter((ct) => ct.name === ttt.topic)[0]) // User has this clauseType
        ) { 
            cts.push(clauseTypes.filter(((ct) => ct.name === ttt.topic))[0]._id)
        }
    })
    return cts;

}

function getChildArray(children) {

    let arr = []

    children.forEach((child) => {
        arr.push([child.getKey(), child]);
        let ccArray = Boolean(child.__children) ? getChildArray(child.getChildren()) : [];
        arr = arr.concat(ccArray);
    })

    return arr;

}

function getNodeWithChildren(node, totalArray) {

    //console.log("x1")
    let n = node
    delete n.cachedText
    delete n.dir
    delete n.parent
    delete n.key
    n.direction = "ltr"
    n.version = 1

    let children = []
    //console.log("x2", n, n.children)
    if(Boolean(n.children)) {
        n.children.forEach((childKey) => {
            //console.log("childKey", childKey)
            let child = totalArray.filter((ta) => ta.key === childKey)[0];
            delete child.cachedText
            delete child.dir
            delete child.parent
            delete child.key
            child.direction = "ltr"
            child.version = 1

            //console.log("child", child);
            let completeChild = getNodeWithChildren(child, totalArray);
            //console.log("completeChild", completeChild)
            if(Boolean(child)) { children.push(completeChild) }
        })
        n.children = children;
    }
    //console.log("x3", n)

    return n;
}

/*
function getLeadSummary(inputString) {
    // LEAD TYPES ie. 1. or A. or I.
    let isNumeric = inputString.match(/^(\d{1,2})(\.|\)| )/) || inputString.match(/^\((\d{1,2})\)/)
    let isAlpha = inputString.match(/^([a-zA-Z]{1,1})(\.|\))/) || inputString.match(/^\(([a-zA-Z]{1,1})\)/)
    let isRoman = inputString.match(/^([ivxIVX]{1,3})(\.|\)| )/) || inputString.match(/^\(([ivxIVX]{1,3})\)/)

    // LEAD VARIANT ie. 1. or 1) or (1)
    let variant = 
        (isNumeric || isAlpha || isRoman) && (inputString.substr(0,5).includes("(") && inputString.substr(0,5).includes(")")) ? 'twobrackets' :
        (isNumeric || isAlpha || isRoman) && (inputString.substr(0,5).includes(")")) ? 'onebracket' :
        (isNumeric || isAlpha || isRoman) && (inputString.trim().endsWith(".")) ? 'nobrackets' :
        (isNumeric || isAlpha || isRoman) && (inputString.endsWith(" ")) ? 'space' : "none";

    return {
      isNumeric: isNumeric ? true : false,
      isAlpha: isAlpha ? true : false,
      isRoman: isRoman ? true : false,
      variant: variant,
      lead: inputString.replaceAll('.', '').replaceAll('(','').replaceAll(')','').replaceAll(' ', '')
    }
}*/