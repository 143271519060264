import { getMonthForMM, getNumberString } from '.'

const dayjs = require('dayjs')

function getSmartFieldValue(type, val1, val2) {

    let s = '(unknown value)';

    if(val1 === undefined || val1 === null || val1 === '' || type === undefined || type === null || type === '') {
        s = '[to be completed]';
        
    } else if(['string-text', 'number', 'email', 'link'].includes(type)) {
        s = val1

    } else if(type === 'list-standard') {
        s = (val1 === 'state' ? 'State of ' : '') + val2

    } else if(type === 'amount') {
        s = val1 + ' ' + (val2 >= 10000 && val2 % 1 === 0 ? Math.round(val2) : val2)

    } else if(type === 'date-stamp') {

        if(val1 !== '') {
            let date = dayjs(val1).format('YYYY[/]MM[/]DD');
            let d = date.substr(8,2).startsWith('0') ? date.substr(9,1) : date.substr(8,2)
            let m = getMonthForMM(date.substr(5,2))
            let y = date.substr(0,4)
            s = d + " " + m + " " + y;
        } else {
            s = "(not defined)"
        }

    } else if(type === 'date-period') {
        let period = (val2 === 'busdays' ? 'business days' : val2)
        if((val1 === 1 || parseInt(val1) === 1) && period.endsWith("s")){ period = period.slice(0, -1) } // slice off the "s" at the end if you only have 1
        s = getNumberString(val1) + " (" + val1 + ") " + period;

    } else if(type === 'list-custom') {
        let arr = val1.split('|')
        s = arr[parseInt(val2)] !== undefined ? arr[parseInt(val2)] : ''

    } else if(type === 'auto') {
        s = "(Automatically populated)"
    }

    return s;

}

export { getSmartFieldValue }