function initializeUser(dispatch, data) {
    dispatch({ type: "INIT_AGRTYPES", payload: data.agrTypes })
    dispatch({ type: "INIT_CPENTS", payload: data.cpents })
    dispatch({ type: "INIT_LABELS", payload: data.labels })
    dispatch({ type: "UPDATE_ORG", payload: data.org })
    dispatch({ type: "INIT_SUBS", payload: data.subs })
    dispatch({ type: "INIT_TEMPLATES", payload: data.templates })
    dispatch({ type: "UPDATE_USER", payload: data.user })
    dispatch({ type: "INIT_USERS", payload: data.users })
    dispatch({ type: "INIT_CLAUSETYPES", payload: data.clauseTypes })
    dispatch({ type: "INIT_CLAUSETYPEGUIDES", payload: data.clauseTypeGuides })
    dispatch({ type: "INIT_CLAUSELIBITEMS", payload: data.clauseLibItems })
    dispatch({ type: "INIT_PARAMS_LIB", payload: data.params })
}

export { initializeUser }