import React, { useContext, useRef, useEffect } from 'react';
import theme from '../theme/theme';
import { globalStore } from '../state/store';
import { Autocomplete, Box, Button, CircularProgress, 
         Dialog, Fade, Grid, InputAdornment, TextField, Typography,
         createFilterOptions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faCircle } from '@fortawesome/pro-solid-svg-icons';

const filter = createFilterOptions();

export default function SelectOrCreateCpty(props) {

    const [state, dispatch] = useContext(globalStore);

    return (
    <div>
    <Grid container direction="column">

        <Grid item sx={{my:2, width: props.width}}>
            <Autocomplete
            value={props.agrType}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    props.setAgrType('newName', newValue);
                    //setStagedTemplate({...stagedTemplate, agrType: { newName: newValue, shortName: ''}})
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    props.setAgrType('newName', newValue.inputValue)
                    //setStagedTemplate({...stagedTemplate, agrType: { newName: newValue.inputValue, shortName: ''}})
                } else {
                    props.setAgrType('agrType', newValue)
                    //setStagedTemplate({...stagedTemplate, agrType: newValue})
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.fullName[0]);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Create "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text"
            options={state.agrTypes.filter((at) => at.active).sort((a, b) => (a.fullName[0] > b.fullName[0]) ? 1 : -1)}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue || option.newName) {
                    return option.newName ? option.newName : option.inputValue;
                }
                // Regular option
                let showOption = option.fullName !== undefined ? option.fullName[0] : 
                    state.agrTypes.filter((at) => at._id === option.agrTypeID)[0] !== undefined ?
                    state.agrTypes.filter((at) => at._id === option.agrTypeID)[0].fullName[0] : 
                    "Please select";
                return showOption;
            }}
            renderOption={(props, option) => <li {...props}>{
                option.fullName !== undefined ? option.fullName[0] : option.title
            }</li>}
            freeSolo
            renderInput={(params) => (
                <TextField
                {...params}
                label="Agreement Type"
                placeholder="Agreement Type..."
                InputProps={
                {
                    ...params.InputProps,
                    startAdornment: (
                    <>
                        <InputAdornment position="start">
                        <FontAwesomeIcon icon={faFileLines} />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                    </>
                    )
                }}
                />
            )}
            />
        </Grid>

        {//stagedTemplate !== null && stagedTemplate.agrType !== undefined &&  stagedTemplate.agrType !== null && 
        //stagedTemplate.agrType.newName !== undefined &&  stagedTemplate.agrType.newName !== null ?
        props.agrType !== null && props.agrType !== undefined && 
        props.agrType.newName !== undefined && props.agrType.newName !== null ?

        <Grid item sx={{my:2, width: props.width}}>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="shortName"
                label={"Agreement Type ShortName"}
                placeholder={"e.g. \"NDA\""}
                value={props.agrType.shortName}
                onChange={e => props.setAgrType('shortName', e.target.value)}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <FontAwesomeIcon icon={faCircle} />
                    </InputAdornment>
                    ),
                }}
            />
        </Grid>
        :''}
    </Grid>
    </div>
    )
}