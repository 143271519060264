function getTimeIndicationForDate(date) { 
    let s = '';
    var seconds = Math.floor((Date.now() - Date.parse(date)) / 1000);
    
    if ((seconds / 31536000) > 1) {
        s = Math.floor(seconds / 31536000) + " year" + ((seconds / 31536000) > 2 ? "s" : "") + " ago";
    } else if ((seconds / 2592000) > 1) {
        s = Math.floor(seconds / 2592000) + " month" + ((seconds / 2592000) > 2 ? "s" : "") + " ago";
    } else if ((seconds / 604800) > 1) {
        s = Math.floor(seconds / 604800) + " week" + ((seconds / 604800) > 2 ? "s" : "") + " ago";
    } else if ((seconds / 86400) > 1) {
        s = Math.floor(seconds / 86400) + " day" + ((seconds / 86400) > 2 ? "s" : "") + " ago";
    } else if ((seconds / 3600) > 1) {
        s = Math.floor(seconds / 3600) + " hour" + ((seconds / 3600) > 2 ? "s" : "") + " ago";
    } else if ((seconds / 60) > 1) {
        s = Math.floor(seconds / 60) + " min" + ((seconds / 60) > 2 ? "s" : "") + " ago";
    } else {
        s = Math.floor(seconds) + " secs ago";
    }

    return s;
}

export default getTimeIndicationForDate