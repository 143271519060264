import React from 'react';
import theme from '../theme/theme';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChipButton = (props) => {

  return (
    <Button 
    variant={props.variant}
    size="small" 
    color={['secondary'].includes(props.color) ? "secondary" : "primary"}
    disableElevation
    sx={
    ['lightGrey'].includes(props.color) ?     
    { 
        boxSizing: 'border-box',
        border: '1px solid' + theme.palette.grey[300],
        color: theme.palette.grey[700], 
        //backgroundColor: theme.palette.grey[100],
        '&:hover': {
            color: theme.palette.grey[900], 
            backgroundColor: theme.palette.grey[200],
        }
    } : 
    {}}
    style={{padding: '0px 18px', height: '30px', fontWeight: '700',}} 
    onClick={props.buttonClick}>
        {props.iconPos === "right" ? props.text : ""}

        {Boolean(props.icon) ?
        <FontAwesomeIcon icon={props.icon} style={{padding: props.iconPos === "right" ? '0px 0px 0px 10px' : '0px 10px 0px 0px'}} />
        :
        props.text}
        
        {props.iconPos === "left" ? props.text : ""}
    </Button>
  );
};
export default ChipButton;