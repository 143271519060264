import theme from '../theme/theme';

function getColorForPartyID(partyID, modifier) { 

    let c = theme.palette.primary.main;

    if(['party1'].includes(partyID) && ['theme'].includes(modifier)) { c = 'primary' }
    else if(['party1'].includes(partyID) && ['dark'].includes(modifier)) { c = theme.palette.primary.dark }
    else if(['party1'].includes(partyID)) { c = theme.palette.primary.main }

    else if(['party2'].includes(partyID) && ['theme'].includes(modifier)) { c = 'secondary' }
    else if(['party2'].includes(partyID) && ['dark'].includes(modifier)) { c = theme.palette.secondary.dark }
    else if(['party2'].includes(partyID)) { c = theme.palette.secondary.main }

    else if(['party3'].includes(partyID) && ['theme'].includes(modifier)) { c = 'tertiary' }
    else if(['party3'].includes(partyID) && ['dark'].includes(modifier)) { c = theme.palette.tertiary.dark }
    else if(['party3'].includes(partyID)) { c = theme.palette.tertiary.main }

    else if(['party4'].includes(partyID) && ['theme'].includes(modifier)) { c = 'quaternary' }
    else if(['party4'].includes(partyID) && ['dark'].includes(modifier)) { c = theme.palette.quaternary.dark }
    else if(['party4'].includes(partyID)) { c = theme.palette.quaternary.main }

    else if(['party5'].includes(partyID) && ['theme'].includes(modifier)) { c = 'quinary' }
    else if(['party5'].includes(partyID) && ['dark'].includes(modifier)) { c = theme.palette.quinary.dark }
    else if(['party5'].includes(partyID)) { c = theme.palette.quinary.main }
    // TODO - expand to 20

    return c;
}

export default getColorForPartyID