import React from 'react';
import theme from '../theme/theme';
import { Box, Button, IconButton, TextField, Menu, MenuItem, ListItemIcon,
         List, ListItem, ListItemText, ListItemSecondaryAction, 
         Tooltip, useMediaQuery } from '@mui/material';
import { DialogExhibits } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faEllipsisH, faArrowLeft, faPlusCircle, faArrowDownShortWide,
         faTrashCan, faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import pdf from '../assets/img/pdf.png';
import docx from '../assets/img/docx.png';
import pptx from '../assets/img/pptx.png';
import xlsx from '../assets/img/xlsx.png';

const AgrExhibitList = (props) => {
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    const listItemWidth = isMdUp ? '600px' : isSmUp ? '440px' : '320px';

    const styles = {
        exhList: {
            borderLeft: '3px solid ' + theme.palette.grey[400],
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
            width: listItemWidth,
        },
        exhListSelected: {
          borderLeft: '3px solid ' + theme.palette.grey[400],
          backgroundColor: theme.palette.grey[100],
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
          },
          width: listItemWidth,
        },
        pdfIcon: {
            marginLeft: '10px',
            height: '13px',
        },
    }

    const navigate = useNavigate();

    const [exhibitTitleEditing, setExhibitTitleEditing] = React.useState(false);
    const [newExhibitTitle, setNewExhibitTitle] = React.useState('');
    const [dialogExhibitsOpen, setDialogExhibitsOpen] = React.useState(false);

    const handleAgrTitleUpdate = () => {

      props.handleTitleUpdate(props.aid, newExhibitTitle)
      setExhibitTitleEditing(false)

    }

    return (
    <Box>
        {exhibitTitleEditing ?

        <Box sx={{mt:3, mb:2, textAlign: 'center'}}>
          
          <TextField 
            multiline
            defaultValue={Boolean(props.ags.filter((a) => a._id === props.aid)[0]) ? 
              props.ags.filter((a) => a._id === props.aid)[0].agrTitle : "Agreement Title"}
            style={{width: '320px'}}
            label={"Exhibit title"}
            onBlur={e => setNewExhibitTitle(e.target.value)}
          />
          <Box sx={{mt:1, textAlign: 'center'}}>
            <Button variant="text" onClick={e => setExhibitTitleEditing(false)}>
              <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button variant="text" onClick={handleAgrTitleUpdate}>
              Update&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} />
            </Button>
          </Box>
        </Box>
        
        :
        <List dense>
          {props.ags
          .sort((a, b) => (a.parentAID === null) ? 1 : (a.priority > b.priority) ? 1 : -1)
          //.sort((a, b) => (a.parentAID === null) ? -1 : 1)
          .map((s,i) => (
            <ListItem button key={s._id} disableRipple={i === 0}
            onClick={e => navigate('/agreement/' + s._id)}
            style={props.aid === s._id ? styles.exhListSelected : styles.exhList}
            >

              <ListItemText 
              primary={
                <span style={props.aid === s._id ? {fontWeight: '700'} : {}}>
                {s.parentAID === null || s.parentAID === undefined ? 
                  "Main Body" : 
                  <>Exhibit {String.fromCharCode(96 + i).toUpperCase() + ": " + 
                  s.agrTitle}{props.avs.filter((av) => av.agrID === s._id)[0] !== undefined &&
                    ['pdf','docx','pptx','xlsx'].includes(props.avs.filter((av) => av.agrID === s._id)[0].versionType)  ? 
                    <img src={
                      ['docx'].includes(props.avs.filter((av) => av.agrID === s._id)[0].versionType) ? docx : 
                      ['pptx'].includes(props.avs.filter((av) => av.agrID === s._id)[0].versionType) ? pptx : 
                      ['xlsx'].includes(props.avs.filter((av) => av.agrID === s._id)[0].versionType) ? xlsx : 
                      pdf} 
                    alt={"versionType"} style={styles.pdfIcon} />:''}</>}
                </span>}
              />

              {props.isTemplating ?
              // Allow to add a default exhbit to a template - references the blueprintID
              <ListItemSecondaryAction>
                <Button size="small">
                  Add default Exhibit&nbsp;&nbsp;<FontAwesomeIcon icon={faPlusCircle} />
                </Button>
              </ListItemSecondaryAction>
              :
              !props.isTemplating && props.isOwner && s.parentAID === null && s._id === props.aid ?
              // All to add / remove / change order of an exhibit from the Main Body
              <ListItemSecondaryAction>
                <Tooltip title="Manage Exhibits" placement="right">
                <IconButton size="small" /*onClick={e => setExhibitTitleEditing(true)}*/ onClick={e => setDialogExhibitsOpen(true)}>
                <FontAwesomeIcon icon={faEllipsisH} style={{fontSize:'12px', padding:'4px'}} />
                </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
              :
              !props.isTemplating && props.isOwner && s.parentAID !== null && s._id === props.aid ? 
              // only enable editing of the title when you own the ag and before exec status
              <ListItemSecondaryAction>
                <Tooltip title="Edit the exhibit title" placement="right">
                <IconButton size="small" onClick={e => setExhibitTitleEditing(true)}>
                <FontAwesomeIcon icon={faPen} style={{fontSize:'12px', padding:'4px'}} />
                </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
              :
              ''}
            </ListItem>
          ))}
        </List>
        }

        {!props.isTemplating && props.isOwner ?
        <DialogExhibits
        open={dialogExhibitsOpen}
        closeDialog={e => setDialogExhibitsOpen(false)}
        ags={props.ags}
        avs={props.avs}
        />
        :''}

        {/*
        <Menu
        sx={{ mt: '25px' }}
        id="menu-exhibits"
        anchorEl={anchorElExhibits}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorElExhibits)}
        onClose={e => setAnchorElExhibits(null)}
        >
        <MenuItem style={{width: '220px'}}>
          <ListItemIcon>
              <FontAwesomeIcon icon={faFilePlus} />
          </ListItemIcon>
          <ListItemText>Add an exhibit</ListItemText>
        </MenuItem>
        <MenuItem style={{width: '220px'}}>
          <ListItemIcon>
              <FontAwesomeIcon icon={faTrashCan} />
          </ListItemIcon>
          <ListItemText>Remove an exhibit</ListItemText>
        </MenuItem>
        <MenuItem style={{width: '220px'}}>
          <ListItemIcon>
              <FontAwesomeIcon icon={faArrowDownShortWide} />
          </ListItemIcon>
          <ListItemText>Reorder exhibits</ListItemText>
        </MenuItem>
        </Menu>
        */}
    </Box> 
    );
}

export default AgrExhibitList