import React from 'react';
import theme from '../theme/theme';
import { Box, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
//import DayJsUtils from '@date-io/dayjs';

//import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { countries, currencies, states, 
         paramautofields, paramlists, paramdateperiods } from '../assets/static';
//import { currencies } from '../assets/static/currencies';
//import { states } from '../assets/static/states';
//import { sfautofields } from '../assets/static/sfautofields'
//import { sflists } from '../assets/static/sflists'
//import { sfdateperiods } from '../assets/static/sfdateperiods'

const ParamEditable = (props) => {

    const styles = {
    bold: {
      fontWeight: '700',
    },
    textAreaAuto: {
      backgroundColor: theme.palette.primary.contrastText,
      padding: '9px 13px 9px 13px',
      border: '1px solid #c4c4c4',
      borderRadius: '10px',
      width: '230px',
      minHeight: '32px',
      fontSize: '16px',
      fontWeight: '300',
    },
    w100pr: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'left',
        //width: '100px',
        //width: 'calc(100%-140px)',
        width: '86px',
        marginRight: '10px'
    },
    w140: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'left',
        width: '140px',
    },
    w160: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'left',
        width: '120px',
        //width: '160px',
    },
    w220: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'left',
        width: '220px'
    },
    w260: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'left',
        width: '260px'
    },
    w280: {
        backgroundColor: theme.palette.primary.contrastText,
        textAlign: 'left',
        width: '100%'
    },
    rootSO: {
        whiteSpace: "unset",
        wordBreak: "break-word",
        maxWidth: '320px'
    }
  }

  /*
  const [dateError, setDateError] = React.useState(null);

  useEffect(() => {

    if(props.type === 'date-stamp' && Boolean(props.val1)) {

        let regex = new RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)
        if(!regex.test(props.val1)) {
            setDateError("Use format: DD/MM/YYYY")
        } else if (Boolean(dateError)) {
            setDateError(null)
        }
    } else if(Boolean(dateError)) {
        setDateError(null)
    }

  }, [props.type, props.val1])*/

    return (
        <div style={{display: 'inline'}}>
        {['string-text'].includes(props.type) ?
            /*<TextareaAutosize
            className={classes.textAreaAuto}
            rowsMin={1}
            rowsMax={8}
            placeholder={"e.g. several words"}
            value={props.val1}
            onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
            />*/
            <TextField
            variant="outlined"
            multiline
            style={styles.w280}
            placeholder={"e.g. several words"}
            value={props.val1}
            onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
            />
        :props.type === 'date-stamp' ?
            <Box>
            <TextField
            variant="outlined"
            style={styles.w280}
            label="DD / MM / YYYY"
            placeholder={"Enter Date..."}
            value={props.val1}
            onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
            />
            {/*Boolean(dateError) ?
            <Typography variant="body2" align="center" color="textSecondary" style={{marginTop: '5px'}}>{dateError}</Typography>
            :''*/}
            </Box>
        //selected={startDate} 
        //onChange={(date) => setStartDate(date)} />
        // TODO DATE PICKER
        /* 
        <DatePicker 
        //selected={isNaN(Date.parse(props.val1)) ? null : props.val1}
        selected={null}
        onChange={date => props.onFieldChange(props.sfid, 'val1', date)}
        />

            <MuiPickersUtilsProvider utils={DayJsUtils} >
            <KeyboardDatePicker
            id={props.sfid}
            className={classes.w220}
            style={{marginTop: '0px', paddingTop: '0px'}}
            margin="normal"
            //id="date-picker-dialog"
            format="DD/MM/YYYY"
            value={isNaN(Date.parse(props.val1)) ? null : props.val1}
            inputVariant="outlined"
            onChange={e => props.onFieldChange(props.sfid, 'val1', e)}
            />
            </MuiPickersUtilsProvider>
        */
        :props.type === 'date-period' ?
            <>
            <TextField
            placeholder={"e.g. 8"}
            type={"number"}
            variant="outlined"
            value={props.val1}
            style={styles.w100pr}
            onChange={e => 
                e.target.value >= 0 ? props.onFieldChange(props.sfid, 'val1', Math.round(e.target.value)) : null}
            />

            <FormControl variant="outlined">
            <Select
            value={props.val2}
            style={styles.w160}
            onChange={e => props.onFieldChange(props.sfid, 'val2', e.target.value)}
            >
            <MenuItem value="" disabled>Select duration</MenuItem>
            {paramdateperiods
            .filter((sfd) => !['initial','renewal'].includes(props.sfid) || !['minutes', 'hours', 'busdays'].includes(sfd.val))
            .map((sfd) => (
                <MenuItem value={sfd.val} key={sfd.val}>{sfd.desc}</MenuItem>
            ))}
            </Select>
            </FormControl>
            </>

        :props.type === 'number' ?
            <TextField
            placeholder={"e.g. 30"}
            type={"number"}
            variant="outlined"
            value={props.val1}
            style={styles.w140}
            onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
            />
        :props.type === 'amount' ?

            <>
            <FormControl variant="outlined">
                <Select
                value={props.val1}
                style={styles.w100pr}
                onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
                >
                {currencies
                .sort((a,b) => (a.iso > b.iso) ? 1 : ((b.iso > a.iso) ? -1 : 0))
                .map((ccy) => (
                    <MenuItem value={ccy.iso} key={ccy.iso}>{ccy.iso}</MenuItem>
                ))}
                </Select>
            </FormControl>

            <TextField
            placeholder={"e.g. 30"}
            type={"number"}
            variant="outlined"
            value={props.val2}
            style={styles.w160}
            //onChange={e => handleAmountChange(props.sfid, 'val2',)}
            onChange={e => props.onFieldChange(props.sfid, 'val2', e.target.value)}
            onBlur={e => props.onFieldChange(props.sfid, 'val2', (Math.round(e.target.value * 100) / 100).toFixed(2))}
            //onChange={e => props.onFieldChange(props.sfid, 'val2', (Math.round(e.target.value * 100) / 100).toFixed(2))}
            />
            </>

        :props.type === 'email' ?
            <TextField
            fullWidth
            placeholder={"name@domain.com"}
            type={"email"}
            variant="outlined"
            value={props.val1}
            style={styles.w280}
            onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
            />
        :props.type === 'link' ?
            <TextField
            fullWidth
            placeholder={"https://www.domain.com/link"}
            type={"text"}
            variant="outlined"
            value={props.val1}
            style={styles.w280}
            onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
            />
        /*:props.type === 'list-standard' && ['country', 'state'].includes(props.val1) && !props.fromClause ?

            <FormControl variant="outlined">
                <Select
                value={props.val2}
                style={styles.w260}
                onChange={e => props.onFieldChange(props.sfid, 'val2', e.target.value)}
                >
                {
                props.val1 === 'country' ?
                countries
                .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                .map((c, i) => (
                    <MenuItem key={i} value={c.label}>{c.label}</MenuItem>
                ))
                :
                props.val1 === 'state' ?
                states
                .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                .map((s, i) => (
                    <MenuItem key={i} value={s.label}>{s.label}</MenuItem>
                ))
                :
                ''
                }
                </Select>
            </FormControl>
        */
        :props.type === 'list-standard' ? // && props.fromClause ?

            <>
            <FormControl variant="outlined">
                <Select
                value={props.val1}
                style={styles.w100pr}
                onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
                >
                {paramlists.map((ls, i) => (
                    <MenuItem key={ls.val} value={ls.val}>{ls.desc}</MenuItem>
                ))}
                </Select>
            </FormControl>

            <FormControl variant="outlined">
                <Select
                value={props.val2}
                style={styles.w160}
                onChange={e => props.onFieldChange(props.sfid, 'val2', e.target.value)}
                >
                {
                props.val1 === 'country' ?
                countries
                .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                .map((c, i) => (
                    <MenuItem key={i} value={c.label}>{c.label}</MenuItem>
                ))
                :
                props.val1 === 'state' ?
                states
                .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                .map((s, i) => (
                    <MenuItem key={i} value={s.label}>{s.label}</MenuItem>
                ))
                :
                <MenuItem key={"nooption"} value={''} disabled>Select Option</MenuItem>
                }
                </Select>
            </FormControl>
            </>

        :props.type === 'list-custom' ?

            <FormControl variant="outlined">
            <Select
            value={props.val2}
            style={styles.w260}
            onChange={e => props.onFieldChange(props.sfid, 'val2', e.target.value)}
            >
            <MenuItem value="" disabled>Select option</MenuItem>
            {props.val1.split('|').map((opt, i) => (
                <MenuItem key={i} value={i} /*classes={{ root: classes.rootSO }}*/>{opt === "" ? "( leave blank )": opt}</MenuItem>
            ))}
            </Select>
            </FormControl>

        :props.type === 'auto' ?

        <FormControl variant="outlined">
        <Select
        value={props.val1}
        style={styles.w220}
        onChange={e => props.onFieldChange(props.sfid, 'val1', e.target.value)}
        >
        {paramautofields.map((al) => (
            <MenuItem key={al.val} value={al.val}>{al.desc}</MenuItem>
        ))}
        </Select>
        </FormControl>
        :
        ''}

    </div>
    );
}

export default ParamEditable