import React, { createContext, useReducer } from 'react';
import { agrExecReducer, agrsReducer, agrTypesReducer, avsReducer, 
         clauseLibItemsReducer,
         clauseTypeGuidesReducer, clauseTypesReducer, cpentsReducer, 
         labelsReducer, orgReducer, paramsReducer,
         partiesReducer, subsReducer, snackbarReducer, templatesReducer, 
         userReducer, usersReducer, userSessionReducer,
         workflowsReducer } from './reducers';

import { countries } from '../assets/static'

const settings = {
  api: '/api/v2/'
}

const initialState = {
  agrExec: {},
  agrs: [],
  agrTypes: [],
  avs: [],
  clauseLibItems: [],
  clauseTypeGuides: [],
  clauseTypes: [],
  cpents: [],
  countries: countries,
  labels: [],
  org: {},
  params: { lib: [], doc: [] },
  parties: [],
  subs: [],
  settings: settings,
  snackbar: null,
  templates: [],
  user: {},
  users: [],
  userSession: {
    filter: null,
  },
  workflows: [],
};
const globalStore = createContext(initialState);
const { Provider } = globalStore;

const combineReducers = reducers => {
  return (state, action) => {
    return Object.keys(reducers).reduce(
      (acc, prop) => {
        return ({
          ...acc,
          ...reducers[prop]({ [prop]: acc[prop] }, action),
        })
      },
      state
    )
  }
}

const StateProvider = ( { children } ) => {

  const reducers = combineReducers({
    agrExec: agrExecReducer,
    agrs: agrsReducer,
    agrTypes: agrTypesReducer,
    avs: avsReducer,
    clauseLibItems: clauseLibItemsReducer,
    clauseTypeGuides: clauseTypeGuidesReducer,
    clauseTypes: clauseTypesReducer,
    cpents: cpentsReducer,
    labels: labelsReducer,
    org: orgReducer,
    params: paramsReducer,
    parties: partiesReducer,
    snackbar: snackbarReducer,
    subs: subsReducer,
    templates: templatesReducer,
    user: userReducer,
    users: usersReducer,
    userSession: userSessionReducer,
    workflows: workflowsReducer,
  })

  const [state, dispatch] = useReducer(reducers, initialState)
  const store = React.useMemo(() => [state, dispatch], [state]);

  return <Provider value={store}>{children}</Provider>;
};

export { globalStore, StateProvider }