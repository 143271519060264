import React from 'react';
import { Fab } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FabStandard = (props) => {

  return (
    <Fab variant="extended" color="secondary" onClick={props.click}>
        {props.text}&nbsp;&nbsp;
        <FontAwesomeIcon icon={props.icon} />
    </Fab>
  );
};
export default FabStandard;


