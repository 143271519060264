import { $getNodeByKey, $isTextNode } from "lexical";
import { getColorForPartyID } from '../../../utils';

export function getClauseHTML(clauseNode) {
    let htmlString = 
        `<table width="100%"><tr><td align="center">
        <div style="border-radius: 20px; border: 1px solid #e0e0e0; background-color: #f9f9f9; max-width: 600px; padding: 15px 25px; font-size: 15px">`;

    clauseNode.getChildren().forEach((n) => {
        let htmlTemp = getChildHTML(n);
        htmlString = htmlString + htmlTemp;
    })

    htmlString = htmlString + 
        `</div>
        </td></tr></table>`
    
    return htmlString;
}

function getChildHTML(node) {
    let html = '', childHtml = '';

    if(Boolean(node.__children) && node.__children.length > 0) {
        node.__children.forEach((childKey) => {
            let child = $getNodeByKey(childKey)
            childHtml = childHtml + getChildHTML(child)
        })
    } else if($isTextNode(node)) {
        if(['redline'].includes(node.getType())) {
            let style = getRedlineStyle(node)
            childHtml = '<span' + style + '>' + node.__text + '</span>'
        } else {
            childHtml = node.__text
        }
    }

    html = html + (
        ['paragraph'].includes(node.getType()) ?
            '<p style="text-align:left;">' + childHtml + '</p>' :
        ['list'].includes(node.getType()) ?
            '<ul>' + childHtml + '</ul>' : // TODO: List items - for emails
        ['heading'].includes(node.getType()) ?
            '<' + node.__tag  + '>' + childHtml + '</' + node.__tag + '>' :
        ['mark'].includes(node.getType()) && node.getIDs().some((id) => id.startsWith('cp_')) ?
            '<span style="background-color: #fcedb5; padding: 2px 0px; border-bottom: 2px solid #f9d548">' + childHtml + '</span>' :
            childHtml
        )

    return html
}

function getRedlineStyle(redlineNode) {
    let style = 'color: ' + getColorForPartyID(redlineNode.partyID) + ';'
    style = style + (
        ['del'].includes(redlineNode.__redlineType) ? 'text-decoration: line-through;' :
        ['add'].includes(redlineNode.__redlineType) ? 'border-bottom: 1px dotted ' + getColorForPartyID(redlineNode.partyID) + ';' : '')
        // todo: xdel, xadd for emails

    return ' style="' + style + '"';
}