import React, { useContext, useEffect, useRef } from 'react';
import initialEditorState from './_initialEditorState';

import { $getRoot, $getSelection } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { AutoScrollPlugin } from '@lexical/react/LexicalAutoScrollPlugin';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { AutoLinkPlugin, ClausePlugin, DefinitionsPlugin, ListMaxIndentLevelPlugin, RedlinePlugin, 
         TemplateVersionPlugin, TocPlugin, ToolbarPlugin, WorkflowPlugin } from "./editor/plugins";

import { editorConfig } from './editor/utils';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import theme from '../theme/theme';
import download from "js-file-download";
import { globalStore } from '../state/store';
import { AgrExecBox, AgrExhibitList, CanveoCircularProgress, CoreDrawer, Header } from '.';
import { Box, Button, Grid, IconButton, InputBase, Snackbar, Typography, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { trunc } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCaretLeft, faCircleCaretRight, faUpload, faDownload, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import xlsx from '../assets/img/xlsx.png'
import pptx from '../assets/img/pptx.png'
import docx from '../assets/img/docx.png'

/*
const editorConfig = {
  theme: editorTheme, // The editor theme
  onError(error) { throw error; }, // Handling of errors during update
  nodes: [HeadingNode, ListNode, ListItemNode, TableNode, TableCellNode, TableRowNode, AutoLinkNode, LinkNode, 
    ClauseNode, RedlineNode, MarkNode]
};*/

/*
function onError(error) {
  console.error("Editor Error", error);
}*/

// Start of Editor
const Editor = (props) => {

    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const styles = {
      agrTitleInput: {
        width: '100%',
        fontWeight: '700',
        fontSize: '44px',
        backgroundColor: theme.palette.primary.contrastText,
      },
      titleBase: {
        width: isLgUp ? '780px' : isMdUp ? '720px' : isSmUp ? '520px' : '320px',
      }
    }

    const containerWithScrollRef = useRef(null)

    const [state, dispatch] = useContext(globalStore);
    //const [editor] = useLexicalComposerContext();
    //const [showContextMenu, setShowContextMenu] = React.useState(false);
    const [showRedlineMenu, setShowRedlineMenu] = React.useState(false);
    const [file, setFile] = React.useState(null);
    //const [ags, setAgs] = React.useState(!isTemplating && Boolean(props.ags) ? props.ags : [])
    const [counterToSkipInitialSave, setCounterToSkipInitialSave] = React.useState(0);

    const isTemplating = ['Template'].includes(props.page)
    const docID = isTemplating ? props.template._id : props.aid
    const mainAg = isTemplating ? props.template : state.agrs.filter((a) => a.parentAID === null)[0]
    const av = isTemplating ? 
        { content: mainAg.content, versionType: 'canveo' } : // Templating Scenario
        state.avs.filter((av) => av.agrID === props.aid)[0] // Agreement Scenario

    const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id)
    const isOwner = !isAgrExec && 
      (isTemplating ||
      (!isTemplating && Boolean(mainAg) && mainAg.avOwners.some((o) => o === state.org._id)))

    const isNonPDFAttachment = !isTemplating && ['docx', 'pptx', 'xlsx'].includes(av.versionType)
    const partyID = isTemplating ? 'party1' :
      mainAg.ents.filter((e) => state.subs.some((s) => s._id === e.entID))[0] !== undefined ? 
          mainAg.ents.filter((e) => state.subs.some((s) => s._id === e.entID))[0].partyID : null

    useEffect(() => { // Load the PDF file or link to the non-PDF attachment
      if(['pdf', 'docx', 'pptx', 'xlsx'].includes(av.versionType) && av.content !== undefined &&
      av.content !== null && av.content.file !== undefined) {
        axios.get( // For PDF: Download to arraybuffer, for others - get a "viewable" temporary URL
          state.settings.api + "download/" + (isNonPDFAttachment ? "read/" : "") + av.content.file, 
          isNonPDFAttachment ? {} : {responseType: 'arraybuffer'})
        .then((res) => {
          setFile({ type: av.versionType, file: isNonPDFAttachment ? res.data.data : res.data, pageNumber: 1 })
        }).catch((err) => console.log("todo err handling", err))
      }
    }, [av])

    /* // TODO : Force Readonly - as appropriate
    useEffect(() => {

      if(!isOwner && !editor.isReadOnly()) {
        editor.setReadOnly(true);
      }

    }, [isOwner])*/

    function onDocumentLoadSuccess({ numPages }) {
      setFile({...file, numPages: numPages})
    }

    const handleDownloadFile = () => {
      if(['pdf'].includes(file.type)) {
        download(file.file, "file" + "." + file.type);
      } else { // Non-PDF attachment
        window.open(file.file, '_blank');
      }
    }

    const RenderUpDownButtons = ({size}) => {
      return (
        <>
        <Button
        size={size}
        variant={size === "large" ? "contained" : "text"}
        sx={size === "large" ? {padding: '5px 20px', fontSize: '17px', margin: '5px'} : {padding: '3px 10px'}}
        disableElevation
        onClick={handleDownloadFile}>
          Download
          &nbsp;&nbsp;<FontAwesomeIcon icon={faDownload} />
        </Button>
        <Button
        size={size}
        variant={size === "large" ? "contained" : "text"}
        sx={size === "large" ? {padding: '5px 20px', fontSize: '17px', margin: '5px'} : {padding: '3px 10px'}}
        disableElevation>
          Upload
          &nbsp;&nbsp;<FontAwesomeIcon icon={faUpload} />
        </Button>
        </>
      )
    }

    function onChange(editorState) {
      editorState.read(() => {
        //console.log(JSON.stringify(editorState.toJSON()))
        //console.log("editorState",editorState.toJSON()._nodeMap)
        //processChange(JSON.stringify(editorState.toJSON()._nodeMap))
        //console.log("x", new Date().toISOString())
        //if(counterToSkipInitialSave > 0) {
        //}

        //{ root: av.content }

        //console.log(JSON.stringify(editorState))
        //console.log(JSON.stringify({ root: av.content }))

        processChange(editorState.toJSON()) // JSON.stringify(
        //processChange(editorState.toJSON()._nodeMap);
      });
    }

    function debounce(func, timeout = 2000){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    }

    function saveEditorState(data){
      if(counterToSkipInitialSave > 0) {
        
        //console.log(JSON.stringify(data))
        /*
        let saveRoute = state.settings.api + (isTemplating ? 'template' : 'agrv') + '/newcontent/' + (isTemplating ? props.template._id : av._id)
        axios.put(saveRoute, { newContent: data })
        .then((res) => {
          if(!res.data.success) { setSnackOpen("An error occurred while saving the updates"); }
        }).catch((err) => setSnackOpen("An error occurred while saving the updates"))*/

      }
      setCounterToSkipInitialSave(counterToSkipInitialSave + 1);
    }

    const processChange = debounce((data) => saveEditorState(data));

    const handleTitleUpdate = (id, newVal) => {

      let newItem = isTemplating ? props.template : state.agrs.filter((a) => a._id === id)[0];

      if(Boolean(newVal) && newVal.length > 2 && Boolean(newItem) && newItem.agrTitle !== undefined && newItem.agrTitle !== newVal) {

        newItem.agrTitle = newVal;
        newItem.lastUpdateBy = state.user._id;

        axios.put(state.settings.api + (isTemplating ? 'template/' : 'agr/') + id, isTemplating ? { template: newItem } : { agr: newItem })
        .then((res) => {
          if(res.data.success) {
            if(!isTemplating) { // Update local ags array with new title
              /*
              let newAgs = ags;
              let idx = newAgs.findIndex((na) => na._id === id)
              newAgs[idx] = res.data.data
              setAgs(newAgs);*/
              dispatch({ type: "UPDATE_AGR", payload: res.data.data})
            }
            dispatch({ type: "NEW_SNACKBAR", payload: "The title was succesfully updated"})
          } else { dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the title"}) }
        }).catch((err) => dispatch({ type: "NEW_SNACKBAR", payload: "An error occurred while updating the title"}))

      }

    }

    return (

      <Box sx={{display: 'flex', justifyContent: 'center',
      width: 'calc(100%-85px)', margin: '0px', marginLeft: '85px', padding: '0px'/*, backgroundColor: '#ffeeff'*/ }}>   

        {// Show the Header / Drawer for any non-Canveo Doc - for Canveo docs, the header will be part of the editor context
        !['canveo'].includes(av.versionType) ? 
        <>
        <Header page={props.page} mainAg={mainAg} isOwner={isOwner} />
        <CoreDrawer page={props.page} template={props.template} />
        </> 
        :''}

        {// If a later version is owned by the counterparty, show a "lock box" to the current User
        !isOwner && !isAgrExec && !['Execution', 'InForce'].includes(mainAg.agrStatus) ?
        <Box sx={{
          position: 'fixed',
          zIndex: '100',
          right: '20px',
          bottom: '20px',
          width: '200px',
          borderRadius: '20px',
          padding: '30px 20px 25px 20px',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 24px 0px',
          backgroundColor: 'rgba(255,255,255,0.7)', 
          backdropFilter: 'blur(10px)',
        }}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              <FontAwesomeIcon icon={faLockKeyhole} style={{color: theme.palette.grey[800], fontSize: '26px'}} />
            </Grid>
            <Grid item sx={{mt: 2, mb: 1}}>
              <Typography variant="body2" color="textSecondary" align="center">
              Latest version owned by
              {mainAg.avOwners.map((avo,i) => {
                let legalName = Boolean(state.parties) && Boolean(state.parties.filter((p) => p.orgID === avo)[0]) ?
                  state.parties.filter((p) => p.orgID === avo)[0].legalName : 'Unknown entity';

                return (<React.Fragment key={i}><br/>- {trunc(legalName,20)}</React.Fragment>)
              })}
              </Typography>
            </Grid>
            <Grid item>
              <Button size="small">
                Send Message
              </Button>
              <Button size="small">
                Retrieve
              </Button>
            </Grid>
          </Grid>
        </Box>
        :''}

        <Grid container direction="column" alignItems="center">
          <Grid item sx={styles.titleBase}>
            {!isOwner ?
            <Typography align="center" style={{
              fontWeight: '700',
              fontSize: '44px',
              padding: '2px 0px 5px 0px'
            }}>{mainAg.agrTitle}</Typography>
            :
            <InputBase
            defaultValue={mainAg.agrTitle}
            onBlur={e => handleTitleUpdate(isTemplating ? props.template._id : props.aid, e.target.value)}
            style={styles.agrTitleInput}
            inputProps={{style: { textAlign: 'center' }}}
            multiline
            />
            }
          </Grid>
          
          {isAgrExec && ['Execution'].includes(mainAg.agrStatus) ?
          <Grid item sx={{my:2}}>

            <AgrExecBox />

          </Grid>
          :''}

          <Grid item sx={{my:2}}>

            <AgrExhibitList
            ags={isTemplating ? [props.template] : state.agrs}
            avs={isTemplating ? [] : state.avs}
            aid={isTemplating ? props.template._id : props.aid}
            isOwner={isOwner}
            isTemplating={isTemplating}
            handleTitleUpdate={handleTitleUpdate}
            />

          </Grid>
          <Grid item container justifyContent="center" sx={{mt:2, width: '100%'}}>
            { // FILE ATTACHMENT DOWN- AND UPLOAD
            Boolean(av) && Boolean(av.content) &&
            ['docx', 'pptx', 'xlsx'].includes(av.versionType) && file !== null ? 
            <div>

              <Grid container direction="column" alignItems="center">
                <Grid item sx={{my:2}}>
                  <img alt={av.versionType} width={70}
                  style={{cursor: 'pointer'}}
                  onClick={handleDownloadFile}
                  src={
                    ['pptx'].includes(av.versionType) ? pptx :
                    ['xlsx'].includes(av.versionType) ? xlsx : docx}  />
                </Grid>
                <Grid item sx={{mt: 2}}>
                  <Typography><b>Reference:</b> {av.content.file}</Typography>
                  <Typography><b>Upload on:</b> {av.content.creationDate}</Typography>
                  <Typography><b>Upload by:</b> {av.content.creationBy}</Typography>
                </Grid>
                <Grid item sx={{my:4}}>
                  <RenderUpDownButtons size="large" />
                </Grid>
              </Grid>
            </div>
            :
            // PDF ATTACHMENT DISPLAY
            ['pdf'].includes(av.versionType) && file !== null ? 
            <div>
              <Document
              file={file.file}
              onLoadSuccess={onDocumentLoadSuccess}>
                <div>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{mb:1}}>
                    <Grid item>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <IconButton
                          sx={{fontSize: '22px', marginRight: '15px'}}
                          onClick={e => setFile({...file, pageNumber: file.pageNumber - 1})}
                          disabled={file.pageNumber < 2}>
                            <FontAwesomeIcon icon={faCircleCaretLeft} />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <Typography className="bold">
                          {file.pageNumber} / {file.numPages}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                          sx={{fontSize: '22px', marginLeft: '15px'}}
                          onClick={e => setFile({...file, pageNumber: file.pageNumber + 1})}
                          disabled={file.pageNumber >= file.numPages}>
                            <FontAwesomeIcon icon={faCircleCaretRight} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item><RenderUpDownButtons size="medium" /></Grid>
                  </Grid>
                </div>
                <div style={{border: '1px solid' + theme.palette.grey[200]}}>
                  <Page pageNumber={file.pageNumber} width={900} />
                </div>
                
              </Document>
            </div>
            : // CANVEO DOC (WITH EDITOR)
            ['canveo'].includes(av.versionType) && 
            partyID !== undefined && partyID !== null // We know the party ref of the current users' entity
            ?
            <div ref={containerWithScrollRef}>
              <LexicalComposer 
              initialConfig={{...editorConfig, readOnly: isTemplating && props.template.active}}
              //</div>initialEditorState={JSON.stringify({ _nodeMap: av.content, _selection: null })}
              >
                
                <Header 
                page={props.page} 
                mainAg={mainAg} 
                isOwner={isOwner} 
                toolbar={<ToolbarPlugin />} 
                templateVersion={isTemplating ? <TemplateVersionPlugin template={props.template} /> : null}
                //templateVersions={} 
                />

                <CoreDrawer 
                page={props.page} 
                template={props.template} 
                toc={<TocPlugin />} 
                definitions={<DefinitionsPlugin />} 
                />

                <div className="editor-container">
                  <RichTextPlugin
                    contentEditable={<ContentEditable className="editor-input" spellCheck={false} />}
                    initialEditorState={JSON.stringify(av.content)}
                    //initialEditorState={}
                    //initialEditorState={JSON.stringify({ _nodeMap: av.content, _selection: null })}
                  />
                  <HistoryPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <AutoLinkPlugin />
                  <AutoScrollPlugin scrollRef={containerWithScrollRef} />
                  <ListMaxIndentLevelPlugin maxDepth={2} />
                  <RedlinePlugin partyID={partyID} />
                  <WorkflowPlugin partyID={partyID} isTemplating={isTemplating} docID={docID} />
                  <ClausePlugin partyID={partyID} />
                  <OnChangePlugin onChange={onChange} ignoreSelectionChange />
                </div>
              </LexicalComposer>
            </div>
            :
            <CanveoCircularProgress />
            }
          </Grid>
        </Grid>
      </Box>
    );
  
};
export default Editor;