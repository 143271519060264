const paramsReducer = (state, action) => {

    switch (action.type) {
        case "INIT_PARAMS_LIB":
            return { params: { lib: action.payload, doc: state.params.doc } };

        case "INIT_PARAMS_DOC":
            return { params: { lib: state.params.lib, doc: action.payload } };

        case "ADD_PARAM": // action.payload.type AND action.payload.item

            return {
                params: ['lib'].includes(action.payload.type) ?
                {
                    lib: [...state.params.lib, action.payload.item],
                    doc: state.params.doc
                } 
                :
                {
                    lib: state.params.lib,
                    doc: [...state.params.doc, action.payload.item]
                }
            }
        
        case "DELETE_PARAM": // action.payload.type AND action.payload.item

            let newLib = [], newDoc = []
            if(['lib'].includes(action.payload.type)) {
                newLib = state.params.lib.filter((li) => li._id !== action.payload.item._id);
                newDoc = state.params.doc;
            } else {
                newLib = state.params.lib;
                newDoc = state.params.doc.filter((di) => di._id !== action.payload.item._id);
            }
            return { params: { lib: newLib, doc: newDoc } };

        case "UPDATE_PARAM": // action.payload.type AND action.payload.item
            let newVal = action.payload.item
            let newLibItems = state.params.lib;
            let newDocItems = state.params.doc;
            if(['lib'].includes(action.payload.type)) {
                let idx = state.params.lib.findIndex((li) => li._id === newVal._id)
                if(idx > -1) { newLibItems[idx] = newVal }
            } else {
                let idx = state.params.doc.findIndex((di) => di._id === newVal._id)
                if(idx > -1) { newDocItems[idx] = newVal }
            }
            return { params: { lib: newLibItems, doc: newDocItems } };

        default:
            return { params: { lib: state.params.lib, doc: state.params.doc } }
            
    }
};
  
export default paramsReducer