import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './index.css';
import theme from "./theme/theme";
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { StateProvider } from './state/store';

import Main from './Main';
import { BrowserRouter } from "react-router-dom";
//import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StateProvider>
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Main />
      </BrowserRouter>
    </MuiThemeProvider>
  </StateProvider>
);

//  <React.StrictMode>  </React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
