import React, { useContext } from 'react';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $setSelection, $createTextNode } from "lexical";
import { $createRedlineNode } from './editor/nodes/RedlineNode';
import { globalStore } from '../state/store';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faCircleLeft, faCircleRight } from '@fortawesome/pro-solid-svg-icons';
import theme from '../theme/theme';

const RedlineMenu = (props) => {

  const styles = {
      iconB: {
        display: 'block',
        color: theme.palette.grey[700],
        fontSize: '22px',
      },
      iconBsuc: {
        display: 'block',
        color: theme.palette.success.main,
        fontSize: '22px',
      },
      iconBerr: {
        display: 'block',
        color: theme.palette.error.main,
        fontSize: '22px',
      },
  }

  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  /*const newRedline = {
    creator: state.user.displayName,
    date: new Date().toISOString(),
  }*/

  const creationDate = new Date().toISOString();

  const handleClick = (clickType) => {
    editor.update(() => {

      const selection = $getSelection();
      const isBackward = selection.isBackward();

      const startKey = isBackward ? selection.focus.key : selection.anchor.key;
      const endKey = isBackward ? selection.anchor.key : selection.focus.key;

      const isCollapsed = selection.isCollapsed();

      if(['accept', 'reject'].includes(clickType) && !isCollapsed) {
        const isBackward = selection.isBackward();

        const startKey = isBackward ? selection.focus.key : selection.anchor.key;
        const startOffset = isBackward ? selection.focus.offset : selection.anchor.offset;
        const endKey = isBackward ? selection.anchor.key : selection.focus.key;
        const endOffset = isBackward ? selection.anchor.offset : selection.focus.offset;

        selection.getNodes().forEach((node) => {
          if(['redline'].includes(node.getType())) {
            
            const coversEntireNode = (startKey !== node.getKey() || startOffset === 0) && (endKey !== node.getKey() || endOffset >= node.getTextContent().length)
            const selectionStartPos = coversEntireNode || startKey !== node.getKey() || startOffset === 0 ? 0 : startOffset
            const selectionEndPos = coversEntireNode || endKey !== node.getKey() || endOffset >= node.getTextContent().length ? node.getTextContent().length : endOffset
            const textAffected = node.getTextContent().substring(selectionStartPos, selectionEndPos)
            const isTwoSided = selectionStartPos > 0 && selectionEndPos < node.getTextContent().length

            const isXadd = (['reject'].includes(clickType) && ['add'].includes(node.getRedlineType()) && node.getPartyID() !== props.partyID)
            const isXdel = (['reject'].includes(clickType) && ['del'].includes(node.getRedlineType()) && node.getPartyID() !== props.partyID)
            const isBackToAdd = (['reject'].includes(clickType) && ['xadd'].includes(node.getRedlineType()))
            const isBackToDel = (['reject'].includes(clickType) && ['xdel'].includes(node.getRedlineType()))

            if( // Accept cpty add OR Reject own del OR Accept xdel - Replace redline by a regular textNode
              (['accept'].includes(clickType) && ['add'].includes(node.getRedlineType())) ||
              (['reject'].includes(clickType) && ['del'].includes(node.getRedlineType()) && node.getPartyID() === props.partyID) ||
              (['accept'].includes(clickType) && ['xdel'].includes(node.getRedlineType()))) { 

              const newNode = $createTextNode(textAffected);
              overwriteRedlineWithNewNode(coversEntireNode, selectionStartPos, selectionEndPos, isTwoSided, node, newNode);

            } else if( // Accept cpty del OR Reject own add OR Accept xadd - Remove entire node
              (['accept'].includes(clickType) && ['del'].includes(node.getRedlineType())) ||
              (['reject'].includes(clickType) && ['add'].includes(node.getRedlineType()) && node.getPartyID() === props.partyID) ||
              (['accept'].includes(clickType) && ['xadd'].includes(node.getRedlineType()))) {

              overwriteRedlineWithNewNode(coversEntireNode, selectionStartPos, selectionEndPos, isTwoSided, node, 'remove');

            } else if(
            isXadd || // Reject cpty add - replace redline to 'xadd'
            isXdel || // Reject cpty del - replace redline to 'xdel'
            isBackToAdd || // Reject 'xadd' - revert to regular add
            isBackToDel) { // Reject 'xadd' - revert to regular add
              
              let redlineType = 
                      isXadd ? 'xadd' :
                      isXdel ? 'xdel' :
                      isBackToAdd ? 'add' :
                      isBackToDel ? 'del' : null;
              let partyID = 
                      isXadd || isXdel ? (node.getPartyID() + "_" + props.partyID) : 
                      isBackToAdd || isBackToDel ? (node.getPartyID().substr(0, node.getPartyID().indexOf('_'))) : null;

              let newNode = $createRedlineNode({
                date: creationDate,
                creator: state.user.displayName,
                redlineType: redlineType, 
                partyID: partyID, 
                text: textAffected })

              overwriteRedlineWithNewNode(coversEntireNode, selectionStartPos, selectionEndPos, isTwoSided, node, newNode);

            }
          }
        })
      }


      // Find first, last, prev and next redline
      let firstRedline = null, lastRedline = null, nextRedline = null, prevRedline = null, foundStartKey = false, foundEndKey = false;
      const nodeMapArray = [...editor.getEditorState()._nodeMap];
      const root = nodeMapArray.filter((n) => n[1].getType() === 'root')[0];
      const isFinalRedline = nodeMapArray.filter((n) => n[1].getType() === 'redline').length === 1;

      // Loop through all clauses
      root[1].__children.forEach((clauseKey) => {
        let clause = Boolean(nodeMapArray.filter((nma) => nma[0] === clauseKey)[0]) ? 
                        nodeMapArray.filter((nma) => nma[0] === clauseKey)[0][1] : null

        if(Boolean(clause)) {
          console.log("clause", clause)
          if(Boolean(clause.__children) && clause.__children.length > 0) {

            /*analyzeChildrenForRedlines = (children, nodeMapArray, startKey, endKey, foundStartKey, foundEndKey, 
              firstRedline, lastRedline, prevRedline, nextRedline)*/

            ({foundStartKey, 
              foundEndKey, 
              firstRedline, 
              lastRedline, 
              prevRedline, 
              nextRedline } = analyzeChildrenForRedlines(
                                  clause.__children, 
                                  nodeMapArray,
                                  startKey, 
                                  endKey, 
                                  isCollapsed,
                                  foundStartKey, 
                                  foundEndKey, 
                                  firstRedline, 
                                  lastRedline, 
                                  prevRedline, 
                                  nextRedline))
          }




          
        }
      })

      console.log("firstRedline", firstRedline)
      console.log("prevRedline", prevRedline)
      console.log("nextRedline", nextRedline)
      console.log("lastRedline", lastRedline)


      // Define the new selection
      let newSelection = selection;
      let clickedItem = 
        ['accept', 'reject'].includes(clickType) && isFinalRedline ? null :
        ['next', 'accept', 'reject'].includes(clickType) && Boolean(nextRedline) ? nextRedline :
        ['next', 'accept', 'reject'].includes(clickType) && Boolean(firstRedline) ? firstRedline : 
        ['prev'].includes(clickType) && Boolean(prevRedline) ? prevRedline :
        ['prev'].includes(clickType) && Boolean(lastRedline) ? lastRedline : null

      if(Boolean(clickedItem)) {
        newSelection.anchor.key = clickedItem.__key
        newSelection.anchor.offset = 0
        newSelection.focus.key = clickedItem.__key
        newSelection.focus.offset = clickedItem.__text.length;
      } else {
        newSelection = null;
      }

      $setSelection(newSelection);

      if(Boolean(newSelection)) {
        console.log("newSelection.getNodes()", newSelection.getNodes())
        const element = editor.getElementByKey(newSelection.anchor.key)
        element.scrollIntoView({ block: 'center'});
      }

    })
  }

  const analyzeChildrenForRedlines = (children, nodeMapArray, startKey, endKey, isCollapsed, foundStartKey, foundEndKey, 
    firstRedline, lastRedline, prevRedline, nextRedline) => {

    // Loop through all children of clauses
    children.forEach((nodeKey) => {
      let node = Boolean(nodeMapArray.filter((nma) => nma[0] === nodeKey)[0]) ? 
                    nodeMapArray.filter((nma) => nma[0] === nodeKey)[0][1] : null

      if(node.__key === startKey) { foundStartKey = true }
      if(node.__key === endKey) { foundEndKey = true }
      
      // Define first, last, prev and next redline as appropriate
      if(Boolean(node) && ['redline'].includes(node.__type)) {
        if(!Boolean(firstRedline) && !foundStartKey) { firstRedline = node } // First Redline
        if(!foundStartKey) { prevRedline = node } // Previous Redline
        if(foundEndKey && (isCollapsed || node.__key !== endKey) && !Boolean(nextRedline)) { nextRedline = node } // Next Redline
        if(foundEndKey && node.__key !== endKey) { lastRedline = node; } // Last Redline

      } else if(Boolean(node.__children) && node.__children.length > 0) {
        ({foundStartKey, 
          foundEndKey, 
          firstRedline, 
          lastRedline, 
          prevRedline, 
          nextRedline } = analyzeChildrenForRedlines(
                              node.__children, 
                              nodeMapArray,
                              startKey, 
                              endKey, 
                              isCollapsed,
                              foundStartKey, 
                              foundEndKey, 
                              firstRedline, 
                              lastRedline, 
                              prevRedline, 
                              nextRedline))
      }
    })

    return {
      foundStartKey, foundEndKey, firstRedline, lastRedline, prevRedline, nextRedline
    }
  }

  const overwriteRedlineWithNewNode = (coversEntireNode, selectionStartPos, selectionEndPos, isTwoSided, node, newNode) => {

    console.log("overwriteRedlineWithNewNode", coversEntireNode, selectionStartPos, selectionEndPos, isTwoSided, node, newNode)

    if(coversEntireNode && newNode === 'remove') {
      node.remove();  // TODO => Merge before and after the node back into one another (if possible)
      console.log("x1")

    } else if(coversEntireNode){
      node.replace(newNode); // TODO => Instead of just replacing, should also merge back into the paragraph
      console.log("x2")

    } else {
      console.log("x3a")
      let newRedlineNodeTextPt1 = node.getTextContent().substring(0, selectionStartPos)
      let newRedlineNodeTextPt2 = node.getTextContent().substring(selectionEndPos)
      const updatedRedlineNode = $createRedlineNode({
        date: node.getDate(),
        creator: node.getCreator(),
        redlineType: node.getRedlineType(),
        partyID: node.getPartyID(),
        text: newRedlineNodeTextPt1 + (isTwoSided && newNode !== 'remove' ? "" : newRedlineNodeTextPt2)
      })
      console.log("x3b", updatedRedlineNode)

      node.replace(updatedRedlineNode)

      console.log("x3c")

      if(newNode !== 'remove') {
        console.log("x4a")
        let newSelection = $getSelection();
        console.log("x4b")
        newSelection.anchor.key = updatedRedlineNode.getKey();
        newSelection.anchor.offset = selectionStartPos;
        newSelection.focus = newSelection.anchor;
        newSelection.insertNodes([newNode])
        console.log("x4c")
  
        if(isTwoSided) { // If the selection was in the middle of the redline, you'd need to add the second part of the previous redline
          console.log("x5a")
          const additionalRedlineNode = $createRedlineNode({
            date: node.getDate(),
            creator: node.getCreator(),
            redlineType: node.getRedlineType(),
            partyID: node.getPartyID(),
            text: newRedlineNodeTextPt2
          })
          console.log("x5b")
          let newSelection = $getSelection();
          newSelection.anchor.key = newNode.getKey();
          newSelection.anchor.offset = newNode.getTextContent().length;
          newSelection.focus = newSelection.anchor;
          newSelection.insertNodes([additionalRedlineNode])
          console.log("x5c")
        }
      }
    }
  }

  return (
    <div style={props.visible ? {} : {display: 'none'}}>
        <Grid container direction="column" alignItems="center"
        sx={{
            width: '68px',
            position: 'fixed',
            right: '20px',
            bottom: '20px',
            borderRadius: '30px',
            backgroundColor: 'rgba(255,255,255,0.7)', 
            backdropFilter: 'blur(10px)',
            zIndex: '1000',
            padding: '13px 8px',
            border: '1px solid' + theme.palette.grey[200],
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 24px 0px',
        }}>
        {[
        { title: "Accept Change", icon: faCheckCircle, style: styles.iconBsuc, clickType: 'accept' },
        { title: "Reject Change", icon: faTimesCircle, style: styles.iconBerr, clickType: 'reject' },
        { title: "Previous Change", icon: faCircleLeft, style: styles.iconB, clickType: 'prev' },
        { title: "Next Change", icon: faCircleRight, style: styles.iconB, clickType: 'next' },
        ].map((b, i) => (
        <Grid item key={i}>
        <Tooltip title={b.title} placement={"left"}>
        <IconButton sx={b.style} onMouseDown={e => { 
          e.preventDefault();
          handleClick(b.clickType);
        }}>
            <FontAwesomeIcon icon={b.icon} style={{padding: '3px'}} />
        </IconButton>  
        </Tooltip>
        </Grid>
        ))
        }
        </Grid>
    </div>
  );
};
export default RedlineMenu;