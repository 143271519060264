const templatesReducer = (state, action) => {

    switch (action.type) {
        case "INIT_TEMPLATES":
            return { templates: action.payload };
        case "ADD_TEMPLATE":
            return {
                templates: [...state.templates, action.payload]
            };
        case "UPDATE_TEMPLATE":
            let newVal = action.payload;
            let newTemplates = state.templates;
            let idx = state.templates.findIndex((item) => item._id === newVal._id);
            if(idx > -1){
                newTemplates[idx] = newVal;
            }
            return { templates: newTemplates };
        case "UPDATE_TEMPLATES_FOR_BPID":
            let bpid = action.payload.bpid;
            let tid = action.payload.tid;
            let newForBP = action.payload.templates;
            let filteredTemplates = state.templates.filter((t) => t.blueprintID !== bpid || t._id === tid); // Remove any existing ones for the BP, EXCEPT current
            return { templates: filteredTemplates.concat(newForBP) };
        default:
            return { templates: state.templates }
    }
};
  
export default templatesReducer