function getMonthForMM(mm) {

    let s = 
        mm === '01' ? "January" : 
        mm === '02' ? "February" : 
        mm === '03' ? "March" : 
        mm === '04' ? "April" : 
        mm === '05' ? "May" : 
        mm === '06' ? "June" : 
        mm === '07' ? "July" : 
        mm === '08' ? "August" : 
        mm === '09' ? "September" : 
        mm === '10' ? "October" : 
        mm === '11' ? "November" : 
        mm === '12' ? "December" : "January"
    
    return s

}

export { getMonthForMM }