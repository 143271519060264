import React, { useContext } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { globalStore } from '../state/store';

import { Avatar, Box, Grid, Skeleton, Typography } from "@mui/material";
import { FabStandard, Header, UploadImg } from '../components';
import { UserForm } from '../components/forms';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

const Account = () => {

  const navigate = useNavigate();
  const [state, dispatch] = useContext(globalStore);
  const [errMsg, setErrMsg] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = (child) => {
    setLoading(true)
    let updatedUser = state.user
    updatedUser.title = child.title.trim();
    updatedUser.email = child.email.trim().toLowerCase();
    updatedUser.firstName = child.firstName.trim();
    updatedUser.lastName = child.lastName.trim();
    updatedUser.phone = '+' + child.phone.trim();
    axios.put(state.settings.api + "user/" + updatedUser._id, { user: updatedUser })
    .then((resUser) => {
      if(resUser.data.success) {
        dispatch({ type: "UPDATE_USER", payload: resUser.data.data }) // Update State
        setLoading(false);
      } else { setErrMsg("An error occured while updating your profile - refresh your browser"); setLoading(false); }
    }).catch((err) => { setErrMsg("An error occured while updating your profile"); setLoading(false); })
  }

  const handleSubmitImage = (child) => {
    setLoading(true);
    setErrMsg(null)
    if(child) { // A new photoURL was submitted
      let updatedUser = state.user
      updatedUser.photoURL = child;
      axios.put(state.settings.api + "user/" + updatedUser._id, { user: updatedUser })
      .then((resUser) => {
        if(resUser.data.success) {
          dispatch({ type: "UPDATE_USER", payload: resUser.data.data }); // Update State
          setLoading(false);
        } else { setErrMsg("An error occured while updating the user - refresh your browser"); setLoading(false); }
      }).catch((err) => { setErrMsg("An error occured while updating your avatar"); setLoading(false); })
    } else { setErrMsg("An error occured while updating your avatar"); setLoading(false); }
  }

  return (
    <div>

      <Header page={"Account"} />

      <FabStandard text={"Home"} icon={faHome} click={e => navigate("/dashboard")} />

      <Box sx={{ my: 8, mx: 3 }}>
        {loading ?
        <Grid container direction="column" alignItems="center">
          <Grid item sx={{mt: 8, mb: 8}}>
            <Skeleton variant="circular" width={120} height={120} />
          </Grid>
          <Grid item sx={{my:1}}>
            <Skeleton variant="text" width={150} height={30} />
          </Grid>
          <Grid item sx={{my:1}}>
            <Skeleton variant="text" width={300} height={50} />
          </Grid>
          <Grid item sx={{my:1}}>
            <Skeleton variant="text" width={300} height={50} />
          </Grid>
          <Grid item sx={{my:1}}>
            <Skeleton variant="text" width={300} height={50} />
          </Grid>
          <Grid item sx={{my:1}}>
            <Skeleton variant="text" width={300} height={50} />
          </Grid>
        </Grid>
        :
        <Grid container direction="column" alignItems="center">
          <Grid item sx={{mt: 4, mb: 2}}>
            {/*<Avatar src={state.user.photoURL} sx={{width: '100px', height: '100px'}} />*/}
            <UploadImg 
            type="avatar" 
            handleSubmitImage={handleSubmitImage}
            initialImg={state.user.photoURL} />
          </Grid>
          {errMsg !== null ?
          <Grid item sx={{mb:4}}>

            <Typography color="error" align="center" variant="subtitle1">{errMsg}</Typography>

          </Grid>
          :''}
          <Grid item>
            <UserForm
            selectRole={false}
            handleSubmit={handleSubmit}
            hideFields={['readOnly']}
            disableFields={['email']}
            initialValues={{
                title: state.user.title,
                email: state.user.email,
                firstName: state.user.firstName,
                lastName: state.user.lastName,
                phone: state.user.phone,
                role: state.user.role,
                readOnly: state.user.readOnly,
            }}
            />
          </Grid>
        </Grid>
        }
      </Box>

    </div>
  );
};
export default Account;