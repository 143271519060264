export function getClauseNode(node) {
    let n = null;
    if(node.getType() === 'root') {
      n = null
    } else if(node.getType() === 'clause') {
      n = node;
    } else if(Boolean(node.getParent())){
      n = getClauseNode(node.getParent());
    }
    return n;
}